import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { Router} from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-intermediate',
  templateUrl: './intermediate.component.html',
  styleUrls: ['./intermediate.component.css']
})
export class IntermediateComponent implements OnInit {
  copilotData: any;
  capture : string;
  constructor(private router: Router,private commonService: CommonService,private authenticationService: AuthService) { }

  ngOnInit(): void {
    //this.copilotData = this.authenticationService.getCopilotForLogin();
    this.commonService.switchAccount().subscribe((response) =>  {
      this.copilotData=response;
    });
  }
  radioClicked(evt) {
    this.capture = evt.target.value;
  }
  routeNavigation() {
    if(this.capture === 'user') {
      if(localStorage.getItem('temp') && localStorage.getItem('currentUser')) {
        localStorage.removeItem('currentUser');
        localStorage.setItem('currentUser',localStorage.getItem('temp'));
      }
      
      this.router.navigate(['/page']);
    } else {
      localStorage.setItem('userId',this.capture);
      let formData = new FormData();
      formData.append('user_id',this.capture); 
      this.commonService.getCopilotToken(formData);
    }
    
      /* .subscribe((response) =>  {
        console.log(response);
          this.router.navigate(['/page']);
      }); */
  }
}
