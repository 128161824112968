<div class="content-wrapper pd-10 bg-white">
    <div class="content-header row border-bottom">
        <div class="content-header-left col-md-12 col-12 mb-2 pl-0 pr-0 pr-0">
            <h3 class="content-header-title font-22 bold-font">
                <i class="yhyl-text icon-width fas fa-info text-center mr-3"></i>
                <span class="yhyl-text pointer" (click)="navigateMenu('main-info')">MY INFORMATION&nbsp;&nbsp;</span>
                <i class="fa fa-angle-right grey-color" aria-hidden="true"></i>
                <i class="fas fa-1x title-icon-style fa-house-user home-color"></i>
                <span class="home-color font-16 pointer" (click)="navigateMenu('main-home')">MY HOME&nbsp;&nbsp;</span>
                <span class="home-color font-16" *ngIf="activeHealthSubTab === 'homechoices'"><i
                        class="fa fa-angle-right grey-color" aria-hidden="true"></i>&nbsp;&nbsp;HOME CHOICES</span>
            </h3>
            <div class="temp" *ngIf="isMainPage">
                <!--Find accurate, reliable and practical information on the "need to know" issues in longevity and ageing. You told us what you need: we sourced it and put it all at your fingertips!-->
                <br />
                <div class="sub-content">
                    <p class="home-color font-20 bold-font">My (Best) Home: Decluttering, Downsizing, Rightsizing and
                        Moving</p>
                    <p class="grey-color">Is your home a perfect fit for you? Is it your “Best Home”?
                        Our definition of "Best Home" is simple: the right home, at the right place, at the right
                        time, and at the right cost<sup>TM</sup> – whatever your age. Are you living in your "Best
                        Home"? Find all the practical information you need to Simplify, Plan, and Manage from start
                        to finish - “ Your Best Home”.</p>
                </div>
            </div>
            <div class="row mt-5" *ngIf="isMainPage">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="card collapse show">
                        <div class="card-body">
                            <div class="card-text text-center">
                                <div class="row">
                                    <div class="col-lg-2 col-m-12 col-sm-12">
                                        <img class="card-img-top img-fluid info-img"
                                            src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-health-resources.png"
                                            alt="can't load">
                                    </div>
                                    <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                                        <p class="grey-color bold-font font-20">HOME CHOICES</p>
                                        <p>It doesn’t
                                            have to be so hard. There are now more options to help make your own home
                                            your 'best home' as you age. Let us introduce you to some<a>(Click
                                                here)</a>. Plus we have
                                            designed the best guidance you'll find to help you decide
                                            whether, when, where, and how to downsize. <b><em>COMING SOON!</em></b> The
                                            Baby Boomer's
                                            Guide to Downsizing!
                                        </p>
                                        <button type="submit"
                                            class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn"
                                            (click)="infoHomeSubTab('homechoices')">Read
                                            More
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="card collapse show">
                        <div class="card-body">
                            <div class="card-text text-center">
                                <div class="row">
                                    <div class="col-lg-2 col-m-12 col-sm-12">
                                        <img class="card-img-top img-fluid info-img"
                                            src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-mental-health.png"
                                            alt="can't load">
                                    </div>
                                    <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                                        <p class="grey-color bold-font font-20">RIGHTSIZING, NOT DOWNSIZING</p>
                                        <p>Is your home a perfect fit for you? Is it your “Best Home”?
                                            Our definition of "Best Home" is simple: the right home, at the right place,
                                            at the right
                                            time, and at the right cost<sup>TM</sup> – whatever your age. Are you living
                                            in your "Best
                                            Home"? Find all the practical information you need to Simplify, Plan, and
                                            Manage from start
                                            to
                                            finish - “ Your Best Home”.
                                        </p>
                                        <button type="submit"
                                            class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">Read
                                            More
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="card collapse show">
                        <div class="card-body">
                            <div class="card-text text-center">
                                <div class="row">
                                    <div class="col-lg-2 col-m-12 col-sm-12">
                                        <img class="card-img-top img-fluid info-img"
                                            src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-medication.png"
                                            alt="can't load">
                                    </div>
                                    <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                                        <p class="grey-color bold-font font-20">“DECLUTTERING” - DO IT YOUR WAY</p>
                                        <p>Decluttering and downsizing are constantly on the mind of many seniors.
                                            Decluttering is
                                            scary, emotional, and we usually postpone it. Check out our wide range of
                                            decluttering methods.
                                            It doesn’t have to be extreme, exhausting
                                            or depressing. We've explored the different approaches and can help you pick
                                            the one that's
                                            best for you. That's <i>Ageing - Personalised&trade;</i>. <a
                                                (click)="infoHomeSubTab('decluttering')">(Click here)</a>
                                        </p>
                                        <button type="submit"
                                            class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">Read
                                            More
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="card collapse show">
                        <div class="card-body">
                            <div class="card-text text-center">
                                <div class="row">
                                    <div class="col-lg-2 col-m-12 col-sm-12">
                                        <img class="card-img-top img-fluid info-img"
                                            src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-checklists.png"
                                            alt="can't load">
                                    </div>
                                    <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                                        <p class="grey-color bold-font font-20">SMART HOME / SAFE HOME</p>
                                        <p>A smart home is a safe and convenient home. We have sourced a huge range of
                                            resources to
                                            make your home safer and easier as you age in place. <em><b>COMING
                                                    SOON!</b></em>
                                        </p>
                                        <button type="submit"
                                            class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">Read
                                            More
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="card collapse show">
                        <div class="card-body">
                            <div class="card-text text-center">
                                <div class="row">
                                    <div class="col-lg-2 col-m-12 col-sm-12">
                                        <img class="card-img-top img-fluid info-img"
                                            src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-checklists.png"
                                            alt="can't load">
                                    </div>
                                    <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                                        <p class="grey-color bold-font font-20">HOME FINANCE</p>
                                        <p>Financing your home has been revolutionalised - and not just by low interest
                                            rates. For people who are retired, however, the financing options are
                                            usually different and more limited. We do not offer financial advice, but we
                                            do suggest you check with your accountant or financial adviser because at
                                            least one category, the 'reverse mortgage' (drawing down on your home equity
                                            for living expenses) is changing.
                                        </p>
                                        <button type="submit"
                                            class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">Read
                                            More
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!isMainPage" id="subpageReading">
                <div class="temp" *ngIf="activeHealthSubTab === 'homechoices'">
                    <!--Find accurate, reliable and practical information on the "need to know" issues in longevity and ageing. You told us what you need: we sourced it and put it all at your fingertips!-->
                    <br />
                    <div class="sub-content">
                        <p class="home-color font-20 bold-font">HOME CHOICES</p>
                        <p class="grey-color">Our definition of 'Best Home' is simple: the right home, at the right
                            place, at the right time, and at the right costTM – whatever your age. Are you living your
                            "Best Home"?
                            Until recently options for living and enjoying our ‘second half’ of life have been extremely
                            limited: over ‘55’s (‘retirement living’) and ‘aged care’ (nursing home), with living
                            seaside for part or all of the year the right mix for some.
                            Things are changing rapidly. Here’s a summary of different options with links to Pro’s and
                            Cons for each. As the housing options for seniors evolves, we’ll be adding more. But first
                            we’ve compiled a short questionnaire to make it simple and painless for you – and your loved
                            ones – to identify what options are best for you. Your Best Home today, and help planning
                            for tomorrow. <i>Ageing - Personalised&trade;</i>. <b><em>COMING SOON!</em></b>
                            <br /><br />
                            <b class="font-18">
                                <i>'Ageing at Home'</i></b>
                            <br /><br />
                            Most seniors in Australia want to remain in their own homes forever. Or at least until such
                            point as they can no longer be assured of the safety of their own home. That includes a lot
                            of seniors who want to move closer to their family, especially to their adult children and
                            grandchildren.
                            <br /><br />
                            The rise and rise of the Baby Boomers is generating a new category, commonly known as
                            “Intentional Communities”. Intentional communities are literally communities created by
                            those who want different and more than the usual. The types are only limited by imagination
                            and determination of those who are creating them.
                            <br /><br />
                            In this section we highlight a range of options already in existence and in use by
                            Australians, with reference to some overseas models that may eventually be adapted here:
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. The Hub model, pioneered by the Waverton
                            Hub <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. HomeShare<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. Retirement Villages<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. Manufactured housing<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5. Intergenerational Living – Duplexes,
                            Granny Flats and More<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6. Co-housing<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7. Offshore ‘retirement’
                        </p>
                    </div>
                </div>
                <div class="row mt-5" *ngIf="activeHealthSubTab === 'homechoices'">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="card collapse show bg-subcontent">
                            <div class="card-body">
                                <div class="card-text text-center">
                                    <div class="row">
                                        <div class="col-12" style="text-align:justify">
                                            <p class="yhyl-text bold-font font-20">THE HUB: OUR OWN AUSTRALIAN MODEL OF
                                                COMMUNITY SUPPORT FOR AGEING IN PLACE</p>
                                            <p>
                                                A version of the Hub model is known and widely adopted in America as
                                                “Naturally occurring retirement communities” (i.e., NORCs, or
                                                neighbourhoods where there is a concentration of seniors, who need and,
                                                through the local Hub or “NORC” receive support enabling them to age
                                                well, for longer, in their own homes).
                                                <br /><br />
                                                A Hub operates not as a ‘mutual association’ (not a for-profit). It
                                                charges a modest annual fee; receives support from local government and
                                                community organisations; and thus operates at little cost to its
                                                members. The Hub’s members organise, as volunteers, the Hub’s
                                                activities. Many of the Hub’s events are open to all members of the
                                                community – not just Hub members.
                                                <br /><br />
                                                Learn more about the Hub model in their video here: <b>Hot link only
                                                    here</b>
                                                <br /><br />
                                                You can find guidelines and a manual on how to establish a Hub in your
                                                own community here: <b>Hot link only here</b>
                                            </p>
                                            <button type="submit"
                                                class="btn btn-grey-color text-white px-5 glow mb-1 mb-sm-0 mr-0 mr-sm-1 info-btn">Website
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="card collapse show bg-subcontent">
                            <div class="card-body">
                                <div class="card-text text-center">
                                    <div class="row">
                                        <div class="col-12" style="text-align:justify">
                                            <p class="yhyl-text bold-font font-20">HOMESHARE
                                            </p>
                                            <p>
                                                For those who own their own homes and prefer to remain sharing one’s
                                                home has long been an option: older people who have a room to share are
                                                matched with younger people who can offer domestic help and
                                                companionship in exchange for reduced or free rent. HomeShare schemes
                                                tend to be operated by not-for-profit organisations, which match, screen
                                                and provide formal documentation for seniors sharing their homes. It is
                                                not intended to be a substitute for formal care, but has been
                                                successful, in complementing the provision of care services and family
                                                support.
                                                In Sydney, Holdsworth House, a not-for-profit, highlights <a
                                                    href="https://holdsworth.org.au/homeshare/" target="_blank">(watch
                                                    the videos)</a> how well HomeShare for seniors can work.
                                            </p>
                                            <button type="submit"
                                                class="btn btn-grey-color text-white px-5 glow mb-1 mb-sm-0 mr-0 mr-sm-1 info-btn">Website
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="card collapse show bg-subcontent">
                            <div class="card-body">
                                <div class="card-text text-center">
                                    <div class="row">
                                        <div class="col-12" style="text-align:justify">
                                            <p class="yhyl-text bold-font font-20">MANUFACTURED HOUSING AND RESIDENTIAL
                                                LAND LEASE COMMUNITIES
                                            </p>
                                            <p>
                                                Back in the day an earlier version of this housing choice was known as
                                                caravan parks. That has changed. Today some are specifically designed
                                                for seniors, many not. Some are specifically designed for seniors, many
                                                not. Some are specifically designed for seniors, many not. Some are
                                                specifically designed for seniors, or permanent homeowners, many not. In
                                                NSW alone close to 500 land lease communities offer a variety of
                                                services, facilities and financial arrangements. These options are
                                                evolving rapidly to meet Baby Boomers’ financial and lifestyle
                                                preferences. Legislation for consumer protection is evolving too.
                                                <br /><br />
                                                The Queensland Government has an excellent guide to making wise
                                                decisions about retiring to a residential land lease community: <a
                                                    href="https://www.qld.gov.au/housing/buying-owning-home/housing-options-in-retirement/manufactured-homes/buying-manufactured-home/before-you-buy-a-manufactured-home"
                                                    target="_blank">Hot link only here</a>
                                            </p>
                                            <button type="submit"
                                                class="btn btn-grey-color text-white px-5 glow mb-1 mb-sm-0 mr-0 mr-sm-1 info-btn">Website
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="card collapse show bg-subcontent">
                            <div class="card-body">
                                <div class="card-text text-center">
                                    <div class="row">
                                        <div class="col-12" style="text-align:justify">
                                            <p class="yhyl-text bold-font font-20">RETIREMENT VILLAGES – OVER 55’S
                                                LIVING & LAND LEASE COMMUNITIES</p>
                                            <p>
                                                As the Australian dream of retirement has shifted in recent decades, so,
                                                too has the concept and reality of Over 55’s living. A generation of
                                                these ‘villages’, built and marketed as ‘resorts’ are often located a
                                                distance from urban centres. Lately ‘retirement villages’ have had to
                                                deal with intense scrutiny by regulators; Baby Boomers still very active
                                                and even employed ‘Over 55’s’; and the ageing of their residents to the
                                                point where they require the supportive care services that these
                                                villages are not set up to supply. 60% of residents of these villages
                                                will move directly into age care (nursing home).
                                                <br /><br />
                                                Golf, beach, lake, urban, super luxury – there is an abundant choice
                                                today for those seeking to live among other ‘Over 55’s’ in a selective
                                                community.
                                                <br /><br />
                                                The NSW Office of Fair Trading has an excellent, easy-to-read up-to-date
                                                Guide for moving into, living in, and leaving a Retirement Village. A
                                                must-read for seniors and their families before making the move: <a
                                                    href="https://www.fairtrading.nsw.gov.au/housing-and-property/retirement-villages"
                                                    target="_blank">https://www.fairtrading.nsw.gov.au/housing-and-property/retirement-villages</a>
                                                <br /><br />
                                                Lend Lease Living has recently introduced a new model of retirement
                                                community, aimed at Over 50’s, in which residents own and maintain their
                                                own homes but lease the land on which it sits, and also contribute to
                                                the shared facilities of the community. You can find out more about this
                                                new concept, designed to lower the purchase price of each home, and
                                                compare it to traditional retirement village living here: <a
                                                    href="https://www.landleaseliving.com/compare-your-options/"
                                                    target="_blank">https://www.landleaseliving.com/compare-your-options/</a>
                                            </p>
                                            <button type="submit"
                                                class="btn btn-grey-color text-white px-5 glow mb-1 mb-sm-0 mr-0 mr-sm-1 info-btn">Website
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="card collapse show bg-subcontent">
                            <div class="card-body">
                                <div class="card-text text-center">
                                    <div class="row">
                                        <div class="col-12" style="text-align:justify">
                                            <p class="yhyl-text bold-font font-20">INTERGENERATIONAL LIVING: DUPLEXES,
                                                ‘GRANNY FLATS’ AND MORE</p>
                                            <p>
                                                Did you know that intergenerational living – where several generations
                                                of the same family live on the same property and often in the same
                                                dwelling (duplexes) - is on the rise? Especially among Baby Boomers. And
                                                in the US there has been a marked increase in family groups pooling
                                                their funds to buy the single property for all? 15% of home purchasers
                                                surveyed last year said they had purchased their home for
                                                multigenerational living!
                                                <br /><br />
                                                Regulation governing approvals for ‘Granny Flats’ in New South Wales was
                                                eased a few years ago to make it fast and easy to get approval and
                                                build. And of course some families design and build their main residence
                                                for the future accommodation of ageing parents where the zoning permits.
                                            </p>
                                            <button type="submit"
                                                class="btn btn-grey-color text-white px-5 glow mb-1 mb-sm-0 mr-0 mr-sm-1 info-btn">Website
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="card collapse show bg-subcontent">
                            <div class="card-body">
                                <div class="card-text text-center">
                                    <div class="row">
                                        <div class="col-12" style="text-align:justify">
                                            <p class="yhyl-text bold-font font-20">CO-HOUSING AND ‘INTENTIONAL
                                                COMMUNITIES’</p>
                                            <p>
                                                The idea of a group of friends coming together to design and build their
                                                own ‘retirement village’ in miniature isn’t new. Its aim is to provide a
                                                mix of private and shared spaces that provides both privacy and
                                                community. Co-housing, a form of ‘intentional community’, is well
                                                established overseas but has grown slowly in Australia. You can find
                                                free Fact Sheets about co-housing at UTS here: <a
                                                    href="https://www.uts.edu.au/research-and-teaching/our-research/institute-sustainable-futures/our-research/social-change-4"
                                                    target="_blank">https://www.uts.edu.au/research-and-teaching/our-research/institute-sustainable-futures/our-research/social-change-4</a>
                                            </p>
                                            <button type="submit"
                                                class="btn btn-grey-color text-white px-5 glow mb-1 mb-sm-0 mr-0 mr-sm-1 info-btn">Website
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="card collapse show bg-subcontent">
                            <div class="card-body">
                                <div class="card-text text-center">
                                    <div class="row">
                                        <div class="col-12" style="text-align:justify">
                                            <p class="yhyl-text bold-font font-20">OVERSEAS RETIREMENT</p>
                                            <p>
                                                Retiring overseas appeals to different people for different reasons –
                                                returning to one’s country of birth, lowering the cost of living, moving
                                                closer to adult children and grandchildren, indulging in another
                                                culture, etc. However, overseas retirement can have serious implications
                                                on pension and other benefits, so it’s best to analyse the pro’s and
                                                con’s before making any firm decision.
                                            </p>
                                            <button type="submit"
                                                class="btn btn-grey-color text-white px-5 glow mb-1 mb-sm-0 mr-0 mr-sm-1 info-btn">Website
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>



<!-- BEGIN: Customizer-->
<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-xl-block" [ngClass]="
    (showUpdates)?'open':''">
    <a class="customizer-close" href="#"><i class="ft-x font-medium-3"></i></a>
    <a class="customizer-toggle primary-yhyl-bg box-shadow-3 btn-hover" (click)=showUpdateToggle()>
        <i class="fa fa-bell h4" theme="light" tooltip="Click to see Updates" placement="left"></i><span
            class="indicator"></span>
    </a>
    <div class="customizer-content p-2">
        <h4 class="border-bottom pb-3 content-header-title font-22 bold-font">Hey Test..</h4>
        <p class="text-animation">There are 5 new updates for you!</p>
        <p>Last 3 days to renew your service for Travel</p>
        <p>2 new offers on Health Service</p>
    </div>
</div>

<div class="sidenav-overlay"></div>
<div class="drag-target"></div>