import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.css','../information2.component.css']
})
export class WorkComponent implements OnInit {isShowing = true;
  showUpdates = false;
  showContent = true;
  isMainPage = true;
  activeHealthSubTab = '';
  activeTab = '';
  showUpdateToggle() {
    this.showUpdates = !this.showUpdates;
  }
  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  navigateMenu(tab) {
    if(tab === 'main-info') {
      this.router.navigate(['/information']);
    }
    if(tab === 'main-work') {
      this.activeHealthSubTab = '';
      this.isMainPage = true;
    }
  }
  infoHomeSubTab(subTab) {
    /* this.activeHealthSubTab =subTab;
    console.log(this.activeHealthSubTab === 'homechoices');
    this.isMainPage=false;
    window.scrollTo(0, 0); */
  }

}
