import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-businesses',
  templateUrl: './businesses.component.html',
  styleUrls: ['./businesses.component.css','../shared/templates.css']
})
export class BusinessesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
