<section id="team" class="team section-bg pd-13">
  <div class="container">
    <div class="section-title">
      <div style="padding-right:15px;padding-left:15px">
        <h3 class="primary-font primary-yhyl-bg p-5 text-center card-radius">
          Our People
        </h3>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12 d-flex align-items-stretch">
        <div class="member card-radius shadow">
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <img
                class="team-img shadow"
                src="../../../assets/New Branding Assets/Team_members/Abby.jpeg"
                alt=""
              />
            </div>
            <div class="col-lg-6 col-sm-12 m-auto">
              <h4 class="team-heading">
                <div>
                  <h2 class="primary-font yhyl-text">Abby</h2>
                </div>
                <div>
                  <h3 class="bold-font">Chief Executive Officer</h3>
                </div>
              </h4>
            </div>
          </div>
          <div class="text-justify">
            Abby founded Your 100 Year Life to alleviate the emotional pain and
            stress of an increasingly common situation: supporting an ageing
            loved one.
          </div>
          <div class="text-justify">
            Building on a career in health, ageing, innovation, and social
            impact, she founded the company to harness digital enablement in the
            service of seniors and their families. Abby also leads the
            consulting practice of Your 100 Year Life, advising companies on how
            to optimise organisational performance by focussing on the benefits
            of age diversity, and to acknowledge and support the silent pain of
            employees responsible for their ageing parents and loved ones. Abby
            has worked in health, ageing and innovation in Australia and globally
            for over 30 years. She is an Adjunct Professor of Public Health at
            Sydney University, and in the Business School of University of
            Technology. Abby's secret powers are her passion for 
            making a positive social impact, empathy, and her energy.
          </div>

          <div class="social">
            <a href=""><i class="bi bi-twitter"></i></a>
            <a href=""><i class="bi bi-facebook"></i></a>
            <a href=""><i class="bi bi-instagram"></i></a>
            <a href=""><i class="bi bi-linkedin"></i></a>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
        <div class="member card-radius shadow">
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <img
                class="shadow subteam-img1"
                src="../../../assets/New Branding Assets/Team_members/Heran.jpg"
                alt=""
              />
            </div>
            <div class="col-lg-6 col-sm-12 m-auto">
              <h4 class="subteam-header1">
                <div>
                  <h2 class="primary-font yhyl-text">Heran</h2>
                </div>
                <div>
                  <h3 class="bold-font">Operations Officer</h3>
                </div>
              </h4>
            </div>
          </div>

          <div class="text-justify">
            A seasoned digital entrepreneur, Heran manages all day-to-day
            operations, including strategy, implementation of our business plan,
            talent acquisition, and coordinating staff and contractors. After
            graduating with an MBA from University of Technology Sydney, Heran
            joined a team researching costs and identifying cost efficiencies in
            a major health system. Heran’s secret powers are: urban gardening,
            extreme efficiency and exceptional insight – and artistic flair.
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
        <div class="member card-radius shadow">
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <img
                class="shadow subteam-img1"
                src="../../../assets/New Branding Assets/Team_members/Saurabh.jpeg"
                alt=""
              />
            </div>
            <div class="col-lg-6 col-sm-12 m-auto">
              <h4 class="subteam-header1">
                <div>
                  <h2 class="primary-font yhyl-text">Saurabh</h2>
                </div>
                <div>
                  <h3 class="bold-font">Chief Technology Officer</h3>
                </div>
              </h4>
            </div>
          </div>

          <div class="text-justify">
            Saurabh is a highly experienced Senior DevOps engineer and project
            team manager. He supervises all DevOps at Your 100 Year Life,
            coordinating the efforts of onshore and offshore staff keeping Your
            100 Year Life at the forefront of digital enablement benefitting
            seniors. Saurabh has previously worked for two of the largest global
            specialist IT companies, and more recently for a leading global
            financial institution. His secret power is a photographic memory.
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
        <div class="member card-radius shadow">
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <img
                class="shadow subteam-img1"
                src="../../../assets/New Branding Assets/Team_members/Ayushi.jpg"
                alt=""
              />
            </div>
            <div class="col-lg-6 col-sm-12 m-auto">
              <h4 class="subteam-header1">
                <div>
                  <h2 class="primary-font yhyl-text">Ayushi</h2>
                </div>
                <div>
                  <h3 class="bold-font">Front end Developer</h3>
                </div>
              </h4>
            </div>
          </div>

          <div class="text-justify">
            Ayushi provides DevOps support for Your 100 Year Life’s interface
            with our company’s customers. Building on experience with a NASDAQ
            100 company specialising in digital transformation, Ayushi supports
            our local DevOps team with her UX-focused IT skills and global
            experience. Ayushi’s secret powers are her impressive artistic
            talents, especially her magnificent singing.
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
        <div class="member card-radius shadow">
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <img
                class="shadow subteam-img1"
                src="../../../assets/New Branding Assets/Team_members/Chloe.jpg"
                alt=""
              />
            </div>
            <div class="col-lg-6 col-sm-12 m-auto">
              <h4 class="subteam-header1">
                <div>
                  <h2 class="primary-font yhyl-text">Chloe</h2>
                </div>
                <div>
                  <h3 class="bold-font">Digital Enablement</h3>
                </div>
              </h4>
            </div>
          </div>
          <div class="text-justify">
            Chloe brings her combination of STEM + innovation skills to the
            company’s Smart Life division. As part of her advanced engineering
            requirements at UTS, Chloe sources and assesses a range of products
            and services that address common problems experienced by families
            trying to support their ageing loved ones at home as long as
            possible. Building on her personal experience in aged care, Chloe’s
            scope includes digitally- and blue tooth-enabled devices as well as
            really simple and low-cost solutions.
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
        <div class="member card-radius shadow">
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <img
                class="shadow subteam-img2"
                src="../../../assets/New Branding Assets/Team_members/Wei.jpg"
                alt=""
              />
            </div>
            <div class="col-lg-6 col-sm-12 m-auto">
              <h4 class="subteam-header2">
                <div>
                  <h2 class="primary-font yhyl-text">Wei (Vicky)</h2>
                </div>
                <div>
                  <h3 class="bold-font">Developer</h3>
                </div>
              </h4>
            </div>
          </div>

          <div class="text-justify">
            Wei is a valuable member of our local team of developers. Currently
            enrolled in the Masters in IT program at University of Technology.
            Vicki is a prodigious worker who surprises and delights our team
            with her suggestions for better and more efficient solutions to
            technical problems. Vicki carries lead responsibility for creating
            Your 100 Year Life’s Boomer Boutique: where seniors and their
            families can find really cool stuff to simplify and de-stress their
            lives. Vicki’s secret power is loving her own Angry Cat.
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
        <div class="member card-radius shadow">
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <img
                class="shadow subteam-img2"
                src="../../../assets/New Branding Assets/Team_members/Taarak.jpeg"
                alt=""
              />
            </div>
            <div class="col-lg-6 col-sm-12 m-auto">
              <h4 class="subteam-header2">
                <div>
                  <h2 class="primary-font yhyl-text">Taarak</h2>
                </div>
                <div>
                  <h3 class="bold-font">Developer</h3>
                </div>
              </h4>
            </div>
          </div>

          <div class="text-justify">
            Taarak holds the record for perfection: all High Distinctions in his
            Master of IT (Extension) studies at the University of Technology
            Sydney! Taarak has honed his skills working on front end and back
            end solutions for globally recognised corporations. Mr. Perfect’s
            secret power is his perpetual search for self-knowledge.
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
        <div class="member card-radius shadow">
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <img
                class="shadow subteam-img2"
                src="../../../assets/New Branding Assets/Team_members/Joan.jpg"
                alt=""
              />
            </div>
            <div class="col-lg-6 col-sm-12 m-auto">
              <h4 class="subteam-header2">
                <div>
                  <h2 class="primary-font yhyl-text">Joan</h2>
                </div>
                <div>
                  <h3 class="bold-font">Developer</h3>
                </div>
              </h4>
            </div>
          </div>
          <div class="text-justify">
            Joan has been acknowledged by his native Colombia as one of their
            most talented engineers, and awarded support to pursue his Masters
            in IT in Australia at the University of Technology Sydney. After
            receiving an honours degree in engineering, Joan worked as a
            professional engineer for 5 years specialising in networking. Joan
            is a member of our impressive local team of developers. Joan’s
            secret power is maths.
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-landing-footer></app-landing-footer>
