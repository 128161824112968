<div class="content-wrapper pd-10 bg-white">
  <div class="content-header row border-bottom">
    <div class="content-header-left col-md-12 col-12 mb-2 pl-0 pr-0 pr-0">
      <h3 class="content-header-title font-22 bold-font">
        <i class="yhyl-text icon-width fas fa-info text-center mr-3"></i>
        <span class="yhyl-text pointer" (click)="navigateMenu('main-info')">MY INFORMATION&nbsp;&nbsp;</span>
        <i class="fa fa-angle-right grey-color" aria-hidden="true"></i>
        <i class="fas fa-1x title-icon-style fa-house-user d-blue-color"></i>
        <span class="d-blue-color pointer font-16" (click)="navigateMenu('main-technology')">MY
          TECHNOLOGY&nbsp;&nbsp;</span>
      </h3>
      <div class="temp" *ngIf="isMainPage">
        <!--Find accurate, reliable and practical information on the "need to know" issues in longevity and ageing. You told us what you need: we sourced it and put it all at your fingertips!-->
        <br />
        <div class="sub-content">
          <!--  <p class="d-blue-color font-20">Evolve with Technology - Safely and Securely
                    </p> -->
          <p class="grey-color">
            Many seniors have thought about and made plans for their eventual
            passing and their legacy. This is the place where you can tell your
            family how you would like to be remembered: <br />Details of any
            funeral or burial arrangements you may have made; <br />Any
            preferences for how you'd like them to celebrate your life;
            <br />Save and share some of the joyous moments in your life;
            <br />Special messages for your children and grandchildren; and lots
            more!
          </p>
        </div>
      </div>
      <div class="row mt-5" *ngIf="isMainPage">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-health-resources.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">MY WISHES</p>
                    <p>
                      A place to record – in print, spoken, or video - your
                      wishes for your family and friends after you depart. Store
                      here any arrangements you might want for your funeral or
                      celebration of life. Specific music? Hymns? Poems? Keep
                      them safe and accessible here. We’ve scoured the web to
                      review and select the best. The death of a loved one is a
                      difficult time. One Government has created a single online
                      place to go for guidance on the essentials you need to
                      know and do when a loved one dies. A starting place will
                      be the essentials, sponsored by Service NSW:
                      <a href="https://www.lawaccess.nsw.gov.au/Pages/representing/after_someone_dies/after_someone_dies.aspx"
                        target="=" _blank>www.lawaccess.nsw.gov...er_someone_dies.aspx</a>
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-mental-health.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">MY MEMORIES</p>
                    <p>
                      An easy way to save and share photos, videos, recordings.
                      We provide guidance to a sample of websites and apps that
                      make recording and storing your memories easier.
                      <b><em>COMING SOON!</em></b> Click here to load and save
                      something in your Personal Page. Absolutely free for 3
                      months when you are one of the first 250 to sign up.
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-medication.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">MY LIFE</p>
                    <p>
                      Family history, meaningful milestones, recipes passed down
                      from generation to generation, maybe your story told in
                      your own words - all belong in this personal space. Don't
                      let meaningful family stories pass with you. We help you
                      find ways to store and share them here, forever.
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-checklists.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">MY AFTERWIFE</p>
                    <p>
                      What do you want your loved ones to know or do after
                      you're gone? Not everything that matters is in a will.
                      Make it easy for family to alert friends and colleagues;
                      where are their contact details? One thing that is only
                      recently a worry is your 'digital life'. It starts with
                      access to your digital files and other spaces. No
                      password, no access. Will you leave your passwords? What
                      about any direct debits? Subscriptions? Ongoing donations?
                      Again, make it easy by compiling and storing a list and
                      instructions so loved ones know what you want.
                      <br /><br />
                      How do you want to be memorialised?

                      <b><em>COMING SOON!</em></b> A checklist for those who
                      want family to know how to celebrate their life
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- BEGIN: Customizer-->
<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-xl-block"
  [ngClass]="showUpdates ? 'open' : ''">
  <a class="customizer-close" href="#"><i class="ft-x font-medium-3"></i></a>
  <a class="customizer-toggle primary-yhyl-bg box-shadow-3 btn-hover" (click)="showUpdateToggle()">
    <i class="fa fa-bell h4" theme="light" tooltip="Click to see Updates" placement="left"></i><span
      class="indicator"></span>
  </a>
  <div class="customizer-content p-2">
    <h4 class="border-bottom pb-3 content-header-title font-22 bold-font">
      Hey Test..
    </h4>
    <p class="text-animation">There are 5 new updates for you!</p>
    <p>Last 3 days to renew your service for Travel</p>
    <p>2 new offers on Health Service</p>
  </div>
</div>

<div class="sidenav-overlay"></div>
<div class="drag-target"></div>