import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import {CommonService} from '../../services/common.service';
 
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  accessData: any;
  showSwitchAcc = false;
  copilotData: any;
  showSwitchOption = false;
  

  mobileToggle() {
    this.commonService.mobileMenuToggle("true");
  }
  constructor(private router: Router,private commonService: CommonService ,private authenticationService: AuthService) { }

  ngOnInit(): void {
    /* this.accessData = this.commonService.getCopilotPermissions();
    if(this.accessData) {
      if(this.accessData.permission) {
        this.showSwitchAcc = true;
      }
    } */

    this.commonService.switchAccount().subscribe((response) =>  {
      this.copilotData=response;
      if(this.copilotData.length != 0) {
        this.showSwitchOption = true;
      } else {
        this.showSwitchOption = false;
      }
      this.accessData = this.commonService.getCopilotPermissions();
      if(this.accessData) {
      if(this.accessData.permission) {
        this.showSwitchAcc = true;
      }
    }
    
    });
  }
  logout(){
    this.commonService.logout().subscribe((response: any) => {
      this.authenticationService.logout();
      this.router.navigate(['/home'])
    }, (errorResponse: any) => {
      
    });
  }

}
