<div class="content-wrapper pd-10 bg-white">
  <div class="content-header row border-bottom">
    <div class="content-header-left col-md-12 col-12 mb-2 pl-0 pr-0 pr-0">
      <h3 class="content-header-title font-22 bold-font">
        <i class="yhyl-text icon-width fas fa-info text-center mr-3"></i>
        <span class="yhyl-text pointer" (click)="navigateMenu('main-info')">MY INFORMATION&nbsp;&nbsp;</span>
        <i class="fa fa-angle-right grey-color" aria-hidden="true"></i>
        <i class="fas fa-1x title-icon-style fa-house-user security-color"></i>
        <span class="security-color pointer font-16" (click)="navigateMenu('main-security')">MY
          SECURITY&nbsp;&nbsp;</span>
      </h3>
      <div class="temp" *ngIf="isMainPage">
        <!--Find accurate, reliable and practical information on the "need to know" issues in longevity and ageing. You told us what you need: we sourced it and put it all at your fingertips!-->
        <br />
        <div class="sub-content">
          <p class="security-color font-20 bold-font">
            Online, Offline and Personal Security
          </p>
          <p class="grey-color">
            Everyone wants to feel safe and secure in their own home, on the
            internet and out and about. We help you protect yourself, your home
            and your belongings! Here you will find tips and tools to make sure
            you and your home are safe and secure.
          </p>
        </div>
      </div>
      <div class="row mt-5" *ngIf="isMainPage">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-health-resources.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">
                      SECURE YOUR VITAL DOCUMENTS
                    </p>
                    <p>
                      Sign up for Premium and get your own, secure, personal
                      spot to store all of those vital documents. If something
                      happened to you, would a trusted loved one be able to find
                      all of your urgent vital documents, policies,
                      subscriptions, direct debits and more? Help your family
                      and loved ones by listing what they need to know and
                      manage here. We provide a free guide and worksheet to make
                      it easy.
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-mental-health.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">
                      MY PHYSICAL SECURITY
                    </p>
                    <p>
                      Protect yourself and loved ones from falls, burglary, and
                      more. We have consulted with experts and put together the
                      best, easy-to-use checklist to make sure you can identify
                      every hazard - and we then provide a menu of simple,
                      low-cost solutions! Safe out and about? There are more and
                      more apps and widgets that can help you stay safe, and
                      raise an alarm, when you're out and about. We keep up on
                      them so you don't have to! <i>COMING SOON!</i>
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-medication.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">ABUSE ALERT</p>
                    <p>
                      Sadly, Elder Abuse is very common and financial abuse
                      happens often. They take lots of forms, and it's important
                      to know how they can sneak into and destroy your life, or
                      the life of a loved one, and what to do about them. Take 3
                      minutes to read this simple guide:
                      <a href="https://moneysmart.gov.au/financial-abuse"
                        target="_blank">moneysmart.gov.au/financial-abuse</a>. Are you or someone you love being taken
                      advantage of?
                      Having money or other valuable things “borrowed” or used
                      for other purposes? Guidance and tools from reliable
                      sources is here.
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-checklists.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">SCAM ALERT</p>
                    <p>
                      Scammers prey on seniors. Full stop. Please keep up with
                      the latest scams: go to your bank's website, and visit
                      this Commonwealth Government website:
                      <a href="https://www.scamwatch.gov.au/" target="_blank">www.scamwatch.gov.au</a>. Here's a link to
                      the free-to-all alert site of The
                      Commonwealth Bank:
                      <a href="https://www.commbank.com.au/a…stay-scam-smart.html"
                        target="_blank">www.commbank.com.au/a…stay-scam-smart.html</a>.

                      <br />
                      Our free guidance to staying safe on line. Protect
                      yourself and your loved ones. Simple, reliable information
                      and guidance is here.
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-checklists.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">MY INSURANCE</p>
                    <p>
                      Don't be surprised if you don't have an easily accessible
                      list of all your insurance and related policies: most
                      people don't. But shouldn't you urge your ageing loved one
                      to compile a list in the event that they become
                      incapacitated and someone else needs to locate them on
                      their behalf? Compile and store in your/their Personal
                      Page. No more chaos.
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- BEGIN: Customizer-->
<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-xl-block"
  [ngClass]="showUpdates ? 'open' : ''">
  <a class="customizer-close" href="#"><i class="ft-x font-medium-3"></i></a>
  <a class="customizer-toggle primary-yhyl-bg box-shadow-3 btn-hover" (click)="showUpdateToggle()">
    <i class="fa fa-bell h4" theme="light" tooltip="Click to see Updates" placement="left"></i><span
      class="indicator"></span>
  </a>
  <div class="customizer-content p-2">
    <h4 class="border-bottom pb-3 content-header-title font-22 bold-font">
      Hey Test..
    </h4>
    <p class="text-animation">There are 5 new updates for you!</p>
    <p>Last 3 days to renew your service for Travel</p>
    <p>2 new offers on Health Service</p>
  </div>
</div>

<div class="sidenav-overlay"></div>
<div class="drag-target"></div>