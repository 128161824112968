<div class="content-wrapper pd-10">
  <div class="content-header row border-bottom">
    <div class="content-header-left col-md-12 col-12 mb-2">
      <h3 class="content-header-title font-22 bold-font">
        <i class="icon-width fas fa-tags text-center mr-3"></i>Really Useful
        Recommendations to simplify and de-stress your ageing marathon
      </h3>
      <div class="temp" [class.show]="showContent">
        <p>
          <br />
          We are always on the lookout for clever solutions that make the ageing
          marathon easier, less stressful, less costly, and more joyful.
          <br /><br />
          We'll be posting some of those finds here and will value your
          feedback.
          <br /><br />
          Tell us what you need:
          <a href="/contact" target="_blank">Click here</a>
        </p>
      </div>
      <!-- <ng-container *ngIf="showContent"> </ng-container>
      <a class="yhyl-text" (click)="showContent = !showContent">{{
        showContent ? "Show less" : "Show More"
      }}</a> -->
    </div>
  </div>
  <div class="content-body">
    <div class="row mt-3 mb-5">
      <div class="col-md-4">
        <!-- <input
          class="form-control filter"
          [(ngModel)]="filterData"
          type="text"
          placeholder="Filter by Pincode.."
          (ngModelChange)="filterOffers(filterData)"
        /> -->
      </div>
      <div class="col-md-4"></div>
      <div class="col-md-4"></div>
    </div>

    <div *ngIf="filterList.length > 0">
      <div id="description" class="card" style="margin-left: 15px;margin-right:15px;" *ngFor="let offer of filterList">
        <div class="row text-center bg-white shadow-light">
          <div class="col-lg-3 col-md-4 border-right">
            <a class="btn top-menu" href="{{ offer.Companyurl }}" target="_blank">
              <img class="offer-img" src="{{ offer.Offer_Image }}" />
            </a>
          </div>
          <div class="col-lg-4 col-md-4 border-right details">
            <div>{{ offer.Offer_Details }}</div>
            <!-- <div><a class="yhyl-text">Click here for more info...</a></div> -->
          </div>
          <div class="col-lg-4 col-md-4 border-right details">
            <div class="termsandconditions">{{ offer.TermsandConditions }}</div>
            <!-- <div class="top-menu">Save up to:</div>
            <div class="top-menu text-animation pt-0">${{ offer.Price }}</div> -->
          </div>
          <div class="col-lg-1 col-md-1 border-right details">
            <a href="{{ offer.Companyurl }}" target="_blank">Visit</a>
          </div>
        </div>
      </div>
      <div *ngIf="isNull">
        <p class="card-text">No Offers Found !!</p>
      </div>
    </div>
  </div>
  <div class="text-center" *ngIf="showLoader">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <!-- BEGIN: Customizer-->
  <div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-xl-block"
    [ngClass]="showUpdates ? 'open' : ''">
    <a class="customizer-close" href="#"><i class="ft-x font-medium-3"></i></a>
    <a class="customizer-toggle primary-yhyl-bg box-shadow-3 btn-hover" (click)="showUpdateToggle()">
      <i class="fa fa-bell h4" theme="light" tooltip="Click to see Updates" placement="left"></i><span
        class="indicator"></span>
    </a>
    <div class="customizer-content p-2">
      <h4 class="border-bottom pb-3 content-header-title font-22 bold-font">
        Hey Test..
      </h4>
      <p class="text-animation">There are 5 new updates for you!</p>
      <p>Last 3 days to renew your service for Travel</p>
      <p>2 new offers on Health Service</p>
    </div>
  </div>

  <div class="sidenav-overlay"></div>
  <div class="drag-target"></div>
</div>