<div class="content-wrapper pd-10">
    <div class="content-header row border-bottom">
        <div class="content-header-left col-md-12 col-12 mb-2">
            <h3 class="content-header-title font-22 bold-font"><i
                    class="icon-width fas fa-wrench text-center mr-3"></i>My Service Providers: Emergency Contacts and
                All Your Regular Service Contacts in one place</h3>
            <!-- <div class="temp" [class.show]="showContent">
                Store and explore contact details for the services you use regularly.
            </div> -->
            <ng-container *ngIf="showContent">
                <br />
                Here is where you can store Emergency Contacts and the details of all of the services used by you and
                your family use. You’d
                be surprised how many: plumber, electrician, gardener, GP specialists, dentist, carers, dog walkers,
                neighbours, bridge partners, senior centre?
                Cleaner, painter, vet, babysitters and more. You'll probably be surprised at the long list of services
                and people in your or your loved one's support system. Are you helping a loved one by managing remotely,
                as many have been forced to do during COVID lockdowns? Or because you're at a distance from your loved
                one? Interstate? Overseas? Then you absolutely need to set up this personalised page.
                When you are managing things remotely for a loved one, why not have just 1 place for all? Faster,
                easier, more reliable.
            </ng-container>
            <a class="yhyl-text" (click)="showContent = !showContent">{{ showContent ? 'Show less': 'Show More' }}</a>
        </div>
    </div>
    <div class="content-body">
        <div class="row mt-3 mb-5">
            <div class="col-md-8" style="padding-left:15px" *ngIf="!isCopilot">
                <div class="btn btn-primary-yhyl font-20 shadow text-md-center" (click)="showAddPopup()">+ Add
                    Emergency Contacts</div>
            </div>
            <div class="col-md-8" style="padding-left:15px" *ngIf="isCopilot"
                [ngClass]="{'disabled': viewServices === true}">
                <div class="btn btn-primary-yhyl font-20 shadow text-md-center" (click)="showAccessMessage()">+ Add
                    Emergency Contacts</div>
            </div>
            <div class="col-md-4">
                <!-- <input class="form-control filter" [(ngModel)]="filterData" type="text"
                    placeholder="Filter by Pincode.." (ngModelChange)="filterOffers(filterData)" /> -->
            </div>
        </div>
        <div class="card m-3">
            <div class="card-body">
                <ul class="nav nav-tabs mb-4 row" role="tablist">
                    <li class="nav-item col-lg-6 col-md-6 col-sm-12 px-0">
                        <a class="nav-link sidebar active text-center" id="custom-tab" data-toggle="tab" href="#custom"
                            aria-controls="custom" role="tab" aria-selected="false">
                            <span class="d-sm-block">My Emergency Contacts</span>
                        </a>
                    </li>
                    <li class="nav-item col-lg-6 col-md-6 col-sm-12 px-0">
                        <a class="nav-link sidebar text-center" id="default-tab" data-toggle="tab" href="#default"
                            aria-controls="default" role="tab" aria-selected="false">
                            <span class="d-sm-block">My Service Providers</span>
                        </a>
                    </li>

                </ul>
            </div>
            <div class="tab-content">
                <div class="tab-pane" id="default" aria-labelledby="default-tab" role="tabpanel">
                    <div class="card-body" *ngIf="defaultServices.length > 0">
                        <div class="row">
                            <div class="col-md-6 col-lg-4 col-sm-12 card" *ngFor="let service of defaultServices">
                                <div class="p-3 my-3 border">
                                    <div>
                                        <img class="card-img-top img-fluid" src="{{service.Service_Company_logo}}"
                                            alt="Card image cap">
                                    </div>

                                    <h4 class="card-title my-3">{{service.Service_Company_Name}}</h4>
                                    <p class="mb-1">{{service.Service_Description}}</p>
                                    <p class="mb-1">{{service.Service_Type}}</p>
                                    <p class="mb-1">Average Quote: ${{service.Price}}</p>
                                    <p class="mb-1">Rate: <span>
                                            <ngb-rating [max]="5" [(rate)]="service.UserRating" [readonly]="true">
                                            </ngb-rating>
                                        </span></p>
                                    <p class="mb-1"><a href="{{service.ServiceCompanyurl}}" class="yhyl-text"
                                            target="_blank">Click here
                                            for more info...</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isDefaultNull">
                        <p class="card-text">There are no services for you right now !!</p>
                    </div>
                    <!-- Row -->
                </div>
                <div class="tab-pane" id="custom" aria-labelledby="custom-tab" role="tabpanel">
                    <div class="card-body" *ngIf="customServices.length > 0">
                        <div class="row">
                            <div class="col-md-6 col-lg-4 col-sm-12" *ngFor="let service of customServices">
                                <div class="p-3 my-3 border">
                                    <h4 class="card-title my-3">{{service.Service_Company}}
                                    </h4>
                                    <p class="mb-1">Description: {{service.Service_Description}}</p>
                                    <p class="mb-1">Type: {{service.Service_Type}}</p>
                                    <p class="mb-1">Service Person: {{service.Service_Person_Name}}</p>
                                    <p class="mb-1">Contact Details : {{service.Contact_Details}}
                                    </p>
                                    <p>

                                        <i class="fas fa-edit" *ngIf="!isCopilot || editServices || deleteServices"
                                            (click)="showEditPopup(service.Service_Id)"></i>&nbsp;
                                        <i class="fa fa-trash" *ngIf="!isCopilot || deleteServices"
                                            (click)="showDeletePopup(service.Service_Id)"></i>
                                        <i class="fas fa-edit disabled"
                                            *ngIf="isCopilot && !editServices && !deleteServices"
                                            (click)="showAccessMessage()"></i>&nbsp;
                                        <i class="fa fa-trash disabled" *ngIf="isCopilot && (deleteServices === false)"
                                            (click)="showAccessMessage()"></i>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 text-center active" *ngIf="isCustomNull">
                        <!-- <p class="card-text">You haven't added any services !!</p> -->
                        <!--  -->
                    </div>
                </div>
            </div>

        </div>

        <!-- popup to add custom service -->
        <div class="overlay" style="z-index: 2;" *ngIf="isCustom">
            <div class="popup col-lg-5 col-md-6 col-sm-6">
                <h3>
                    <a class="close" (click)="close()">&times;</a>
                    <div class="clearfix"></div>
                </h3><br />
                <div class="content">
                    <div *ngIf="!isSuccess">
                        <div class="row">
                            <div class="col-md-12">
                                <h4 class="yhyl-text">Service Name (Company)</h4>
                                <input class="form-control" [(ngModel)]="company" name="title" placeholder="Company"
                                    type="text" />
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-md-12">
                                <h4 class="yhyl-text">Service Type</h4>
                                <input class="form-control" [(ngModel)]="type" name="type" placeholder="Type"
                                    type="text" />
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-md-12">
                                <h4 class="yhyl-text">Contact Name<span class="required-asterisk">*</span></h4>
                                <input class="form-control" [(ngModel)]="personName" name="name" placeholder="Name"
                                    required="true" type="text" />
                                <div
                                    *ngIf=" isErrorpersonName && (personName === '' || personName === null || personName=== undefined)">
                                    <div class="mb-5 mt-3 text-center" class="invalid-feedback block">
                                        This field should not be blank.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-md-12">
                                <h4 class="yhyl-text">Contact Number<span class="required-asterisk">*</span></h4>
                                <input class="form-control" [(ngModel)]="contact" name="contact" placeholder="Contact"
                                    required="true" />
                                <div
                                    *ngIf=" isErrorcontact && (contact === '' || contact === null || contact=== undefined)">
                                    <div class="mb-5 mt-3 text-center" class="invalid-feedback block">
                                        This field should not be blank.
                                    </div>
                                </div>
                                <div *ngIf="isErrorcontactnumber &&  contact != '' " class="mb-5 mt-3 text-center"
                                    class="invalid-feedback block">
                                    Contact number is not valid.
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-md-12">
                                <h4 class="yhyl-text">Service Description</h4>
                                <input class="form-control" [(ngModel)]="description" name="description"
                                    placeholder="Description" type="text" />
                            </div>
                        </div>
                        <br />



                        <div class="row">
                            <div class="col-12 d-flex flex-column justify-content-end mt-1">
                                <button class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 active"
                                    (click)="addService()">
                                    Add Service
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="mb-5 mt-3 text-center alert alert-success" *ngIf="isSuccess">
                        {{message}}
                    </div>
                    <div class="mb-5 mt-3 text-center alert alert-danger" *ngIf="isError">
                        {{message}}
                    </div>
                </div>
            </div>
        </div>
        <!-- popup for edit custom service -->
        <div class="overlay" style="z-index: 2;" *ngIf="isEdit">
            <div class="popup col-lg-5 col-md-6 col-sm-6">
                <h3>
                    <a class="close" (click)="close()">&times;</a>
                    <div class="clearfix"></div>
                </h3><br />
                <div class="content">
                    <div *ngIf="!isSuccess && !isError">
                        <div class="row">
                            <div class="col-md-12">
                                <h4 class="yhyl-text">Service Title(Company)</h4>
                                <input class="form-control" [(ngModel)]="company" name="title" placeholder="Company"
                                    required="true" type="text" />
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-md-12">
                                <h4 class="yhyl-text">Service Description</h4>
                                <input class="form-control" [(ngModel)]="description" name="description"
                                    placeholder="Description" required="true" type="text" />
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-md-12">
                                <h4 class="yhyl-text">Service Type</h4>
                                <input class="form-control" [(ngModel)]="type" name="type" placeholder="Type"
                                    required="true" type="text" />
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-md-12">
                                <h4 class="yhyl-text">Service Person Name</h4>
                                <input class="form-control" [(ngModel)]="personName" name="name" placeholder="Name"
                                    required="true" type="text" />
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-md-12">
                                <h4 class="yhyl-text">Contact Details</h4>
                                <input class="form-control" [(ngModel)]="contact" name="contact" placeholder="Contact"
                                    required="true" type="tel" />
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-12 d-flex flex-column justify-content-end mt-1">
                                <button class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 active"
                                    (click)="editService()">
                                    Edit My Emergency Contacts
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 mt-3 text-center alert alert-danger" *ngIf="isError">
                        {{message}}
                    </div>
                    <div class="mb-5 mt-3 text-center alert alert-success" *ngIf="isSuccess">
                        {{message}}
                    </div>
                </div>
            </div>
        </div>
        <!-- popup for delete custom service -->
        <div class="overlay" style="z-index: 2;" *ngIf="isDelete">
            <div class="popup del-height col-lg-5 col-md-6 col-sm-6">
                <h3>
                    <a class="close" (click)="close()">&times;</a>
                    <div class="clearfix"></div>
                </h3><br />
                <div class="content">
                    <div *ngIf="!isSuccess && !isError">
                        <div class="row text-center m-4 border-bottom">
                            <div class="col-md-12">
                                <h4 class="yhyl-text">Do you want to delete this service?</h4>
                            </div>
                        </div>
                        <div class="row mb-5 text-center">
                            <div class="col-6">
                                <button style="width:100%"
                                    class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 active"
                                    (click)="deleteService()">
                                    Yes
                                </button>
                            </div>
                            <div class="col-6">
                                <button style="width:100%"
                                    class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 active"
                                    (click)="isDelete = false">
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 mt-3 text-center alert alert-success" *ngIf="isSuccess">
                        {{message}}
                    </div>
                    <div class="mb-5 mt-3 text-center alert alert-danger" *ngIf="isError">
                        {{message}}
                    </div>
                </div>
            </div>
        </div>
        <!-- popup for access message -->
        <div class="overlay" style="z-index: 2;" *ngIf="isAction">
            <div class="popup custom-height col-lg-5 col-md-6 col-sm-6">
                <h3>
                    <a class="close" (click)="isAction = false">&times;</a>
                    <div class="clearfix"></div>
                </h3><br />
                <div class="content">
                    <div class="mb-5 mt-3 text-center alert alert-danger">
                        {{actionMessage}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="text-center" *ngIf="showLoader">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<!-- BEGIN: Customizer-->
<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-xl-block" [ngClass]="
    (showUpdates)?'open':''">
    <a class="customizer-close" href="#"><i class="ft-x font-medium-3"></i></a>
    <a class="customizer-toggle primary-yhyl-bg box-shadow-3 btn-hover" (click)=showUpdateToggle()>
        <i class="fa fa-bell h4" theme="light" tooltip="Click to see Updates" placement="left"></i><span
            class="indicator"></span>
    </a>
    <div class="customizer-content p-2">
        <h4 class="border-bottom pb-3 content-header-title font-22 bold-font">Hey Test..</h4>
        <p class="text-animation">There are 5 new updates for you!</p>
        <p>Last 3 days to renew your service for Travel</p>
        <p>2 new offers on Health Service</p>
    </div>
</div>

<div class="sidenav-overlay"></div>
<div class="drag-target"></div>