<div class="content-wrapper pt-7 mob-pt">
  <div class="content-body">
    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <div class="card card-signin my-5 shadow">
        <div class="card-body" *ngIf="!isReset">
          <h4 class="card-title text-center yhyl-text bold-font my-4">
            Password Reset
          </h4>
          <p>Please enter the new password and confirm new password here</p>
          <div class="form-group input-group mb-4">
            <input type="text" name="name" [(ngModel)]="password" class="form-control" id="name" placeholder="Password"
              data-rule="minlen:4" data-msg="Password" />
          </div>
          <div class="form-group input-group mb-4">
            <input type="password" name="name" [(ngModel)]="confirmPassword" class="form-control" id="name"
              placeholder="Confirm Password" data-rule="minlen:4" data-msg="Please confirm the password" />
          </div>
          <div class="form-group ">
            <button type="submit" class="btn btn-primary btn-block primary-yhyl-bg font-20" [disabled]="
                !(
                  token != null &&
                  confirmPassword === password &&
                  password !== undefined
                )
              " (click)="resetPassword()">
              Reset Password
            </button>
          </div>
        </div>

        <div class="card-body" *ngIf="isReset">
          <p>Your password has been changed successfully.</p>
          <div class="mt-5">
            <button class="btn btn-primary btn-block primary-yhyl-bg font-20" type="submit" routerLink="/login">
              Click here to Login
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>