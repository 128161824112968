<!-- ======= about-story Section ======= -->
<section id="about" class="about">
    <div class="container" data-aos="fade-up">
        <h2 class="primary-font text-center yhyl-text bold-font">Website Terms and Conditions of Use
        </h2>
        <div class="row content py-5">
            <div data-aos="zoom-in" data-aos-delay="200" class="col-12 d-flex p-0 aos-init aos-animate" style="flex-direction: column;">
                    <div
                    data-aos="zoom-in"
                    data-aos-delay="200"
                    class="col-12 d-flex p-0 aos-init aos-animate"
                  >
                    <div class="card p-0">
                            <h3 class="primary-font primary-yhyl-bg p-5 text-center"> Terms and Conditions of Use
                                </h3>
                                <ol class="p-5" type="1">
                                    <li class="bold-font">About the Application</li>
                            <ol class="p-5" type="a">
                                    <li class="bold-font">
                                       <!--Welcome to
                                        https://your100yearlife.com (the 'Website'). The Website provides
                                        you with an opportunity to browse and purchase various products that have been
                                        listed for sale through the Website (the 'Products'). The Website provides this
                                        service by way of granting you access to the content on the Website (the
                                        'Purchase Services').-->
                                        Welcome to Your 100 Year Life Pty Ltd (the 'Application'). 
                                        The Application provides information, links, checklists and toolkits (the 'Services ').
                                        </li>
                                    <li class="bold-font">The Application is operated by Your 100 Year Life Pty Ltd PTY. LTD. (ABN 93630809111) . Access to and use of the Application, or any of its associated Products or Services, is provided by Your 100 Year Life Pty Ltd. Please read these terms and conditions (the 'Terms ') carefully. By using, browsing and/or reading the Application, this signifies that you have read, understood and agree to be bound by the Terms. If you do not agree with the Terms,
                                         you must cease usage of the Application, or any of its Services, immediately.
                                    </li>
                                    <li class="bold-font">Your 100 Year Life Pty Ltd reserves the right to review and change any of the Terms by updating this page at its sole discretion. When Your 100 Year Life Pty Ltd updates the Terms, it will use reasonable endeavours to provide you with notice of updates to the Terms. Any changes to the Terms take immediate effect from the date of their publication. Before you continue, we recommend you keep a copy of the Terms for your records.
                                    </li>
                                </ol>
                                <li class="bold-font"> Acceptance of the Terms</li>

                                <ol class="p-5" type="a">
                                    <li class="bold-font">You accept the Terms by using or browsing the Application. You may also accept the Terms by clicking to accept or agree to the Terms where this option is made available to you by Your 100 Year Life Pty Ltd in the user interface.</li>
                                </ol>

                                <!--3. Subscription to use the Services

(a) In order to access the Services, you must first purchase a subscription through the Application (the 'Subscription') and pay the applicable fee for the selected Subscription (the 'Subscription Fee').

(b) In purchasing the Subscription, you acknowledge and agree that it is your responsibility to ensure that the Subscription you elect to purchase is suitable for your use.

(c) Once you have purchased the Subscription, you will then be required to register for an account through the Application before you can access the Services (the 'Account').

(d) As part of the registration process, or as part of your continued use of the Services, you may be required to provide personal information about yourself (such as identification or contact details), including:

(i) Email address

(ii) Preferred username

(iii) Password

(e) You warrant that any information you give to Your 100 Year Life Pty Ltd in the course of completing the registration process will always be accurate, correct and up to date.

(f) Once you have completed the registration process, you will be a registered member of the Application ('Member ') and agree to be bound by the Terms. As a Member you will be granted immediate access to the Services from the time you have completed the registration process until the subscription period expires (the 'Subscription Period').

(g) You may not use the Services and may not accept the Terms if:

(i) you are not of legal age to form a binding contract with Your 100 Year Life Pty Ltd; or

(ii) you are a person barred from receiving the Services under the laws of Australia or other countries including the country in which you are resident or from which you use the Services.-->

                                <li class="bold-font">Subscription to use the Services</li>

                                    <ol type="a" class="p-5">
                                        <li class="bold-font">

                                            In order to access the Services, you must first purchase a subscription through the
                                             Application (the <b>'Subscription'</b>)
                                             and pay the applicable fee for the selected Subscription (the <b>'Subscription Fee'</b>).
                                        </li>
                                        
                                        <li class="bold-font">
                                        In purchasing the Subscription, you acknowledge and agree that it is your responsibility to ensure that the Subscription you elect to purchase is suitable for your use.
                                            </li>
                                            <li class="bold-font">
                                            Once you have purchased the Subscription, you will then be required to register for an account through the Application before you can access the Services (the 'Account').
                                        </li>
                                            <li class="bold-font">As part of the registration process, or as part of your continued use of the Services, you may be required to provide personal information about yourself (such as identification or contact details), including:
                                            <ol type="i">
                                                <li> Email address</li>
                                                <li> Preferred username</li>
                                                
                                                <li> Password</li>
                                                
                                            </ol>
            
                                        </li>
                                        <li class="bold-font">You warrant that any information you
                                            give to Your 100 Year Life Pty Ltd in the
                                            course of completing the registration process will 
                                            always be accurate, correct and
                                            up to date.
                                        </li>
                                        <li class="bold-font">

                                            Once you have completed the registration process, you will be a registered member of the Application 
                                            (<b>'Member '</b>) and agree to be bound by the Terms. As a Member you will be granted immediate access to 
                                            the Services from the time you have completed the registration process 
                                            until the subscription period expires (the <b>'Subscription Period'</b>).
                                        </li>
                                        <li class="bold-font">You may not use the Services and may not accept the Terms if:
                                            <ol type="i">
                                                <li> You are not of legal age to form a binding contract with Your 100 Year Life Pty Ltd; or</li>
                                                <li> You are a person barred from receiving the Services under the laws of Australia or other countries 
                                                    including the country in which you are resident or from which you use the Services</li>
                                            </ol>
                                        </li>
                                    </ol>

                                    <li class="bold-font">Your obligations as a Member</li>

                                    <ol type="a" class="p-5">
                                        <li class="bold-font">As a Member, you agree to comply with the following: 
                                        
                                            <ol type="i">
                                                <li>You will use the Services only for purposes that are permitted by:
                                                    <ol type="i">
                                                        <li> The Terms; and</li>
                                                        <li> Any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions;</li>
                                                    </ol>
                                                </li>
                                                <li> You have the sole responsibility for protecting the confidentiality of your password and/or email address. Use of your password by
                                                     any other person may result in the immediate cancellation of the Services;</li>
                                                <li> Any use of your registration information by any other person, or third parties, is strictly prohibited. You agree to immediately notify Your 100 Year Life Pty Ltd of any unauthorised use of your password or email address or any breach of security of which you have become aware;</li>
                                                <li> Access and use of the Application is limited, non-transferable and allows for the sole use of the Application by you for the purposes of Your 100 Year Life Pty Ltd providing the Services;</li>
                                                <li> You will not use the Services or the Application in connection with any commercial endeavours except those that are specifically endorsed or approved by the management of Your 100 Year Life Pty Ltd;</li>
                                                <li>You will not use the Services or the Application in connection with any commercial endeavours except those that are specifically endorsed or approved by the management of Your 100 Year Life Pty Ltd;</li>
                                                <li>
                                                    You agree that commercial advertisements, affiliate links, and other forms of solicitation may be removed from the Application without notice and may result in termination of the Services. Appropriate legal action will be taken by Your 100 Year Life Pty Ltd for any illegal or unauthorised use of the Application; and
                                                </li>
                                                <li>
                                                    You acknowledge and agree that any automated use of the Application or its Services is prohibited.
                                                </li>
            
                                            </ol>
            
                                        </li>
                                    </ol>


                                    <li class="bold-font">Payment</li>
                                    <ol type="a" class="p-5">

                                        <li class="bold-font">Where the option is given to you, you may make payment of the Subscription Fee by way of:</li>
                                        <ol type="i">
                                            <li class="bold-font">Electronic funds transfer (<b>'EFT '</b>) into our nominated bank accoun</li>
                                            <li class="bold-font">Credit Card Payment (<b>'Credit Card'</b>)</li>
                                            <li class="bold-font">PayPal (<b>'PayPal'</b>)</li>
                                            
                                        </ol>
                                        <li class="bold-font">All payments made in the course of your use of the Services are made using VISA. In using the Application, the Services or when making any payment in relation to your use of the Services, you warrant that you have read, understood 
                                            and agree to be bound by the VISA terms and conditions which are available on their Application.</li>
                                            <li class="bold-font">  You acknowledge and agree that where a request for the payment of the Subscription Fee is returned or denied, for whatever reason, by your financial institution or is unpaid by you for any other reason, then you are liable for any costs, including banking fees and charges, associated with the Subscription Fee. </li> 

                                        <li class="bold-font">You agree and acknowledge that Your 100 Year Life Pty Ltd can vary the Subscription Fee at any time and that the varied Subscription Fee will come into effect following the conclusion of the existing Subscription Period.</li>


                                    </ol>
                                    
                                    <li class="bold-font">Refund Policy</li>
                                    <ol type="a" class="p-5">
                                        <li class="bold-font">

                                            Your 100 Year Life Pty Ltd will only provide you with a refund of the Subscription 
                                            Fee in the event they are unable to continue to provide the Services or if the manager of Your 100 
                                            Year Life Pty Ltd makes a decision, at its absolute discretion, that it is reasonable to do so under the
                                             circumstances . Where this occurs, the refund 
                                            will be in the proportional amount of the Subscription Fee that remains unused by the Member (the <b>'Refund'</b>).
                                        </li>   

                                    </ol>
                                    
<!--
                                    <li class="bold-font">Purchase of Products and Returns
                                        Policy</li>

                                        <ol type="a" class="p-5">
                                            <li class="bold-font">In using the Purchase Services to use or purchase the services through the
                                                Website, you
                                                will agree to the payment of the purchase price listed on the Website for the
                                                Services (the 'Purchase Price').
                                            </li>
                                            <li class="bold-font">Payment of the Purchase Price may be made through including but not
                                                limited to
                                                PayPal, SecurePay, Credit or Debit Cards (the 'Payment Gateway Provider').
                                                In using the Purchase Services, you warrant that you have familiarised yourself
                                                with, and agree to be bound by, the applicable Terms and Conditions of Use,
                                                Privacy Policy and other relevant legal documentation provided by the Payment
                                                Gateway Providers.</li>
                                            <li class="bold-font">Following payment of the Purchase Price being confirmed by Your 100
                                                Year Life
                                                Pty Ltd, you will be issued with a receipt to confirm that the payment has been
                                                received and Your 100 Year Life Pty Ltd may record your purchase details for
                                                future use.</li>
                                            <li class="bold-font">Your 100 Year Life Pty Ltd may, at their sole discretion, provide a
                                                refund on the
                                                return of the Services within the supplier's terms and conditions days where the
                                                Product packaging is unopened and remains in a saleable condition. You
                                                acknowledge and agree that you are liable for any postage and shipping costs
                                                associated with any refund pursuant to this clause.</li>
                                        </ol>
                                       -->
                                       <!--
                                        <li class="bold-font">Warranty</li>

                                        <ol type="a" class="p-5">
                                            <li class="bold-font">Your 100 Year Life Pty Ltd's Services come with guarantees that cannot
                                                be
                                                excluded under the Australian Consumer Law. You are entitled to a replacement or
                                                refund for a major failure of the Service and compensation for any other
                                                reasonably foreseeable loss or damage. You are also entitled to have the
                                                Products repaired or replaced if the Products fail to be of acceptable quality and
                                                the failure does not amount to a major failure (the 'Warranty').
                                            </li>
                                            <li class="bold-font">You may make a claim under this clause (the 'Warranty Claim') for
                                                material
                                                defects and workmanship in the Services according to suppliers' Terms and
                                                Warranty from the date of purchase (the 'Warranty Period').</li>
                                            <li class="bold-font">In order to make a Warranty Claim during the Warranty Period, you must
                                                provide
                                                proof of purchase to Your 100 Year Life Pty Ltd showing the date of purchase of
                                                the Products, provide a description of the Products and the price paid for the
                                                Products by sending written notice to Your 100 Year Life Pty Ltd at PO Box 496,
                                                Spit Junction, New South Wales, 2088 or by email at admin@your100yearlife.com.</li>
                                           
                                            <li class="bold-font">The Warranty shall be the sole and exclusive warranty granted by Your
                                                100 Year
                                                Life Pty Ltd and shall be the sole and exclusive remedy available to you in addition
                                                to other rights and under a law in relation to the Services to which this warranty
                                                relates.</li>
                                            <li class="bold-font">All implied warranties including the warranties of merchantability and
                                                fitness for
                                                use are limited to the Warranty Period.</li>
                                            <li class="bold-font">The Warranty does not apply to any appearance of the supplied Services
                                                nor to
                                                the additional excluded items set forth below nor to any supplied Services where
                                                the exterior of which has been damaged or defaced, which has been subjected to
                                                misuse, abnormal service or handling, or which has been altered or modified in
                                                design or construction.</li>
                                        </ol>


                                        <li class="bold-font">Delivery</li> 

                                        <ol type="a" class="p-5">
                                            <li class="bold-font">You acknowledge that the Purchase Services offered by Your 100 Year
                                                Life Pty Ltd
                                                integrate delivery (the ' Delivery Services') through the use of third party
                                                delivery companies (the ' Delivery Service Providers').
                                            </li>
                                            <li class="bold-font">In providing the Purchase Services, Your 100 Year Life Pty Ltd may
                                                provide you
                                                with a variety of delivery and insurance options offered as part of the Delivery
                                                Services by the Delivery Service Providers. You acknowledge and agree that Your
                                                100 Year Life Pty Ltd is not the provider of these delivery and insurance options
                                                and merely facilitates your interaction with the Delivery Service Providers in
                                                respect to providing the Delivery Services.</li>
                                            <li class="bold-font">In the event that an item is lost or damaged in the course of the
                                                Delivery Services,
                                                Your 100 Year Life Pty Ltd asks that you:
                                                <ol type="i">
                                                    <li> Contact the Delivery Service Provider directly to request a refund or to claim
                                                        on any insurance options available</li>
                                                    <li>
                                                        Contact us by sending an email to admin@your100yearlife.com outlining in
                                                        what way the Services were deficient to
                                                        determine if the Delivery Service Provider should be removed from the
                                                        Purchase Services.
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                        -->

                                        <li class="bold-font">Copyright and Intellectual Property</li> 

                                        <ol type="a" class="p-5">
                                            <li class="bold-font">The Application, the Services and all of the related products of Your 100 Year Life
                                                 Pty Ltd are subject to copyright. The material on the Application is protected by copyright under the 
                                                 laws of Australia and through international treaties. Unless otherwise indicated, all rights (including 
                                                 copyright) in the Services and compilation of the Application (including but not limited to text, graphics,
                                                  logos, button icons, video images, audio clips, Application, code, 
                                                scripts, design elements and interactive features) or the Services are owned or controlled for these purposes,
                                                 and are reserved by Your 100 Year Life Pty Ltd or its contributors.
                                            </li>
                                            <li class="bold-font">All trademarks, service marks and trade names are owned, registered and/or licensed by Your 100 Year Life Pty Ltd, who grants to you a worldwide, non-exclusive, royalty-free, revocable license whilst you are a Member to:
                                                <ol type="i">
                                                    <li> use the Application pursuant to the Terms;
                                                    </li>
                                                    <li> copy and store the Application and the material contained in the Application in your device's cache memory; and
                                                    </li>
                                                    <li>
                                                        print pages from the Application for your own personal and non-commercial use.
                                                    </li>


                                                </ol>

                                                Your 100 Year Life Pty Ltd does not grant you any other rights whatsoever in relation to the Application or the Services. All other rights are expressly reserved by Your 100 Year Life Pty Lt
                                            </li>

                                            <li class="bold-font">Your 100 Year Life Pty Ltd retains all rights, title and interest in and to the Application and all related Services. Nothing you do on or in relation to the Application will transfer any:
                                                <ol type="i">
                                                    <li> business name, trading name, domain name, trade mark, industrial design, patent, registered design or copyright, or
                                                    </li>
                                                    <li> a right to use or exploit a business name, trading name, domain name, trade mark or industrial design, or
                                                    </li>
                                                    <li>
                                                        a thing, system or process that is the subject of a patent, registered design or copyright (or an adaptation or modification of such a thing, system or process),
                                                    </li>
                                                </ol>
                                            </li>


                                            <li class="bold-font">You may not, without the prior written permission of Your 100 Year Life Pty Ltd and the permission of any other relevant rights owners: broadcast, republish, upload to a third party, transmit, post, distribute, show or play in public, adapt or change in any way the Services or third party Services for any purpose, unless otherwise provided by these Terms. This prohibition does not extend to materials

                                                on the Application, which are freely available for re-use or are in the public domain.
                                            </li>
                                        </ol>

                                        <li class="bold-font">Privacy</li>
                                        <ol type="a" class="p-5">
                                            <li class="bold-font">
                                                Your 100 Year Life Pty Ltd takes your privacy seriously and any information provided through your use of the Application and/or Services are subject to Your 100 Year Life Pty Ltd's Privacy Policy, which is available on the Application.
                                            </li>
                                            
                                        </ol>

                                        <li class="bold-font">General Disclaimer</li> 

                                        <ol type="a" class="p-5">
                                            <li class="bold-font">Nothing in the Terms limits or excludes any
                                                 guarantees, warranties, representations or conditions implied 
                                                or imposed by law, including the Australian Consumer Law (or any
                                                 liability under them) which by law may not be limited or excluded.
                                            </li>
                                            
                                            <li class="bold-font">Subject to this clause, and to the extent permitted by law:
                                                <ol type="i">
                                                    <li> all terms, guarantees, warranties, representations or conditions which are not expressly stated in the Terms are excluded; and
                                                    </li>
                                                    <li> Your 100 Year Life Pty Ltd will not be liable for any special, indirect or consequential loss or damage (unless such loss or damage is reasonably foreseeable resulting from our failure to meet an applicable Consumer Guarantee), loss of profit or opportunity, or damage to goodwill arising out of or in connection with the Services or these Terms (including as a result of not being able to use the Services or the late supply of the Services), whether at common law, under contract, tort (including negligence), in equity, pursuant to statute or otherwise.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li class="bold-font">
                                                
                                                Use of the Application and the Services is at your own risk. Everything
                                                 on the Application and the Services is provided to you "as is" and "as 
                                                 available" without warranty or condition of any kind. None of the affiliates, 
                                                 directors, officers, employees, agents, contributors and licensors of Your 100
                                                  Year Life Pty Ltd make any express or implied representation or warranty about
                                                   the Services or any products or Services (including the products or Services of 
                                                   Your 100 Year Life Pty Ltd) referred to on the Application. This includes
                                                    (but is not restricted to) loss or damage you might suffer as a result of any of the following:
                                            
                                                <ol type="i">
                                                    <li> failure of performance, error, omission, interruption, deletion, defect, failure to correct defects, delay in operation or transmission, computer virus or other harmful component, loss of data, communication line failure, unlawful third party conduct, or theft, destruction, alteration or unauthorised access to records;
                                                    </li>
                                                    <li> the accuracy, suitability or currency of any information on the Application, the Services, or any of its Services related products (including third party material and advertisements on the Application);
                                                    </li>
                                                    <li>costs incurred as a result of you using the Application, the Services or any of the products of Your 100 Year Life Pty Ltd; and</li>
                                                    <li> the Services or operation in respect to links which are provided for your convenience.</li>
                                                    
                                                </ol>
                                            </li>
                                        </ol>

                                        <li class="bold-font">Competitors</li> 
                                        <ol type="a" class="p-5">

                                            <li class="bold-font">
                                                If you are in the business of providing similar Services for the purpose of providing them to users for a commercial gain, whether business users or domestic users, then you are a competitor of Your 100 Year Life Pty Ltd. Competitors are not permitted to use or access any information or content on our Application. If you breach this provision, Your 100 Year Life Pty Ltd will hold you fully responsible for any loss that we may sustain and hold you accountable for all profits that you might make from such a breach.
                                            </li>
                                        </ol>

                                        <li class="bold-font">Limitation of Liability</li> 



                                        <ol type="a" class="p-5">
                                            <li class="bold-font">Your 100 Year Life Pty Ltd's total liability arising out 
                                                of or in connection with the Services or these Terms, however arising, including 
                                                under contract, tort (including negligence), in equity, under statute or otherwise,
                                                 will not exceed the resupply of the Services to you.
                                            </li>
                                            <li class="bold-font">You expressly understand and agree that Your 100 Year Life Pty Ltd,
                                                 its affiliates, employees, agents, contributors and licensors shall not be liable to
                                                  you for any direct, indirect, incidental, special consequential or exemplary damages
                                                   which may be incurred by you, however caused and under any theory of liability. 
                                                   This shall include, but is not limited to, any loss of profit (whether incurred 
                                                   directly or indirectly), any loss of goodwill or business reputation and any other 
                                                   intangible loss.</li>
                                           
                                        </ol>


                                        <li class="bold-font"> Termination of Contract</li> 
                                        <ol type="a" class="p-5">
                                            <li class="bold-font">The Terms will continue to apply until terminated by either you or by Your 100 Year Life Pty Ltd as set out below..</li>
                                            <li class="bold-font">If you want to terminate the Terms, you may do so by:
                                                <ol type="i">
                                                    <li>
                                                        not renewing the Subscription prior to the end of the Subscription Period;
                                                    </li>
                                                    <li>providing Your 100 Year Life Pty Ltd with 10 days' notice of your intention to terminate; and</li>
                                                    <li>closing your accounts for all of the services which you use, where Your 100 Year Life Pty Ltd has made this option available to you.</li>
                                                </ol>

                                                
                                            </li>
                                            <li class="bold-font">Your notice should be sent, in writing, to Your 100 Year Life Pty Ltd
                                                via the
                                                'Contact Us' link on our homepage.</li>
                                            <li class="bold-font">Your 100 Year Life Pty Ltd may at any time terminate the Terms with
                                                you if:
                                                <ol type="i">
                                                    <li>you do not renew the Subscription at the end of the Subscription Period;</li>
                                                    <li> you have breached any provision of the Terms or intend to breach any provision;</li>
                                                    <li> Your 100 Year Life Pty Ltd is required to do so by law;</li>
                                                    <li> the provision of the Services to you by Your 100 Year Life Pty Ltd is, in the opinion 
                                                        of Your 100 Year Life Pty Ltd, no longer commercially viable.</li>
                                                    <!--
                                                    <li> Your 100 Year Life Pty Ltd is transitioning to no longer providing the
                                                        Purchase Services to Users in the country in which you are resident or from
                                                        which you use the service; or</li>
                                                    <li> the provision of the Purchase Services to you by Your 100 Year Life Pty Ltd
                                                        is, in the opinion of Your 100 Year Life Pty Ltd, no longer commercially
                                                        viable.</li>

                                                        -->
                                                </ol>
                                            </li>
                                            <li class="bold-font">Subject to local applicable laws, Your 100 Year Life Pty
                                                 Ltd reserves the right to discontinue or cancel your membership at any time
                                                  and may suspend or deny, in its sole discretion, your access to all or any 
                                                  portion of the Application or the Services without notice if you breach any
                                                   provision of the Terms or any applicable law or if your conduct impacts Your
                                                    100 Year Life Pty Ltd's name or reputation or violates the rights of those of another party.</li>
                                           
                                           <!--
                                                    <li class="bold-font">
                                                When the Terms come to an end, all of the legal rights, obligations and liabilities
                                                that you and Your 100 Year Life Pty Ltd have benefited from, been subject to (or
                                                which have accrued over time whilst the Terms have been in force) or which are
                                                expressed to continue indefinitely, shall be unaffected by this cessation, and the
                                                provisions of this clause shall continue to apply to such rights, obligations and
                                                liabilities indefinitely.</li>

                                                -->
                                        </ol>


                                        <li class="bold-font"> Indemnity</li>



                                        <ol type="a" class="p-5">
                                            <li class="bold-font">You agree to indemnify Your 100 Year Life Pty Ltd, its affiliates, employees, agents, contributors, third party content providers and licensors from and against:
                                                <ol type="i">
                                                    <li> all actions, suits, claims, demands, liabilities, costs, expenses, loss and damage (including legal fees on a full indemnity basis) incurred, suffered or arising out of or in connection with Your Content;</li>
                                                    <li> any direct or indirect consequences of you accessing, using or transacting on the Application or attempts to do so; and/or</li>
                                                    <li> any breach of the Terms.</li>
                                                </ol>
                                            </li>
                                        </ol>

                                        <li class="bold-font"> Dispute Resolution</li>


                                        <ol type="a" class="p-5">
                                                                                   
                                            <li class="bold-font">Compulsory: <br />
                                                If a dispute arises out of or relates to the Terms, either party may not commence any Tribunal or Court proceedings in relation to the dispute, unless the following clauses have been complied with (except where urgent interlocutory relief is sought).</li>
                                            <li class="bold-font">Notice: <br />
                                                A party to the Terms claiming a dispute (<b>'Dispute'</b>) has arisen under the Terms, must give written notice to the other party detailing the nature of the dispute, the desired outcome and the action required to settle the Dispute.</li>
                                            <li class="bold-font">Resolution: <br />
                                                On receipt of that notice (<b>'Notice'</b>) by that other party, the parties to the Terms (<b>'Parties '</b>) must:
                                                <ol type="i">
                                                    <li> Within 21 days of the Notice endeavour in good faith to resolve the Dispute expeditiously by negotiation or such other means upon which they may mutually agree;</li>
                                                    <li> If for any reason whatsoever, 10 days after the date of the Notice, the Dispute has not been resolved, the Parties must either agree upon selection of a mediator or request that an appropriate mediator be appointed by the President of the Resolution Institute or his or her nominee;</li>
                                                    <li> The Parties are equally liable for the fees and reasonable expenses of a mediator and the cost of the venue of the mediation and without limiting the foregoing undertake to pay any amounts requested by the mediator as a pre-condition to the mediation commencing. The Parties must each pay their own costs associated with the mediation;</li>
                                                    <li> The mediation will be held in Sydney, Australia.</li>
                                                </ol>
                                            </li>
                                            <li class="bold-font">Confidential: <br />
                                                All communications concerning negotiations made by the Parties arising out of and in connection with this dispute resolution clause are confidential and to the extent possible, must be treated as "without prejudice" negotiations for the purpose of applicable laws of evidence.</li>
                                            <li class="bold-font">Termination of Mediation: <br />
                                                If 3 days have elapsed after the start of a mediation of the Dispute and the Dispute has not been resolved, either Party may ask the mediator to terminate the mediation and the mediator must do so.</li>
                                        </ol>


                                        <li class="bold-font">Venue and Jurisdiction</li> 

                                        <ol type="a" class="p-5">
                                            <li class="bold-font">
                                                The Services offered by Your 100 Year Life Pty Ltd is intended to be viewed by residents of Australia. 
                                                In the event of any dispute arising out of or in relation to the Application, you agree that the 
                                                exclusive venue for 
                                                resolving any dispute shall be in the courts of New South Wales, Australia.
                                            </li>
                                        </ol>

                                       

                                        <li class="bold-font"> Governing Law</li> 


                                        <ol type="a" class="p-5">
                                            <li class="bold-font">

                                                The Terms are governed by the laws of New South Wales, Australia.
                                                 Any dispute, controversy, proceeding or claim of whatever nature 
                                                 arising out of or in any way relating to the Terms and the rights
                                                  created hereby shall be governed, interpreted and construed by,
                                                   under and pursuant to the laws of New South Wales, Australia,
                                                    without reference to conflict of law principles, notwithstanding
                                                     mandatory rules. The validity of this governing law clause is not
                                                      contested. The Terms shall be binding to the benefit of the parties hereto and their successors and assigns.
                                            </li>
                                        </ol>



                                        <li class="bold-font"> Independent Legal Advice</li> 

                                        <ol type="a" class="p-5">
                                            <li class="bold-font">Both parties confirm and declare that the provisions of the Terms are
                                                 fair and reasonable and both parties having taken the opportunity to obtain independent
                                                  legal advice and declare the Terms are not against public policy on 
                                                the grounds of inequality or bargaining power or general grounds of restraint of trade.
                                            </li>
                                        </ol>


                                       

                                        <li class="bold-font"> Severance</li>


                                            <ol type="a" class="p-5">
                                                                                                    <li class="bold-font">
                                                                                                        If any part of these Terms is found to be void or unenforceable by a 
                                                                                                        Court of competent jurisdiction,
                                                                                                         that part shall be severed and the rest of the Terms shall remain in force.
                                                                                                    </li>
                                                                                                </ol>

                            </ol>
                          </div>
                    </div>
                               
            </div>
        </div>
    </div>
</section><!-- End about Section -->

<app-landing-footer></app-landing-footer>