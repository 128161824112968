import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-landing-footer',
  templateUrl: './landing-footer.component.html',
  styleUrls: ['./landing-footer.component.css']
})
export class LandingFooterComponent implements OnInit {
  email: string = '';
  message: string = '';
  isSubscribed: Boolean = false;
  isError: Boolean = false;
  isLoading_subscribe: Boolean = false;
  isSuccessful_subscribe: Boolean = false;
  constructor(private commonService: CommonService) {}

  ngOnInit(): void {}
  subscribe() {
    this.isLoading_subscribe = true;
    let formData = new FormData();
    formData.append('Limit', '1000');
    formData.append('ContactsList', '19076');
    formData.append('Email', this.email);

    this.commonService.checkSubscribed(formData).subscribe((data: boolean) => {
      if (data === true) {
        this.isLoading_subscribe = false;

        this.isError = true;
        this.message = 'Oops you have already subscribed Your 100 Year Life. ';
      } else {
        this.addToSubList();
      }
    });
  }
  addToSubList() {
    let formData = new FormData();
    formData.append('email', this.email);
    formData.append('subscribe', 'true');
    this.commonService
      .addSubscriptionList(formData)
      .subscribe((data: string) => {
        console.log('data is ', formData);
        if (data.hasOwnProperty('Data')) {
          this.subscribeSuccess();
        } else if (data.hasOwnProperty('ErrorInfo')) {
          this.isLoading_subscribe = false;
          this.isError = true;
          this.message =
            'Oops something goes wrong. Please fill out the empty fields and make sure the email is valid. ';
        } else {
          this.isLoading_subscribe = false;

          this.isError = true;

          this.message =
            'Oops something goes wrong. Please fill out the empty fields and make sure the email is valid. ';
        }
      });
  }
  subscribeSuccess() {
    let formData = new FormData();
    formData.append('toEmail', this.email);
    //  2631193 is the subscribe successfully template
    formData.append('templateID', '2631193');
    formData.append('subject', 'Welcome to Your-100-year-life!');
    this.commonService
      .sendSubSuccessEmail(formData)
      .subscribe((data: string) => {
        if (data['Messages'][0]['Status'] === 'success') {
          this.isLoading_subscribe = false;
          this.isSuccessful_subscribe = true;
          this.isSubscribed = true;
          this.message =
            'Thank you for providing your name and email. You have successfully subscribed to Your 100 Year Life! ';
        } else {
        }
      });
  }
  enableSubscribe() {
    this.isSuccessful_subscribe = false;
  }
}
