<div class="content-wrapper pd-10 bg-white">
  <div class="content-header row border-bottom">
    <div class="content-header-left col-md-12 col-12 mb-2 pl-0 pr-0 pr-0">
      <h3 class="content-header-title font-22 bold-font">
        <i class="yhyl-text icon-width fas fa-info text-center mr-3"></i>
        <span class="yhyl-text pointer" (click)="navigateMenu('main-info')">MY INFORMATION&nbsp;&nbsp;</span>
        <i class="fa fa-angle-right grey-color" aria-hidden="true"></i>
        <i class="fas fa-1x title-icon-style fa-house-user yellow-color"></i>
        <span class="yellow-color pointer font-16" (click)="navigateMenu('main-work')">MY WORK&nbsp;&nbsp;</span>
      </h3>
      <div class="temp" *ngIf="isMainPage">
        <!--Find accurate, reliable and practical information on the "need to know" issues in longevity and ageing. You told us what you need: we sourced it and put it all at your fingertips!-->
        <br />
        <div class="sub-content">
          <p class="yellow-color font-20 bold-font">
            Continue to stay involved throughout your transition to your
            retirement
          </p>
          <p class="grey-color">
            The old idea of ‘retiring’ (literally ‘withdrawing’) at age 60 or 65
            is out of step with the long, productive lives many people live
            today. Longevity means we should all think about and plan for a
            transition to retirement thay may last decades. Here you can find
            resources that help you plan. And work opportunities, paid or
            volunteer, that gives life meaning.
          </p>
        </div>
      </div>
      <div class="row mt-5" *ngIf="isMainPage">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-health-resources.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">
                      PREPARING FOR RETIREMENT
                    </p>
                    <p>
                      'Retirement' means different things to different people.
                      Some people can't wait - golf, long bush-walks, time with
                      grandchildren. Others say they'll never retire. It's
                      important to have a conversation with your partner or
                      other loved ones; decide what your retirement should look
                      and feel like; and plan ahead for your living
                      arrangements, finances, and more. We'll provide you with
                      free advice on how to start that conversation and arrive
                      what works best for you.
                      <i>Ageing - Personalised&trade;</i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-mental-health.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">VOLUNTEERING</p>
                    <p>
                      Want to give back? There are so many opportunities to
                      volunteer, and put your skills, experience and passions to
                      good use and help others. Here are two places to start
                      looking:
                      <a href="https://www.volunteering.nsw.gov.au/" target="_blank">www.volunteering.nsw.gov.au</a>
                      and
                      <a href="https://www.volunteeringaustralia.org/" target="_blank">www.volunteeringaustralia.org</a>
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-medication.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">
                      ACTIVITIES FINDER
                    </p>
                    <p>
                      Volunteer close to home? Find an opportunity that matches
                      best with your place and passion here:
                      <a href="https://govolunteer.com.au/" target="_blank">govolunteer.com.au</a>
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-checklists.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">
                      EMPLOYMENT OPTIONS
                    </p>
                    <p>
                      Many seniors will wind down their paid work gradually, not
                      suddenly. Others will start a new chapter with different,
                      part-time work. It's sad but true that it is hard for most
                      people over 55 to get a new job, but there are options. We
                      have identified firms specialising in 'mature age'
                      recruitment and we have sourced guidance for tuning up
                      your CV and more.
                      <b><em>COMING SOON!</em></b>
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- BEGIN: Customizer-->
<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-xl-block"
  [ngClass]="showUpdates ? 'open' : ''">
  <a class="customizer-close" href="#"><i class="ft-x font-medium-3"></i></a>
  <a class="customizer-toggle primary-yhyl-bg box-shadow-3 btn-hover" (click)="showUpdateToggle()">
    <i class="fa fa-bell h4" theme="light" tooltip="Click to see Updates" placement="left"></i><span
      class="indicator"></span>
  </a>
  <div class="customizer-content p-2">
    <h4 class="border-bottom pb-3 content-header-title font-22 bold-font">
      Hey Test..
    </h4>
    <p class="text-animation">There are 5 new updates for you!</p>
    <p>Last 3 days to renew your service for Travel</p>
    <p>2 new offers on Health Service</p>
  </div>
</div>

<div class="sidenav-overlay"></div>
<div class="drag-target"></div>