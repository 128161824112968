import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ViewChild } from '@angular/core';
import { OnDestroy } from '@angular/core';

@Component({
  selector: 'app-co-pilots',
  templateUrl: './co-pilots.component.html',
  styleUrls: ['./co-pilots.component.css']
})
export class CoPilotsComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isShowing = true;
  showLoader = true;
  showUpdates = false;
  copilotList: any;
  isNewCopilot = false;
  isRestricted = false;
  isFullAccess = false;
  showContent = true;
  username: string;
  isSelectPermission = false;
  isEditPermission = false;
  isDelete = false;
  isSuccess = false;
  isError = false;
  message: string;
  isEdit = false;

  //permissionFormArray: Array<any> = [];

  idToEdit: any;
  copilotToEdit: any;
  docCheckList: any;
  serviceCheckList: any;
  diaryCheckList: any;
  offerCheckList: any;
  docSelectedList: any;
  doc_permission: string;
  serviceSelectedList: any;
  service_permission: string;
  diarySelectedList: any;
  diary_permission: string;
  /* offerSelectedList: any;
  offers_permission:string; */
  constructor(private commonService: CommonService) {}

  title = 'angulardatatables';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };

    this.getColpilots();
    this.docCheckList = [
      {
        label: 'V',
        isChecked: false
      },
      {
        label: 'E',
        isChecked: false
      },
      {
        label: 'D',
        isChecked: false
      }
    ];
    this.serviceCheckList = [
      {
        label: 'V',
        isChecked: false
      },
      {
        label: 'E',
        isChecked: false
      },
      {
        label: 'D',
        isChecked: false
      }
    ];
    this.diaryCheckList = [
      {
        label: 'V',
        isChecked: false
      },
      {
        label: 'E',
        isChecked: false
      },
      {
        label: 'D',
        isChecked: false
      }
    ];
    this.offerCheckList = [
      {
        label: 'V',
        isChecked: false
      },
      {
        label: 'E',
        isChecked: false
      },
      {
        label: 'D',
        isChecked: false
      }
    ];
  }
  changeSelection(type: string) {
    if (type === 'documents') {
      this.docCheckList.forEach((value, index) => {
        if (value.isChecked) {
          this.docSelectedList.push(value.label);
        }
        if (this.docSelectedList.includes('D')) {
          this.doc_permission = 'D';
        } else if (this.docSelectedList.includes('E')) {
          this.doc_permission = 'E';
        } else if (this.docSelectedList.includes('V')) {
          this.doc_permission = 'V';
        } else {
          this.doc_permission = '';
        }
      });
    }
    if (type === 'diary') {
      this.diaryCheckList.forEach((value, index) => {
        if (value.isChecked) {
          this.diarySelectedList.push(value.label);
        }
        if (this.diarySelectedList.includes('D')) {
          this.diary_permission = 'D';
        } else if (this.diarySelectedList.includes('E')) {
          this.diary_permission = 'E';
        } else if (this.diarySelectedList.includes('V')) {
          this.diary_permission = 'V';
        } else {
          this.diary_permission = '';
        }
      });
    }
    if (type === 'services') {
      this.serviceCheckList.forEach((value, index) => {
        if (value.isChecked) {
          this.serviceSelectedList.push(value.label);
        }
        if (this.serviceSelectedList.includes('D')) {
          this.service_permission = 'D';
        } else if (this.serviceSelectedList.includes('E')) {
          this.service_permission = 'E';
        } else if (this.serviceSelectedList.includes('V')) {
          this.service_permission = 'V';
        } else {
          this.service_permission = '';
        }
      });
    }
    /* if(type === 'offers') {
      this.offerCheckList.forEach((value, index) => {
        if (value.isChecked) {
          this.offerSelectedList.push(value.label);
        }
        if(this.offerSelectedList.includes('D')){
          this.offers_permission = 'D';
        } else if(this.offerSelectedList.includes('E')) {
          this.offers_permission = 'E';
        } else if(this.offerSelectedList.includes('V')) {
          this.offers_permission = 'V';
        } else {
          this.offers_permission = '';
        }
      });
    } */
  }

  getColpilots() {
    this.commonService.getCopilots().subscribe(data => {
      this.copilotList = data.map((item: { Permission_choices: string }) =>
        item.Permission_choices === 'RA'
          ? { ...item, Permission_choices: 'Restricted Access' }
          : { ...item, Permission_choices: 'Full Access' }
      );

      this.showLoader = false;
      this.dtTrigger.next();
      // Calling the DT trigger to manually render the table
    });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      this.getColpilots();
    });
  }

  showUpdateToggle() {
    this.showUpdates = !this.showUpdates;
  }

  showAddPopup() {
    this.docSelectedList = [];
    this.diarySelectedList = [];
    /* this.offerSelectedList = [];
    this.offers_permission = ''; */
    this.serviceSelectedList = [];
    this.doc_permission = '';
    this.service_permission = '';
    this.diary_permission = '';
    this.username = '';
    this.isNewCopilot = true;
    this.isSuccess = false;
    this.isError = false;
  }

  showEditPopup(id) {
    this.docSelectedList = [];
    this.diarySelectedList = [];
    //this.offerSelectedList = [];
    this.serviceSelectedList = [];
    this.isSuccess = false;
    this.isError = false;
    this.idToEdit = id;
    this.copilotToEdit = this.copilotList.find(obj => {
      return obj.id == this.idToEdit;
    });
    for (let i = 0; i < this.docCheckList.length; i++) {
      if (this.copilotToEdit.Documents === this.docCheckList[i].label) {
        this.docCheckList[i].isChecked = true;
      }
    }
    for (let i = 0; i < this.serviceCheckList.length; i++) {
      if (this.copilotToEdit.Services === this.serviceCheckList[i].label) {
        this.serviceCheckList[i].isChecked = true;
      }
    }
    for (let i = 0; i < this.diaryCheckList.length; i++) {
      if (this.copilotToEdit.Diary === this.diaryCheckList[i].label) {
        this.diaryCheckList[i].isChecked = true;
      }
    }
    if (this.copilotToEdit.Permission_choices === 'FA') {
      this.isFullAccess = true;
      this.isRestricted = false;
      this.isEditPermission = false;
    } else {
      this.isFullAccess = false;
      this.isRestricted = true;
      this.isEditPermission = true;
    }
    this.isEdit = true;
  }

  showDeletePopup(id) {
    this.idToEdit = id;
    this.isDelete = true;
    this.isSuccess = false;
    this.isError = false;
  }

  setFullAccess() {
    this.isRestricted = false;
    this.isFullAccess = true;
    this.isSelectPermission = false;
    this.isEditPermission = false;
  }

  setRestrictedAccess() {
    if (this.isEdit) {
      this.isSelectPermission = false;
      this.isEditPermission = true;
    } else {
      this.isSelectPermission = true;
      this.isEditPermission = false;
    }
    this.isFullAccess = false;
    this.isRestricted = true;
  }

  savePermissions() {
    this.isSelectPermission = false;
    this.isEditPermission = false;
  }

  saveCopilot() {
    if (this.isFullAccess) {
      var permission = 'FA';
    } else if (this.isRestricted) {
      var permission = 'RA';
    }
    const data = {
      userid: localStorage.getItem('userId'),
      Email: this.username,
      Permission_choices: permission,
      Diary: this.diary_permission,
      Documents: this.doc_permission,
      Offers: 'D',
      Services: this.service_permission
    };
    this.commonService.addCopilots(data).subscribe(
      (response: any) => {
        if (response) {
          this.isSuccess = true;
          this.isError = false;
          this.isNewCopilot = false;
          this.rerender();
        }
      },
      (errorResponse: any) => {
        this.isSuccess = false;
        this.isError = true;
        //On unsuccessful response
      }
    );
  }

  editCopilot() {
    if (this.isFullAccess) {
      var permission = 'FA';
    } else if (this.isRestricted) {
      var permission = 'RA';
    }
    const data = {
      userid: localStorage.getItem('userId'),
      Email: this.copilotToEdit.Email,
      Permission_choices: permission,
      Diary: this.diary_permission,
      Documents: this.doc_permission,
      Offers: 'D',
      Services: this.service_permission
    };
    this.commonService.editCopilot(data, this.idToEdit).subscribe(
      (response: any) => {
        if (response) {
          this.rerender();
          this.isSuccess = false;
          this.isError = false;
          this.isEdit = false;
        }
      },
      (errorResponse: any) => {
        this.isSuccess = false;
        this.isError = false;
        //On unsuccessful response
      }
    );
  }

  deleteCopilot() {
    this.commonService.deleteCopilot(this.idToEdit).subscribe(
      (response: any) => {
        if (response) {
          this.rerender();
        }
        this.isSuccess = true;
        this.isError = false;
        this.message = 'Co-pilot has been deleted successfully'; //On success response
      },
      (errorResponse: any) => {
        this.isSuccess = false;
        this.isError = true;
        this.message = 'Some error occured.Please try again.';
        //On unsuccessful response
      }
    );
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
