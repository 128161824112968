<!-- ======= FAQs Section ======= -->
<section id="faqs" class="faqs about">
  <div class="container" data-aos="fade-up">
    <h2 class="primary-font text-center yhyl-text bold-font">
      Your 100 Year Life - FAQs
      <br />
    </h2>
    <div class="row content py-5">
      <div
        data-aos="zoom-in"
        data-aos-delay="200"
        class="col-12 d-flex p-0 aos-init aos-animate"
      >
        <div class="card p-0">
          <h3 class="primary-font primary-yhyl-bg p-5 text-center">
            Your 100 Year Life FAQs - Common Questions
          </h3>
          <div class="p-5">
            <div class="mt-5">
              <h2 class="primary-font yhyl-text text-left">
                What is Your 100 Year Life?
              </h2>
              <p>
                Your 100 Year Life is a one-stop shop for all the reliable
                information, guides, and tools you need to support your loved
                ones – especially ageing loved ones. You can not only make wiser
                decisions, you can manage and coordinate all of the complicated
                day-to-day, vital arrangements – and store, and share, and more.
                <br /><br />
                We focus exclusively on ‘Your caring life’. We continuously
                listen to what you tell us you need to manage better. We offer
                information, wisdom, guidance for specific issues and decisions,
                and a toolkit for managing better.
              </p>
            </div>

            <div class="mt-5">
              <h2 class="primary-font yhyl-text text-left">
                How can I access free information and guidance?
              </h2>
              <p>
                Easy: You can access all the information and a lot of the
                guidance <b>for free!</b> Just click on
                <span class="yhyl-text"
                  ><a herf="#" target="_blank">www.your100yearlife.com</a></span
                >, sign in, and you can immediately find accurate, reliable and
                timely answers to the main issues that you, our customers, tell
                us you need in order to provide the best support for your loved
                ones as they age in place. <br /><br />
                There are 2 ways to use Your 100 Year Life to simplify your
                caring life:
              </p>
              <ul class="decimal-ul">
                <li>
                  Just go to
                  <span class="yhyl-text"
                    ><a herf="#" target="_blank"
                      >www.your100yearlife.com</a
                    ></span
                  >. Enter your name and email address. You're registered and have access 
                  to all of the free information you'll need to manage your caring life.
                   And for a limited time you can have
                  free (“Freemium”) access to a world of really important
                  information, tools and guidance, relieving a lot of the
                  stress, worry, emotional pain and cost of your caring life.
                  <!-- Click here for a
                  <span class="yhyl-text"
                    ><a herf="#" target="_blank"
                      >How To….Use Your 100 Year Life Freemium video</a
                    ></span
                  > -->
                </li>
                <li>
                  Soon you will be able to access your Personalised Toolkit" to manage it all.
                   The first 250 subscrbers will get free access to this Premium service.
                </li>
              </ul>
            </div>
            <div class="mt-5">
              <h2 class="primary-font yhyl-text text-left">
                How can Your 100 Year Life help me?
              </h2>
              <p>
                Wow! So glad you asked!
                <br /><br />
                <b>At no cost</b>, Your 100 Year Life can help you save time,
                money, worry and stress:
              </p>
              <ul>
                <li>
                  <i class="bx bx-check-double"></i>
                  Find really essential information - instantly. No need to
                  scour the web and try to figure out what is relevant and
                  reliable. For example, some basic Government websites can make
                  your caring life a lot easier – if you know that they exist,
                  where to find them, and how they can save you time and money.
                  Really useful guidance, including videos, are scattered around
                  the web: they help you become more informed and improve the
                  decisions you will make – but only if you can find them and
                  have confidence they are reliable. We do that work for you.
                </li>
                <li>
                  <i class="bx bx-check-double"></i>
                  Instantly find guidance, tips and tools that take the emotion
                  and delay out of some common life experiences. For example,
                  helping your loved one accept that they aren’t safe enough to
                  drive a car anymore; walking your loved one and family members
                  through the process of preparing to execute the legally valid
                  document guiding their end-of-life treatment. Or identifying
                  the range of free, subsidised and other support for daily
                  activities available in your loved one’s community.
                </li>
                <li>
                  <i class="bx bx-check-double"></i>Access simple questionnaires
                  that help you focus on what matters most, and what your
                  options are. For example, How do you know you’re ready to
                  downsize, how to identify the most important features of
                  your new home and community, or open a tough conversation about an
                   older person's driving safely or their Advanced Care Directive? We have sourced really useful
                  questionnaires on downsizing and many other topics. They
                  enable you to make more thoughtful decisions, and share the
                  process with significant others.
                </li>
              </ul>
            </div>

            <div class="mt-5">
              <h2 class="primary-font yhyl-text text-left">
                How do I use Your 100 Year Life?
              </h2>
              <p>
                Soon you will be able to explore our Home page, and Click on our
                <span class="yhyl-text"
                  ><a herf="#" target="_blank">Freemium Video</a></span
                >
                for a quick ‘need to know’ Freemium guide. Click on My
                personalised Toolkit for a quick guide to the world-first
                combination of features you can access at a minimal cost.
                One click on My Personalised Toolkit and you will be guided through the 
                world-first combination of features you can access at a minimal cost.
              </p>
            </div>
            <div class="mt-5">
              <h2 class="primary-font yhyl-text text-left">
                What if I want to do more: How do I personalise, save, manage
                and share?
              </h2>
              <p>
                Manage, store, share, schedule and care by upgrading to a Premium plan. And you will
                see how our Toolkit can help you and your family members – because up to
                5 people may share the same subscription. Saving you stress,
                time, money and making it so much easier to coordinate your
                caring life.
              </p>
            </div>
            <div class="mt-5">
              <h2 class="primary-font yhyl-text text-left">
                What does it cost?
              </h2>
              <p>
                Our treasure trove of information plus a lot of useful guides
                are always FREE. During our early launch, the first 250 people
                to sign up for our Personalised Toolkit get 3 months FREE
                access to Premium, and then they receive a 3 month Trial
                Subscription at only $19. It pays to be
                <i>First in, Best Dressed!</i>
              </p>
            </div>
            <div class="mt-5">
              <h2 class="primary-font yhyl-text text-left">
                How secure are my personal details when I use Your 100 Year
                Life?
              </h2>
              <p>
                <b>Very</b>: Military-grade security helps you protect your
                personal details. We collect only very minimal information about you,
                just enough to make our service work for you. We never sell your
                personal information! <br /><br />
                Instead, when you subscribe to our Premium Personalised Toolkit
                <b
                  ><i
                    >your personal data stays with you – on your device, in the
                    ‘cloud’ or where you choose to store it. We do not collect
                    or store anything but minimal personal details.</i
                  ></b
                ><br /><br />
                So you can rest assured that we only collect minimal details
                about you (See Privacy Policy). Just enough to make sure you
                maintain access and stay up to date.
                <br /><br />
                And as a Premium member, when you activate your toolkit the
                personal details you enter are <b><i>‘exchanged’</i></b> safely
                and only momentarily through our partner, the military-grade
                data ‘exchange’, <b>digi.me</b>. What this means:
              </p>
              <ul>
                <li>
                  <i class="bx bx-check-double"></i>You control your personal
                  data
                </li>
                <li>
                  <i class="bx bx-check-double"></i>You choose when and with
                  whom you share it
                </li>
                <li>
                  <i class="bx bx-check-double"></i>You keep your data, and you
                  keep it safe
                </li>
              </ul>
              <p>
                It means that you can use your customisable Toolkit with
                confidence and peace of mind.
                <br /><br />
                For more about our partners in personal data security, see:
                <b>digi.me</b> and <b>idxchange.me</b>
              </p>
            </div>
            <div class="mt-5">
              <h2 class="primary-font yhyl-text text-left">
                How do I sign up?
              </h2>
              <p>
                Simple: Just enter your name and email address on our main web
                page:
                <span class="yhyl-text"
                  ><a herf="#" target="_blank">www.your100yearlife.com</a></span
                >. <br /><br />
                And you’re good to go!
              </p>
            </div>
            <div class="mt-5">
              <h2 class="primary-font yhyl-text text-left">
                I run a business. How do I sign up my business?
              </h2>
              <p>
                Easy. On our website Home page, scroll down to ‘Are you a
                business?’ and Click.
                <br /><br />

                You can also contact us directly. We are currently signing up
                businesses as partners, channels, suppliers and supporters.
                Please contact:
                <span class="yhyl-text"
                  ><a href="wisdom@your100yearlife.com"
                    >wisdom@your100yearlife.com</a
                  ></span
                >
                and we’ll arrange to take you through what we offer that can
                boost your business, cement your commitment to community, and
                offer unique advantages to your customers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End FAQs Section -->

<app-landing-footer></app-landing-footer>
