<div class="content-wrapper pd-10">
  <div class="content-header row border-bottom">
    <div class="content-header-left col-md-12 col-12 mb-2 pl-0 pr-0">
      <h3 class="content-header-title font-22 bold-font">
        <i class="icon-width fas fa-file text-center mr-3"></i>My Important
        Things
      </h3>
      <p>
        <br />
        This is your secure, private space to store all that is important to you
        in your caring life: vital documents like Power of Attorney and Enduring
        Guardianship; a list of all of your subscriptions that automatically
        renew; a copy of the title to your home; the agreements for a prepaid
        funeral or funeral bond; a list of all your shares, bonds, and other
        assets; location of any safe deposit box; and more. You can give limited
        access to your solicitor or accountant, and help your trusted loved ones
        manage your affairs if you become unable - with your clear approval.
        <br /><br />
        Simply add existing files here, or create a new file with the
        information you compile and add it here.
      </p>
    </div>
  </div>
  <div class="content-body">
    <div class="row mt-3 mb-5" *ngIf="!isCopilot || editDocs || deleteDocs">
      <div class="col-md-12 pl-0">
        <div class="btn btn-primary-yhyl font-20 shadow" (click)="showAddPopup()">
          + Click Here to Add a new Document
        </div>
      </div>
    </div>
    <div class="row mt-3 mb-5 disabled" *ngIf="isCopilot && !editDocs && !deleteDocs">
      <div class="col-md-12 pl-0">
        <div class="btn btn-primary-yhyl font-20 shadow" (click)="showAccessMessage()">
          + Click Here to Add a new Document
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-content collapse show">
        <div class="card-body">
          <div class="card-text">
            <table class="table table-striped table-bordered table-sm row-border hover" datatable
              [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                <tr>
                  <th class="shadow-light">File name</th>
                  <th class="shadow-light">Comments</th>
                  <th class="shadow-light">Download</th>
                  <th class="shadow-light">Edit</th>
                  <th class="shadow-light">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let file of fileList">
                  <td class="file shadow-light">{{ file.document_name }}</td>
                  <td class="comment shadow-light" *ngIf="!(file.comments === '')">
                    {{ file.comments }}
                  </td>
                  <td class="comment shadow-light" *ngIf="file.comments === ''">
                    ---
                  </td>
                  <td class="download shadow-light" (click)="downloadFile(file.file_id, file.document)">
                    <i class="fa fa-file-download"></i>
                  </td>
                  <td class="edit shadow-light" *ngIf="!isCopilot || editDocs || deleteDocs"
                    (click)="showEditPopup(file.file_id)">
                    <i class="fas fa-edit"></i>
                  </td>
                  <td class="edit shadow-light disabled" *ngIf="isCopilot && !editDocs && !deleteDocs"
                    (click)="showAccessMessage()">
                    <i class="fas fa-edit"></i>
                  </td>
                  <td class="delete shadow-light" *ngIf="!isCopilot || deleteDocs"
                    (click)="showDeletePopup(file.file_id)">
                    <i class="fa fa-trash"></i>
                  </td>
                  <td class="delete shadow-light disabled" *ngIf="isCopilot && deleteDocs === false"
                    (click)="showAccessMessage()">
                    <i class="fa fa-trash"></i>
                  </td>
                </tr>
              </tbody>
              <!--  <tbody *ngIf="fileList.length === 0">
                <tr>
                  <td colspan="3" class="no-data-available">No data available in table</td>
                </tr>
              </tbody> -->
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- popup for add new document-->
    <div class="overlay" style="z-index: 2;" *ngIf="isNewUpload">
      <div class="popup col-lg-5 col-md-6 col-sm-6">
        <h3>
          <a class="close" (click)="close()">&times;</a>
          <div class="clearfix"></div>
        </h3>
        <br />
        <div class="content pb-3">
          <div class="row" *ngIf="!isSuccess">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 px-0">
                  <label class="file-input">Choose File<input class="hide-file" type="file"
                      (change)="handleFileInput($event)" /></label>

                  <label class="nav-item pt-1 pl-2" *ngIf="isUploaded">{{
                    fileToUpload.name
                    }}</label>
                  <label class="nav-item pt-1 pl-2" *ngIf="!isUploaded">No File Chosen</label>
                  <div class="col-md-12" *ngIf="noFileError">
                    <div class="mb-5 mt-3 text-center" class="invalid-feedback block">
                      Please select a file.
                    </div>
                  </div>
                  <div class="text-center" *ngIf="upload">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <!-- <progress id="progressBar" value="0" max="100" style="width:300px;"></progress> -->
                  <!-- <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                    attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100"
                    [ngStyle]="{ width: progress + '%' }">
                    {{ progress }}%
                  </div> -->
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 px-0">
                  <h4 class="yhyl-text">Add a Title*</h4>
                  <input class="form-control" [(ngModel)]="fileTitle" name="title" placeholder="Document Title"
                    required="true" type="text" />
                  <div *ngIf="noTitleError && fileTitle === ''">
                    <div class="mb-5 mt-3 text-center" class="invalid-feedback block">
                      This field should not be blank.
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-12 px-0">
                  <h4 class="yhyl-text">Comments(Optional)</h4>
                  <input class="form-control" [(ngModel)]="fileComment" name="comments" placeholder="Comments(Optional)"
                    type="textbox" />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-12 px-0 d-flex flex-column justify-content-end ">
                  <button class="btn btn-primary-yhyl float-right" (click)="addNewDoc()">
                    Add
                  </button>
                </div>
              </div>
            </div>
            <p style="font-size:12px;text-align: center;padding-top:1rem;">
              Your data is secured with military-grade encryption
              <br />
              powered by
              <span><img src="../../assets/New Branding Assets/Shell Logos/digi me.png" class="img-fluid"
                  style="max-width: 17%;" />
              </span>
              and
              <span><img src="../../assets/New Branding Assets/Shell Logos/ID exchange.png" class="img-fluid"
                  style="max-width: 8%;" /></span>
            </p>
          </div>
          <div class="mb-5 mt-3 text-center" class="alert alert-success" *ngIf="isSuccess">
            {{ message }}
          </div>
          <div class="mb-5 mt-3 text-center alert alert-danger" *ngIf="isError">
            {{ message }}
          </div>
        </div>
      </div>
    </div>

    <!-- popup for edit document-->
    <div class="overlay" style="z-index: 2;" *ngIf="isEdit">
      <div class="popup edit-doc-popup  col-lg-5 col-md-6 col-sm-6">
        <h3>
          <a class="close" (click)="close()">&times;</a>
          <div class="clearfix"></div>
        </h3>
        <br />
        <div class="content pb-3">
          <div class="row" *ngIf="!isSuccess">
            <div class="col-md-12">
              <!-- <div class="mb-5 mt-3 text-center" class="alert alert-danger" *ngIf="isEditError">
                {{message}}
              </div> -->
              <div class="row">
                <div class="col-md-12 px-0">
                  <label class="file-input">Choose File*<input class="hide-file" type="file"
                      (change)="handleFileInput($event)" /></label>

                  <label class="nav-item pt-1 pl-2">{{ filename }}</label>
                  <!-- <label class="nav-item pt-1 pl-2" *ngIf="!isUploaded">No File Chosen</label> -->
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-12 px-0">
                  <h4 class="yhyl-text">Add a Title*</h4>
                  <input class="form-control" [(ngModel)]="fileTitle" name="title" placeholder="Document Title"
                    value="fileTitle" required="true" type="text" />
                  <div *ngIf="noTitleError && fileTitle === ''">
                    <div class="mb-5 mt-3 text-center" class="invalid-feedback block">
                      This field should not be blank.
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-12 px-0">
                  <h4 class="yhyl-text">Comments(Optional)</h4>
                  <input class="form-control" [(ngModel)]="fileComment" name="comments" value="fileComment"
                    placeholder="Comments(Optional)" required="true" type="textbox" required />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-12 px-0 d-flex flex-column justify-content-end ">
                  <button class="btn btn-primary-yhyl float-right" (click)="editRecord()">
                    Edit
                  </button>
                </div>
              </div>
            </div>
            <p style="font-size:12px;text-align: center;padding-top:1rem;">
              Your data is secured with military-grade encryption
              <br />
              powered by
              <span><img src="../../assets/New Branding Assets/Shell Logos/digi me.png" class="img-fluid"
                  style="max-width: 17%;" />
              </span>
              and
              <span><img src="../../assets/New Branding Assets/Shell Logos/ID exchange.png" class="img-fluid"
                  style="max-width: 8%;" /></span>
            </p>
          </div>
          <div class="mb-5 mt-3 text-center" class="alert alert-success" *ngIf="isSuccess">
            {{ message }}
          </div>
          <div class="mb-5 mt-3 text-center alert alert-danger" *ngIf="isError">
            {{ message }}
          </div>
        </div>
      </div>
    </div>

    <!-- popup for delete -->
    <div class="overlay" style="z-index: 2;" *ngIf="isDelete">
      <div class="popup edit-doc-popup  col-lg-5 col-md-6 col-sm-6">
        <h3>
          <a class="close" (click)="close()">&times;</a>
          <div class="clearfix"></div>
        </h3>
        <br />
        <div class="content">
          <div *ngIf="!isSuccess">
            <div class="row text-center m-4 border-bottom">
              <div class="col-md-12">
                <h4 class="yhyl-text">Do you want to delete this record?</h4>
              </div>
            </div>
            <div class="row mb-5 text-center">
              <div class="col-6">
                <button style="width:100%" class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 active"
                  (click)="deleteRecord()">
                  Yes
                </button>
              </div>
              <div class="col-6">
                <button style="width:100%" class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 active"
                  (click)="isDelete = false">
                  No
                </button>
              </div>
            </div>
          </div>
          <div class="mb-5 mt-3 text-center alert alert-success" *ngIf="isSuccess">
            {{ message }}
          </div>
          <div class="mb-5 mt-3 text-center alert alert-danger" *ngIf="isError">
            {{ message }}
          </div>
        </div>
      </div>
    </div>

    <!-- popup for access message -->
    <div class="overlay" style="z-index: 2;" *ngIf="isAction">
      <div class="popup col-lg-5 col-md-6 col-sm-6">
        <h3>
          <a class="close" (click)="isAction = false">&times;</a>
          <div class="clearfix"></div>
        </h3>
        <br />
        <div class="content">
          <div class="mb-5 mt-3 text-center alert alert-danger">
            {{ actionMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="text-center" *ngIf="showLoader">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<!-- BEGIN: Customizer-->
<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-xl-block"
  [ngClass]="showUpdates ? 'open' : ''">
  <a class="customizer-close" href="#"><i class="ft-x font-medium-3"></i></a>
  <a class="customizer-toggle primary-yhyl-bg box-shadow-3 btn-hover" (click)="showUpdateToggle()">
    <i class="fa fa-bell h4" theme="light" tooltip="Click to see Updates" placement="left"></i><span
      class="indicator"></span>
  </a>
  <div class="customizer-content p-2">
    <h4 class="border-bottom pb-3 content-header-title font-22 bold-font">
      Hey Test..
    </h4>
    <p class="text-animation">There are 5 new updates for you!</p>
    <p>Last 3 days to renew your service for Travel</p>
    <p>2 new offers on Health Service</p>
  </div>
</div>

<div class="sidenav-overlay"></div>
<div class="drag-target"></div>