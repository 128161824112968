<div class="content-wrapper pd-10 bg-white">
    <div class="content-header row">
        <div class="content-header-left col-md-12 col-12 mb-2 pl-0 pr-0 pr-0">
            <h3 class="content-header-title font-22 bold-font">
                <i class="yhyl-text icon-width fas fa-info text-center mr-3"></i>
                <span class="yhyl-text pointer" (click)="navigateMenu('main-info')">MY INFORMATION&nbsp;&nbsp;</span>
                <i class="fa fa-angle-right grey-color" aria-hidden="true"></i>
                <i class="fas fa-1x title-icon-style fa-heartbeat health-color"></i>
                <span class="health-color pointer font-16" (click)="navigateMenu('main-health')">MY
                    HEALTH&nbsp;&nbsp;</span>
                <span class="health-color font-16" *ngIf="activeHealthSubTab === 'medication'"><i
                        class="fa fa-angle-right grey-color" aria-hidden="true"></i>&nbsp;&nbsp;MEDICATION</span>
                <span class="health-color font-16" *ngIf="activeHealthSubTab === 'checklists'"><i
                        class="fa fa-angle-right grey-color" aria-hidden="true"></i>&nbsp;&nbsp;CHECKLISTS AND
                    QUESTIONAIRE</span>
                <span class="health-color font-16" *ngIf="activeHealthSubTab === 'resources'"><i
                        class="fa fa-angle-right grey-color" aria-hidden="true"></i>&nbsp;&nbsp;HEALTH RESOURCES</span>
            </h3>
            <div class="temp" *ngIf="isMainPage">
                <!--Find accurate, reliable and practical information on the "need to know" issues in longevity and ageing. You told us what you need: we sourced it and put it all at your fingertips!-->
                <br />
                <div class="sub-content">
                    Lorem Ipsum
                </div>
            </div>
            <div class="row mt-5" *ngIf="isMainPage">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="card collapse show">
                        <div class="card-body">
                            <div class="card-text text-center">
                                <div class="row">
                                    <div class="col-lg-2 col-m-12 col-sm-12">
                                        <img class="card-img-top img-fluid info-img"
                                            src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-health-resources.png"
                                            alt="can't load">
                                    </div>
                                    <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                                        <p class="grey-color bold-font font-20">HEALTH RESOURCES</p>
                                        <p>Your 100-year Life does not give health advice. But we do assemble really
                                            useful information from impeccable sources. We can save you a lot of time
                                            and effort – and make it easy to share new information and concerns with
                                            family and friends. And that’s just one example of what you will find in My
                                            Information Centre: My Health. All the resources you need, all in one place,
                                            right here.
                                            <br />
                                            Your first stop for the most complete, authoritative health advice and
                                            questions for all Australians is here: <a
                                                href="https://www.healthdirect.gov.au/" target="_blank">
                                                www.healthdirect.gov.au</a>. Health direct is the official,
                                            evidence-based.
                                            It takes you to the official evidence based resource for all your health
                                            questions. <b class="font-18">You can have access to a qualified
                                                and certified nurse 24x7 for free!</b> It can direct you to
                                            resources including checklists that you can bring with you on doctor visits
                                            to make sure
                                            that all of your questions are answered. Use the HealthDirect question
                                            builder to create customised questions our mum can use when she visits her
                                            specialists.
                                            Click here to visit: <a href="https://www.healthdirect.gov.au/"
                                                target="_blank">
                                                www.healthdirect.gov.au</a>.
                                        </p>
                                        <button type="submit"
                                            class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn"
                                            (click)="infoHealthSubTab('resources')">Read
                                            More
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="card collapse show">
                        <div class="card-body">
                            <div class="card-text text-center">
                                <div class="row">
                                    <div class="col-lg-2 col-m-12 col-sm-12">
                                        <img class="card-img-top img-fluid info-img"
                                            src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-mental-health.png"
                                            alt="can't load">
                                    </div>
                                    <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                                        <p class="grey-color bold-font font-20">MENTAL HEALTH</p>
                                        <p>You’ll find an amazing collection of trusted resources, tools, and guidance
                                            here: <a href="https://headtohealth.gov.au/"
                                                target="_blank">headtohealth.gov.au. </a>And not
                                            just for seniors. For everyone. Lots of services available online, by phone
                                            and of course in
                                            person. All reviewed and promoted by authoritative sources.
                                            You'll land in the most useful and complete place for information, guidance
                                            and apps for
                                            mental health and wellbeing.
                                        </p>
                                        <button type="submit"
                                            class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">Read
                                            More
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="card collapse show">
                        <div class="card-body">
                            <div class="card-text text-center">
                                <div class="row">
                                    <div class="col-lg-2 col-m-12 col-sm-12">
                                        <img class="card-img-top img-fluid info-img"
                                            src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-medication.png"
                                            alt="can't load">
                                    </div>
                                    <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                                        <p class="grey-color bold-font font-20">MEDICATION</p>
                                        <p>Looking for accurate and reliable information about
                                            medications? Here it is: <a href="https://www.healthdirect.gov.au/medicines"
                                                target="_blank">www.healthdirect.gov.au/medicines</a>. Save valuable
                                            information, guidance and links onto your
                                            Personal Page, and share what you need for your own unique circumstances.

                                            Did you know that you can monitor and manage your loved one's medications at
                                            a distance,
                                            online, and you can have them delivered without anyone stopping into the
                                            chemist?
                                        </p>
                                        <button type="submit"
                                            class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn"
                                            (click)="infoHealthSubTab('medication')">Read
                                            More
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="card collapse show">
                        <div class="card-body">
                            <div class="card-text text-center">
                                <div class="row">
                                    <div class="col-lg-2 col-m-12 col-sm-12">
                                        <img class="card-img-top img-fluid info-img"
                                            src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-checklists.png"
                                            alt="can't load">
                                    </div>
                                    <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                                        <p class="grey-color bold-font font-20">CHECKLISTS AND QUESTIONAIRE</p>
                                        <p>We make it simple and convenient to get all your loved one's important health
                                            and wellbeing information' organised. For instance, get organised for
                                            important doctor's visits - whether you or your loved one is the patient.
                                            Let us show you how:
                                            A sample of a trustworthy and thorough checklist you need to do before your
                                            next doctor's
                                            visit - for yourself or ageing loved one - is here: <a
                                                href="https://www.healthdirect.gov.au/question-builder"
                                                target="_blank">www.healthdirect.gov.au/question-builder</a>. Try it and
                                            see how it will
                                            help simplify and organise your next doctor's visit. You'll never again walk
                                            out of a
                                            doctor's office and suddenly think - "I forgot to ask something really
                                            important!". It's a
                                            sample of what we'll be adding in coming weeks and months to simplify and
                                            organise your
                                            caring life.
                                        </p>
                                        <button type="submit"
                                            class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn"
                                            (click)="infoHealthSubTab('checklists')">Read
                                            More
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!isMainPage" id="subpageReading">
                <div class="row mt-5" *ngIf="activeHealthSubTab === 'medication'">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="card collapse show bg-subcontent">
                            <div class="card-body">
                                <div class="card-text text-center">
                                    <div class="row">
                                        <div class="col-12" style="text-align:justify">
                                            <p class="yhyl-text bold-font font-20">TRUSTWORTHY AND RELIABLE MEDICATION
                                                INFORMATION</p>
                                            <p>
                                                is available online at HealthDirect the Australian Government's online
                                                site.
                                                It contains all the information you need, for Australians by
                                                Australians. <a href="https://www.healthdirect.gov.au/medicines"
                                                    target="_blank">Click Here</a>.
                                            </p>

                                            <button type="submit"
                                                class="btn btn-grey-color text-white px-5 glow mb-1 mb-sm-0 mr-0 mr-sm-1 info-btn">Website
                                            </button>
                                            <!-- <img class="float-right card-img-top img-fluid info-img"
                                                src="../../../../assets/New Branding Assets/yhyl-for-web-images/share-icon.png">
                                            <img class="float-right card-img-top img-fluid info-img"
                                                src="../../../../assets/New Branding Assets/yhyl-for-web-images/fav-icon.png"> -->

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="card collapse show bg-subcontent">
                            <div class="card-body">
                                <div class="card-text text-center">
                                    <div class="row">
                                        <div class="col-12" style="text-align:justify">
                                            <p class="yhyl-text bold-font font-20">ASSURING THAT YOUR LOVED ONE IS
                                                RECEIVING THE MEDICATION PRESCRIBED
                                            </p>
                                            <p>
                                                as and when the doctor orders. One of the most frequent questions we
                                                receive concerns making sure that loved ones living at a distance are in
                                                fact taking the right medications, at the right times, and in the right
                                                doses. There are many 'apps for that'. But apps are only one way. There
                                                are also many other ways to ensure essential prescription medications
                                                are taken correctly. We examine a few of the alternatives so you can
                                                choose what is best for you and your individual circumstances.
                                            </p>
                                            <button type="submit"
                                                class="btn btn-grey-color text-white px-5 glow mb-1 mb-sm-0 mr-0 mr-sm-1 info-btn">Website
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="card collapse show bg-subcontent">
                            <div class="card-body">
                                <div class="card-text text-center">
                                    <div class="row">
                                        <div class="col-12" style="text-align:justify">
                                            <p class="yhyl-text bold-font font-20">CONSOLIDATE ALL OF YOUR - OR YOUR
                                                LOVED ONES'S MEDICATION INFORMATION IN ONE PLACE
                                            </p>
                                            <p>
                                                in case of emergency or confusion. Safeguard the names, dosages and
                                                prescribing doctors' names - and photos of each medication and its
                                                container - in your personal and secure space. ※Consider sharing it with
                                                your trusted loved one.
                                            </p>
                                            <button type="submit"
                                                class="btn btn-grey-color text-white px-5 glow mb-1 mb-sm-0 mr-0 mr-sm-1 info-btn">Website
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="card collapse show bg-subcontent">
                            <div class="card-body">
                                <div class="card-text text-center">
                                    <div class="row">
                                        <div class="col-12" style="text-align:justify">
                                            <p class="yhyl-text bold-font font-20">MEDICATION SUPPLIES AND REMINDERS:
                                                COOMING SOON!</p>
                                            <p>
                                                A wide range of kits, tools, apps and devices to help the correct and
                                                timely supply and administration of medication.
                                            </p>
                                            <button type="submit"
                                                class="btn btn-grey-color text-white px-5 glow mb-1 mb-sm-0 mr-0 mr-sm-1 info-btn">Website
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5" *ngIf="activeHealthSubTab === 'checklists'">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="card collapse show bg-subcontent">
                            <div class="card-body">
                                <div class="card-text text-center">
                                    <div class="row">
                                        <div class="col-12" style="text-align:justify">
                                            <p class="yhyl-text bold-font font-20">TAKING YOUR LOVED ONE TO THE DOCTOR
                                            </p>
                                            <p>
                                                Prepare well - support better. Take a moment to discuss with your loved
                                                one the purpose of their next doctor's visit. Use the checklist at
                                                HealthDirect as your guide. You both will get a lot more out of the
                                                visit: answers to all of your questions, a clear understand of what it
                                                means and what to do next.
                                                <a href="https://www.healthdirect.gov.au/question-builder"
                                                    target="_blank">Click here</a> for a trustworthy and thorough
                                                checklist that will simplify and organise your next doctor's visit.
                                                Whether it is for yourself or a loved one. You'll never again walk out
                                                of a doctor's office and suddenly think - "I forgot something really
                                                important to ask!".
                                                <br /><br />
                                                It's a sample of what we'll be adding in coming weeks and months to
                                                simplify and organise your caring life.
                                            </p>
                                            <button type="submit"
                                                class="btn btn-grey-color text-white px-5 glow mb-1 mb-sm-0 mr-0 mr-sm-1 info-btn">Website
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5" *ngIf="activeHealthSubTab === 'resources'">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="card collapse show bg-subcontent">
                            <div class="card-body">
                                <div class="card-text text-center">
                                    <div class="row">
                                        <div class="col-12" style="text-align:justify">
                                            <p class="yhyl-text bold-font font-20">HEALTH DIRECT
                                            </p>
                                            <p class="grey-color">It takes you to the official evidence based resource
                                                for all your health
                                                questions.
                                                <br /><br />
                                                <span class="bold-font p-3 bg-text m-2">You can have access to a
                                                    qualified
                                                    and certified nurse
                                                    24x7 for free!</span><br /><br />
                                                It can direct you to resources including checklists that you can bring
                                                with you on doctor
                                                visits to
                                                make sure that all of your questions are answered. Use the HealthDirect
                                                question builder to
                                                create
                                                customised questions our mum can use when she visits her specialists.

                                            </p>
                                            <button type="submit"
                                                class="btn btn-grey-color text-white px-5 glow mb-1 mb-sm-0 mr-0 mr-sm-1 info-btn">Website
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>



<!-- BEGIN: Customizer-->
<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-xl-block" [ngClass]="
    (showUpdates)?'open':''">
    <a class="customizer-close" href="#"><i class="ft-x font-medium-3"></i></a>
    <a class="customizer-toggle primary-yhyl-bg box-shadow-3 btn-hover" (click)=showUpdateToggle()>
        <i class="fa fa-bell h4" theme="light" tooltip="Click to see Updates" placement="left"></i><span
            class="indicator"></span>
    </a>
    <div class="customizer-content p-2">
        <h4 class="border-bottom pb-3 content-header-title font-22 bold-font">Hey Test..</h4>
        <p class="text-animation">There are 5 new updates for you!</p>
        <p>Last 3 days to renew your service for Travel</p>
        <p>2 new offers on Health Service</p>
    </div>
</div>

<div class="sidenav-overlay"></div>
<div class="drag-target"></div>