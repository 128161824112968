<div class="content-wrapper pd-10">
  <div class="welcome_user_card">
    <div class="card_left">
      <div>
        <h1 style="text-align: left;">
          WELCOME
          <span class="deep_green_color">{{ userData.first_name }},</span>
        </h1>
      </div>
      <div>
        <h3>You've arrived!</h3>
      </div>
      <div class="body">
        You've taken the first step toward de-stressing and simplifying your
        ageing marathon.
      </div>
    </div>
    <div class="card_right">
      <img src="../../../../assets/New Branding Assets/yhyl-for-web-images/welcome-lady.png" />
    </div>
  </div>
  <div class="all_info_card">
    <h4>
      ALL THE INFORMATIONN AND GUIDANCE YOU NEED TO MANAGE THE MARATHON of AGEING
    </h4>
  </div>
  <div class="explore_control">
    <div class="explore_control_card explore_color">
      <div class="icon_wrapper">
        <img src="../../../../assets/New Branding Assets/yhyl-for-web-images/mark-info-explore.png" />
      </div>
      <div>
        <h1>EXPLORE</h1>
      </div>
      <div class="lead_text">
        Everything you need to know, plan and do is here at your fingertips. So
        you can care better for your loved ones - simply and effectively.
      </div>
      <a type="button" class="explore_btn" href="/information">
        CONTINUE
      </a>
      <div class="card_mark_explore">
        <img src="../../../../assets/New Branding Assets/yhyl-for-web-images/ribbon-forever-free.png" />
      </div>
    </div>
    <div class="explore_control_card control_color">
      <div class="icon_wrapper">
        <img src="../../../../assets/New Branding Assets/yhyl-for-web-images/mark-premium-control.png" />
      </div>
      <div>
        <h1>CONTROL</h1>
      </div>
      <div class="lead_text">
        Take control, manage and care all in your personal control centre.
        Monitor, track, store, solve, delegate, coordinate, schedule, remind.
      </div>
      <a class="control_btn">
        CONTINUE
      </a>
      <div class="card_mark_premium">
        <img src="../../../../assets/New Branding Assets/yhyl-for-web-images/ribbon-premium.png" />
      </div>
    </div>
  </div>
</div>