<div class="content-wrapper pd-10 bg-white">
  <div class="content-header row border-bottom">
    <div class="content-header-left col-md-12 col-12 mb-2 pl-0 pr-0 pr-0">
      <h3 class="content-header-title font-22 bold-font">
        <i class="yhyl-text icon-width fas fa-info text-center mr-3"></i>
        <span class="yhyl-text pointer" (click)="navigateMenu('main-info')">MY INFORMATION&nbsp;&nbsp;</span>
        <i class="fa fa-angle-right grey-color" aria-hidden="true"></i>
        <i class="fas fa-1x title-icon-style fa-house-user purple-color"></i>
        <span class="purple-color pointer font-16" (click)="navigateMenu('main-technology')">MY
          FINANCE&nbsp;&nbsp;</span>
      </h3>
      <div class="temp" *ngIf="isMainPage">
        <!--Find accurate, reliable and practical information on the "need to know" issues in longevity and ageing. You told us what you need: we sourced it and put it all at your fingertips!-->
        <br />
        <div class="sub-content">
          <p class="purple-color font-20 bold-font">
            Make Your Money Go Further and Protect Your Assets
          </p>
          <p class="grey-color">
            Planning finance for the retirement years is challenging: the
            regulations around superannuation (retirement) are complicated; none
            of us knows how long we will live; and sometimes other big decisions
            are involved (like selling the family home and relocating). Your 100
            Year Life does not provide financial advice. But we do give you
            direct links to access a lot of useful information and resources.
          </p>
        </div>
      </div>
      <div class="row mt-5" *ngIf="isMainPage">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-health-resources.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">MONEY MANAGEMENT</p>
                    <p>
                      Making your money go further should be a top priority for
                      all of us. It’s essential for those of us who have left
                      the workforce and are relying on superannuation, the
                      pension, savings, and other means to fund our longevity.
                      Check frequently with this page for new and clever ways to
                      make sure you are getting the best value for money on
                      life’s essentials.
                      <br />
                      We have compiled the ‘need to know’ sources for keeping
                      track of and managing your money, assets and policies. We
                      also guide you on how to take advantage of the discounts
                      and rebates available to you. You probably know and use
                      some, but probably not all.
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-mental-health.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">MONEY</p>
                    <p>
                      Keep track of, protect, and manage your money better. It
                      seems that every day there is something new - usually
                      digital - that helps all of us keep track of our
                      expenditure. One by one the "Big 4 Banks" are rolling out
                      planners and apps. So check with your bank and see what
                      they already offer.
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-medication.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">
                      INSURANCE AND OTHER POLICIES
                    </p>
                    <p>
                      Keep track of and manage all of your policies. If
                      something were to happen to you, could your loved ones
                      find all of your policies and reassess, modify or cancel
                      them? When you sign up for Premium you can access a
                      high-security place to list and store all of your
                      policies. That's essential because you want to make sure
                      that you have renewed and are covered with the insurances
                      you want. And you want to make sure that you don't have
                      overlapping or unnecessary policies. It happens more often
                      than you think.
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-checklists.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">SUPERANNUATION</p>
                    <p>
                      Keep track of, manage, and make sure your retirement
                      savings will support you through your life, and make sure
                      you are using your resources to support a comfortable -
                      not necessarily minimal - lifestyle in retirement. Free
                      apps, retirement calculators, and other resources are
                      available to help you plan.
                      <a href="https://www.moneysmart.gov.au/glossary/asfa-retirement-standard" target="_blank">Click
                        here for an example of what you will find on this
                        page.</a>
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-checklists.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">
                      DISCOUNTS AND REBATES
                    </p>
                    <p>
                      The NSW Government, through Service NSW, makes it easy for
                      you to learn what discounts and rebates may be available
                      to you. Service NSW is continually expanding the list. And
                      now you can even schedule a personal appointment at a NSW
                      Service Centre that helps you make sure you are getting
                      all the discounts and rebates you're eligible for.
                      <a href="https://www.service.nsw.gov.au/campaign/cost-living" target="_blank">Click here to visit
                        Service NSW.</a>
                      <br /><br />
                      In addition, Service NSW has a growing list of
                      ‘comparison’ sites that enable you to find, the least
                      expensive petrol (<a href="https://www.service.nsw.gov.au/transaction/check-fuel-prices"
                        target="_blank">Click here to visit</a>) , Green Slip (<a
                        href="https://www.service.nsw.gov.au/transaction/use-green-slip-price-check"
                        target="_blank">Click here to visit</a>) and more. <br /><br />If you are a CBA customer you can
                      access CBA's list of benefits and rebates. Easily
                      accessible after you log in as a customer.

                      <br /><br />

                      The granddaddy of discounts and deals available to seniors
                      in Australia: If you are 60 years of age, and meet the
                      minimal requirements, you can save tens of dollars a week
                      by using your Seniors Card for transport and basic goods
                      and services. (Links to Seniors Card in other states are
                      here:
                      <a href="https://www.australia.gov.au/content/seniors-card" target="_blank">Click here to view
                        Seniors Card in other states.</a>
                      Commonwealth bank customers have access to the bank's
                      benefits, rebates and concessions finder.
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-checklists.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">MY ASSETS</p>
                    <p>
                      There are many times when you need to have an inventory of
                      all of your assets. Money, shares, your home - that's
                      obvious. But what about a pre-paid cemetery plot or
                      funeral? What about some never-used bank account with
                      little in it - but useful to a grandchild? Absolutely make
                      sure you check if you have any 'lost super':
                      <a href="https://www.ato.gov.au/forms/…hing-for-lost-super/"
                        target="_blank">www.ato.gov.au/forms/…hing-for-lost-super</a>. <br /><br />
                      When you sign up for Premium, you get a safe place to
                      store a list and details of your assets. And, if you wish,
                      safe sharing with your trusted loved ones, solicitor, and
                      financial planner. List once and you'll always have it
                      safe and ready.
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-checklists.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">
                      USE YOUR LOCAL CLUBS
                    </p>
                    <p>
                      Local clubs are the heartthrob of many and regional
                      communities, and always have been. Lately many clubs have
                      undergone change, ranging from a refresh to a major
                      transformation, to appeal to new members and guests. Make
                      use not only of your member discounts in your club, but
                      also the discounts offered by their vendors. <br /><i>Your 100 Year Life does not provide
                        financial advice or
                        products. You should always check with your financial
                        adviser before making any financial decision.</i>
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- BEGIN: Customizer-->
<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-xl-block"
  [ngClass]="showUpdates ? 'open' : ''">
  <a class="customizer-close" href="#"><i class="ft-x font-medium-3"></i></a>
  <a class="customizer-toggle primary-yhyl-bg box-shadow-3 btn-hover" (click)="showUpdateToggle()">
    <i class="fa fa-bell h4" theme="light" tooltip="Click to see Updates" placement="left"></i><span
      class="indicator"></span>
  </a>
  <div class="customizer-content p-2">
    <h4 class="border-bottom pb-3 content-header-title font-22 bold-font">
      Hey Test..
    </h4>
    <p class="text-animation">There are 5 new updates for you!</p>
    <p>Last 3 days to renew your service for Travel</p>
    <p>2 new offers on Health Service</p>
  </div>
</div>

<div class="sidenav-overlay"></div>
<div class="drag-target"></div>