<section id="contact" class="contact">
  <div class="container" data-aos="fade-up">
    <div class="section-title text-center">
      <h2>Message Us Here</h2>
    </div>

    <div id="bg" class="row content">
      <div class="col-lg-12 mt-5 mt-lg-0">
        <form
          action="forms/contact.php"
          method="post"
          role="form"
          class="php-email-form"
        >
          <div class="form-row">
            <div class="col-md-6 form-group">
              <input
                type="text"
                name="name"
                class="form-control"
                id="name"
                placeholder="Your Name"
                data-rule="minlen:4"
                data-msg="Please enter at least 4 chars"
              />
              <div class="validate"></div>
            </div>
            <div class="col-md-6 form-group">
              <input
                type="email"
                class="form-control"
                name="email"
                id="email"
                placeholder="Your Email"
                data-rule="email"
                data-msg="Please enter a valid email"
              />
              <div class="validate"></div>
            </div>
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              name="subject"
              id="subject"
              placeholder="Subject"
              data-rule="minlen:4"
              data-msg="Please enter at least 8 chars of subject"
            />
            <div class="validate"></div>
          </div>
          <div class="form-group">
            <textarea
              class="form-control"
              name="message"
              rows="5"
              data-rule="required"
              data-msg="Please write something for us"
              placeholder="Message"
            ></textarea>
            <div class="validate"></div>
          </div>

          <div class="text-center">
            <button type="submit">
              <div
                class="spinner-border"
                role="status"
                *ngIf="isLoading_sendMsg"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <div *ngIf="!isLoading_sendMsg && !isSuccessful_sendMsg">
                Send Message
              </div>
              <div *ngIf="isSuccessful_sendMsg">
                <i class="fas fa-check"></i>
              </div>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<!-- End Contact Section -->

<app-landing-footer></app-landing-footer>
