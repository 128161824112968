<div class="content-overlay">

</div>
<div class="content-wrapper  pt-1">

  <div class="content-body">
    <!-- Description -->
    <section id="description" class="">
      <div class="col-sm-9 col-md-7 col-lg-4 mx-auto">
        <div class="card card-signin my-5 shadow">
          <div class="card-body">
            <h4 class="card-title text-cenpter yhyl-text">Thankyou for visiting !</h4>
          </div>
        </div>
      </div>
    </section>

  </div>
</div>