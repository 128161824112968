import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
  name: string = '';
  email: string = '';
  message: string = '';
  isSubscribed: Boolean = false;
  isError: Boolean = false;
  isLoading_signmeup: Boolean = false;
  isLoading_starthere: Boolean = false;
  isLoading_clickbusiness: Boolean = false;
  isSuccessful_signmeup: Boolean = false;
  constructor(private commonService: CommonService) {}

  ngOnInit(): void {}
  subscribe() {
    this.isLoading_signmeup = true;
    let formData = new FormData();
    formData.append('Limit', '1000');
    formData.append('ContactsList', '19076');
    formData.append('Email', this.email);

    this.commonService.checkSubscribed(formData).subscribe((data: boolean) => {
      if (data === true) {
        this.isLoading_signmeup = false;
        this.isError = true;
        this.message = 'Oops you have already subscribed Your 100 Year Life. ';
      } else {
        this.addToSubList();
      }
    });
  }

  addToSubList() {
    let formData = new FormData();
    formData.append('name', this.name);
    formData.append('email', this.email);
    formData.append('subscribe', 'true');
    this.commonService
      .addSubscriptionList(formData)
      .subscribe((data: string) => {
        console.log('data is ', formData);
        if (data.hasOwnProperty('Data')) {
          this.subscribeSuccess();
        } else if (data.hasOwnProperty('ErrorInfo')) {
          this.isLoading_signmeup = false;
          this.isError = true;
          this.message =
            'Oops something goes wrong. Please fill out the empty fields and make sure the email is valid. ';
        } else {
          this.isLoading_signmeup = false;
          this.isError = true;
          this.message =
            'Oops something goes wrong. Please fill out the empty fields and make sure the email is valid. ';
        }
      });
  }
  subscribeSuccess() {
    let formData = new FormData();
    formData.append('toName', this.name);
    formData.append('toEmail', this.email);
    //  2631193 is the subscribe successfully template
    formData.append('templateID', '2631193');
    formData.append('subject', 'Welcome to Your-100-year-life!');
    this.commonService
      .sendSubSuccessEmail(formData)
      .subscribe((data: string) => {
        if (data['Messages'][0]['Status'] === 'success') {
          this.isLoading_signmeup = false;
          this.isSuccessful_signmeup = true;
          this.isSubscribed = true;
          this.message =
            'Thank you for providing your name and email. You have successfully subscribed to Your 100 Year Life! ';
        } else {
        }
      });
  }
  enableSignmeup() {
    this.isSuccessful_signmeup = false;
  }
  enableLoadingStarthere() {
    this.isLoading_starthere = true;
  }
  enableLoadingClickbusiness() {
    this.isLoading_starthere = true;
  }
}
