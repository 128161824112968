<div class="content-wrapper pt-7 mob-pt">
    <div class="content-body">
        <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
            <div class="card card-signin my-5 shadow">
                <div class="card-body">
                    <!-- <h4 class="card-title text-center yhyl-text bold-font my-4"></h4> -->
                    <p class="bold-font">Who do you want to login as?</p>
                    <div class="yhyl-text  mr-0">
                        <input style="margin-right: 5px;" (click)="radioClicked($event)" type="radio" name="user"
                            value="user">Registered User
                    </div>
                    <p class="mt-3 mb-2">As a co-pilot of:</p>
                    <div class="yhyl-text mr-0" *ngFor="let user of copilotData">
                        <input style="margin-right:5px;" (click)="radioClicked($event)" type="radio"
                            value="{{user.userid}}" name="user">{{user.username}}
                    </div>
                    <div class="divider-text mb-2 mt-4" style="vertical-align: middle; text-align: center">
                        <button class="btn btn-primary btn-block primary-yhyl-bg font-20 font-sm"
                            (click)="routeNavigation()" type="submit">
                            Continue to Dashboard
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>