import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.css']
})
export class ConfirmPasswordComponent implements OnInit {
  token: string;
  password: string;
  email: string;
  confirmPassword: string;
  isReset = false;
  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParamMap.get('token');
    this.email = this.route.snapshot.queryParamMap.get('email');

    console.log('reset psw token', this.token);
    console.log('reset psw token', this.password);
  }
  resetPassword() {
    this.commonService
      .resetPassword(this.email, this.token, this.password)
      .subscribe(
        response => {
          console.log(response);
          this.isReset = true;
        },
        (errorResponse: any) => {
          this.isReset = false;
          //On unsuccessful response
        }
      );
  }
}
