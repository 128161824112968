<div class="content-wrapper pd-10 bg-white">
  <div class="content-header row border-bottom">
    <div class="content-header-left col-md-12 col-12 mb-2 pl-0 pr-0 pr-0">
      <h3 class="content-header-title font-22 bold-font">
        <i class="yhyl-text icon-width fas fa-info text-center mr-3"></i>
        <span class="yhyl-text pointer" (click)="navigateMenu('main-info')">MY INFORMATION&nbsp;&nbsp;</span>
        <i class="fa fa-angle-right grey-color" aria-hidden="true"></i>
        <i class="fas fa-1x title-icon-style fa-house-user yhyl-text"></i>
        <span class="yhyl-text pointer font-16" (click)="navigateMenu('main-friends')">MY FRIENDS &
          FAMILY&nbsp;&nbsp;</span>
      </h3>
      <div class="temp" *ngIf="isMainPage">
        <!--Find accurate, reliable and practical information on the "need to know" issues in longevity and ageing. You told us what you need: we sourced it and put it all at your fingertips!-->
        <br />
        <div class="sub-content">
          <p class="yhyl-text font-20 bold-font">My Family and Friends</p>
          <p class="grey-color">
            The COVID-19 pandemic of 2020 and 2021 have caused family and
            friends to remain at a distance for long periods. Older Australians
            have been severely isolated. Their adult children have felt anguish
            and desperation at not being able to see, let alone hug, their ageing
            loved ones.
          </p>
        </div>
      </div>
      <div class="row mt-5" *ngIf="isMainPage">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-health-resources.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">
                      STAYING CONNECTED
                    </p>
                    <p>
                      You're probably already using Facebook, What'sApp, good
                      old phones, and other ways to see and speak with friends
                      and family.
                      <br />Don't stop! They are your lifeline when you can't
                      visit with family and friends as often as you'd like.

                      <br /><br />
                      But there are a lot of ways to be even better connected.
                      The very best is by using Your 100 Year Life's tool that
                      gives you one place to store and share photos, videos,
                      tributes and more - only with your permission. The first
                      250 people to register get free access to our innovative
                      personalised toolkit. Go to Explore to see how to connect
                      better. <em><b>COMING SOON!</b></em><br /><br />

                      We've reviewed the best and latest in easy-to-use
                      technology to keep connected with loved ones. And, as
                      always, we help you identify what will work best for the
                      needs and preferences of your loved one. You won't have to
                      search dozens of websites, read lots of reviews. But best
                      of all we help you figure out what is likely to work best
                      for you and your ageing relatives and friends.
                      Recommendations customised for you.
                      <i>Ageing - Personalised&trade;</i>&nbsp;<em><b>COMING SOON!</b></em>
                      <br /><br />
                      <!-- <i class="fas fa-tablet-alt fa-2x inline-icon-style home-color"
                                                aria-hidden="true"></i> -->
                      Not all seniors want to use mobile phones, ipads or other
                      digital devices. Mobile phones are too small for them for
                      anything but use as a telephone. Not all seniors are today
                      connected to wifi (or live where they can get wifi
                      reception). They may not see the point because they don't
                      intend to use wifi-enabled devices like iPads.
                      <br />
                      But for the adult children of seniors living at a
                      distance, two devices can open up a world of connection
                      and personal safety for these seniors:
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. A mifi
                      device, which creates a mini wireless broadband cloud or
                      hotspot - without the need for wifi; and
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. A
                      voice-activated iPad. These can post reminders about
                      medication, appointments, and other vital daily
                      activities. And the latest versions allow family to 'beam
                      in' through their loved one's ipad to check on them or
                      just connect any time - of course only with prior
                      authorisation!
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- BEGIN: Customizer-->
<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-xl-block"
  [ngClass]="showUpdates ? 'open' : ''">
  <a class="customizer-close" href="#"><i class="ft-x font-medium-3"></i></a>
  <a class="customizer-toggle primary-yhyl-bg box-shadow-3 btn-hover" (click)="showUpdateToggle()">
    <i class="fa fa-bell h4" theme="light" tooltip="Click to see Updates" placement="left"></i><span
      class="indicator"></span>
  </a>
  <div class="customizer-content p-2">
    <h4 class="border-bottom pb-3 content-header-title font-22 bold-font">
      Hey Test..
    </h4>
    <p class="text-animation">There are 5 new updates for you!</p>
    <p>Last 3 days to renew your service for Travel</p>
    <p>2 new offers on Health Service</p>
  </div>
</div>

<div class="sidenav-overlay"></div>
<div class="drag-target"></div>