import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  model: any = {};
  show: boolean;
  error: string;
  isRegistered: boolean = false;
  isError: boolean = false;
  message: string;
  isLoading_register: boolean = false;
  isSuccessful_register: boolean = false;

  constructor(private router: Router, private commonService: CommonService) {
    this.show = false;
  }

  showPassword() {
    this.show = !this.show;
  }

  ngOnInit(): void {}

  onRegister() {
    this.isError = false;
    this.isLoading_register = true;
    const data = {
      first_name: this.model.firstName,
      last_name: this.model.lastName,
      email: this.model.email,
      password1: this.model.password,
      password2: this.model.confirmPassword
    };
    this.commonService.registerData(data).subscribe(
      (response: any) => {
        console.log('response', response);
        this.isSuccessful_register = true;
        if (response.token) {
          console.log('response token', response.token);
          this.isLoading_register = false;
          this.isRegistered = true;
          //this.router.navigate(['/page']);
        } //On success response
      },
      (errorResponse: any) => {
        this.isLoading_register = false;
        if (errorResponse.error.email) {
          this.isError = true;
          this.message = errorResponse.error.email[0];
          this.isRegistered = false;
        } else {
          this.isError = true;
          this.message = 'Oops! Something goes wrong.';
          this.isRegistered = false;
        }
      }
    );
  }
}
