import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css', '../shared/templates.css']
})
export class ContactComponent implements OnInit {
  isLoading_sendMsg: boolean = false;
  isSuccessful_sendMsg: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
