<section id="landing" class="d-flex align-items-center pt-0">
  <div
    class="landing-container container position-relative"
    style="padding-top:100px"
  >
    <!-- data-aos="fade-up" data-aos-delay="300" -->
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <h1>
          Ageing is a <span class="yhyl-text">Marathon</span>, <br />
          not a Sprint!
        </h1>
        <h2 class="pt-5 pb-3">
          World's first Life Journey Manager for Ageing!
        </h2>
        <p class="font-italic text-white">
          Sign up for 3 months FREE ACCESS at your fingertips
          <br />
          All you need to manage the ageing journey of loved ones
        </p>
        <div class="row">
          <div class="col-lg-3 col-sm-4 pl-0 pb-3">
            <input
              class="form-control input-lg"
              style="font-size: 1.5rem;"
              name="name"
              placeholder="Your name"
              type="text"
            />
          </div>
          <div class="col-lg-3 col-sm-4 pl-0 pb-3">
            <input
              class="form-control input-lg"
              style="font-size: 1.5rem;"
              name="email"
              placeholder="Your email"
              type="text"
            />
          </div>
          <div class="col-lg-3 col-sm-4 pl-0 pb-3">
            <button
              routerLink="/login"
              class="btn btn-lg primary-yhyl-bg font-20 col-sm-12"
            >
              Sign Me Up!
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End landing -->
<main id="main">
  <!-- ======= About Section ======= -->
  <section id="about" class="about">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Why Sign Up?</h2>
      </div>

      <div class="row content text-center bold-font">
        <div class="col-lg-12 font-20">
          <ul>
            <li>
              Because you're probably already 'caring' for an ageing loved one,
              grandchildren, or more.
            </li>
            <li>
              Because your life is really complicated, and you desperately need
              to simplify
            </li>
            <li>
              Because you know you could do better with reputable information
              and guidance at your fingertips
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- End About Section -->
  <!-- ======= Services Section ======= -->
  <section id="services" class="services">
    <div class="container" data-aos="fade-up">
      <div class="row">
        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div class="icon-box shadow">
            <div class="icon">
              <i class="bx bx-file-find"></i>
            </div>
            <h4>Find and Simplify</h4>
            <p>
              You’ll get all the information you need to manage the life of your
              loved ones. It’s all about saving you time and eliminating stress.
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
          data-aos="zoom-in"
          data-aos-delay="200"
        >
          <div class="icon-box shadow">
            <div class="icon">
              <i class="bx bx-store"></i>
            </div>
            <h4>Store and Share</h4>
            <p>
              You can securely store, share and access everything you need here
              - vital documents, videos, guidance - that simplify your caring
              life.
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
          data-aos="zoom-in"
          data-aos-delay="300"
        >
          <div class="icon-box shadow">
            <div class="icon">
              <i class="bx bx-user"></i>
            </div>
            <h4>Manage and Care</h4>
            <p>
              Caring for a loved one means a lot to think about! We’ll make sure
              you know what to do next without overwhelming you. One day at a
              time.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Sevices Section -->
  <div class="main-test">
    <!-- ======= Testimonials Section ======= -->
    <section id="testimonials" class="testimonials pb-0">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>What our users say about us:</h2>
          <p></p>
        </div>
        <div class="col-12">
          <ngb-carousel>
            <ng-template ngbSlide>
              <div class="row testimonials-carousel">
                <div
                  data-aos="flip-down"
                  data-aos-delay="300"
                  class="col-xl-12 p-0 col-lg-12 col-sm-12 testimonial-item"
                >
                  <p>
                    <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                    I’m an ageing expert and if I can’t figure out my options,
                    what hope do others have? I wish I had this when I was
                    looking after my mother.
                    <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <img
                    src="../../assets/New Branding Assets/Avatars/set-social-people-media-profile-member/boy_1.jpg"
                    class="testimonial-img"
                    alt=""
                  />
                  <h3>Ageing Expert, Caring for father</h3>
                  <h4>64yrs old</h4>
                </div>
              </div>
            </ng-template>
            <ng-template ngbSlide>
              <div class="row testimonials-carousel">
                <div
                  data-aos="flip-down"
                  data-aos-delay="300"
                  class="col-xl-12 p-0 col-lg-12 col-sm-12 testimonial-item"
                >
                  <p>
                    <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                    This is really useful for millennials like me co-ordinating
                    childcare, work and interstate travel especially with COVID
                    chaos.
                    <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <img
                    src="../../assets/New Branding Assets/Avatars/set-social-people-media-profile-member/girl_1.jpg"
                    class="testimonial-img"
                    alt=""
                  />
                  <h3>Mother and Carer</h3>
                  <h4>35yrs old</h4>
                </div>
              </div>
            </ng-template>
            <ng-template ngbSlide>
              <div class="row testimonials-carousel">
                <div
                  data-aos="flip-down"
                  data-aos-delay="300"
                  class="col-xl-12 p-0 col-lg-12 col-sm-12 testimonial-item"
                >
                  <p>
                    <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                    This is exactly what I need to care for my parents
                    especially when my sister cannot return home. I want to use
                    this now.
                    <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <img
                    src="../../assets/New Branding Assets/Avatars/set-social-people-media-profile-member/girl_2.jpg"
                    class="testimonial-img"
                    alt=""
                  />
                  <h3>Caring for mother and father</h3>
                  <h4>47yrs old</h4>
                </div>
              </div>
            </ng-template>
            <ng-template ngbSlide>
              <div class="row testimonials-carousel">
                <div
                  data-aos="flip-down"
                  data-aos-delay="300"
                  class="col-xl-12 p-0 col-lg-12 col-sm-12 testimonial-item"
                >
                  <p>
                    <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                    Really useful for me and my clients: we don’t have to spend
                    a lot of time searching all over the internet for important
                    information. It will provide a starting point for my clients
                    and their families to discuss things they need to agree on.
                    And clients can make sure they have a complete inventory of
                    all assets – including things like prepaid funerals and
                    small share holdings
                    <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <img
                    src="../../assets/New Branding Assets/Avatars/set-social-people-media-profile-member/mature_man.png"
                    class="testimonial-img"
                    alt=""
                  />
                  <h3>Experienced Financial Adviser</h3>
                  <h4>54 yrs old</h4>
                </div>
              </div>
            </ng-template>
          </ngb-carousel>
        </div>
      </div>
    </section>
    <!-- End Testimonials Section -->

    <!-- ======= Counts Section ======= -->
    <section id="facts" class="facts">
      <div class="container">
        <div class="row no-gutters">
          <div
            class="col-lg-4 col-md-6 d-md-flex align-items-md-stretch aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div class="count-box">
              <span
                ><span class="purecounter">1</span
                ><span class="count-subfont ml-0">in 6</span></span
              >
              <p>Adult Australians are formally caring for someone.</p>
            </div>
          </div>
          <div
            class="col-lg-4 col-md-6 d-md-flex align-items-md-stretch aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div class="count-box">
              <span
                ><span class="purecounter">7</span
                ><span class="count-subfont ml-0">in 10</span></span
              >
              <p>
                Adult primary carers <br />
                in Australia were women.
              </p>
            </div>
          </div>

          <div
            class="col-lg-4 col-md-6 d-md-flex align-items-md-stretch aos-init aos-animate"
            data-aos="fade-up"
          >
            <div class="count-box">
              <span
                ><span class="purecounter">12+</span>
                <!-- <span class="count-subfont ml-0">months</span> -->
              </span>
              <p>months waiting time for Government-funded assistance.</p>
            </div>
          </div>
        </div>
        <!--  <div class="row">
                    <div class="col-lg-3 col-4 d-md-flex">
                        <img src="../../assets/static/img/Num1.png" class="img-fluid" style="max-width: 90%;">
                    </div>
                    <div class="col-lg-4 col-4 d-md-flex">
                        <img src="../../assets/static/img/Num2.png" class="img-fluid" style="max-width: 90%;">
                    </div>
                    <div class="col-lg-5 col-4 d-md-flex">
                        <img src="../../assets/static/img/Num3.png" class="img-fluid" style="max-width: 90%;">
                    </div>
                </div> -->
      </div>
    </section>
    <!-- End Counts Section -->

    <!-- ======= Clients Section ======= -->
    <section id="clients" class="clients">
      <div class="container aos-init aos-animate" data-aos="fade-up">
        <div class="section-title">
          <h2>Our Partners</h2>
          <br />
        </div>

        <div class="section-title">
          <h3>Powered By</h3>
        </div>

        <div class="row clearfix aos-init aos-animate" data-aos="fade-up">
          <!-- <div class="col-lg-12 col-md-12 col-12">
                        <div class="client-logo">
                            <img src="../../assets/New Branding Assets/With Words/With Words/Brand_Logos.png"
                                class="img-fluid" alt="">
                        </div>
                    </div> -->
          <div class="col-lg-1 col-xl-1"></div>

          <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4">
            <div class="client-logo">
              <img
                src="../../assets/New Branding Assets/WithoutWords/digime.png"
                class="img-fluid"
                alt=""
                style="
                height:100px;"
              />
            </div>
          </div>

          <div class="col-xl-2 col-lg-2 col-md-4  col-sm-4">
            <div class="client-logo">
              <img
                src="../../assets/New Branding Assets/WithoutWords/ID.png"
                class="img-fluid"
                alt=""
                style="width:100px;
                height:100px;"
              />
            </div>
          </div>

          <div class="col-xl-2 col-lg-2 col-md-4  col-sm-4">
            <div class="client-logo">
              <img
                src="../../assets/New Branding Assets/WithoutWords/aws.png"
                class="img-fluid"
                alt=""
                style="width:100px;
                height:100px;"
              />
            </div>
          </div>
          <div class="col-sm-2 d-lg-none col-md-2"></div>
          <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4">
            <div class="client-logo">
              <img
                src="../../assets/New Branding Assets/WithoutWords/UTSstartups.png"
                class="img-fluid"
                alt=""
                style="
                height:100px;"
              />
            </div>
          </div>

          
          <div class="col-xl-2 col-lg-2 col-md-4  col-sm-4">
            <div class="client-logo">
              <img
                      src="../../assets/New Branding Assets/yhyl-front-page-v15/yhyl-front-page-v15 Folder/Links/movers-logo-hor-blue-450x300.png"
                      class="img-fluid"
                      alt=""
                      style="
                      height:100px;"
                    />
            </div>
          </div>


          

          <div class="col-sm-2 d-lg-none col-md-2"></div>
          <div class="col-xl-2 col-lg-1"></div>
        </div>
      </div>
    </section>
    <!-- ======= Cta Section ======= -->
    <section id="cta" class="cta">
      <div class="container" data-aos="zoom-in" data-aos-delay="200">
        <!-- <div class="text-center">
                    <h3>Call To Action</h3>
                    <p> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur.
                        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
                        id
                        est laborum.
                    </p>
                    <a class="cta-btn" href="#">Call To Action</a>
                </div> -->
        <div class="row">
          <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 textHolder">
            <h1 class="primary-font text-white" style="font-weight:bold">
              We've simplified caring for your
              <span class="text-blue">{{ mainText }}</span>
            </h1>
            <h2 class="primary-font text-white font-20" style="font-weight:300">
              With a comprehensive toolkit to help them manage
              <span class="text-blue">{{ secondaryText }}</span>
            </h2>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
            <!-- <button class="btn btn-lg btn-block">Sign Me Up!</button> -->
            <a routerLink="/login" class="cta-btn shadow">Sign Up!</a>
          </div>
        </div>
      </div>
    </section>
    <!-- End Cta Section -->
  </div>
  <!-- ======= Team Section ======= -->
  <section id="team" class="team">
    <div class="container" data-aos="fade-up">
      <div class="section-title pb-0">
        <h2 class="yhyl-text" style="color:#1D3D59;">
          Are you a business?&nbsp;&nbsp;
          <span>
            <button
              class="btn btn-lg text-white shadow"
              routerLink="/businesses"
              style="font-size:26px !important;background-color:#1D3D59;"
            >
              Click here to know more
            </button>
          </span>
        </h2>
      </div>
    </div>
  </section>
  <!-- End Team Section -->
</main>
<!-- End #main -->
<app-landing-footer></app-landing-footer>

<a class="back-to-top" (click)="onScrollToTop()"
  ><i class="ri-arrow-up-line"></i
></a>
<!-- <div id="preloader"></div> -->
