<!-- ======= about-story Section ======= -->
<section id="about" class="about">
  <div class="container" data-aos="fade-up">
    <div class="row content">
      <div class="row content">
        <div data-aos="zoom-in" data-aos-delay="200" class="col-12 d-flex p-0 aos-init aos-animate">
          <div class="card p-0">
            <h3 class="primary-font primary-yhyl-bg p-5 text-center">
              We have entered a long era of gradual ageing - What does that mean
              for you?
            </h3>
            <div class="p-5">
              <p>
                Your 100 Year Life is built on lived experience, extensive
                interviews, other research and evidence.
              </p>
              <ul>
                <li class="bold-font">
                  <i class="bx bx-check-double"></i> Advances in longevity mean
                  that many of us will live to be 100, or more.
                </li>
                <li class="bold-font">
                  <i class="bx bx-check-double"></i> A very small fraction of
                  Baby Boomers will spend any significant time in a “retirement
                  village”, let alone in residential aged care (nursing home).
                </li>
                <li class="bold-font">
                  <i class="bx bx-check-double"></i> Most of us will have to
                  manage our ageing journey ourselves, with increasing help from
                  relatives and friends.
                </li>
                <li class="bold-font">
                  <i class="bx bx-check-double"></i> That process becomes more
                  and more complex as we age in our own homes.
                </li>
                <li class="bold-font">
                  <i class="bx bx-check-double"></i> Many of us have had the
                  frustrating and emotionally stressful experience of supporting
                  ageing loved ones – and especially during the past 2 years, from a
                  distance.
                </li>
              </ul>
              <p>
                We set out to address the needs of families supporting loved
                ones through the ageing journey. We interviewed and researched,
                and we listened. And then we created an ambitious,
                “one-stop-shop” with the essential information, guidance and
                unique toolkit to meet those needs.
              </p>
              <p class="font-italic bold-font">
                Immersed as we are in a digital world, we were convinced there
                has to be a better way – and we made it happen !
              </p>
            </div>
          </div>
        </div>
      </div>
      <div data-aos="zoom-in" data-aos-delay="200" class="col-12 d-flex p-0 aos-init aos-animate">
        <div class="card p-0">
          <h3 class="primary-font primary-yhyl-bg p-5 text-center">
            How and why we founded Your 100 Year Life
          </h3>
          <div class="p-5">
            <p>
              We founded Your 100 Year Life because as we and our friends
              entered their 'Boomer' years, so many people we know were stressed
              and stretched watching their older parents and other ageing loved
              ones need more and more support over time to remain living
              independently in their own homes. We also saw a lot of anguish and
              guilt:
            </p>
            <ul>
              <li class="bold-font">
                <i class="bx bx-check-double"></i> “Am I making the best
                decisions for them?”
              </li>
              <li class="bold-font">
                <i class="bx bx-check-double"></i> “How can I find out more
                about specific problems, but from reliable sources?” “How do I
                find those reliable sources?”
              </li>
              <li class="bold-font">
                <i class="bx bx-check-double"></i> “How can I easily share that
                knowledge, and care decisions, with my sisters and brothers so
                they are comfortable with the options and agree with decisions?”
              </li>
              <li class="bold-font">
                <i class="bx bx-check-double"></i> And especially during
                COVID-19 restrictions: “How do I manage my ageing parents’
                deterioration and met their needs from distance?”
              </li>
            </ul>
            <p>
              The closer we looked the more we saw how common these problems
              are. Because ageing is a marathon, not a sprint: over 90% of
              Australians will age in place, in their own homes. The reality is
              that their families are and will increasingly be the support they
              need to remain safe, well and engaged. We also discovered that
              this near universal challenge is borne most often by women – it’s
              a silent burden that can preoccupy them constantly. We approach
              ageing, and the support of ageing loved ones, as an
              intergenerational challenge - because it can involve as many as 4
              generations in the one family. Successful ageing also involves a
              broader community. Over time is becomes very complicated. That's
              the complex problem Your 100 Year Life set out to solve.
            </p>
          </div>
        </div>
      </div>
    </div>
    <h2 class="primary-font text-center yhyl-sec-color bold-font">
      There has to be a better way. And with
      <span class="yhyl-text">Your 100 Year Life</span> we created it!
    </h2>

    <div class="row content py-5">
      <div data-aos="zoom-in" data-aos-delay="200" class="col-12 d-flex p-0 aos-init aos-animate">
        <div class="card p-0">
          <h3 class="primary-font primary-yhyl-bg p-5 text-center">
            We Are Experts - We Share our Expertise with You for Free
          </h3>
          <div class="p-5">
            <p>
              Your 100 Year Life is led by experts in ageing, health, digital,
              and care. We are all strongly committed to improving the
              well-being of seniors and to helping them and their families to
              navigate the marathon of ageing. We believe in and are active in
              philanthropy. We also believe that many humanitarian goals can be
              achieved through social enterprise: businesses that exist to make
              a beneficial social impact, but exist as sustainable businesses
              not charities. For that reason we have made a lot of Your 100 Year
              Life free for you to access, always. And we have also created a
              more advanced feature, your personal toolkit, which enables paying
              subscribers to simplify and manage within an intergenerational
              context, their complex caring lives.
            </p>

            <div class="section-title pb-0">
              <h2 class="primary-font text-left">Our Inspiration</h2>
              <p class="pt-3">
                Our lived experience of ageing came to a tipping point in 2017.
                Grandma Belle, our founder’s alert and engaged mother, was
                turning 104. She is now 107 1/2. Although she was on her computer every day, she was
                increasingly reliant on others and on some basic devices to
                maintain her health, wellbeing and independence.
              </p>
              <p class="pt-3">
                Then we realised that Grandma Belle’s 40 years in retirement
                were now equal to her 40 years employed in the full-time
                workforce. She had already aged in her own home for many years,
                with increasing support over the years. A member of the Greatest
                Generation, she was generously funded by Government and
                job-related benefits. Still, it was becoming harder and harder
                to identify resources to meet her needs; coordinate with
                siblings; persuade her she needed certain support; and manage it
                all from a distance. Virtually all of our friends were facing
                the same challenges.
              </p>
              <p class="pt-3">
                Grandma Belle’s era of ample Government and other support is
                well and truly over. <span class="bold-font">How are today’s seniors going to design and finance their
                  longevity?</span>
                <br />
                And who will coordinate and manage the complex ecosystem of
                support they need to live with independence and dignity– for up
                to 40 years post-retirement? As one professional in ageing said,
                <span class="font-italic bold-font">“If I can’t manage it what hope does an ordinary person
                  have?” The answer: they will rely increasingly on family and
                  other supporters.
                </span>
              </p>
              <p class="pt-3">
                And that’s why and how we started designing Your 100 Year Life,
                for the millions of adult children globally who are living apart
                from their ageing parents. For the millions of families who are
                struggling to pool their knowledge, make informed decisions
                jointly, and help support their loved ones through the marathon
                of ageing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End about Section -->

<app-landing-footer></app-landing-footer>