import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ɵInternalFormsSharedModule } from '@angular/forms';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
  providers: [NgbCarouselConfig]
})
export class ServicesComponent implements OnInit, AfterViewInit {
  isShowing = true;
  showUpdates = false;
  serviceList: any;
  filterData: any;
  defaultServices: any = [];
  isDefaultNull = false;
  isCustomNull = true;
  showContent = true;
  isCustom = false;
  company: string;
  description: string;
  personName: string;
  contact: string;
  type: string;
  customServices: any = [];
  showLoader = true;
  copilotData: any;
  isCopilot = false;
  viewServices = false;
  editServices = false;
  deleteServices = false;
  serviceToEdit: any;
  isSuccess = false;
  isError = false;
  isErrorpersonName = false;
  isErrorcontact = false;
  message: string;
  idToEdit: any;
  isDelete: boolean;
  isEdit: boolean;
  isAction = false;
  actionMessage: string;
  isErrorcontactnumber = false;

  constructor(private commonService: CommonService, config: NgbCarouselConfig) {
    config.interval = 10000;
    config.wrap = true;
    config.keyboard = true;
    config.pauseOnHover = true;
    config.showNavigationIndicators = false;
    config.showNavigationArrows = true;
  }

  ngOnInit() {
    this.getServiceData();
    this.copilotData = this.commonService.getCopilotPermissions();
    if (this.copilotData.permission) {
      this.isCopilot = true;
      let permission = this.copilotData.permission;

      for (let i = 0; i < permission.length; i++) {
        /* permissions */
        if (permission[i].includes('service')) {
          if (permission[i].includes('delete')) {
            this.deleteServices = true;
            this.editServices = true;
            this.viewServices = true;
          } else if (
            permission[i].includes('add') ||
            permission[i].includes('change')
          ) {
            this.editServices = true;
            this.viewServices = true;
            this.deleteServices = false;
          } else if (permission[i].includes('view')) {
            this.viewServices = true;
            this.editServices = false;
            this.deleteServices = false;
          }
        }
      }
    }
  }
  showAccessMessage() {
    this.isAction = true;
    this.actionMessage = this.commonService.getAccessMessage();
  }

  showUpdateToggle() {
    this.showUpdates = !this.showUpdates;
  }

  ngAfterViewInit(): void {}

  getServiceData() {
    this.commonService.getServiceProviderData().subscribe(response => {
      this.showLoader = false;
      this.serviceList = response;
      this.defaultServices = this.serviceList.DefaultServices;
      this.customServices = this.serviceList.CustomServices;
      if (this.defaultServices.length === 0) {
        this.isDefaultNull = true;
      } else {
        this.isDefaultNull = false;
      }
      if (this.customServices.length === 0) {
        this.isCustomNull = true;
      } else {
        this.isCustomNull = false;
      }
    });
  }

  filterOffers(value) {
    this.defaultServices = [];
    for (let i = 0; i < this.serviceList.DefaultServices.length; i++) {
      let zipcode = this.serviceList.DefaultServices[i].Zipcode;
      if (zipcode.toString().includes(value)) {
        this.defaultServices.push(this.serviceList.DefaultServices[i]);
      }
    }
  }

  showAddPopup() {
    this.isCustom = true;
    this.isSuccess = false;
    this.isError = false;
    this.company = '';
    this.description = '';
    this.personName = '';
    this.contact = '';
    this.type = '';
  }

  showEditPopup(id) {
    this.idToEdit = id;
    this.isEdit = true;
    this.isSuccess = false;
    this.isError = false;
    this.serviceToEdit = this.customServices.find(obj => {
      return obj.Service_Id == this.idToEdit;
    });
    this.company = this.serviceToEdit.Service_Company;
    this.description = this.serviceToEdit.Service_Description;
    this.personName = this.serviceToEdit.Service_Person_Name;
    this.contact = this.serviceToEdit.Contact_Details;
    this.type = this.serviceToEdit.Service_Type;
  }

  showDeletePopup(id) {
    this.idToEdit = id;
    this.isDelete = true;
    this.isSuccess = false;
    this.isError = false;
  }

  editService() {
    let formData = new FormData();
    formData.append('userid', localStorage.getItem('userId'));
    formData.append('Service_Type', this.type);
    formData.append('Service_Person_Name', this.personName);
    formData.append('Service_Company', this.company);
    formData.append('Service_Description', this.description);
    formData.append('Contact_Details', this.contact);
    this.commonService.editService(formData, this.idToEdit).subscribe(
      (response: any) => {
        if (response) {
          this.isSuccess = true;
          this.message = 'Your service has been edited successfully!'; //On success response
          //this.isDelete = false;
          this.getServiceData();
        }
      },
      (errorResponse: any) => {
        this.isError = true;
        this.message = 'Some error occured.Please try again.';
        //On unsuccessful response
      }
    );
  }

  deleteService() {
    this.commonService.deleteService(this.idToEdit).subscribe(
      (response: any) => {
        if (response) {
          this.isSuccess = true;
          this.message = 'Your service has been deleted successfully!'; //On success response
          //this.isDelete = false;
          this.getServiceData();
        }
      },
      (errorResponse: any) => {
        this.isError = true;
        this.message = 'Some error occured.Please try again.';
        //On unsuccessful response
      }
    );
  }
  addService() {
    let formData = new FormData();
    if (this.personName === '' || this.personName === null) {
      this.isErrorpersonName = true;
    } else {
      this.isErrorpersonName = false;
    }
    if (this.contact === '' || this.contact === null) {
      this.isErrorcontact = true;
    } else {
      this.isErrorcontact = false;
    }
    const regex = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
    //let regex = new RegExp('/^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}\d$/');
    let validmobilenumber = regex.test(this.contact);
    if (!validmobilenumber) {
      this.isErrorcontactnumber = true;
    } else {
      this.isErrorcontactnumber = false;
    }
    if (
      !this.isErrorpersonName &&
      !this.isErrorcontact &&
      !this.isErrorcontactnumber
    ) {
      formData.append('userid', localStorage.getItem('userId'));
      formData.append('Service_Type', this.type);
      formData.append('Service_Person_Name', this.personName);
      formData.append('Service_Company', this.company);
      formData.append('Service_Description', this.description);
      formData.append('Contact_Details', this.contact);

      this.commonService.addCustomService(formData).subscribe(
        response => {
          this.isSuccess = true;
          this.message = 'Your service has been added successfully!';
          this.getServiceData();
        },
        (errorResponse: any) => {
          this.isError = true;
          this.message = 'Some error occured.Please try again.';
          //On unsuccessful response
        }
      );
    }
  }
  close() {
    this.isEdit = false;
    this.isDelete = false;
    this.isCustom = false;
  }
}
