<div class="content-overlay">

</div>
<div class="content-wrapper pd-10">
    <div class="content-header row border-bottom">
        <div class="content-header-left col-md-12 col-12 mb-2 pl-0 pr-0">
            <h3 class="content-header-title font-22 bold-font"><i class="icon-width fas fa-info text-center mr-3"></i>My
                Information Centre: {{activeTab}}
                <i class="fas fa-1x title-icon-style " [ngClass]="activeTab==='My Health'?'fa-heartbeat health-color':
                activeTab==='My Home'?'fa-house-user home-color':
                activeTab==='My Friends and Family'?'fa-user-friends home-color':
                activeTab==='My Security'?'fa-shield-alt security-color':
                activeTab==='My Technology'?'fa-chalkboard-teacher tech-color':
                activeTab==='My Finance'?'fa-hand-holding-usd finance-color':
                activeTab==='My Work'?'fa-hands-helping work-color':
                activeTab==='My Care'?'fa-users care-color':
                activeTab==='My Legacy'?'fa-feather-alt memories-color':
                ''" aria-hidden="true"></i>
            </h3>
            <div class="temp" [class.show]="showContent">
                <!--Find accurate, reliable and practical information on the "need to know" issues in longevity and ageing. You told us what you need: we sourced it and put it all at your fingertips!-->
                <br/>
                All you need to know, plan and do 
                {{activeTab==="My Health"?"about health and well-being ": 
                activeTab==="My Home"?"about making sure your home is your 'best home' for your ageing marathon  ":
                activeTab==="My Friends and Family"?"to make sure you stay connected with your friends and family  ":
                activeTab==="My Security"?"to make sure you and your loved ones are safe and secure, in life and online, ":
                activeTab==="My Technology"?"to find and use safely the technology that works best for you ":
                activeTab==="My Work"?"about paid work, transitioning to retirement, and volunteering ":
                ""}}
                is here at your fingertips. So you can care better for your
                loved ones - simply and effectively.
                <!--Everything you need to know, plan and do to care better for your ageing loved ones is right here! Accurate, reliable information and guidance at your fingertips.-->

            </div>
            <ng-container *ngIf="showContent">
                <br />

                We help you manage your complex and shifting responsibilities by finding, checking and supplying the
                information,
                services and technology that enable you to stay on top of all it. No need to scour the web and jump
                between apps. We make sure you have 'Uncommon Knowledge'.
                <br /><br />
                <b class="font-18">It's simple: Just click on the box that you want to know more about. For example,
                    click on My Health and
                    you'll see a few of the places we've identified for 100% trustworthy advice and help.</b>
                <br /><br />
                <b class="font-18">It’s all here for you in one place. </b>
            </ng-container>
            <a class="yhyl-text" (click)="showContent = !showContent">{{ showContent ? 'Show less': 'Show More' }}</a>
        </div>
    </div>
    <div class="content-body" id="subpageReading">
        <div class="row mt-3 mb-5 bg-white shadow-light">
            <div class="col-md-12 pl-0 pr-0">
                <ul class="sub-nav nav nav-justified bg-white">
                    <li class="nav-item">
                        <a data-toggle="tab" aria-expanded="false" class="nav-link rounded-0 yhyl-text full-height"
                            (click)="infoTab('My Health','main health')">
                            <span class="font-18">My Health</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a data-toggle="tab" aria-expanded="false"
                            class="nav-link rounded-0 border-left yhyl-text full-height"
                            (click)="infoTab('My Home','main home')">
                            <span class="font-18">My Home</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a data-toggle="tab" aria-expanded="false"
                            class="nav-link rounded-0 border-left yhyl-text full-height"
                            (click)="infoTab('My Friends and Family','')">
                            <span class="font-18">My Friends and Family</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a data-toggle="tab" aria-expanded="false"
                            class="nav-link rounded-0 border-left yhyl-text full-height"
                            (click)="infoTab('My Security','')">
                            <span class="font-18">My Security</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a data-toggle="tab" aria-expanded="false"
                            class="nav-link rounded-0 border-left yhyl-text full-height"
                            (click)="infoTab('My Technology','')">
                            <span class="font-18">My Technology</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a data-toggle="tab" aria-expanded="false"
                            class="nav-link rounded-0 border-left yhyl-text full-height"
                            (click)="infoTab('My Finance','')">
                            <span class="font-18">My Finance (Money)</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a data-toggle="tab" aria-expanded="false"
                            class="nav-link rounded-0 border-left yhyl-text full-height"
                            (click)="infoTab('My Work','')">
                            <span class="font-18">My Work</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a data-toggle="tab" aria-expanded="false"
                            class="nav-link rounded-0 border-left yhyl-text full-height"
                            (click)="infoTab('My Care','')">
                            <span class="font-18">My Care</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a data-toggle="tab" aria-expanded="false"
                            class="nav-link rounded-0 border-left yhyl-text full-height"
                            (click)="infoTab('My Legacy','')">
                            <span class="font-18">My Legacy</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="card pull-up"
            *ngIf="activeTab ==='One stop to find all the essential, reliable information to manage your caring life'">
            <div class="card-content collapse show">
                <div class="card-body">
                    <div class="=" card-text>
                        <p class="text-justify intro" style="color: black ;">Can't find what you need? We value your
                            opinion.
                            <a href="/contact" target="_blank">Send us a message</a> here so we can do better.
                        </p>
                    </div>
                </div>
            </div>

        </div>
        <div class="card pull-up" *ngIf="activeTab ==='My Health'">
            <div class="card-content collapse show">
                <div class="card-body">
                    <div class="card-text">
                        <p class="text-justify intro" style="color: black ;" *ngIf="activeHealthSubTab==='main health'">
                            <i class="fas fa-heartbeat fa-2x inline-icon-style health-color" aria-hidden="true"></i>
                            <b class="font-18"> Health and Wellbeing: </b>
                            Your 100-year Life does not give health advice.
                            But we do assemble really useful information from impeccable sources.
                            We can save you a lot of time and effort – and make it easy to share new information and
                            concerns with family and friends. And that’s just one example of what you will find in My
                            Information Centre: My Health. All
                            the resources you need, all in one place, right here. <br />
                            <br />
                            <i class="far fa-heart fa-2x inline-icon-style health-color" aria-hidden="true"></i>
                            Your first stop for the most complete, authoritative health advice and questions for all
                            Australians is here: <a href="https://www.healthdirect.gov.au/" target="_blank">
                                www.healthdirect.gov.au</a>. Health direct is the official, evidence-based.
                            It takes you to the official evidence based resource for all your health questions. <b
                                class="font-18">You can have access to a qualified
                                and certified nurse 24x7 for free!</b> It can direct you to
                            resources including checklists that you can bring with you on doctor visits to make sure
                            that all of your questions are answered. Use the HealthDirect question
                            builder to create customised questions our mum can use when she visits her specialists.
                            Click here to visit: <a href="https://www.healthdirect.gov.au/" target="_blank">
                                www.healthdirect.gov.au</a>.
                            <br /><br />
                            <i class="fas fa-hospital-user fa-2x inline-icon-style health-color" aria-hidden="true"></i>
                            <b class="font-18"> Mental health questions or worries?</b>
                            You’ll find an amazing collection of trusted resources, tools, and guidance here: <a
                                href="https://headtohealth.gov.au/" target="_blank">headtohealth.gov.au. </a>And not
                            just for seniors. For everyone. Lots of services available online, by phone and of course in
                            person. All reviewed and promoted by authoritative sources.
                            You'll land in the most useful and complete place for information, guidance and apps for
                            mental health and wellbeing.
                            <br />
                            <br />
                            <i class="fas fa-capsules fa-2x inline-icon-style health-color" aria-hidden="true"></i>
                            <b class="font-18">Medications:</b> Looking for accurate and reliable information about
                            medications? Here it is: <a href="https://www.healthdirect.gov.au/medicines"
                                target="_blank">www.healthdirect.gov.au/medicines</a>. Save valuable information,
                            guidance and links onto your
                            Personal Page, and share what you need for your own unique circumstances.

                            Did you know that you can monitor and manage your loved one's medications at a distance,
                            online, and you can have them delivered without anyone stopping into the chemist? We direct
                            you to some of the options here: <a class="subTabTrigger"
                                (click)="infoHealthSubTab('medication information')">(Click here to visit)</a>.


                            <br />
                            <br />
                            <i class="fas fa-clipboard-list fa-2x inline-icon-style health-color"
                                aria-hidden="true"></i>
                            <b class="font-18">
                                Checklists and Questionnaires:</b>
                            We make it simple and convenient to get all your loved one's important health and wellbeing
                            information' organised. For instance, get organised for important doctor's visits - whether
                            you or your loved one is the patient <a class="subTabTrigger"
                                (click)="infoHealthSubTab('useful checklists')">(Click here)</a>. Let us show you how:
                            A sample of a trustworthy and thorough checklist you need to do before your next doctor's
                            visit - for yourself or ageing loved one - is here: <a
                                href="https://www.healthdirect.gov.au/question-builder"
                                target="_blank">www.healthdirect.gov.au/question-builder</a>. Try it and see how it will
                            help simplify and organise your next doctor's visit. You'll never again walk out of a
                            doctor's office and suddenly think - "I forgot to ask something really important!". It's a
                            sample of what we'll be adding in coming weeks and months to simplify and organise your
                            caring life.
                            <br />
                            <br />


                            <!-- <b class="font-14">
                                Checklists and guides:
                            </b> It’s sometimes tough to make major health decisions. So we’ve sourced and saved useful
                            checklists, questionnaires for you and your loved ones to use and share. -->
                            <!-- Did you know that you can monitor and manage your loved one's medications at a distance,
                            online, and you can have them delivered without anyone stopping into the chemist? We direct
                            you to some of the options here.
                            <br />
                            <br /> -->


                            <!--It's been a stressful year*. There are so many useful and reassuring resources to help you get through the day. 
                        Click on Mental Health Resources and look for headtohealth.gov.au, 
                        headspace.org.au and more for a wealth of free, reliable advice and tools.

                        <b class="font-14">Did you know that people over 55 can now locate exercise and movement classes within 5 kilometres of home? </b><br/>
                        The website active and healthy, sponsored by the NSW Government lists all the essential details for a wide range of classes. Some are free;
                         others range on cost from $10-$58 per session.-->

                            <!--
                        <a href="https://www.activeandhealthy.nsw.gov.au/" target="_blank">Click here to visit www.activeandhealthy.com.au</a>
                        -->



                        </p>
                        <p class="text-justify intro" style="color: black ;"
                            *ngIf="activeHealthSubTab==='medication information'">
                            <a data-toggle="tab" aria-expanded="false" class="nav-link subtab rounded-0 full-height"
                                (click)="infoHealthSubTab('main health')"><i class="fas fa-chevron-left"></i> <b
                                    class="font-18"> Back</b></a>
                            <br />
                            <i class="fas fa-question-circle fa-2x inline-icon-style health-color"
                                aria-hidden="true"></i>
                            <b class="font-18">
                                Medication Information and Management</b>
                            <br /><br />
                            Your 100 Year Life does not provide medication advice, but we do put trustworthy and
                            convenient medication resources at your fingertips.
                            Here are some examples.
                            <br />
                            <br />
                            <i class="bx bx-check-double fa-2x inline-icon-style health-color"></i>
                            <b class="font-18">1. Trustworthy and reliable medication information</b> is available
                            online at HealthDirect the Australian Government's online site.
                            It contains all the information you need, for Australians by Australians. <a
                                href="https://www.healthdirect.gov.au/medicines" target="_blank">Click Here</a>.
                            <br />
                            And remember that if you can't find the answers there, HealthDirect has nurses on standby to
                            answer your health-related questions at: 1800 022 222.
                            <br /><br />
                            <i class="bx bx-check-double fa-2x inline-icon-style health-color"></i>

                            <b class="font-18">2. Assuring that your loved one is receiving the medication
                                prescribed</b>, as and when the doctor orders. One of the most frequent
                            questions we receive concerns making sure that loved ones living at a distance are in fact
                            taking the right medications, at the right times, and in the right doses.
                            There are many 'apps for that'. But apps are only one way. There are also many other ways to
                            ensure essential prescription medications are taken correctly.
                            We examine a few of the alternatives so you can choose what is best for you and your
                            individual circumstances.
                            <br /><br />
                            <i class="bx bx-check-double fa-2x inline-icon-style health-color"></i>

                            <b class="font-18">3. Consolidate all of your - or your loved one's medication information
                                in one place</b> in case of emergency or confusion.
                            Safeguard the names, dosages and prescribing doctors' names - and photos of each medication
                            and its container - in your personal and secure space.
                            <span style="vertical-align: text-top; color: #00b9b1;">※</span>Consider sharing it with
                            your trusted loved one.
                            <br />
                            Why? So you can easily and instantaneously remember or remind your loved one (or their
                            carer) of the current and correct medication regime.
                            In case you or your loved is suddenly taken ill and paramedics or ambulance officers need to
                            know the medications taken by you, or your loved one.
                            The information is secure, but it is there at your fingertips when you need it most. You are
                            not saving your or their medical record. Just some critical details.
                            <br /><br />
                            <i class="bx bx-check-double fa-2x inline-icon-style health-color"></i>

                            <b class="font-18">4. Medication supplies and reminders: </b><b><em>COMING SOON!</em></b> A
                            wide range of kits, tools, apps and devices to help the correct and timely supply and
                            administration of medication.
                            <br /><br />
                            <span style="vertical-align: text-top; color: #00b9b1;">※</span>Available with Premium
                            subscription.
                            <br />
                        </p>
                        <p class="text-justify intro" style="color: black ;"
                            *ngIf="activeHealthSubTab==='useful checklists'">
                            <a data-toggle="tab" aria-expanded="false" class="nav-link subtab rounded-0 full-height"
                                (click)="infoHealthSubTab('main health')"><i class="fas fa-chevron-left"></i> <b
                                    class="font-18"> Back</b></a>
                            <br />
                            <i class="fas fa-clipboard-list fa-2x inline-icon-style health-color"
                                aria-hidden="true"></i>
                            <b class="font-18">
                                Checklists and Questionnaires</b>
                            <br /><br />
                            Let us show you how: Our recommended checklists can help simplify your caring life.
                            <br /><br />
                            <i class="bx bx-check-double fa-2x inline-icon-style health-color"></i>

                            <b class="font-18">Taking your loved one to the doctor:</b> Prepare well - support better.
                            Take a moment to discuss with your loved one the purpose of their next doctor's visit. Use
                            the checklist at HealthDirect as your guide. You both will get a lot more out of the visit:
                            answers to all of your questions, a clear understand of what it means and what to do next.
                            <a href="https://www.healthdirect.gov.au/question-builder" target="_blank">Click here</a>
                            for a trustworthy and thorough checklist that will simplify and organise your next doctor's
                            visit. Whether it is for yourself or a loved one. You'll never again walk out of a doctor's
                            office and suddenly think - "I forgot something really important to ask!".
                            <br /><br />
                            It's a sample of what we'll be adding in coming weeks and months to simplify and organise
                            your caring life.
                            <br />

                        </p>
                        <div class="row">
                            <div class="col-md-12 pl-0 pr-0">
                                <ul class="sub-nav nav nav-justified bg-white pills-display">
                                    <li class="nav-item">
                                        <div data-toggle="tab" aria-expanded="false"
                                            class="nav-link subtab rounded-0 full-height"
                                            [ngClass]="(activeHealthSubTab==='health resources')?'active':'inactive'"
                                            (click)="infoHealthSubTab('health resources')">
                                            <i class="fas fa-heartbeat fa-4x info-style" aria-hidden="true">
                                                <span class="info-content">Health Resources</span></i>
                                        </div>
                                    </li>
                                    <li class="nav-item">
                                        <div data-toggle="tab" aria-expanded="false"
                                            class="nav-link subtab rounded-0 full-height"
                                            [ngClass]="(activeHealthSubTab==='mental resources')?'active':'inactive'"
                                            (click)="infoHealthSubTab('mental resources')">
                                            <i class="fas fa-hospital-user fa-4x info-style" aria-hidden="true">
                                                <!--<img src="../../assets/New Branding Assets/Shell Logos/ID exchange.png"
                      class="img-fluid" style="max-width: 8%;">-->
                                                <span class="info-content">Mental Health Resources</span>
                                            </i>
                                        </div>
                                    </li>
                                    <li class="nav-item">
                                        <div data-toggle="tab" aria-expanded="false"
                                            class="nav-link subtab rounded-0 full-height"
                                            [ngClass]="(activeHealthSubTab==='medication information')?'active':'inactive'"
                                            (click)="infoHealthSubTab('medication information')">
                                            <i class="fas fa-question-circle fa-4x info-style" aria-hidden="true">
                                                <span class="info-content">Medication Information</span></i>
                                        </div>
                                    </li>
                                    <li class="nav-item">
                                        <div data-toggle="tab" aria-expanded="false"
                                            class="nav-link subtab rounded-0 full-height"
                                            [ngClass]="(activeHealthSubTab==='useful checklists')?'active':'inactive'"
                                            (click)="infoHealthSubTab('useful checklists')">
                                            <i class="fas fa-clipboard-list fa-4x info-style" aria-hidden="true">
                                                <span class="info-content">Useful Checklists</span></i>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card pull-up" *ngIf="activeTab ==='My Technology'">
            <div class="card-content collapse show">
                <div class="card-body">
                    <div class="card-text">
                        <p class="text-justify intro" style="color: black ;">
                            <b class="font-18"> Evolve with Technology - Safely and Securely </b>
                            <br /><br />
                            It’s a digital world and it’s not easy to keep up and stay safe.
                            Here you can find the information and tools to keep up - and keep safe.<br />
                            Check here to keep up with emerging technology that can help you and your ageing loved ones.
                            <br />
                            <br />
                            <i class="fas fa-lock fa-2x inline-icon-style tech-color" aria-hidden="true"></i>
                            <b class="font-18">Online Security & Safety:</b> We all know that being online comes with
                            risks. Unfortunately the scammers and criminals always seem to be a step ahead.
                            Some of the scams are shockingly clever and anyone is easily duped. Fortunately, Australia
                            now has an official eSafety Commissioner.
                            Here is a direct link to their guidance for eSafety for Seniors: <a
                                href="https://beconnected.esafety.gov.au/bookings"
                                target="_blank">https://beconnected.esafety.gov.au/bookings</a>. Why not watch one of
                            these helpful Free Webinars with your adult children or grandchildren?
                            Caring for grandchildren? Help keep them safe online: <a
                                href="https://www.esafety.gov.au/seniors/online-safety-grandparents-carers"
                                target="_blank">https://www.esafety.gov.au/seniors/online-safety-grandparents-carers</a>.


                            <br /><br />
                            <i class="fas fa-chalkboard-teacher fa-2x inline-icon-style tech-color"
                                aria-hidden="true"></i>

                            <b class="font-18">
                                Get with the program and keep up!
                            </b>
                            There's so much technology available to help you learn with the online resources made
                            specially for seniors!
                            We love the simple and comprehensive learning modules in the BeConnected program that we
                            became a partner so we can get out and help seniors do more online! Check out the many
                            topics available here:
                            <a href="https://beconnected.esafety.gov.au/topic-library "
                                target="_blank">beconnected.esafety.gov.au/topic-library</a>.


                            Start here:
                            <a href="https://beconnected.esafety.gov.au" target="_blank">beconnected.esafety.gov.au.</a>

                            You'll be amazed at how simple it is to pick up skills that make your life easier and
                            better.
                            <br /><br />
                            Residents of New South Wales can learn the basics and keep up easily and affordably,
                            thanks to the partnership between the NSW Government and Telstra, Tech Savvy Seniors.
                            Tech Savvy Seniors runs courses teaching you the basics to survive in a digital world,
                            at no cost in public libraries, and at low cost in NSW community colleges. Find the sessions
                            nearest to you here:
                            <a href="https://www.telstra.com.au/tech-savvy-seniors"
                                target="_blank">www.telstra.com.au/tech-savvy-seniors</a>.
                            Click here
                            <a href="https://www.facs.nsw.gov.au/inclusion/ageing/overview/chapters/tech-savvy"
                                target="_blank">(www.facs.nsw.gov.au/inclusion/ageing/overview/chapters/tech-savvy)</a>

                            to locate the course closest to you. Courses are fun and easy; in numerous languages; at
                            beginner, intermediate as well as advanced level; and cover notebooks, iPads, phones, the
                            internet, social media and more.
                            Auslan courses are available for people with restricted hearing.
                            <br />
                            <br />

                            <b>And there’s more...</b>
                            <br />
                            Many local government-supported community centres conduct training and help sessions
                            specifically for seniors. So check with your local Council. In other suburbs, like the NSW
                            Northern Beaches, a non-profit computer club for seniors (supported by the Council) may be
                            the way to go.
                            <a href="https://www.ascca.org.au/" target="_blank">

                                (Click here to visit www.ascca.org.au/)</a><br />
                            What happens to your “life online” after you die? Think about all that depends on you having
                            the right password. We provide guidance on how to save a lot of trouble for your survivors
                            <b><em>COMING SOON!</em></b> Everything you store using a password:
                            <br />
                            <br />
                            <i class="fas fa-key fa-2x inline-icon-style tech-color" aria-hidden="true"></i>

                            <b class="font-18">
                                Passwords and Logins:
                            </b>
                            Everyone should use a "Password Manager"; some are free, most cost a few dollars a month.
                            Read about why, how, and the extra advantages here, from the trustworthy people at
                            <i>Choice</i>: <a href="https://www.choice.com.au/ele…es/password-managers"
                                target="_blank">www.choice.com.au/ele…es/password-managers</a>





                        <div class="temp" [class.show]="showContent">
                            <b class="font-14">Prefer to have low-cost assistance available to you at home? </b><br />
                            What if you could dial up and get help with your online and device problems when you need
                            it? Would you be willing to pay a very small amount for that help? We'll be asking for your
                            input soon.

                            <!--
            Your 100-Year Life has partnered to bring you My Digital Telehelp, help at your fingertips. 
                            All you need is a phone and a credit card or other payment method.-->
                        </div>

                        <!--
            <ng-container *ngIf="showContent">
                         What happens to your “life online” after you die? Everything you store using a password:

                <br/><br/>

                
                <b>Vital documents</b><br/>
                <b>Accounts</b><br/>

                <b>Photos</b><br/>
                <b>Videos</b><br/>
                <b>Your outlook contact list and more</b>

                <br/>
                <br/>
                <b>Your digitla Afterlife </b>
                <br/>
                Do you know that you cannot automatically access your spouse’s/partner’s/parents’ digital accounts after they die? Why not and what you can (must!) do about it….
                <br/>

                Next week on this page in Your 100-Year Life.com

            </ng-container>
            <a class="yhyl-text" (click)="showContent = !showContent">{{ showContent ? 'Show less': 'Show More' }}</a>
            -->








                        <div class="row">
                            <div class="col-md-12 pl-0 pr-0">
                                <ul class="sub-nav nav nav-justified bg-white pills-display">
                                    <li class="nav-item">
                                        <i class="fas fa-lock fa-4x info-style tech-color" aria-hidden="true">
                                            <span class="info-content">Online <br /> Security & Safety</span></i>
                                    </li>
                                    <li class="nav-item">
                                        <i class="fas fa-chalkboard-teacher fa-4x info-style tech-color"
                                            aria-hidden="true">
                                            <span class="info-content">Learn new skills</span></i>
                                    </li>
                                    <li class="nav-item">
                                        <i class="fas fa-key fa-4x info-style tech-color" aria-hidden="true">
                                            <span class="info-content">Passwords & Logins</span></i>
                                    </li>
                                    <!-- <li class="nav-item">
                                        <i class="fas fa-tablet-alt fa-4x info-style tech-color" aria-hidden="true">
                                            <span class="info-content">Helpful new<br />Technology/apps</span></i>
                                    </li> -->

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card pull-up" *ngIf="activeTab ==='My Work'">
            <div class="card-content collapse show">
                <div class="card-body">
                    <div class="card-text">
                        <p class="text-justify intro" style="color: black ;">
                            <b class="font-14">Continue to stay involved throughout your transition to your
                                retirement</b>
                            <br />
                            <br />
                            The old idea of ‘retiring’ (literally ‘withdrawing’) at age 60 or 65 is out of step with the
                            long, productive lives many people live today.
                            <br />
                            Longevity means we should all think about and plan for a transition to retirement thay may
                            last decades.
                            Here you can find resources that help you plan.
                            And work opportunities, paid or volunteer, that gives life meaning.
                            <br />
                            <br />
                            <i class="fas fa-piggy-bank fa-2x inline-icon-style work-color" aria-hidden="true"></i>
                            <b class="font-18"> Preparing for Retirement: </b>'Retirement' means different things to
                            different people. Some people can't wait - golf, long bush-walks, time with grandchildren.
                            Others say they'll never retire. It's important to have a conversation with your partner or
                            other loved ones; decide what your retirement should look and feel like; and plan ahead for
                            your living arrangements, finances, and more. We'll provide you with free options on how to start that conversation and arrive what works best for you. <i>Ageing - Personalised&trade;</i>

                            <br />
                            <br />
                            <i class="fas fa-hands-helping fa-2x inline-icon-style work-color" aria-hidden="true"></i>
                            <b class="font-18"> Volunteering: </b>
                            Want to give back? There are so many opportunities to volunteer, and put your skills,
                            experience and passions to good use and help others. Here are two places to start looking:
                            <a href="https://www.volunteering.nsw.gov.au/"
                                target="_blank">www.volunteering.nsw.gov.au</a>
                            and <a href="https://www.volunteeringaustralia.org/"
                                target="_blank">www.volunteeringaustralia.org</a>
                            <br />
                            <br />
                            <i class="fas fa-hiking fa-2x inline-icon-style work-color" aria-hidden="true"></i>
                            <b class="font-18"> Activities Finder: </b>

                            Volunteer close to home? Find an opportunity that matches best with your place and passion
                            here:
                            <a href="https://govolunteer.com.au/" target="_blank">govolunteer.com.au</a>
                            <br />
                            <br />
                            <i class="fas fa-laptop-house fa-2x inline-icon-style work-color" aria-hidden="true"></i>
                            <b class="font-18"> Employment Options: </b>
                            Many seniors will wind down their paid work gradually, not suddenly. Others will start a new
                            chapter with different, part-time work. It's sad but true that it is hard for most people
                            over 55 to get a new job, but there are options. We have identified firms specialising in
                            'mature age'
                            recruitment and we have sourced guidance for tuning up your CV and more. <b><em>COMING
                                    SOON!</em></b>
                            <br />
                            <br />


                        </p>
                        <p class="font-italic">


                            <!--
                        <b class="font-14">Why Age-Proof Your Resume?</b>

                        <br/>
                        <br/>
                        Because we know that age discrimination is a real thing. 
                        In one survey nearly half of recruiters admitted to taking age into account: no wonder ¾ of all job applicants over 50 say they’ve experienced age discrimination in their job search. 
                        We’ve compiled the knowledge and experience of recruiters, executives, regulators and researchers to come up with the best advice. The basics are really simple:

                        <br/><br/>
                        <b class="font-14">1. Omit older dates.</b>
                        <br/>
                        Example: List your Honours Degree, and the institution of higher learning, 
                        but omit the date you received it. Secondary school dates, which will enable recruiters to think 
                        (unconsciously of course) “Gee, I wasn’t even born when s/he finished school. S/he’s older than my parents!”
                        <br/><br/>
                        <b class="font-14">2. Keep your resume to 2 pages.</b>
                        <br/>
                        The days when length = relevant experience and value are well and truly over. Eliminate merely ‘interesting’ and extraneous information. Highlight up front your strengths and the value you will bring in your professional statement. Add another page for details of specific achievements related to the job, but that means a different CV for every job application.
                        <br/><br/>
                        <b class="font-14">3. Make sure your contact details reflect how up to date you are: </b>
                        <br/>
                        Keep your LinkedIn profile current, and add it to the top of your resume along with other vital ‘contact’ details especially your mobile number. Get a new email address (not Hotmail or AOL).
                        <br/>
                        Use Keywords that will immediately be picked up by scanning, these days often using computer aided algorithms. That means you will need to research and identify the keywords relevant to that job in that industry. Be mindful that over 90% of recruiters trawl social media. Forewarned is forearmed.
                        <br/><br/>
                        <b class="font-14">
                        Mind the subtle clues that give away your age if you are over 50 (Over 45?). Here are some:
                        </b><br/>
                        <b class="font-14">
                        Font style:</b>
                         Use Calibri or Garamond, Cambria or Verdana (not Times New Roman or Arial)<br/>
                         <b class="font-14">Format:</b>
                       

 Limit Bold to your name and your employers’ names. Italics for your job titles. Bullets yes but limited.

  <b class="font-14">Spacing:</b>
One space after a full stop, not two. Two spaces dates you.
<br/>
<b class="font-14">
Remnants of job applications past:</b>
 No residential address, only 1 phone number – your mobile. <br/><br/>

Guess we all better get to work updating our resumes!!-->

                        </p>
                        <div class="row">
                            <div class="col-md-12 pl-0 pr-0">
                                <ul class="sub-nav nav nav-justified bg-white pills-display">


                                    <li class="nav-item">
                                        <i class="fas fa-piggy-bank fa-4x info-style work-color" aria-hidden="true">
                                            <span class="info-content">Preparing for Retirement</span></i>
                                    </li>

                                    <li class="nav-item">
                                        <i class="fas fa-hands-helping fa-4x info-style work-color" aria-hidden="true">
                                            <span class="info-content">Volunteering</span></i>
                                    </li>

                                    <!--<li class="nav-item">
                                        <i class="fas fa-pager fa-4x info-style work-color" aria-hidden="true">
                                            <span class="info-content">Age proof your resume</span></i>
                                    </li>-->


                                    <li class="nav-item">
                                        <i class="fas fa-hiking fa-4x info-style work-color" aria-hidden="true">
                                            <span class="info-content">Activities Finder</span></i>
                                    </li>


                                    <li class="nav-item">
                                        <i class="fas fa-laptop-house fa-4x info-style work-color" aria-hidden="true">
                                            <span class="info-content">Employement Options</span></i>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card pull-up" *ngIf="activeTab ==='My Finance'">
            <div class="card-content collapse show">
                <div class="card-body">
                    <div class="card-text">
                        <p class="text-justify intro" style="color: black ;">
                            <b class="font-18">Make Your Money Go Further and Protect Your Assets</b>

                            <!--
                            Protecting and growing your money and other assets is a high priority at any age. But our
                            needs and opportunities change over time. --><br /><br />
                            Planning finance for the retirement years is challenging: the regulations around
                            superannuation (retirement) are complicated; none of us knows how long we will live; and
                            sometimes other big decisions are involved (like selling the family home and relocating).
                            Your 100 Year Life does not provide financial advice. But we do give you direct links to
                            access a lot of useful information and resources.
                            <br />
                            <br />
                            <i class="fas fa-money-check-alt fa-2x inline-icon-style finance-color"
                                aria-hidden="true"></i>

                            <b class="font-18">Money Management<sup>*</sup>: </b>

                            Making your money go further should be a top priority for all of us.
                            It’s essential for those of us who have left the workforce and are relying on
                            superannuation, the pension, savings, and other means to fund our longevity.
                            Check frequently with this page for new and clever ways to make sure you are getting the
                            best value for money on life’s essentials.
                            <br />
                            <br />
                            We have compiled the ‘need to know’ sources for keeping track of and managing your money,
                            assets and policies.
                            We also guide you on how to take advantage of the discounts and rebates available to you.
                            You probably know and use some, but probably not all.
                            <br /><br />
                            <i class="fas fa-comment-dollar fa-2x inline-icon-style finance-color"
                                aria-hidden="true"></i>

                            <b class="font-18">Money: </b>
                            Keep track of, protect, and manage your money better. It seems that every day there is
                            something new - usually digital - that helps all of us keep track of our expenditure. One by
                            one the "Big 4 Banks" are rolling out planners and apps. So check with your bank and see
                            what they already offer.
                            <br /><br />
                            <i class="fas fa-calculator fa-2x inline-icon-style finance-color" aria-hidden="true"></i>

                            <b class="font-18">
                                Insurance and other policies: </b>
                            Keep track of and manage all of your policies.
                            <br />
                            If something were to happen to you,
                            could your loved ones find all of your policies and reassess, modify or cancel them? When
                            you sign up for Premium you can access a high-security place to list and store all of your
                            policies. That's essential because you want to make sure that you have renewed and are
                            covered with the insurances you want. And you want to make sure that you don't have
                            overlapping or unnecessary policies. It happens more often than you think.
                            <br /><br />
                            <i class="fas fa-percentage fa-2x inline-icon-style finance-color" aria-hidden="true"></i>

                            <b class="font-18">Superannuation: </b>
                            Keep track of, manage, and make sure your retirement savings will support you
                            through your life, and make sure you are using your resources to support a comfortable - not
                            necessarily minimal - lifestyle in retirement.
                            Free apps, retirement calculators, and other resources are available to help you plan.
                            <a href="https://www.moneysmart.gov.au/glossary/asfa-retirement-standard"
                                target="_blank">Click here for an example of what you will find on this page.</a>
                            <br /><br />
                            <i class="fas fa-wallet fa-2x inline-icon-style finance-color" aria-hidden="true"></i>

                            <b class="font-18">Discounts and rebates: </b>
                            The NSW Government, through Service NSW, makes it easy for you to learn what discounts and
                            rebates may be available to you. Service NSW is continually expanding the list.
                            And now you can even schedule a personal appointment at a NSW Service Centre that helps you
                            make sure you are getting all the discounts and rebates you're eligible for.
                            <a href="https://www.service.nsw.gov.au/campaign/cost-living" target="_blank">Click here to
                                visit Service NSW.</a>
                            <br /><br />
                            In addition, Service NSW has a growing list of ‘comparison’ sites that enable you to find,
                            the least expensive petrol (<a
                                href="https://www.service.nsw.gov.au/transaction/check-fuel-prices"
                                target="_blank">Click here to visit</a>)
                            ,
                            Green Slip (<a href="https://www.service.nsw.gov.au/transaction/use-green-slip-price-check"
                                target="_blank">Click here to visit</a>) and more.
                            <br /><br />If you are a CBA customer you can access CBA's list of benefits and rebates.
                            Easily
                            accessible after you log in as a customer.

                            <br /><br />

                            The granddaddy of discounts and deals available to seniors in Australia: If you are 60 years
                            of age, and meet the minimal requirements,
                            you can save tens of dollars a week by using your Seniors Card for transport and basic goods
                            and services.
                            (Links to Seniors Card in other states are here:
                            <a href="https://www.australia.gov.au/content/seniors-card" target="_blank">Click here to
                                view Seniors Card in other states.</a>
                            Commonwealth bank customers have access to the bank's benefits, rebates and concessions
                            finder.
                            <br />
                            <br />
                            <i class="fas fa-hand-holding-usd fa-2x inline-icon-style finance-color"
                                aria-hidden="true"></i>
                            <b class="font-18">My Assets: </b>
                            There are many times when you need to have an inventory of all of your assets. Money,
                            shares, your home - that's obvious. But what about a pre-paid cemetery plot or funeral? What
                            about some never-used bank account with little in it - but useful to a grandchild?
                            Absolutely make sure you check if you have any 'lost super': <a
                                href="https://www.ato.gov.au/forms/…hing-for-lost-super/"
                                target="_blank">www.ato.gov.au/forms/…hing-for-lost-super</a>.
                            <br /><br />
                            When you sign up for Premium, you get a safe place to store a list and details of your
                            assets. And, if you wish, safe sharing with your trusted loved ones, solicitor, and
                            financial planner. List once and you'll always have it safe and ready.
                            <br />
                            <br />
                            <b class="font-18">
                                Use Your Local Clubs:
                            </b>

                            Local clubs are the heartthrob of many and regional communities, and always have been.
                            Lately many clubs have undergone change, ranging from a refresh to a major transformation,
                            to appeal to new members and guests.
                            Make use not only of your member discounts in your club, but also the discounts offered by
                            their vendors.

                        </p>

                        <p class="font-italic font-10">
                            Your 100 Year Life does not provide financial advice or products.
                            You should always check with your financial adviser before making any financial decision.
                        </p>
                        <br /><br />

                        <!-- <b class="font-18">
                            

                            Your money-saving tips
                            <br/>

                            In development
                            </b>-->











                        <!--Here you will find many really helpful resources to help you save and spend wisely. Your 100
                            Year Life does not provide financial advice. We do guide you to curated information and
                            toolkits-->

                        <!--
                        <p class="font-italic">
                           Real life examples: Shaun realised his mum probably wasn’t taking
                            advantage of all of the discounts, rebates and other money-saving apps, tips, and websites
                            available to her as a pensioner, so he shares this page with her every few months, and helps
                            her register online.
                            <br/>
                            Debbie and Paul: need to cut through all of the conflicting advice about retirement
                            information and options. Here they found tools and information that they can use to plan
                            their retirement.
                        </p>-->

                        <div class="row">
                            <div class="col-md-12 pl-0 pr-0">
                                <ul class="sub-nav nav nav-justified bg-white pills-display">
                                    <li class="nav-item">
                                        <i class="fas fa-money-check-alt fa-4x info-style finance-color"
                                            aria-hidden="true">
                                            <span class="info-content">Money Management</span></i>
                                    </li>
                                    <li class="nav-item">
                                        <i class="fas fa-calculator fa-4x info-style finance-color" aria-hidden="true">
                                            <span class="info-content">Insurance etc.</span></i>
                                    </li>
                                    <li class="nav-item">
                                        <i class="fas fa-percentage fa-4x info-style finance-color" aria-hidden="true">
                                            <span class="info-content">Superannuation</span></i>
                                    </li>

                                    <li class="nav-item">
                                        <i class="fas fa-wallet fa-4x info-style finance-color" aria-hidden="true">
                                            <span class="info-content">Discounts and rebates</span></i>
                                    </li>
                                    <li class="nav-item">
                                        <i class="fas fa-hand-holding-usd fa-4x info-style finance-color"
                                            aria-hidden="true">
                                            <span class="info-content">My Assets</span></i>
                                    </li>

                                    <!--<li class="nav-item">
                                        <i class="fas fa-question-circle fa-4x info-style finance-color"
                                            aria-hidden="true">
                                            <span class="info-content">Frequent Questions</span></i>
                                    </li>-->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card pull-up" *ngIf="activeTab ==='My Care'">
            <div class="card-content collapse show">
                <div class="card-body">
                    <div class="card-text">
                        <p class="text-justify intro" style="color: black ;">




                            <b class="font-18"> <em>COMING SOON!</em></b>
                            <br /><br />
                            <i class="fas fa-users fa-2x inline-icon-style care-color" aria-hidden="true"></i>
                            <b class="font-18">Care at Home: </b> Explore and qualify for a wide range of care and other
                            support options at home.
                            <br />
                            <br />
                            <i class="fas fa-users-cog fa-2x inline-icon-style care-color" aria-hidden="true"></i>
                            <b class="font-18">Coordinating Care:</b> Coordinate, keep track of and communicate with the
                            many
                            people and services that keep your ageing loved one at home - safe, well and loved.


                            <br />
                            <br />
                            <i class="fas fa-hand-holding-usd  fa-2x inline-icon-style care-color"
                                aria-hidden="true"></i>
                            <b class="font-18">Funding Care at Home:</b> Make sure you are receiving all the benefits
                            and financial help you qualify for.
                            <br />
                            <br />
                            <i class="fas fa-umbrella fa-2x inline-icon-style care-color" aria-hidden="true"></i>
                            <b class="font-18">Other Care Resources:</b> Lots of other clever, affordable ways to
                            support your ageing loved one at home

                            <!--Do you travel with first aid essentials? You should.<br/>
                            If you do, do you buy a generic, humdrum, heavy all-purpose kit?<br/>
                            Is it full of bandages, gauze and wipes that you’ll never use? But lacking what you really need?<br/>
                            Why lug a kit designed for a road trip when you’re flying with carry on and watching every kilogram of baggage?<br/>

                           <br/>
                           We have you covered: Lightweight, stylish, practical and functional first aid kits that 
                           match your travel plans, not ‘one-size-fits all’, heavy, bulky and impractical bundles.
                           <br/>
                           <b class="font-18">Click Here </b>to see samples of our Designed-for-You Travel First Aid Kits
                           <br/><br/>
                           We can do the work for you and ship you your own customised travel first-aid kit. 
                           Tell us where you are going, for how long, and we’ll have our travel health partners fill your order.
                            <br/>
                            <b class="font-18">Click Here</b> to get a Standard travel first aid kit<br/>
                            <b class="font-18">Click Here</b> to get a Deluxe travel first aid kit<br/>-->


                        </p>

                        <div class="row">
                            <div class="col-md-12 pl-0 pr-0">
                                <ul class="sub-nav nav nav-justified bg-white pills-display">

                                    <li class="nav-item">
                                        <i class="fas fa-users fa-4x info-style care-color" aria-hidden="true">
                                            <span class="info-content">Care at Home</span></i>
                                    </li>
                                    <li class="nav-item">
                                        <i class="fas fa-users-cog fa-4x info-style care-color" aria-hidden="true">
                                            <span class="info-content">Coordinating Care</span></i>
                                    </li>
                                    <li class="nav-item">
                                        <i class="fas fa-hand-holding-usd fa-4x info-style care-color"
                                            aria-hidden="true">
                                            <span class="info-content">Funding Care</span></i>
                                    </li>
                                    <li class="nav-item">
                                        <i class="fas fa-umbrella fa-4x info-style care-color" aria-hidden="true">
                                            <span class="info-content">Other Care Resources</span></i>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card pull-up" *ngIf="activeTab ==='My Home'">
            <div class="card-content collapse show">
                <div class="card-body">
                    <div class="card-text">
                        <p class="text-justify intro" style="color: black ;" *ngIf="activeHomeSubTab ==='main home'">
                            <b class="font-18">My (Best) Home: Decluttering, Downsizing, Rightsizing and Moving</b>
                            <br /><br />

                            Is your home a perfect fit for you? Is it your “Best Home”?
                            Our definition of "Best Home" is simple: the right home, at the right place, at the right
                            time, and at the right cost<sup>TM</sup> – whatever your age. Are you living in your "Best
                            Home"? Find all the practical information you need to Simplify, Plan, and Manage from start
                            to
                            finish - “ Your Best Home”.<br /><br />
                            <i class="fas fa-tasks fa-2x inline-icon-style home-color" aria-hidden="true"></i>

                            <b class="font-18">
                                Home Choices: </b>It doesn’t
                            have to be so hard. There are now more options to help make your own home your 'best home'
                            as you age. Let us introduce you to some<a (click)="infoHomeSubTab('home choices')">(Click
                                here)</a>. Plus we have designed the best guidance you'll find to help you decide
                            whether, when, where, and how to downsize. <b><em>COMING SOON!</em></b> The Baby Boomer's
                            Guide to Downsizing!<br /><br />
                            <i class="fas fa-house-damage fa-2x inline-icon-style home-color" aria-hidden="true"></i>

                            <b class="font-18">
                                Rightsizing, not downsizing:
                            </b>
                            Downsizing is constantly on the mind of many seniors for lots of reasons. But there are so
                            many factors that need to be considered long before you make the decision to
                            move.
                            Do it systematically and share with your loved ones. We make it simple and easy.
                            <b><em>COMING SOON!</em></b> our "Best Home" (Rightsizing) questionnaire and checklist!
                            Helping you make one of the biggest decisions of your life.
                            <br />
                            <br />
                            <i class="fas fa-trash-alt fa-2x inline-icon-style home-color" aria-hidden="true"></i>

                            <b class="font-18">“Decluttering” - do it your way: </b>
                            Decluttering and downsizing are constantly on the mind of many seniors. Decluttering is
                            scary, emotional, and we usually postpone it. Check out our wide range of decluttering
                            methods.
                            It doesn’t have to be extreme, exhausting
                            or depressing. We've explored the different approaches and can help you pick the one that's
                            best for you. That's <i>Ageing - Personalised&trade;</i>. <a (click)="infoHomeSubTab('decluttering')">(Click here)</a>
                            <br /><br />

                            <i class="fas fa-house-user fa-2x inline-icon-style home-color" aria-hidden="true"></i>

                            <b class="font-18">
                                Smart Home/Safe Home:
                            </b>

                            A smart home is a safe and convenient home. We have sourced a huge range of resources to
                            make your home safer and easier as you age in place. <em><b>COMING SOON!</b></em>
                            <br />
                            <br />
                            <i class="fas fa-laptop-house fa-2x inline-icon-style home-color" aria-hidden="true"></i>

                            <b class="font-18">
                                Home Finance:
                            </b>
                            Financing your home has been revolutionalised - and not just by low interest rates. For
                            people who are retired, however, the financing options are usually different and more
                            limited. We do not offer financial advice, but we do suggest you check with your accountant
                            or financial adviser because at least one category, the 'reverse mortgage' (drawing down on
                            your home equity for living expenses) is changing.
                            <br /><br />
                            <!-- <i class="fas fa-laptop-house fa-2x inline-icon-style home-color" aria-hidden="true"></i>

                            <b class="font-18">
                                Best Home: Transitioning to a different kind of living?
                            </b>

                            There are an increasing range of options for seniors who want to “age in place”, in their
                            own home. Explore your options, and the pro’s and con’s, here. <em><b>COMING SOON!</b></em>


                            <br /><br /> -->

                            <!--
                            <b class="font-18">
                            New and Innovative Models for ‘Ageing at Home’</b>
                            <br/><br/>

                            In the past, options for living and enjoying our ‘second half’ of life have been extremely limited: over ‘55’s (‘retirement living’) and ‘aged care’ (nursing home), although seaside living in a caravan appealed to some.
                            <br/>
                            The rise and rise of the Baby Boomers is generating a new category, commonly known as “Intentional Communities”. Intentional communities are literally communities created by those who want different and more than the usual. 
                            The types are only limited by imagination and determination of those who are creating them.<br/><br/>
                            In this section we highlight a range of options already in existence and in use by Australians, with reference to some overseas models that may eventually be adapted here:

                            <br/>
                            <br/>
                            The Hub model, pioneered by the Waverton Hub – <b class="font-18"> Click here to read more</b><br/>
                            Co-housing<br/>
                            Offshore ‘retirement’<br/>
                            Nuns and Nones – <b class="font-18">Click here to read more</b><br/>
                            Students moving into nursing homes
                            <br/>
                            <br/>
                            <b class="font-18">
                            The Hub model, pioneered by the Waverton Hub
                            </b>
                            <br/>
                            <br/>
                            <b>Our own Australian Model of Community Support for Ageing in Place</b>
                            Most people wish to stay in their own homes as long as possible. That might mean increasing amounts of assistance with daily activities, and support to avoid becoming isolated. That’s where the “Hub” model comes in. A version of the Hub model is known and widely adopted in America as “Naturally occurring retirement communities” (i.e., NORCs, or neighbourhoods where there is a concentration of seniors, who need and, through the local Hub or “NORC” receive that support).
                            <br/>
                            <br/>

“The Waverton Hub is a local community organisation that aims to help older residents of the Sydney suburbs of Waverton, Wollstonecraft and nearby areas age well in their own homes, for as long as possible, as meaningfully as possible and as cost-effectively as possible. The Hub operates not as a for-profit company, but as a ‘mutual association’. It charges a modest annual fee to cover its costs. It receives support from local government and community organisations, and thus can operate at little cost to its members: over 80 of the Hub’s 320 members organise, as volunteers, the Hub’s activities. 
Many of the Hub’s events are open to all members of the community – not just Hub members.<br/><br/>
<b class="font-18">Learn more about the Hub model in their video here:
</b>

<br/><br/>
The Hub is not only an Australian pioneer, its leaders have worked selflessly to promote the Australian model and help other communities to build their own local Hubs. They have developed and share a step-by-step process that guides communities methodically through the process.

<br/>You can learn more about the process of designing and building your own Hub here:<br/>
<b class="font-18">  (In development) 
Watch this space….
</b>
<br/>
<br/>
<b class="font-18">
De-cluttering Simplified
</b>
<br/>
<br/>

In this section we focus on the first steps we all can take to make sure we are living sensibly and comfortably in our best home.
<br/><br/>


<b>Good News:</b> The majority of Australians over the age of 50 years have lived through times that have enabled many of us to acquire many convenience items, luxury goods, fashionable accents, and generally…… stuff.
<br/>
<b>Bad News:</b> Stuff doesn’t disappear without human intervention. So if we want to keep it all, we need to organize it, store it so we can find it, and/or also find time to use and enjoy all that stuff.

<br/><br/>
Many of us who have reached 55, 60 and older will begin to transition to a new home that fits our needs as well as our budget. Many of us will not be moving into a home with the same or greater storage capacity as our current home. Many of us have concluded that we are increasingly more interested in lived experiences, in meaning and in family than in objects.

<br/><br/>
It’s never too soon to start thinking about ‘decluttering’, or what most experts euphemistically call “tidying”. We agree, but we also know from first-hand experience that:
<br/><br/>
1) Starting the de-cluttering process decluttering can be emotionally challenging for many people.
<br/>

2) It is often a huge as well as confronting task.<br/>
3) No one method fits all.<br/>
4) It helps to go step by step according to guidelines that resonate with you personally.<br/><br/>
Here is a collection of hand-picked books that we have read and assessed for you to help you better select and follow a process of decluttering that suits you, your situation, and your personality.
Some are available as e-books. All can be ordered from us by clicking ‘Click here to Buy’.
<br/><br/>
<b class="font-18">
Books by Marie Kondo 


</b><br/><br/>
The method Marie Kondo describes in her best-selling guide to decluttering, the KonMari Method, is a step-by-step approach to simplify, organize, and store only the essentials.
<br/>
<b>Warning:</b> Be prepared to give away anything that does not ‘spark joy.’ Yes, some may find it extreme; others will achieve contentment with the catharsis of the zen approach.
<br/><br/>
<b class="font-18">
The Gentle Swedish Art of Death Cleaning 
</b>
<br/><br/>
It has an unattractive title but provides thoughtful insights and useful guidance. It starts from the obvious fact that every one of is going to die sometime. It encourages readers to consider the advantages of organizing their home and their legacy in the way they would like their children or relatives to remember them when they are gone.
<br/>
Like Marie Kondo’s ‘Spark Joy’ approach, the author’s method involves minimalism and joy but has a distinctive Scandinavian flavour plus humour.

<br/><br/>
<b class="font-18">
The Sentimental Person’s Guide to Decluttering
</b><br/><br/>
Does saving memories mean saving mementos? Not necessarily. Here are a few guides that help you keep the memories but avoid the endless boxes, closets and drawers of things from days gone by.
 It will give you practical suggestions for downsizing the volume of things in your home. The tips apply whether you are ‘decluttering’, downsizing, or simply transitioning at a major life stage – like no more children living at home! Like many other ‘decluttering’ guides, it claims that decluttering delivers freedom and joy while preserving the memories and other feelings that you associate with heirlooms, books, clothes and more.
<br/>
<br/>
<b class="font-18">Don’t Toss My Memories in the Trash
</b>
<br/><br/>
One of the best reviewed books, this recent guide covers the spectrum from understanding why it is so difficult, to the step-by-step process. The majority of reviewers hailed it as “sensitive”, “compassionate” and especially useful for two or more generations to read before starting or helping a parent or older relative with the process of downsizing and moving.
<br/><br/>
“Really useful for us Baby Boomers with ageing parents.”
<br/>
<br/>
<b class="font-18">Don’t Downsize, RightsizeTM</b>
<br/><br/>
We at Your 100 Year Life believe in ‘right-sizing, not down-sizing!’™. And we believe that the principles behind “rightsizing” apply at every stage of life. 
<br/>

Following our belief that all of us need ‘the right home, at the right place, at the right time, at the right cost’, complete our short questionnaire HERE. It will help you decide whether you are indeed living in your ‘best home’ now and in the foreseeable future. And if you aren’t it can help you focus on the essentials that would improve your quality of life and help you better plan your future.
<br/>

Here is a collection of hand-picked books to help you better select and follow a process of right sizing needs that suits you, your situation, and your personality. Some are available as e-books. All can be ordered from us by clicking ‘Click here to Buy’.

<br/>
<b class="font-18">

Right-Sizing Your Home
</b>
“Right-sizing” as used here means fitting your furniture and décor to your current home – it does not mean getting the “right size” home for your lifestyle and needs. But you will get some useful tips on how to make the rooms in your home work better.
<br/>
<br/>
<b class="font-18">
Right-Sizing Your Life
</b>
A guide to what to keep, what to discard, this book is more about paring back in preparation for a move – but with regard for the emotions involved. The foreword is by the acknowledged expert on life “Passages”, Gail Sheehy.
<br/>
<br/>
<b class="font-18">
Downsizing the Family Home
</b><br/>
Practical – but also “sensitive”, “hand-holding”, “kind”a “game changer”, “feeling lighter”,according to readers. It is not surprisingly the US giant American Association for Retired Persons (AARP) published a great guide and workbook to this emotionally charged topic. It has received stellar reviews. It’s the only book whose title tells it like it is for seniors: it is about “downsizing” and about “family”.
<br/>
<br/>
<b class="font-18">
Thoreau’s Downsizing Planner for seniors
</b><br/>
Mixed reviews, but the worksheets and templates are useful. “Thoreau” refers to the American author-philosopher Thoreau. You may not apply or even like the Thoreau quotes – or they might inspire you to read more Thoreau
<br/>
<br/>
<b class="font-18">Moving in the Right Direction</b><br/>
Using his professional experience in helping older people prepare to downsize and move, this book is more helpful for understanding the reasons why it is so hard for older people to whittle down and move. Not as useful as others on the process itself – but that is the service provided by the author.
<br/>
<br/>
<b class="font-18">
Scaling Down
</b><br/>
Another very highly rated book, clearly aimed at Baby Boomers (for themselves and their parents), this book goes into the underlying issues (shopping), and psychology of keeping and tossing. It applies several systematic techniques for winnowing down memorabilia, and has been useful for younger people and those trying to declutter and just stay put.
<br/>
<br/>
<b class="font-18">
Questionnaire / Checklist
</b>
<br/><br/>
<b class="font-18">Are you living in your “Best Home”?
</b>
<br/>
What does “Rightsizing” mean for you?<br/>
Are there simple, low-cost ways of making your home your “Best Home”?<br/><br/>
This one-page questionnaire is designed to help you think about whether your home is truly the “right fit” for you today and tomorrow. Feel free to use the questions, and your answers and thoughts, to guide a discussion with your partner and/or family, and friends.
<br/>
<br/>
1) What do you love most about your home? (What makes you happy to be in your home?)
<br/>
2) What is it about your home that makes it a good home for today?<br/>
3) What would you change about your home if you could? (Ex: install a lift; extra room/s; install or remove a pool?)<br/>
4) Is your home a good match for the physical needs of you and your family?<br/>
5) Are the physical and financial demands of maintaining your home affordable? A concern?<br/>
6) Do you feel secure in your home? Do you feel safe? If not, why not? Have you considered doing anything to make it more secure?<br/>
7) Is your home the right size for the life you are living? If not, why not – what would you change?<br/>
8) Have you found yourself thinking about changing your home – or moving - to better fit your lifestyle and needs? Now? Sometime in the future?<br/>
9) Do you have any ideas about what kind of home environment would work well for you? A home with garden? An apartment?<br/>
10) Have any of your friends or family “right-sized” recently? What did they do and how would you describe their experience? Would it work for you?<br/>
11) Are you aware of the following housing options for people over 55? Retirement living? Aged care? Co-housing? “Lifestyle communities”?<br/>

























                        </p>



                        <p class="font-italic">Real life examples: and Sam, Mike and Steve recognise that their mum is
                            having difficulty living alone in her own home. They used the information and questionnaires
                            to walk Mum through her options and find resources that work for her.
                            Richard and Connie are always thinking about ‘downsizing’. They aren’t quite ready, but keep
                            up with the latest here, and used the resources here to start de-cluttering gradually.
                            -->
                        </p>
                        <p class="test-justify intro" style="color: black ;" *ngIf="activeHomeSubTab ==='home choices'">
                            <a data-toggle="tab" aria-expanded="false" class="nav-link subtab rounded-0 full-height"
                                (click)="infoHomeSubTab('main home')"><i class="fas fa-chevron-left"></i> <b
                                    class="font-18"> Back</b></a>
                            <br />
                            <i class="fas fa-tasks fa-2x inline-icon-style home-color" aria-hidden="true"></i>
                            <b class="font-18">
                                Home Choices</b>
                            <br /><br />
                            Our definition of 'Best Home' is simple: the right home, at the right place, at the right
                            time, and at the right costTM – whatever your age. Are you living your "Best Home"?
                            Until recently options for living and enjoying our ‘second half’ of life have been extremely
                            limited: over ‘55’s (‘retirement living’) and ‘aged care’ (nursing home), with living
                            seaside for part or all of the year the right mix for some.
                            Things are changing rapidly. Here’s a summary of different options with links to Pro’s and
                            Cons for each. As the housing options for seniors evolves, we’ll be adding more. But first
                            we’ve compiled a short questionnaire to make it simple and painless for you – and your loved
                            ones – to identify what options are best for you. Your Best Home today, and help planning
                            for tomorrow. <i>Ageing - Personalised&trade;</i>. <b><em>COMING SOON!</em></b>
                            <br /><br />
                            <b class="font-18">
                                <i>'Ageing at Home'</i></b>
                            <br /><br />
                            Most seniors in Australia want to remain in their own homes forever. Or at least until such
                            point as they can no longer be assured of the safety of their own home. That includes a lot
                            of seniors who want to move closer to their family, especially to their adult children and
                            grandchildren.
                            <br /><br />
                            The rise and rise of the Baby Boomers is generating a new category, commonly known as
                            “Intentional Communities”. Intentional communities are literally communities created by
                            those who want different and more than the usual. The types are only limited by imagination
                            and determination of those who are creating them.
                            <br /><br />
                            In this section we highlight a range of options already in existence and in use by
                            Australians, with reference to some overseas models that may eventually be adapted here:
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. The Hub model, pioneered by the Waverton
                            Hub <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. HomeShare<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. Retirement Villages<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. Manufactured housing<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5. Intergenerational Living – Duplexes,
                            Granny Flats and More<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6. Co-housing<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7. Offshore ‘retirement’
                            <br /> <br />
                            <i class="bx bx-check-double fa-2x inline-icon-style home-color"></i>
                            <b>1. The Hub: Our Own Australian Model of Community Support for Ageing in Place</b>
                            <br /> <br />
                            A version of the Hub model is known and widely adopted in America as “Naturally occurring
                            retirement communities” (i.e., NORCs, or neighbourhoods where there is a concentration of
                            seniors, who need and, through the local Hub or “NORC” receive support enabling them to age
                            well, for longer, in their own homes).
                            <br /><br />
                            A Hub operates not as a ‘mutual association’ (not a for-profit). It charges a modest annual
                            fee; receives support from local government and community organisations; and thus operates
                            at little cost to its members. The Hub’s members organise, as volunteers, the Hub’s
                            activities. Many of the Hub’s events are open to all members of the community – not just Hub
                            members.
                            <br /><br />
                            Learn more about the Hub model in their video here: <b>Hot link only here</b>
                            <br /><br />
                            You can find guidelines and a manual on how to establish a Hub in your own community here:
                            <b>Hot link only here</b>
                            <br />
                            <br />
                            <i class="bx bx-check-double fa-2x inline-icon-style home-color"></i>

                            <b>2. HomeShare</b>
                            <br /><br />
                            For those who own their own homes and prefer to remain sharing one’s home has long been an
                            option: older people who have a room to share are matched with younger people who can offer
                            domestic help and companionship in exchange for reduced or free rent. HomeShare schemes tend
                            to be operated by not-for-profit organisations, which match, screen and provide formal
                            documentation for seniors sharing their homes. It is not intended to be a substitute for
                            formal care, but has been successful, in complementing the provision of care services and
                            family support.
                            In Sydney, Holdsworth House, a not-for-profit, highlights <a
                                href="https://holdsworth.org.au/homeshare/" target="_blank">(watch the videos)</a> how
                            well HomeShare for seniors can work.
                            <br /><br />
                            <i class="bx bx-check-double fa-2x inline-icon-style home-color"></i>

                            <b>3. Manufactured housing and residential land lease communities</b>
                            <br /><br />
                            Back in the day an earlier version of this housing choice was known as caravan parks. That
                            has changed. Today some are specifically designed for seniors, many not. Some are
                            specifically designed for seniors, many not. Some are specifically designed for seniors,
                            many not. Some are specifically designed for seniors, or permanent homeowners, many not. In
                            NSW alone close to 500 land lease communities offer a variety of services, facilities and
                            financial arrangements. These options are evolving rapidly to meet Baby Boomers’ financial
                            and lifestyle preferences. Legislation for consumer protection is evolving too.
                            <br /><br />
                            The Queensland Government has an excellent guide to making wise decisions about retiring to
                            a residential land lease community: <a
                                href="https://www.qld.gov.au/housing/buying-owning-home/housing-options-in-retirement/manufactured-homes/buying-manufactured-home/before-you-buy-a-manufactured-home"
                                target="_blank">Hot link only here</a>
                            <br /><br />
                            <i class="bx bx-check-double fa-2x inline-icon-style home-color"></i>

                            <b>4. Retirement Villages – Over 55’s Living & Land Lease Communities</b>
                            <br /><br />
                            As the Australian dream of retirement has shifted in recent decades, so, too has the concept
                            and reality of Over 55’s living. A generation of these ‘villages’, built and marketed as
                            ‘resorts’ are often located a distance from urban centres. Lately ‘retirement villages’ have
                            had to deal with intense scrutiny by regulators; Baby Boomers still very active and even
                            employed ‘Over 55’s’; and the ageing of their residents to the point where they require the
                            supportive care services that these villages are not set up to supply. 60% of residents of
                            these villages will move directly into age care (nursing home).
                            <br /><br />
                            Golf, beach, lake, urban, super luxury – there is an abundant choice today for those seeking
                            to live among other ‘Over 55’s’ in a selective community.
                            <br /><br />
                            The NSW Office of Fair Trading has an excellent, easy-to-read up-to-date Guide for moving
                            into, living in, and leaving a Retirement Village. A must-read for seniors and their
                            families before making the move: <a
                                href="https://www.fairtrading.nsw.gov.au/housing-and-property/retirement-villages"
                                target="_blank">https://www.fairtrading.nsw.gov.au/housing-and-property/retirement-villages</a>
                            <br /><br />
                            Lend Lease Living has recently introduced a new model of retirement community, aimed at Over
                            50’s, in which residents own and maintain their own homes but lease the land on which it
                            sits, and also contribute to the shared facilities of the community. You can find out more
                            about this new concept, designed to lower the purchase price of each home, and compare it to
                            traditional retirement village living here: <a
                                href="https://www.landleaseliving.com/compare-your-options/"
                                target="_blank">https://www.landleaseliving.com/compare-your-options/</a>
                            <br /><br />
                            <i class="bx bx-check-double fa-2x inline-icon-style home-color"></i>

                            <b>5. Intergenerational Living: Duplexes, ‘Granny Flats’ and more</b>
                            <br /><br />
                            Did you know that intergenerational living – where several generations of the same family
                            live on the same property and often in the same dwelling (duplexes) - is on the rise?
                            Especially among Baby Boomers. And in the US there has been a marked increase in family
                            groups pooling their funds to buy the single property for all? 15% of home purchasers
                            surveyed last year said they had purchased their home for multigenerational living!
                            <br /><br />
                            Regulation governing approvals for ‘Granny Flats’ in New South Wales was eased a few years
                            ago to make it fast and easy to get approval and build. And of course some families design
                            and build their main residence for the future accommodation of ageing parents where the
                            zoning permits.
                            <br /><br />
                            <i class="bx bx-check-double fa-2x inline-icon-style home-color"></i>

                            <b>6. Co-housing and ‘Intentional Communities’</b>
                            <br /><br />
                            The idea of a group of friends coming together to design and build their own ‘retirement
                            village’ in miniature isn’t new. Its aim is to provide a mix of private and shared spaces
                            that provides both privacy and community. Co-housing, a form of ‘intentional community’, is
                            well established overseas but has grown slowly in Australia. You can find free Fact Sheets
                            about co-housing at UTS here: <a
                                href="https://www.uts.edu.au/research-and-teaching/our-research/institute-sustainable-futures/our-research/social-change-4"
                                target="_blank">https://www.uts.edu.au/research-and-teaching/our-research/institute-sustainable-futures/our-research/social-change-4</a>
                            <br /><br />
                            <i class="bx bx-check-double fa-2x inline-icon-style home-color"></i>

                            <b>7. Overseas Retirement</b>
                            <br /><br />
                            Retiring overseas appeals to different people for different reasons – returning to one’s
                            country of birth, lowering the cost of living, moving closer to adult children and
                            grandchildren, indulging in another culture, etc. However, overseas retirement can have
                            serious implications on pension and other benefits, so it’s best to analyse the pro’s and
                            con’s before making any firm decision.

                            <br /><br />
                        </p>
                        <p class="test-justify intro" style="color: black ;" *ngIf="activeHomeSubTab ==='downsizing'">
                            <a data-toggle="tab" aria-expanded="false" class="nav-link subtab rounded-0 full-height"
                                (click)="infoHomeSubTab('main home')"><i class="fas fa-chevron-left"></i> Back</a>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </p>
                        <p class="test-justify intro" style="color: black ;" *ngIf="activeHomeSubTab ==='decluttering'">
                            <a data-toggle="tab" aria-expanded="false" class="nav-link subtab rounded-0 full-height"
                                (click)="infoHomeSubTab('main home')"><i class="fas fa-chevron-left"></i> Back</a>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </p>
                        <p class="test-justify intro" style="color: black ;" *ngIf="activeHomeSubTab ==='smart home'">
                            <a data-toggle="tab" aria-expanded="false" class="nav-link subtab rounded-0 full-height"
                                (click)="infoHomeSubTab('main home')"><i class="fas fa-chevron-left"></i> Back</a>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </p>
                        <p class="test-justify intro" style="color: black ;" *ngIf="activeHomeSubTab ==='home finance'">
                            <a data-toggle="tab" aria-expanded="false" class="nav-link subtab rounded-0 full-height"
                                (click)="infoHomeSubTab('main home')"><i class="fas fa-chevron-left"></i> Back</a>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </p>
                        <div class="row">
                            <div class="col-md-12 pl-0 pr-0">
                                <ul class="sub-nav nav nav-justified bg-white pills-display">
                                    <li class="nav-item">
                                        <div data-toggle="tab" aria-expanded="false"
                                            class="nav-link subtab rounded-0 full-height"
                                            [ngClass]="(activeHomeSubTab==='home choices')?'active':'inactive'"
                                            (click)="infoHomeSubTab('home choices')">

                                            <i class="fas fa-tasks fa-4x info-style home-color" aria-hidden="true">
                                                <span class="info-content">Home Choices</span></i>
                                        </div>

                                    </li>
                                    <li class="nav-item">
                                        <div data-toggle="tab" aria-expanded="false"
                                            class="nav-link subtab rounded-0 full-height"
                                            [ngClass]="(activeHomeSubTab==='downsizing')?'active':'inactive'"
                                            (click)="infoHomeSubTab('downsizing')">
                                            <i class="fas fa-house-damage  fa-4x info-style home-color"
                                                aria-hidden="true">
                                                <span class="info-content">Downsizing/Rightsizing</span></i>
                                        </div>

                                    </li>
                                    <li class="nav-item">
                                        <div data-toggle="tab" aria-expanded="false"
                                            class="nav-link subtab rounded-0 full-height"
                                            [ngClass]="(activeHomeSubTab==='decluttering')?'active':'inactive'"
                                            (click)="infoHomeSubTab('decluttering')">
                                            <i class="fas fa-trash-alt fa-4x info-style home-color" aria-hidden="true">
                                                <span class="info-content">Decluttering</span>
                                            </i>
                                        </div>
                                    </li>
                                    <li class="nav-item">
                                        <div data-toggle="tab" aria-expanded="false"
                                            class="nav-link subtab rounded-0 full-height"
                                            [ngClass]="(activeHomeSubTab==='smart home')?'active':'inactive'"
                                            (click)="infoHomeSubTab('smart home')">
                                            <i class="fas fa-house-user fa-4x info-style home-color" aria-hidden="true">
                                                <span class="info-content">Smart Home</span></i>
                                        </div>
                                    </li>
                                    <li class="nav-item">
                                        <div data-toggle="tab" aria-expanded="false"
                                            class="nav-link subtab rounded-0 full-height"
                                            [ngClass]="(activeHomeSubTab==='home finance')?'active':'inactive'"
                                            (click)="infoHomeSubTab('home finance')">
                                            <i class="fas fa-laptop-house fa-4x info-style home-color"
                                                aria-hidden="true">
                                                <span class="info-content">Home Finance</span></i>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card pull-up" *ngIf="activeTab ==='My Friends and Family'">
            <div class="card-content collapse show">
                <div class="card-body">
                    <div class="card-text">
                        <p class="text-justify intro" style="color: black ;">
                            <b class="font-18">My Family and Friends</b>
                            <br /><br />
                            The COVID-19 pandemic of 2020 and 2021 have caused family and friends to remain at a
                            distance for long periods. Older Australians have been severely isolated. Their adult
                            children have felt anguish and desperation at not being able to see, let alone hug, their
                            ageing loved ones.
                            <br /><br />
                            <i class="fas fa-user-friends fa-2x inline-icon-style home-color" aria-hidden="true"></i>

                            <b class="font-18">Staying Connected: </b>

                            <!--Ties with family and friends are usually the main source of meaningful engagement, ideally joy, as we age. 
                           At least they should be. But mobility, distance and time pressures are common reasons why seniors may have 
                           less contact with family members than everyone likes and needs. In today’s mobile world, many happy families do
                            not have the regular contact that previous generations of families once did. 
                           <br/>

                        As we age our need for meaningful contact is heightened by isolation, loneliness, 
                        lack of mobility and dearth of transportation. 
                        And as we age our need for physical support for common daily activities increases.
                         The gap between what we need emotionally and physically, and what our families can provide, continues to grow,
                          heightened by the declining ability to fund essential support services for the growing population of seniors.
                    <br/>

                    Facebook, FaceTime and Skype have to some degree bridged the gap digitally. 
                    But they are limited. At Your 100-Year Life we have assembled at your fingertips a broad, 
                    up-to-date and customised platform for you and your family and friends to mutually support one another.
                        <br/>-->

                            You're probably already using Facebook, What'sApp, good old phones, and other ways to see
                            and speak with friends and family.
                            <br />Don't stop! They are your lifeline when you can't visit with family and friends as
                            often as you'd like.

                            <br /><br />
                            But there are a lot of ways to be even better connected. The very best is by using Your 100
                            Year
                            Life's tool that gives you one place to store and share photos, videos, tributes and more -
                            only with your permission.
                            The first 250 people to register get free access to our innovative personalised toolkit. Go
                            to Explore to see how to connect better. <em><b>COMING
                                    SOON!</b></em><br /><br />


                            We've reviewed the best and latest in easy-to-use technology to keep connected with loved
                            ones. And, as always, we help you identify what will work best for the needs and preferences
                            of your loved one.
                            You won't have to search dozens of websites, read lots of reviews. But best of all we help
                            you figure out what is likely to work best for you and your ageing relatives and friends.
                            Recommendations customised for you. <i>Ageing - Personalised&trade;</i>&nbsp;<em><b>COMING
                                    SOON!</b></em>
                            <br /><br />
                            <i class="fas fa-tablet-alt fa-2x inline-icon-style home-color" aria-hidden="true"></i>
                            Not all seniors want to use mobile phones, ipads or other digital devices. Mobile phones are
                            too small for them for anything but use as a telephone. Not all seniors are today connected
                            to wifi (or live where they can get wifi reception). They may not see the point because they
                            don't intend to use wifi-enabled devices like iPads.
                            <br />
                            But for the adult children of seniors living at a distance, two devices can open up a world
                            of connection and personal safety for these seniors:
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. A mifi device, which creates a mini
                            wireless broadband cloud or hotspot - without the need for wifi; and
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. A voice-activated iPad. These can post
                            reminders about medication, appointments, and other vital daily activities. And the latest
                            versions allow family to 'beam in' through their loved one's ipad to check on them or just
                            connect any time - of course only with prior authorisation!



                        </p>

                        <!-- <div class="row"
                            style="align-items: center; margin-left: 150px; padding:5px; overflow-x:auto; overflow-y:auto;">
                            
                            <div class="col-1"></div>
                            <div class="col-2 text-center">
                                <i class="fas fa-lock fa-4x info-style tech-color" aria-hidden="true">
                                    <span class="info-content">Online <br /> Security & Safety</span></i>
                            </div>
                            <div class="col-2 text-center">
                                <i class="fas fa-chalkboard-teacher fa-4x info-style tech-color" aria-hidden="true">
                                    <span class="info-content">Learn new skills</span></i>
                            </div>
                            <div class="col-2 text-center">
                                <i class="fas fa-key fa-4x info-style tech-color" aria-hidden="true">
                                    <span class="info-content">Passwords & Logins</span></i>
                            </div>
                            <div class="col-2 text-center">
                                <i class="fas fa-tablet-alt fa-4x info-style tech-color" aria-hidden="true">
                                    <span class="info-content">Helpful new<br />Technology/apps</span></i>
                            </div>
                            <div class="col-2 text-center">
                                <i class="fas fa-pen-square fa-4x info-style tech-color" aria-hidden="true">
                                    <span class="info-content">Software <br />updates & installations</span></i>
                            </div>
                            <div class="col-1"></div>
                        </div> -->
                        <div class="row">
                            <div class="col-md-12 pl-0 pr-0">
                                <ul class="sub-nav nav nav-justified bg-white pills-display">
                                    <li class="nav-item">
                                        <i class="fas fa-user-friends fa-4x info-style home-color" aria-hidden="true">
                                            <span class="info-content">Staying Connected</span></i>
                                    </li>
                                    <li class="nav-item">
                                        <i class="fas fa-tablet-alt fa-4x info-style home-color" aria-hidden="true">
                                            <span class="info-content">iPads, phones <br /> and more</span></i>
                                    </li>

                                    <li class="nav-item">
                                        <i class="fas fa-film fa-4x info-style home-color" aria-hidden="true">
                                            <span class="info-content">Memories</span></i>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card pull-up" *ngIf="activeTab ==='My Security'">
            <div class="card-content collapse show">
                <div class="card-body">
                    <div class="card-text">
                        <p class="text-justify intro" style="color: black ;">
                            <b class="font-18">Online, Offline and Personal Security</b>
                            <br />
                            Everyone wants to feel safe and secure in their own home, on the internet and out and about.
                            We help you protect yourself, your home and your belongings!
                            Here you will find tips and tools to make sure you and your home are safe and secure.


                            <br />
                            <br />
                            <i class="fas fa-file fa-2x inline-icon-style security-color" aria-hidden="true"></i>

                            <b class="font-18">
                                Secure Your Vital Documents:
                            </b>
                            Sign up for Premium and get your own, secure, personal spot to store all of those vital
                            documents.

                            If something happened to you, would a trusted loved one be able to find all of your urgent
                            vital documents, policies, subscriptions, direct debits and more?
                            Help your family and loved ones by listing what they need to know and manage here.
                            We provide a free guide and worksheet to make it easy.
                            <br />
                            <br />
                            <i class="fas fa-shield-alt fa-2x inline-icon-style security-color" aria-hidden="true"></i>

                            <b class="font-18">
                                My Physical Security:
                            </b>
                            Protect yourself and loved ones from falls, burglary, and more. We have consulted with
                            experts and put together the best, easy-to-use checklist to make sure you can identify every
                            hazard - and we then provide a menu of simple, low-cost solutions!
                            <br />
                            Safe out and about? There are more and more apps and widgets that can help you stay safe,
                            and raise an alarm, when you're out and about. We keep up on them so you don't have to!
                            <b><em>COMING SOON!</em></b>
                            <br />
                            <br />

                            <i class="fas fa-exclamation-triangle fa-2x inline-icon-style security-color"
                                aria-hidden="true"></i>

                            <b class="font-18">Abuse Alert: </b>
                            Sadly, Elder Abuse is very common and financial abuse happens often. They take lots of
                            forms, and it's important to
                            know how they can sneak into and destroy your life, or the life of a loved one, and what to
                            do
                            about them. Take 3 minutes to read this simple guide:
                            <a href="https://moneysmart.gov.au/financial-abuse"
                                target="_blank">moneysmart.gov.au/financial-abuse</a>.

                            Are you or someone you love being taken advantage of?
                            Having money or other valuable things “borrowed” or used for other purposes? Guidance and
                            tools from reliable sources is here.
                            <br /><br />
                            <i></i>
                            <i class="fas fa-exclamation fa-2x inline-icon-style security-color" aria-hidden="true"></i>

                            <b class="font-18">Scam Alert:</b>
                            Scammers prey on seniors. Full stop. Please keep up with the latest scams: go to your bank's
                            website, and visit this Commonwealth Government website: <a
                                href="https://www.scamwatch.gov.au/" target="_blank">www.scamwatch.gov.au</a>.
                            Here's a link to the free-to-all alert site of The Commonwealth Bank: <a
                                href="https://www.commbank.com.au/a…stay-scam-smart.html"
                                target="_blank">www.commbank.com.au/a…stay-scam-smart.html</a>.

                            <br />
                            Our free guidance to staying safe on line. Protect yourself and your loved ones.
                            Simple, reliable information and guidance is here.
                            <br /><br />
                            <i class="fas fa-umbrella fa-2x inline-icon-style security-color" aria-hidden="true"></i>
                            <b class="font-18">My Insurance:</b> Don't be surprised if you don't have an easily
                            accessible list of all your insurance and related policies: most people don't. But shouldn't
                            you urge your ageing loved one to compile a list in the event that they become incapacitated
                            and someone else needs to locate them on their behalf? Compile and store in your/their
                            Personal Page. No more chaos.


                            <!--    ***********
                                What you'll find here:<br/>
                            All you need to know to stay safe in your home, on the internet, out and about, and in case of emergency.<br/><br/>

                            Why: Because we all want to feel safe, always.<br/><br/>

                            How to get the information and guidance you need: Choose the icon and category that best describes your concern, click once, and find all you need. Simple and neat so you can go right to what you need: information and guidance in one place.
                            <br/>
                            <br />-->


                        </p>
                        <!--<p class="font-italic">Real life examples:
                            Derek: realised how often and easily his mother was falling for online scams. He took her
                            through the toolkit here so she could identify a scam. He printed out the simple page on
                            “Do’s and Don’ts” so she can read it often. So far no more money to scammers.
                            Louise: TO KEEP HER mum safe…
                            John, Theresa, Anne and Thomas: INSURANCE ETC POLICIES
                        </p>-->
                        <div class="row">
                            <div class="col-md-12 pl-0 pr-0">
                                <ul class="sub-nav nav nav-justified bg-white">
                                    <li class="nav-item">
                                        <i class="fas fa-file fa-4x info-style security-color" aria-hidden="true">
                                            <span class="info-content">Vital Documents</span></i>
                                    </li>
                                    <li class="nav-item">
                                        <i class="fas fa-shield-alt fa-4x info-style security-color" aria-hidden="true">
                                            <span class="info-content">My Physical Security</span></i>
                                    </li>
                                    <li class="nav-item">
                                        <i class="fas fa-exclamation-triangle fa-4x info-style security-color"
                                            aria-hidden="true">
                                            <span class="info-content">Abuse Alert</span></i>
                                    </li>
                                    <li class="nav-item">
                                        <i class="fas fa-exclamation fa-4x info-style security-color"
                                            aria-hidden="true">
                                            <span class="info-content">Scam Alert</span></i>
                                    </li>

                                    <li class="nav-item">
                                        <i class="fas fa-umbrella fa-4x info-style security-color" aria-hidden="true">
                                            <span class="info-content">My Insurance</span></i>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card pull-up" *ngIf="activeTab ==='My Legacy'">
            <div class="card-content collapse show">
                <div class="card-body">
                    <div class="card-text">
                        <p class="text-justify intro" style="color: black ;">

                            <b class="font-18">My Legacy:</b> Many seniors have thought about and made plans for their
                            eventual passing and their legacy. This is the place where you can tell your family how you
                            would like to be remembered:
                            <br />
                            <i class="fas fa-circle fa-1x memories-color inline-icon-style" aria-hidden="true"
                                style="    font-size: 10px;"></i>details of any funeral or burial arrangements you may
                            have made;
                            <br />
                            <i class="fas fa-circle fa-1x memories-color inline-icon-style" aria-hidden="true"
                                style="    font-size: 10px;"></i>any preferences for how you'd like them to celebrate
                            your life;
                            <br />
                            <i class="fas fa-circle fa-1x memories-color inline-icon-style" aria-hidden="true"
                                style="    font-size: 10px;"></i>save and share some of the joyous moments in your life;
                            <br />
                            <i class="fas fa-circle fa-1x memories-color inline-icon-style" aria-hidden="true"
                                style="    font-size: 10px;"></i>special messages for your children and grandchildren;
                            and lots more!
                            <br /><br />
                            <i class="fas fa-praying-hands fa-2x memories-color inline-icon-style"
                                aria-hidden="true"></i>
                            <b class="font-18">My Wishes:</b> A place to record – in print, spoken, or video - your
                            wishes for your family and friends after you depart.
                            Store here any arrangements you might want for your funeral or celebration of life. Specific
                            music? Hymns? Poems? Keep them safe and accessible here.
                            We’ve scoured the web to review and select the best.
                            The death of a loved one is a difficult time. One Government has created a single online
                            place to go for guidance on the essentials you need to know and do when a loved one dies.
                            A starting place will be the essentials, sponsored by Service NSW: <a
                                href="https://www.lawaccess.nsw.gov.au/Pages/representing/after_someone_dies/after_someone_dies.aspx"
                                target="=" _blank>www.lawaccess.nsw.gov...er_someone_dies.aspx</a>
                            <br />
                            <br />
                            <i class="fas fa-camera fa-2x memories-color inline-icon-style" aria-hidden="true"></i>
                            <b class="font-18">My Memories:</b> An easy way to save and share photos, videos,
                            recordings. We provide guidance to a sample of websites and apps that make recording and
                            storing your memories easier. <b><em>COMING SOON!</em></b> Click here to load and save
                            something in your Personal Page. Absolutely free for 3 months when you are one of the first
                            250 to sign up.
                            <br />
                            <br />
                            <i class="fas fa-tree fa-2x memories-color inline-icon-style" aria-hidden="true"></i>
                            <b class="font-18">My Life:</b> Family history, meaningful milestones, recipes passed down
                            from generation to generation, maybe your story told in your own words - all belong in this
                            personal space. Don't let meaningful family stories pass with you. We help you find ways to
                            store and share them here, forever.
                            <br /><br />

                            <i class="fas fa-seedling fa-2x memories-color inline-icon-style" aria-hidden="true"></i>
                            <b class="font-18">My Afterlife:</b> What do you want your loved ones to know or do after
                            you're gone? Not everything that matters is in a will. Make it easy for family to alert
                            friends and colleagues; where are their contact details? One thing that is only recently a
                            worry is your 'digital life'.
                            It starts with access to your digital files and other spaces. No password, no access.
                            Will you leave your passwords? What about any direct debits? Subscriptions? Ongoing
                            donations? Again, make it easy by compiling and storing a list and instructions so loved
                            ones know what you want.
                            <br /><br />
                            How do you want to be memorialised?

                            <b><em>COMING SOON!</em></b> A checklist for those who want family to know how to celebrate
                            their life
                        </p>
                        <div class="row">
                            <div class="col-md-12 pl-0 pr-0">
                                <ul class="sub-nav nav nav-justified bg-white">
                                    <li class="nav-item">
                                        <i class="fas fa-praying-hands fa-4x info-style memories-color"
                                            aria-hidden="true">
                                            <span class="info-content">My Wishes</span></i>
                                    </li>
                                    <li class="nav-item">
                                        <i class="fas fa-camera fa-4x info-style memories-color" aria-hidden="true">
                                            <span class="info-content">My Memories</span></i>
                                    </li>
                                    <li class="nav-item">
                                        <i class="fas fa-tree fa-4x info-style memories-color" aria-hidden="true">
                                            <span class="info-content">My Life</span></i>
                                    </li>
                                    <li class="nav-item">
                                        <i class="fas fa-seedling fa-4x info-style memories-color" aria-hidden="true">
                                            <span class="info-content">My Afterlife</span></i>
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- BEGIN: Customizer-->
<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-xl-block" [ngClass]="
    (showUpdates)?'open':''">
    <a class="customizer-close" href="#"><i class="ft-x font-medium-3"></i></a>
    <a class="customizer-toggle primary-yhyl-bg box-shadow-3 btn-hover" (click)=showUpdateToggle()>
        <i class="fa fa-bell h4" theme="light" tooltip="Click to see Updates" placement="left"></i><span
            class="indicator"></span>
    </a>
    <div class="customizer-content p-2">
        <h4 class="border-bottom pb-3 content-header-title font-22 bold-font">Hey Test..</h4>
        <p class="text-animation">There are 5 new updates for you!</p>
        <p>Last 3 days to renew your service for Travel</p>
        <p>2 new offers on Health Service</p>
    </div>
</div>

<div class="sidenav-overlay"></div>
<div class="drag-target"></div>