<!-- ======= about Section ======= -->
<section id="about" class="about">
  <div class="container" data-aos="fade-up">
    <div class="content">
      <h3 class="primary-font primary-yhyl-bg p-5 text-center"
        style="border-radius: 15px 15px 0 0;margin-bottom: auto;">
        About Us
      </h3>
    </div>
    <div class="about-bg row content p-5 card">
      <div class="section-title">
        <h2 class="primary-font text-left">Our Mission</h2>
        <p class=" border-bottom font-16 bold-font" style="text-align: justify;">
          Our sole mission at Your 100 Year Life is to simplify and ease the
          journey of ageing for seniors and their families.
        </p>
        <h2 class="primary-font text-left pt-5">Our Purpose</h2>
        <p class=" font-16" style="text-align: justify;">
          We are a purpose-driven company. Our sole purpose is to improve the
          lives of seniors and their loved ones and supporters by harnessing the
          best of the digital world, and personalising it for each senior.
          Because ageing is a marathon not a sprint.
          <br />
          <br />
          First we bring you a wealth of really useful, practical, carefully
          selected, mostly free information and guidance about life's
          challenges, focussing on ageing in your own home. Next, we guide you
          through tough decisions using simple guidelines, questionnaires and
          quizzes. So what you get is specific to you:
          <i class="bold-font">Ageing - Personalised &trade;</i>. We then help you to create your
          personalised and secure toolkit to manage day-to-day. We cover all the
          really important topics our customers need: reliable and practical
          information and toolkits in health and wellbeing, how to find paid and
          volunteer work, physical and digital safety, social connections, all
          the rebates that you are entitled to and things that make day-to-day
          life easier and more convenient. So the time you spend with your loved
          ones is spent on the most meaningful things.
        </p>
      </div>
    </div>
    <div class="row content">
      <div data-aos="zoom-in" data-aos-delay="200" class="col-12 d-flex p-0 aos-init aos-animate">
        <div class="card p-0">
          <h3 class="primary-font primary-yhyl-bg p-5 text-center">
            What exactly is Your 100 Year Life?
          </h3>
          <div class="p-5">
            <p>
              Your 100 Year Life is a special service that combines in one place
              - a 'portal' - everything you need to know, plan and do to make
              sure you and your ageing loved ones stay safe and well through the
              ageing journey. We are the only comprehensive toolkit that seniors,
              their families and their supporters will ever need to manage the
              complex challenges of ageing. Our toolkit relieves the stress,
              frustration, anxiety, wasted time, and unnecessary cost associated
              with caring for ageing loved ones. Our toolkit is available to you
              and your loved ones any time, day or night, because we are on line
              and can be accessed on any device.
              <br />
              <br />
              We are a customer-driven company that addresses the silent crisis
              of ageing: the burden that family and friends bear over many years
              of caring through the ageing journey.
              <br />
              <br />
              We build on our deep expertise in ageing and harness the best of
              the digital world to meet your needs, bring it right to your
              fingertips, help you set up your own personalised control center,
              and then we safeguard it with military-grade security. We are more
              than a secure, sharing app: we are a personalised and secure place
              for you, your family, and your loved ones, so you can easily
              manage the complex ‘ecosystem’ of supporters who you and your
              loved ones depend on <i class="bold-font">Ageing - Personalised&trade;</i>.
            </p>
          </div>
        </div>
      </div>

      <div data-aos="zoom-in" data-aos-delay="200" class="col-12 d-flex p-0 aos-init aos-animate">
        <div class="card p-0">
          <h3 class="primary-font primary-yhyl-bg p-5 text-center">
            How can we help you? Why should you trust us?
          </h3>
          <div class="p-5">
            <p>
              We help by giving you the tools to make the ageing marathon
              simpler, less stressful and more caring for everyone involved.
              It's all here, and its yours to use and share safely. There are
              many ways of ‘sharing’ information, memories, photos, videos, and
              an increasing number of ‘apps’ for sharing. We combine in one
              secure place the guidance, information, coaching and other
              services currently scattered across dozens of apps, links and
              portals. We are a powerful one-stop shop for all things ageing.
              What's more, we enable you to securely share with your caring
              co-pilots the essential information that they need to support you,
              and help you make tough decisions. What makes us different is that
              we contain very rich, carefully vetted information, guidance,
              tools and products and services aimed at making your life easier
              when you are caring – or simply caring about – an ageing loved one.
              <br />
              <br />
              We've gone to great lengths to understand and meet the needs of
              seniors and their families.
              <br />
              <br />
              We do a thorough check to make sure our information is
              independent, based on the latest science and evidence, and has
              been screened by the best minds on the subject. Only then do we
              place it on our site. At your fingertips, with only 1 or 2 clicks,
              you will find lots of really useful information, tips, and
              guidance that equip you to solve your ageing dilemmas. Always free.
              <br />
              <br />

              First, we sift through tons of material, spread all over, and
              choose the most useful information, products and services that
              matche what our audience and customers need and want.
              <br />
              <br />
              Next, we guide you through some of the tough times, with easy to
              use guides and questionnaires.
              <br />
              <br />
              What's more, any time you disclose any personal information we
              make sure is secured with world-leading military-grade data
              protection through our partner: digi.me.
              <br />
              <br />
              Finally, we provide your secure, personal control centre, a
              toolkit, that you can use to manage the ageing journey - for
              yourself and your loved ones. Your personalised toolkit is dynamic
              and real-time so you and trusted family members or other
              'co-pilots' can share the support of a loved one. Share the
              knowledge, share their wishes, and share the physical and
              emotional work.
              <br />
              <br />
              <span class="bold-font">Why trust us?</span> It's simple:<br /><br />
            <ul>
              <li class="bold-font">
                <i class="bx bx-check-double"></i>We do not hold your personal information: it remains with you,
                wherever you store it.
              </li>
              <li class="bold-font">
                <i class="bx bx-check-double"></i>We do not sell or 'lend' your personal information to anyone
                for commercial purposes.
              </li>
              <li class="bold-font">
                <i class="bx bx-check-double"></i>The 100 Year Life team have deep knowledge and experience in
                ageing and health, and the credentials to prove it.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div data-aos="zoom-in" data-aos-delay="200" class="col-12 d-flex p-0 aos-init aos-animate">
        <div class="card p-0">
          <h3 class="primary-font primary-yhyl-bg p-5 text-center">
            What’s a ‘social enterprise’?
          </h3>
          <div class="p-5">
            <p>
              We are a social enterprise, which means we are dedicated to making
              a beneficial social impact on seniors, their families and
              supporters, and the community at large. Our sole purpose is to
              have a beneficial social impact: to make the ageing journey easier,
              simpler and less stressful for individuals and families as you
              support ageing parents, relatives and friends. And to help you make
              time for self-care, in anticipation of more stressful moments.
              When we make a profit, we reinvest most of it into doing better so
              we have a greater positive impact. That's why social enterprises
              are often called <span class="bold-font">"profit for purpose".</span>
              <br />
              <br />
              We are not a charity, we are not affiliated with any religious
              organisation, we are not associated with any political or other
              special interest group, and we are not part of any other company
              or organisation. We are an independent social enterprise aiming to
              be financially sustainable, so we can re-invest our profits in the
              services you need. And that’s important: it makes our advice and
              recommendations trustworthy and reliable.
            </p>
          </div>
        </div>
      </div>

      <div data-aos="zoom-in" data-aos-delay="200" class="col-12 d-flex p-0 aos-init aos-animate">
        <div class="card p-0">
          <h3 class="primary-font primary-yhyl-bg p-5 text-center">
            Ageing is a Marathon, not a Sprint
          </h3>
          <div class="p-5">
            <ul>
              <li>
                <i class="bx bx-check-double"></i>Longevity is a blessing. As we age our longevity means we need
                increasing support of many kinds and it can get very complicated.
              </li>
              <li>
                <i class="bx bx-check-double"></i>Longevity means that on average people live longer, ideally in
                good health and independently. An Australian woman who is 65 years
                old today may, on average, live to nearly 100. A man aged 65 will
                on average live a few years less.
              </li>
              <li>
                <i class="bx bx-check-double"></i>It’s a blessing in disguise. And as we age we begin to need help
                to do more and more things. Contrary to stereoypes, relatively few
                people end up in institutional settings: most will travel along
                their ageing journey in their own homes, either entirely or with
                only a brief stay in residential aged care.
              </li>
              <li>
                <i class="bx bx-check-double"></i>What that means is that family and friends start to step in with
                little things that help the ageing loved one remain in their homes
                safely and securely.
              </li>
              <li>
                <i class="bx bx-check-double"></i>As loved ones’ support needs grow and change with passing time,
                keeping them safe and well at home becomes more complicated and
                demanding for all involved.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div data-aos="zoom-in" data-aos-delay="200" class="col-12 d-flex p-0 aos-init aos-animate">
        <div class="card p-0">
          <h3 class="primary-font primary-yhyl-bg p-5 text-center">
            Reliable, trustworthy, accurate information and guidance – When you
            need it – At your fingertips
          </h3>
          <div class="p-5">
            <p>
              Our company was founded and is led by experts with deep
              understanding of health and ageing. We’ve spent years listening to
              what matters to you, our community, and learning from your
              experiences.
              <br />
              <br />
              We heard from you the challenges you face with ageing – whether
              your own journey or supporting relatives and friends. And we’ve
              used your experience, wisdom and advice to produce a one-stop,
              powerful and empowering place, just for you!
              <br />
              <br />
              Your 100-Year Life is designed as a single, reliable place where
              you can find all the essentials about supporting an ageing loved
              one. We designed it more than just a basic “app”. It’s a rich,
              multi-layered “portal” giving you access to dozens of sources of
              information and guidance. Your personal toolkit and control centre
              combine all of the tools that help you organise: and a lot more
              than a storage space and shared diary. You're in control: you can
              share what you authorise to be shared, so that the pressure and
              demands of the ageing journey is shared with other trusted family
              members and loved ones. You are not alone, and you are in control.
              It has been carefully designed to be your one-stop shop to
              simplify the journey of ageing.
            </p>
          </div>
        </div>
      </div>

      <div data-aos="zoom-in" data-aos-delay="200" class="col-12 d-flex p-0 aos-init aos-animate">
        <div class="card p-0">
          <h3 class="primary-font primary-yhyl-bg p-5 text-center">
            How much is free? And what do I have the option of paying for?
          </h3>
          <div class="p-5">
            <p>
              Our pledge is to give everyone free access, at no cost whatsoever,
              to the carefully collected, curated materials that will give you
              and your family peace of mind about supporting your ageing loved
              ones. We also provide at no cost many useful checklists and
              questionnaires to help your family make hard decisions.
              <br /><br />
              Then for a very modest price – less than you are already paying
              for other non-essential apps and subscriptions - Your 100 Year
              Life helps you create your own private/family space, accessible to
              you 24/7, and only to others you authorise. It's yours, it's
              private and it's highly secured. Here you can store and easily
              access, and share, really useful information. You might want to
              have, at your fingertips, some or all of the following: notes on
              specific illnesses and medications of your ageing parents;
              documents (like an Advance Directive or Enduring Power of
              Attorney, in case of emergency); contacts for regular or emergency
              carers, gardeners, prepared meals delivery); and a diary
              containing future medical appointments, doctors’ contact details,
              scheduled home nursing visits). Whatever is essential to you, your
              loved ones, and others involved in the ageing marathon.
              <br />
              <br />
              Our dynamic real-time toolkit then helps you manage all the
              services, communication, planning, sourcing new and better
              ageing-related products, and more. It’s the only go-to support you
              need so you can support others in your caring life.
              <br />
              <br />
              <span class="bold-font">What does that mean for you?</span> It means that you can save time and
              worry – and coordinate efficiently – to keep your loved ones safe,
              secure and provided with all the help and social contact they need
              and deserve even at a distance. One place does all: Find and
              Simplify, Store and Share, Manage and Care.
            </p>
          </div>
        </div>
      </div>
      <!---
            <div data-aos="zoom-in" data-aos-delay="200" class="col-12 d-flex p-0 aos-init aos-animate">
                <div class="card p-0">
                    <h3 class="primary-font primary-yhyl-bg p-5 text-center">How do we deliver what you care about, need and want?</h3>
                    <div class="p-5">
                        <p>We've gone to great lengths to understand and meet the needs of seniors and their families. <br/>

                            First, we sift through tons of material, spread all over, and choose the most useful
                            information, products and services that matches what our audience and customers need and
                            want.
                            <br />
                            Next, we do a thorough check to make sure that it is independent, based on the latest
                            science and evidence, and has been screened by the best minds on the subject. Only then do
                            we place it on our site for your convenience. At your fingertips with only 1 or 2 clicks you
                            will find at no cost lots of really useful information, tips, and guidance that equips you
                            to solve your ageing dilemmas.
                        </p>
                    </div>
                </div>
            </div>
            -->

      <!--
            <div data-aos="zoom-in" data-aos-delay="200" class="col-12 d-flex p-0 aos-init aos-animate">
                <div class="card p-0">
                    <h3 class="primary-font primary-yhyl-bg p-5 text-center"> Why sign up? Because Ageing is a Marathon but it doesn't have to be so hard.</h3>
                    <div class="p-5">
                        <p>
                            Longevity means challenges: health, wellbeing, financial, loneliness, increasing
                            dependency and more, over many years. There are lots of new things to know and use. 
                             And what works for one or some won’t work for all.
                            <br />
                            That’s why we’re here for the long term: we are here to support you and your family across your
                            ageing journey.
                    
                            We not only assemble the essential information you need. We help you through the
                            process with unique and simple-to-use toolkits. Making those really tough decisions like:
                            <br/>
                            - Whether to ‘downsize’ or not, and if so when, where and how.
                            <br/>
                            - Deciding what
                            tech could make your life easier and keep your loved ones safe.
                        
                            <br/>
                            - How to have tough conversation like: when it's time to stop driving your own car, for your safety and the safety of others.

                            <br/>
                            
                            <br/>

                            Some people call us their "one-stop shop". Because not only do we inform and guide you,
                             we are also a highly secured place to keep your vital documents and
                            information. You decide and you control whether to share some or all with your family and others you rely on.
                            <br/>
                            <br/>

                            Sadly we can’t always be there for our ageing loved ones, even when we desperately want to be.
                             Modern life means that families are often scattered over large
                            distances.
                            But we give you valuable tools to make sure that you can
                            organise and coordinate the full range of support your loved ones need- wherever you are, 24/7.
                            <br/>
                            SIMPLIFY, MANAGE, PROTECT AND LOVE with YOUR 100 YEAR LIFE:
                            Your must-have tool for your family’s ageing marathon.
                        </p>
                    </div>
                </div>
            </div>
            -->
    </div>
  </div>
</section>
<!-- End about Section -->

<app-landing-footer></app-landing-footer>