import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.css','../information2.component.css']
})
export class HealthComponent implements OnInit {
  isShowing = true;
  showUpdates = false;
  showContent = true;
  isMainPage = true;
  activeHealthSubTab = '';
  activeTab = '';
  showUpdateToggle() {
    this.showUpdates = !this.showUpdates;
  }
  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  navigateMenu(tab) {
    if(tab === 'main-info') {
      this.router.navigate(['/information']);
    }
    if(tab === 'main-health') {
      this.activeHealthSubTab = '';
      this.isMainPage = true;
    }
  }
  infoHealthSubTab(subTab) {
    this.activeHealthSubTab = subTab;
    this.isMainPage=false;
    window.scrollTo(0, 0);
  }
  
}
