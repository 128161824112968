<div class="content-wrapper pd-10">
  <div class="content-header row border-bottom">
    <div class="content-header-left" col-md-12 col-12 mb-2 pl-0>
      <h3 class="content-header-title font-22 bold-font">
        <i class="icon-width fas fa-book text-center mr-3"></i>My Diary
      </h3>
      <p>
        <br />
        Click on a date, and follow the prompts to enter the details. Use the
        "Comments" space for reminders, like "Confirm which daughter is driving
        me" or "Bring questions for doctor" or "Do not buy Becky any more tutus
        for her birthday".
      </p>
    </div>
  </div>
  <div class="content-body">
    <div class="row text-center bg-white py-4 shadow-light mt-3">
      <div class="col-lg-3 col-md-12 py-3 pl-0" *ngIf="!isCopilot || editEvents || deleteEvents">
        <div class="btn btn-primary-yhyl font-20 shadow text-md-center" (click)="showAddPopup()">
          + Add New Event
        </div>
      </div>
      <div class="col-lg-3 col-md-12 py-3 pl-0 disabled"
        *ngIf="isCopilot && deleteEvents === false && editEvents === false">
        <div class="btn btn-primary-yhyl font-20 shadow text-md-center" (click)="showAccessMessage()">
          + Add New Event
        </div>
      </div>
      <div class="col-lg-3 col-md-12 py-3">
        <div class="btn-group">
          <div class="btn btn-yhyl font-20 yhyl-br2" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()" (click)="viewSelectedfn($event)" [class.active]="isPrevious">
            Previous
          </div>
          <div class="btn btn-yhyl font-20" mwlCalendarToday [(viewDate)]="viewDate" (click)="viewSelectedfn($event)"
            [class.active]="isToday">
            Today
          </div>
          <div class="btn btn-yhyl yhyl-border-left font-20" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()" (click)="viewSelectedfn($event)" [class.active]="isNext">
            Next
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-12 py-3 mt-2 font-20 bold-font">
        <h3>{{ viewDate | calendarDate: view + "ViewTitle":"en" }}</h3>
      </div>
      <div class="col-lg-3 col-md-12 py-3">
        <div class="btn-group">
          <div class="btn btn-yhyl yhyl-border-right font-20" (click)="setView(CalendarView.Month)"
            [class.active]="view === CalendarView.Month">
            Month
          </div>
          <div class="btn btn-yhyl font-20" (click)="setView(CalendarView.Week)"
            [class.active]="view === CalendarView.Week">
            Week
          </div>
          <div class="btn btn-yhyl yhyl-border-left font-20" (click)="setView(CalendarView.Day)"
            [class.active]="view === CalendarView.Day">
            Day
          </div>
        </div>
      </div>
    </div>
    <br />
    <div [ngSwitch]="view" class="shadow-light">
      <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
        [refresh]="refresh" [@.disabled]="true" [activeDayIsOpen]="activeDayIsOpen"
        (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)">
      </mwl-calendar-month-view>
      <!-- <ng-template #customCellTemplate let-day="day" let-locale="locale">
        <div class="cal-cell-top">
          <span class="cal-day-badge" *ngIf="day.badgeTotal > 0"
            >{{ day.badgeTotal }}</span
          >
          <span class="cal-day-number"
            >{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span
          >
        </div>
        <small *ngIf="daySelected" (click)="editDay($event)" style="margin: 5px;"
          ><i class="fa fa-edit"></i></small
        >
      </ng-template> -->
      <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
        [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)">
      </mwl-calendar-week-view>

      <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
        [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)">
      </mwl-calendar-day-view>
    </div>

    <!--add popup -->
    <div class="overlay" style="z-index: 2;" *ngIf="daySelected">
      <div class="popup col-lg-5 col-md-6 col-sm-6">
        <h3>
          <a class="close" (click)="daySelected = false">&times;</a>
          <div class="clearfix"></div>
        </h3>
        <br />
        <div class="content">
          <div *ngIf="!isSuccess && !isError">
            <div class="row">
              <div class="col-md-12">
                <h4 class="yhyl-text">Event Title*</h4>
                <input [(ngModel)]="eventTitle" class="form-control" name="title" placeholder="Event Title"
                  required="true" type="text" />
                <div *ngIf="
                    titleError &&
                    (eventTitle === '' ||
                      eventTitle === null ||
                      eventTitle === undefined)
                  ">
                  <div class="mb-5 mt-3 text-center" class="invalid-feedback block">
                    This field should not be blank.
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-12">
                <h4 class="yhyl-text">Event Description*</h4>
                <input [(ngModel)]="eventDescription" class="form-control" name="description"
                  placeholder="Event Description" required="true" type="text" />
                <div *ngIf="descError">
                  <div class="mb-5 mt-3 text-center" class="invalid-feedback block">
                    This field should not be blank.
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6">
                <h4 class="yhyl-text">Start Date & Time</h4>
                <input [(ngModel)]="eventStartDate" class="form-control" type="text" mwlFlatpickr [altInput]="true"
                  [convertModelValue]="true" [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i"
                  placeholder="Not set" />
              </div>
              <br />
              <div class="col-md-6">
                <h4 class="yhyl-text">End Date & Time</h4>
                <input [(ngModel)]="eventEndDate" class="form-control" type="text" mwlFlatpickr [altInput]="true"
                  [convertModelValue]="true" [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i"
                  placeholder="Not set" />
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-12">
                <h4 class="yhyl-text">Email-Address</h4>
                <p>
                  A notice email will be sent to your registered email. Please
                  add other email addressed you want to send notice and split by
                  ",". E.g. 123@123.com,456@456.com
                </p>
                <input [(ngModel)]="eventEmail" class="form-control" name="email" placeholder="Email Address"
                  required="true" type="text" />
                <!-- <div *ngIf="mailError && (eventEmail === '' || eventEmail === null || eventEmail===undefined)">
                  <div class="mb-5 mt-3 text-center" class="invalid-feedback block">
                    This field should not be blank.
                  </div>
                </div> -->
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-12 d-flex flex-column justify-content-end mt-1">
                <button *ngIf="eventAction != 'Edited'"
                  class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 active" (click)="addEvent()">
                  Add Event
                </button>
                <!-- <button *ngIf="eventAction === 'Edited'"
                  class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 active" (click)="editEvent()">
                  Edit Event
                </button> -->
              </div>
            </div>
          </div>
          <div class="mb-5 mt-3 text-center alert alert-success" *ngIf="isSuccess">
            {{ message }}
          </div>
          <div class="mb-5 mt-3 text-center alert alert-danger" *ngIf="isError">
            {{ message }}
          </div>
        </div>
      </div>
    </div>
    <!-- popup for edit -->
    <div class="overlay" style="z-index: 2;" *ngIf="isEdit">
      <div class="popup edit-diary-popup col-lg-5 col-md-6 col-sm-6">
        <h3>
          <a class="close" (click)="isEdit = false">&times;</a>
          <div class="clearfix"></div>
        </h3>
        <br />
        <div class="content">
          <div *ngIf="!isSuccess && !isError">
            <div class="row">
              <div class="col-md-12">
                <h4 class="yhyl-text">Event Title*</h4>
                <input [(ngModel)]="eventTitle" class="form-control" name="title" placeholder="Event Title"
                  required="true" type="text" />
                <div *ngIf="
                    titleError && (eventTitle === '' || eventTitle === null)
                  ">
                  <div class="mb-5 mt-3 text-center" class="invalid-feedback block">
                    This field should not be blank.
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-12">
                <h4 class="yhyl-text">Event Description*</h4>
                <input [(ngModel)]="eventDescription" class="form-control" name="description"
                  placeholder="Event Description" required="true" type="text" />
                <div *ngIf="
                    descError &&
                    (eventDescription === '' || eventDescription === null)
                  ">
                  <div class="mb-5 mt-3 text-center" class="invalid-feedback block">
                    This field should not be blank.
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6">
                <h4 class="yhyl-text">Start Date & Time</h4>
                <input [(ngModel)]="eventStartDate" class="form-control" type="text" mwlFlatpickr [altInput]="true"
                  [convertModelValue]="true" [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i"
                  placeholder="Not set" />
              </div>
              <br />
              <div class="col-md-6">
                <h4 class="yhyl-text">End Date & Time</h4>
                <input [(ngModel)]="eventEndDate" class="form-control" type="text" mwlFlatpickr [altInput]="true"
                  [convertModelValue]="true" [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i"
                  placeholder="Not set" />
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-12">
                <h4 class="yhyl-text">Email-Address</h4>
                <p>
                  A notice email will be sent to your registered email. Please
                  add other email addressed you want to send notice and split by
                  ",". E.g. 123@123.com,456@456.com
                </p>
                <input [(ngModel)]="eventEmail" class="form-control" name="email" placeholder="Email Address"
                  required="true" type="text" />
                <!-- <div *ngIf="mailError && (eventEmail === '' || eventEmail === null)">
                  <div class="mb-5 mt-3 text-center" class="invalid-feedback block">
                    This field should not be blank.
                  </div>
                </div> -->
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-12 d-flex flex-column justify-content-end mt-1">
                <button class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 active" (click)="editEvent()">
                  Edit Event
                </button>
              </div>
            </div>
          </div>
          <div class="mb-5 mt-3 text-center alert alert-success" *ngIf="isSuccess">
            {{ message }}
          </div>
          <div class="mb-5 mt-3 text-center alert alert-danger" *ngIf="isError">
            {{ message }}
          </div>
        </div>
      </div>
    </div>
    <!-- delete popup -->
    <div class="overlay" style="z-index: 2;" *ngIf="isDelete">
      <div class="popup edit-diary-popup col-lg-5 col-md-6 col-sm-6">
        <h3>
          <a class="close" (click)="isDelete = false">&times;</a>
          <div class="clearfix"></div>
        </h3>
        <br />
        <div class="content">
          <div *ngIf="!isSuccess && !isError">
            <div class="row text-center m-4 border-bottom">
              <div class="col-md-12">
                <h4 class="yhyl-text">Do you want to delete this event?</h4>
              </div>
            </div>
            <div class="row mb-5 text-center">
              <div class="col-6">
                <button style="width:100%" class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 active"
                  (click)="deleteEvent()">
                  Yes
                </button>
              </div>
              <div class="col-6">
                <button style="width:100%" class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 active"
                  (click)="isDelete = false">
                  No
                </button>
              </div>
            </div>
          </div>
          <div class="mb-5 mt-3 text-center alert alert-success" *ngIf="isSuccess">
            {{ message }}
          </div>
          <div class="mb-5 mt-3 text-center alert alert-danger" *ngIf="isError">
            {{ message }}
          </div>
        </div>
      </div>
    </div>

    <!-- popup for access message -->
    <div class="overlay" style="z-index: 2;" *ngIf="isAction">
      <div class="popup col-lg-5 col-md-6 col-sm-6">
        <h3>
          <a class="close" (click)="isAction = false">&times;</a>
          <div class="clearfix"></div>
        </h3>
        <br />
        <div class="content">
          <div class="mb-5 mt-3 text-center alert alert-danger">
            {{ actionMessage }}
          </div>
        </div>
      </div>
    </div>

    <!-- <ng-template #modalContent let-close="close">
      <div class="modal-header">
        <h4 class="modal-title">Event action occurred</h4>
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          Action:
          <pre>{{ modalData?.action }}</pre>
        </div>
        <div>
          Event:
          <pre>{{ modalData?.event | json }}</pre>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="close()">
          OK
        </button>
      </div>
    </ng-template> -->
  </div>
</div>
<div class="text-center" *ngIf="showLoader">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<!-- BEGIN: Customizer-->
<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-xl-block"
  [ngClass]="showUpdates ? 'open' : ''">
  <a class="customizer-close" href="#"><i class="ft-x font-medium-3"></i></a>
  <a class="customizer-toggle primary-yhyl-bg box-shadow-3 btn-hover" (click)="showUpdateToggle()">
    <i class="fa fa-bell h4" theme="light" tooltip="Click to see Updates" placement="left"></i><span
      class="indicator"></span>
  </a>
  <div class="customizer-content p-2">
    <h4 class="border-bottom pb-3 content-header-title font-22 bold-font">
      Hey Test..
    </h4>
    <p class="text-animation">There are 5 new updates for you!</p>
    <p>Last 3 days to renew your service for Travel</p>
    <p>2 new offers on Health Service</p>
  </div>
</div>
<div class="sidenav-overlay"></div>
<div class="drag-target"></div>