import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  isShowing = true;
  showUpdate = false;
  constructor() { }

  ngOnInit(): void {
  }
  toggle(e){
    this.showUpdate = !this.showUpdate;
/* e.classList.toggle("show");
var element = document.getElementById("navID");
Style */
  }
}
