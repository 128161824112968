<div class="content-overlay">

</div>
<div class="content-wrapper">
    <div class="content-header row">
        <div class=content-header-left col-md-12 col-12 mb-2 pl-0 pr-0>
            <h3 class="content-header-title font-22 bold-font">Page Content</h3>

        </div>
        <div class="content-header-right col-md-6 col-12">
            <!-- <div class="btn-group float-md-right" role="group" aria-label="Button group with nested dropdown">
                <button class="btn btn-info round dropdown-toggle dropdown-menu-right box-shadow-2 px-2 mb-1"
                    id="btnGroupDrop1" type="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false"><i class="ft-settings icon-left"></i> Settings</button>
                <div class="dropdown-menu" aria-labelledby="btnGroupDrop1"><a class="dropdown-item"
                        href="card-bootstrap.html">Cards</a><a class="dropdown-item"
                        href="component-buttons-extended.html">Buttons</a></div>
            </div>  -->
        </div>
    </div>
    <div class="content-body">
        <!-- Description -->
        <section id="description" class="card">
            <div class="card-header">
                <h4 class="card-title">Description</h4>
            </div>
            <div class="card-content collapse show">
                <div class="card-body">
                    <div class="card-text">
                        <p>
                            description
                        </p>
                    </div>
                    <div class="alert alert-icon-left mb-2" role="alert">
                        <span class="alert-icon"><i class="la la-pencil-square"></i></span>

                    </div>
                </div>
            </div>
        </section>
        <!--/ Description -->
        <!-- CSS Classes -->
        <section id="css-classes" class="card">
            <div class="card-header">
                <h4 class="card-title">Another card</h4>
                <a class="heading-elements-toggle"><i class="la la-ellipsis-v font-medium-3"></i></a>
                <div class="heading-elements">
                    <ul class="list-inline mb-0">
                        <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                        <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                        <li><a data-action="close"><i class="ft-x"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="card-content collapse show">
                <!-- <div class="card-body">
                    <div class="card-text">
                        <p>This table contains all classes related to the vertical collapsible navigation option.
                        </p>
                        <p>All these options can be set via following classes:</p>
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Classes</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row"><code>.menu-collapsible</code></th>
                                        <td>To create vertical collapsible navigation, you need to add
                                            <code>.menu-collapsible</code>
                                            class in navigation <code>&lt;div&gt;</code> tag. Refer HTML markup line
                                            no 12.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> -->
            </div>
        </section>
        <!--/ CSS Classes -->
    </div>
</div>

<!-- BEGIN: Customizer-->
<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-xl-block" [ngClass]="
    (showUpdates)?'open':''">
    <a class="customizer-close" href="#"><i class="ft-x font-medium-3"></i></a>
    <a class="customizer-toggle primary-yhyl-bg box-shadow-3 btn-hover" (click)=showUpdateToggle()>
        <i class="fa fa-bell h4" theme="light" tooltip="Click to see Updates" placement="left"></i><span
            class="indicator"></span>
    </a>
    <div class="customizer-content p-2">
        hello this works !
    </div>
</div>

<div class="sidenav-overlay"></div>
<div class="drag-target"></div>