<div class="content-wrapper pd-10">
    <div class="content-header row border-bottom">
        <div class="content-header-left col-md-12 col-12 mb-2 pl-0 pr-0">
            <h3 class="content-header-title font-22 bold-font" style="text-align: justify;"><i
                    class="icon-width fas fa-user text-center mr-3"></i>My Account Settings, {{firstname}}</h3>
        </div>
    </div>
    <div class="content-body mt-3">
        <section class="users-edit">
            <div class="card">
                <div class="card-content">
                    <div class="card-body">
                        <ul class="nav nav-tabs mb-4 row" role="tablist">
                            <li class="nav-item col-lg-3 col-md-12 col-sm-12 px-0">
                                <a class="nav-link sidebar active" id="general-tab" data-toggle="tab" href="#general"
                                    (click)="navigate()" aria-controls="general" role="tab" aria-selected="false">
                                    <i class="fa fa-info-circle mr-2"></i><span class="d-sm-block">General</span>
                                </a>
                            </li>
                            <li class="nav-item col-lg-3 col-md-12 col-sm-12 px-0">
                                <a class="nav-link sidebar" id="account-tab" data-toggle="tab" href="#account"
                                    (click)="navigate()" aria-controls="account" role="tab" aria-selected="true">
                                    <i class="fa fa-user mr-2"></i><span class="d-sm-block">Account</span>
                                </a>
                            </li>
                            <li class="nav-item col-lg-3 col-md-12 col-sm-12 px-0">
                                <a class="nav-link sidebar" id="delete-tab" data-toggle="tab" href="#delete"
                                    (click)="navigate()" aria-controls="delete" role="tab" aria-selected="false">
                                    <i class="fas fa-trash-alt mr-2"></i>
                                    <span class="d-sm-block">Delete Account</span>
                                </a>
                            </li>
                            <li class="nav-item col-lg-3 col-md-12 col-sm-12 px-0">
                                <a class="nav-link sidebar" id="plans-tab" data-toggle="tab" href="#plans"
                                    (click)="navigate()" aria-controls="plans" role="tab" aria-selected="false">
                                    <i class="fa fa-id-card mr-2"></i>
                                    <span class="d-sm-block">Free or Premium</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <!-- general tab -->
                            <div class="tab-pane active" id="general" aria-labeledby="general-tab" role="tabpanel">
                                <div class="row">
                                    <div class="col-lg-4 col-sm-12 mt-3">

                                        <!--  src="{{profilePic}}" -->
                                        <div class="text-center">
                                            <img [src]="profilePic" class="users-avatar-shadow rounded-circle"
                                                height="235" width="235">
                                        </div>
                                        <div class="mb-5 mt-3 text-center" class="alert alert-success"
                                            *ngIf="isGenSuccess">
                                            {{message}}
                                        </div>
                                        <div class="mb-5 mt-3 text-center alert alert-danger" *ngIf="isGenError">
                                            {{message}}
                                        </div>
                                        <div class="col-12">
                                            <!-- <h3 class="text-center mt-4">Admin</h3> -->
                                            <div class="col-12 text-center mt-4">
                                                <a (click)="changepicture()"
                                                    class="btn btn-primary-yhyl mb-1 mb-sm-0 mr-0 mr-sm-1">Edit</a>
                                                <a class="btn btn-secondary mb-1 mb-sm-0 mr-0 mr-sm-1">Delete</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-8 col-sm-12 media mt-3">
                                        <div style="width:100%">
                                            <div class="form-group">
                                                <div class="controls">
                                                    <label>First Name</label>
                                                    <input type="text" [(ngModel)]="firstname" class="form-control"
                                                        name="firstname" placeholder="First Name">
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="controls">
                                                    <label>Last Name</label>
                                                    <input type="text" [(ngModel)]="lastname" name="lastname"
                                                        placeholder="Last Name" class="form-control">
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="controls">
                                                    <label>Preferred Name</label>
                                                    <input type="text" [(ngModel)]="preferredName" name="preferredName"
                                                        placeholder="User Name" class="form-control">
                                                </div>
                                            </div>
                                            <div
                                                class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1 px-0">
                                                <button type="submit"
                                                    class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                                    (click)="updateGeneralDetails()">Save Changes</button>
                                                <button type="reset" class="btn btn-secondary"
                                                    (click)="cancelChanges()">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- account tab -->
                            <div class="tab-pane" id="account" aria-labelledby="account-tab" role="tabpanel">
                                <div class="row">
                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                        <div class="mb-5 mt-3 text-center" class="alert alert-success"
                                            *ngIf="isAccntSuccess">
                                            {{message}}
                                        </div>
                                        <div class="mb-5 mt-3 text-center alert alert-danger" *ngIf="isAccntError">
                                            {{message}}
                                        </div>
                                        <!-- date of birth -->
                                        <!-- <div class="form-group">
                                            <div class="controls">
                                                <label>Date of Birth</label>
                                                <input [(ngModel)]="dob" name="date" class="form-control" placeholder=""
                                                    type="date">
                                            </div>
                                        </div> -->
                                        <div class="form-group">
                                            <label>Email Address</label>
                                            <input type="text" [(ngModel)]="email" name="email" placeholder="email"
                                                class="form-control">
                                        </div>
                                        <!-- <div class="form-group">
                                            <label>Phone Number</label>
                                            <input type="text" [(ngModel)]="phone" name="phone" placeholder="phone"
                                                class="form-control">
                                        </div> -->
                                        <div class="form-group">
                                            <label>Postcode</label>
                                            <input type="text" [(ngModel)]="postcode" name="postcode"
                                                placeholder="postcode" class="form-control">
                                        </div>

                                        <div class="form-group d-flex flex-column justify-content-end pl-0">
                                            <a class="yhyl-text" style="text-decoration: underline;"
                                                (click)="showPwdTemplate()">Change Password</a>
                                        </div>
                                        <div *ngIf="isPwdChange">
                                            <div class="form-group">
                                                <label>Old Password</label>
                                                <input type="text" [(ngModel)]="oldPwd" name="oldPwd"
                                                    placeholder="Old Password" class="form-control">
                                                <label *ngIf="isPwdError"
                                                    style="color:red">{{passwordError.old_password}}</label>
                                            </div>
                                            <div class="form-group">
                                                <label>New Password</label>
                                                <input type="text" [(ngModel)]="newPwd" name="newPwd"
                                                    placeholder="New Password" class="form-control">
                                            </div>
                                            <div class="form-group">
                                                <label>Confirm Password</label>
                                                <input type="password" [(ngModel)]="confirmPwd" name="confirmPwd"
                                                    placeholder="Confirm Password" class="form-control">
                                                <label *ngIf="isPwdError"
                                                    style="color:red">{{passwordError.new_password2}}</label>
                                            </div>
                                        </div>
                                        <div
                                            class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1 px-0">
                                            <button type="submit"
                                                class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                                (click)="updateAccountDetails()">Save Changes</button>
                                            <button type="reset" class="btn btn-secondary">Cancel</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- delete tab -->
                            <div class="tab-pane" id="delete" aria-labelledby="delete-tab" role="tabpanel">

                                <div class="row">
                                    <div class="col-12 col-sm-12 mb-4">We respect your privacy. Your data is yours. We
                                        never store any data with us. If you wish to delete your account, you can delete
                                        it here.</div>
                                    <div class="col-12 col-sm-12">
                                        <div class="form-group">
                                            <label>Account Info :</label>
                                        </div>
                                        <div class="form-group">
                                            <label>Name : {{firstname}}&nbsp;{{lastname}}</label>
                                        </div>
                                        <div class="form-group">
                                            <label>Email ID : {{email}}</label>
                                        </div>
                                        <!-- <div
                                            class="form-group col-lg-2 col-md-6 col-sm-6 d-flex flex-column justify-content-end pl-0">
                                            <button type="submit"
                                                class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                                (click)="showDisablePopup()">Disable
                                                Account
                                            </button>
                                        </div> -->
                                        <div
                                            class="form-group col-lg-2 col-sm-6 col-md-6 pl-0  d-flex flex-column justify-content-end">
                                            <button type="submit"
                                                class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                                (click)="showDeletePopup()">Delete
                                                Account
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- subscriptions tab -->
                            <div class="tab-pane" id="plans" aria-labelledby="plan-tab" role="tabpanel">
                                <div class="row">
                                    <div class="col-12 col-sm-12">
                                        <div class="form-group">
                                            <label>Your current Plan : {{subscription}}</label>
                                            <!-- <input class="form-control" type="text" value=""> -->
                                        </div>
                                        <div class="form-group">
                                            <label>Current Plan Details : {{subscription}}</label>
                                            <!-- <input class="form-control" type="text" value=""> -->
                                        </div>
                                        <div class="form-group">
                                            <label>Upgrade Plan : We are currently in beta
                                                testing mode and we are offering the first 250 customers access to
                                                Premium features.</label>
                                            <div class="mt-2">
                                                <button type="submit" *ngIf="subscription === 'Premium'"
                                                    class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                                    (click)="changeSubscription()">Click here to get a free trial for 3
                                                    months!</button>
                                                <button type="submit" *ngIf="subscription === 'Free'"
                                                    class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                                    (click)="changeSubscription()">Click here to get Premium
                                                    Plan</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- popup for changing profile pic -->
                    <div class="overlay" style="z-index: 2;" *ngIf="picturePopUp">
                        <div class="popup col-lg-5 col-md-6 col-sm-6">
                            <h3>
                                <a class="close" (click)="picturePopUp = false">&times;</a>
                                <div class="clearfix"></div>
                            </h3>
                            <br />
                            <div class="content">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h4 class="yhyl-text">Upload Your Picture</h4>
                                        <div class="mt-3 mb-3">
                                            <div [innerHtml]='msg' *ngIf="msg" style="color: red;"></div>
                                            <p>
                                                <label class="file-input">Choose File
                                                    <input #file type="file" accept='image/*' class="hide-file"
                                                        type="file" (change)="selectFile($event)"></label>
                                                <label class="nav-item pt-1 pl-2"
                                                    *ngIf="isUploaded">{{fileToUpload.name}}</label>
                                                <label class="nav-item pt-1 pl-2" *ngIf="!isUploaded">No File
                                                    Chosen</label>
                                                <!-- <img [src]="profilePic" height="200" *ngIf="profilePic"> -->
                                            </p>
                                        </div>
                                        <div class="text-center" *ngIf="upload">
                                            <div class="spinner-border" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- popup for disable -->
                    <div class="overlay" style="z-index: 2;" *ngIf="isDisable">
                        <div class="popup col-lg-5 col-md-6 col-sm-6">
                            <h3>
                                <a class="close" (click)="isDisable = false">&times;</a>
                                <div class="clearfix"></div>
                            </h3><br />
                            <div class="content">
                                <div class="row text-center m-4 border-bottom">
                                    <div class="col-md-12">
                                        <h4 class="yhyl-text">This will temporarily disable your account</h4>
                                    </div>
                                </div>
                                <div class="row mb-5 text-center">
                                    <div class="col-6">
                                        <button style="width:100%"
                                            class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 active"
                                            (click)="disableProfile()">
                                            <span class="text-white">Yes</span>
                                        </button>
                                    </div>
                                    <div class="col-6">
                                        <button style="width:100%"
                                            class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 active"
                                            (click)="isDisable = false">
                                            <span class="text-white">No</span>
                                        </button>
                                    </div>
                                </div>
                                <!-- <div class="mb-5 mt-3 text-center" *ngIf="isDeleteSuccess">
                    {{message}}
                    </div> -->
                            </div>
                        </div>
                    </div>

                    <!-- popup for delete -->
                    <div class="overlay" style="z-index: 2;" *ngIf="isDelete">
                        <div class="popup col-lg-5 col-md-6 col-sm-6">
                            <h3>
                                <a class="close" (click)="isDelete = false">&times;</a>
                                <div class="clearfix"></div>
                            </h3><br />
                            <div class="content">
                                <div class="row text-center m-4 border-bottom">
                                    <div class="col-md-12">
                                        <h4 class="yhyl-text">Are you sure? Once deleted, this data cannot be retrieved.
                                        </h4>
                                    </div>
                                </div>
                                <div class="row mb-5 text-center">
                                    <div class="col-6">
                                        <button style="width:100%"
                                            class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 active"
                                            (click)="deleteProfile()">
                                            <span class="text-white">Yes</span>
                                        </button>
                                    </div>
                                    <div class="col-6">
                                        <button style="width:100%"
                                            class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 active"
                                            (click)="isDelete = false">
                                            <span class="text-white">No</span>
                                        </button>
                                    </div>
                                </div>
                                <!-- <div class="mb-5 mt-3 text-center" *ngIf="isDeleteSuccess">
              {{message}}
            </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<div class="text-center" *ngIf="showLoader">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>