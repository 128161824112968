<div class="content-wrapper pd-10 bg-white">
  <div class="content-header row border-bottom">
    <div class="content-header-left col-md-12 col-12 mb-2 pl-0 pr-0 pr-0">
      <h3 class="content-header-title font-22 bold-font">
        <i class="yhyl-text icon-width fas fa-info text-center mr-3"></i>
        <span class="yhyl-text pointer" (click)="navigateMenu('main-info')">MY INFORMATION&nbsp;&nbsp;</span>
        <i class="fa fa-angle-right grey-color" aria-hidden="true"></i>
        <i class="fas fa-1x title-icon-style fa-house-user d-blue-color"></i>
        <span class="d-blue-color pointer font-16" (click)="navigateMenu('main-technology')">MY
          TECHNOLOGY&nbsp;&nbsp;</span>
      </h3>
      <div class="temp" *ngIf="isMainPage">
        <!--Find accurate, reliable and practical information on the "need to know" issues in longevity and ageing. You told us what you need: we sourced it and put it all at your fingertips!-->
        <br />
        <div class="sub-content">
          <p class="d-blue-color font-20 bold-font">
            Evolve with Technology - Safely and Securely
          </p>
          <p class="grey-color">
            It’s a digital world and it’s not easy to keep up and stay safe.
            Here you can find the information and tools to keep up - and keep
            safe. Check here to keep up with emerging technology that can help
            you and your ageing loved ones.
          </p>
        </div>
      </div>
      <div class="row mt-5" *ngIf="isMainPage">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-health-resources.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">
                      ONLINE SECURITY AND SAFETY
                    </p>
                    <p>
                      We all know that being online comes with risks.
                      Unfortunately the scammers and criminals always seem to be
                      a step ahead. Some of the scams are shockingly clever and
                      anyone is easily duped. Fortunately, Australia now has an
                      official eSafety Commissioner. Here is a direct link to
                      their guidance for eSafety for Seniors:
                      <a href="https://beconnected.esafety.gov.au/bookings"
                        target="_blank">https://beconnected.esafety.gov.au/bookings</a>. Why not watch one of these
                      helpful Free Webinars with
                      your adult children or grandchildren? Caring for
                      grandchildren? Help keep them safe online:
                      <a href="https://www.esafety.gov.au/seniors/online-safety-grandparents-carers"
                        target="_blank">https://www.esafety.gov.au/seniors/online-safety-grandparents-carers</a>.
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-mental-health.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">
                      GET WITH THE PROGRAM AND KEEP UP!
                    </p>
                    <p>
                      There's so much technology available to help you learn
                      with the online resources made specially for seniors! We
                      love the simple and comprehensive learning modules in the
                      BeConnected program that we became a partner so we can get
                      out and help seniors do more online! Check out the many
                      topics available here:
                      <a href="https://beconnected.esafety.gov.au/topic-library "
                        target="_blank">beconnected.esafety.gov.au/topic-library</a>. Start here:
                      <a href="https://beconnected.esafety.gov.au" target="_blank">beconnected.esafety.gov.au.</a>

                      You'll be amazed at how simple it is to pick up skills
                      that make your life easier and better.
                      <br /><br />
                      Residents of New South Wales can learn the basics and keep
                      up easily and affordably, thanks to the partnership
                      between the NSW Government and Telstra, Tech Savvy
                      Seniors. Tech Savvy Seniors runs courses teaching you the
                      basics to survive in a digital world, at no cost in public
                      libraries, and at low cost in NSW community colleges. Find
                      the sessions nearest to you here:
                      <a href="https://www.telstra.com.au/tech-savvy-seniors"
                        target="_blank">www.telstra.com.au/tech-savvy-seniors</a>. Click here
                      <a href="https://www.facs.nsw.gov.au/inclusion/ageing/overview/chapters/tech-savvy"
                        target="_blank">(www.facs.nsw.gov.au/inclusion/ageing/overview/chapters/tech-savvy)</a>

                      to locate the course closest to you. Courses are fun and
                      easy; in numerous languages; at beginner, intermediate as
                      well as advanced level; and cover notebooks, iPads,
                      phones, the internet, social media and more. Auslan
                      courses are available for people with restricted hearing.
                      <br />
                      <br />

                      <b>And there’s more...</b>
                      <br />
                      Many local government-supported community centres conduct
                      training and help sessions specifically for seniors. So
                      check with your local Council. In other suburbs, like the
                      NSW Northern Beaches, a non-profit computer club for
                      seniors (supported by the Council) may be the way to go.
                      <a href="https://www.ascca.org.au/" target="_blank">
                        (Click here to visit www.ascca.org.au/)</a><br />
                      What happens to your “life online” after you die? Think
                      about all that depends on you having the right password.
                      We provide guidance on how to save a lot of trouble for
                      your survivors
                      <b><em>COMING SOON!</em></b> Everything you store using a
                      password
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-medication.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">
                      PASSWORDS AND LOGINS
                    </p>
                    <p>
                      Everyone should use a "Password Manager"; some are free,
                      most cost a few dollars a month. Read about why, how, and
                      the extra advantages here, from the trustworthy people at
                      <i>Choice</i>:
                      <a href="https://www.choice.com.au/ele…es/password-managers"
                        target="_blank">www.choice.com.au/ele…es/password-managers</a>
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-checklists.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">
                      PREFER TO HAVE LOW-COST ASSISTANCE AVAILABLE TO YOU AT
                      HOME?
                    </p>
                    <p>
                      What if you could dial up and get help with your online
                      and device problems when you need it? Would you be willing
                      to pay a very small amount for that help? We'll be asking
                      for your input soon.
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- BEGIN: Customizer-->
<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-xl-block"
  [ngClass]="showUpdates ? 'open' : ''">
  <a class="customizer-close" href="#"><i class="ft-x font-medium-3"></i></a>
  <a class="customizer-toggle primary-yhyl-bg box-shadow-3 btn-hover" (click)="showUpdateToggle()">
    <i class="fa fa-bell h4" theme="light" tooltip="Click to see Updates" placement="left"></i><span
      class="indicator"></span>
  </a>
  <div class="customizer-content p-2">
    <h4 class="border-bottom pb-3 content-header-title font-22 bold-font">
      Hey Test..
    </h4>
    <p class="text-animation">There are 5 new updates for you!</p>
    <p>Last 3 days to renew your service for Travel</p>
    <p>2 new offers on Health Service</p>
  </div>
</div>

<div class="sidenav-overlay"></div>
<div class="drag-target"></div>