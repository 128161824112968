import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css']
})
export class InformationComponent implements OnInit {
  isShowing = true;
  showUpdates = false;
  activeTab =
    'One stop to find all the essential, reliable information to manage your caring life';
  activeHomeSubTab = '';
  activeHealthSubTab = '';
  showContent = true;
  showUpdateToggle() {
    this.showUpdates = !this.showUpdates;
  }
  constructor() {}

  ngOnInit(): void {}
  infoTab(tab, subTab) {
    this.activeTab = tab;
    this.activeHomeSubTab = subTab;
    this.activeHealthSubTab = subTab;
    this.showContent = false;
    window.scrollTo(0, 0);
  }
  infoHomeSubTab(subTab) {
    this.activeHomeSubTab = subTab;
    this.onScrollToTop();
  }
  infoHealthSubTab(subTab) {
    this.activeHealthSubTab = subTab;
    this.onScrollToTop();
  }
  onScrollToTop() {
    var subpageReading = document.querySelector<HTMLElement>('#subpageReading');
    let bridge = subpageReading;
    let body = document.body;
    let height = 0;
    do {
      height += bridge.offsetTop;
      bridge = <HTMLElement>bridge.offsetParent;
    } while (bridge !== body);
    window.scrollTo({
      top: height - 160,
      behavior: 'smooth'
    });
  }
}
