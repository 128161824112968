import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css', '../shared/templates.css']
})
export class FaqsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
