import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { map, mergeMap, filter } from 'rxjs/operators';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  visibility: any;
  islogin = false;
  viewedPage;
  any;
  title = 'yhyl-ui';
  isHeader = true;
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}
  ngOnInit() {
    AOS.init();
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        if (event.header === false) {
          this.isHeader = false;
          this.islogin = true;
        } else if (event.navbar === false && event.sidebar === false) {
          this.islogin = true;
          this.isHeader = true;
        } else {
          this.islogin = false;
          this.isHeader = true;
        } // title of page
        this.showToolbar(event.navbar);
        this.showToolbar(event.sidebar); // show the toolbar?
      });
  }

  showToolbar(event) {
    if (event === false) {
      this.visibility = false;
    } else if (event === true) {
      this.visibility = true;
    } else {
      this.visibility = this.visibility;
    }
  }
  isShowing = true;

  toggle() {
    this.isShowing = !this.isShowing;
  }
}
