<!-- ======= about-story Section ======= -->
<section id="about" class="about">
    <div class="container" data-aos="fade-up">
        <h2 class="primary-font text-center yhyl-text bold-font">Your 100 Year Life - Privacy Policy
            <br />
        </h2>
        <div class="row content py-5">
            <div data-aos="zoom-in" data-aos-delay="200" class="col-12 d-flex p-0 aos-init aos-animate">
                <div class="card p-0">
                    <h3 class="primary-font primary-yhyl-bg p-5 text-center">Your 100 Year Life Privacy Policy
                    </h3>
                    <div class="p-5">
                        <div>
                            <p> Your 100 Year Life is firmly committed to the protection of its customers’ personal
                                information and privacy. We go way beyond basic compliance because helping
                                you
                                protect your personal information and privacy is baked into our DNA.
                                <br/><br />We honour our users’ growing concerns about data privacy and we have anticipated 
                                increasing protection of 
                                private data in the way we have designed our portal and services.
                            </p>
                            <p>
                                That is why…
                            </p>
                            <ul>
                                <li class="bold-font"><i class="bx bx-check-double"></i>We will ask you for your consent
                                    to
                                    collect information about you, such as your email address or other information </li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>We will never sell your
                                    information
                                </li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>We share with our trusted
                                    partners
                                    only what they absolutely need to enable you to conveniently and safely use our
                                    information and services
                                </li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>We scoured the world for a
                                    privacy
                                    protection partner who could supply us and you with military grade protection of
                                    personal information
                                </li>
                            </ul>
                            <p>
                                Your 100 Year Life operates only in Australia as it builds and
                                refines
                                its services and products and expands its customer base. It is fully compliant with the
                                Australian Privacy Act 1988 Cth, Australia’s privacy legislation.
                                <br /><br />
                                If you are accessing Your 100 Year Life from outside of Australia, you may want to know
                                that
                                Your 100 Year Life also complies with international privacy protection laws, including
                                the
                                <span class="font-italic bold-font">General Data Protection Legislation (GDPR)</span>
                                applicable to members nations of the EU and the
                                <span class="font-italic bold-font">California Consumer Privacy Act 2018.</span>
                            </p>
                        </div>
                        <div class="mt-5">
                            <h2 class="primary-font yhyl-text text-left">Your 100 Year Life Privacy – The Essential
                                Details
                            </h2>
                            <p>
                                This section details when and how Your 100 Year Life collects information from you and
                                how
                                we handle your information.
                            </p>
                        </div>

                        <div class="mt-5">
                            <h2 class="primary-font yhyl-text text-left">Our Commitment to You
                            </h2>
                            <p>
                                Your 100 Year Life is committed to the Australian Privacy Principles contained in the
                                Australian Privacy Act 1988 Cth. You can learn more about the Privacy Act and Principles
                                here:
                                <span class="yhyl-text"><a
                                        href="https://www.oaic.gov.au/privacy/the-privacy-act/">https://www.oaic.gov.au/privacy/the-privacy-act/</a></span>
                            </p>
                        </div>

                        <div class="mt-5">
                            <h2 class="primary-font yhyl-text text-left">We Collect Minimal Information
                            </h2>
                            <p>
                                Your 100 Year Life tries to minimise the collection of personally identifiable
                                information and its sharing. We may collect the following information:
                            </p>
                            <ul>
                                <li class="bold-font"><i class="bx bx-check-double"></i>Your name, email address,
                                    telephone number and other contact details</li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>Your digital signature; device
                                    ID and type; geolocation information; computer and connection information;
                                    statistical SEO (search engine optimisation) data on page views; traffic to and from
                                    our portal; advertising and sales data pertaining directly to our portal; IP
                                    address; and standard web statistics including log information.
                                </li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>Other information you provide
                                    through our portal, including information related to your use of our portal, or
                                    other websites accessed from our portal, and accounts from which you permit us to
                                    collect information.
                                </li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>Basic personal information to
                                    communicate with you and provide relevant services. With your consent, we may
                                    collect further information so you can create a personalised space that you control.
                                </li>
                            </ul>
                            <p>
                                We take reasonable steps to protect your personal information from misuse, interference
                                or loss, and prevent unauthorised access, modification or disclosure. To protect your
                                personal information we use physical, administrative and technical measures.
                                <br /><br />
                                While we cannot guarantee the security of your personal information, we have gone well
                                beyond most organisations to protect your information: In recognition of the sensitivity
                                of personal data, we have unique arrangements for most of your personal information to
                                be ‘exchanged’ solely for the purpose for which you use it at any point in time. We hold
                                a minimum set of personal data, and seamlessly facilitate the exchange of additional
                                personal information for your specific purpose. Rather than storing it, we have
                                partnered with digi.me, a globally recognised company specialising in ‘data exchange’ of
                                highly sensitive information (such as health and financial information). You can learn
                                more about how digi.me and Your 100 Year Life keep your personal information safe, with
                                military-level protection, by reading the rest of this Policy.
                                <br /><br />
                                We will continuously update our Privacy Policy to reflect changes in our operations and
                                laws and regulations and aim to alert you of any changes that may affect you.

                            </p>
                        </div>

                        <div class="mt-5">
                            <h2 class="primary-font yhyl-text text-left">How we collect your personal information
                            </h2>
                            <p>
                                We may collect personal information from you when you visit our portal,
                                <span class="yhyl-text"><a
                                        href="www.your100yearllife.com">www.your100yearllife.com</a></span> search the
                                really useful information and links on our portal;
                                participate in our polls and surveys; or shop the really practical products and services
                                offered on our portal.
                                <br /><br />
                                We may also collect some information about you when we interact with you in person or
                                via correspondence, email, or phone; through social media or other Your 100 Year Life
                                digital websites; or through interaction with any advertising that might be located on
                                or linked with our portal.

                            </p>
                        </div>

                        <div class="mt-5">
                            <h2 class="primary-font yhyl-text text-left">Why we collect, analyse and may have to disclose
                                personal information*
                            </h2>
                            <p>
                                We minimise the amount of personal information we collect, keep to a minimum the
                                personal information we store, and wherever appropriate we ‘depersonalise it’: remove
                                details that might link it back to you personally.
                                <br /><br />
                                Here are the main reasons why we collect personalised data:
                            </p>
                            <ul>
                                <li class="bold-font"><i class="bx bx-check-double"></i>To enable you to access and use
                                    our portal and websites operated by us</li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>To enable us to communicate with
                                    you and send updates; offer services; provide support, including reminders,
                                    important notices, and security alerts; and information requested by you or related
                                    you your account
                                </li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>To obtain your consent to invoke
                                    your Privacy Rights, in accordance with the Australian Privacy Act 1988 Cth, by
                                    acting on your behalf as your authorised delegate to provide permission or
                                    authorisation relating to access rights to use of data containing your personal data
                                </li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>To comply with our legal
                                    obligations, to enforce our agreements with third parties, and in relation to any
                                    disputes that may arise with other parties
                                </li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>To operate and continuously
                                    improve our portal and business by applying analytics and conducting research and
                                    for advertising and marketing programs</li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>To enable us to send you other
                                    information, including marketing and promotional information, that may be of
                                    interest and/or benefit to you
                                </li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>To offer you the option of
                                    participating in online surveys, polls, membership in loyalty programs, or other
                                    activities and events managed by us or our affiliates or partners
                                </li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>To work with other parties who
                                    we may authorise to assist us in the analysis and interpretation of our customers,
                                    in the interest of improving or products and services
                                </li>
                            </ul>
                        </div>

                        <div class="mt-5">
                            <h2 class="primary-font yhyl-text text-left">To whom we may disclose your personal
                                information
                            </h2>
                            <p>
                                *We may disclose some personal information to:
                            </p>
                            <ul>
                                <li class="bold-font"><i class="bx bx-check-double"></i>Our employees and contractors
                                </li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>Third party suppliers and
                                    service providers for the purpose of operating our business, portal and websites,
                                    and for the purpose of providing our products and services to you
                                </li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>Our professional advisers and
                                    agents
                                </li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>Our payment system providers,
                                    including merchants receiving credit and other card payments)
                                </li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>The sponsors or promoters of any
                                    competition, survey or poll we may conduct via our business, portal or websites
                                </li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>Specified third parties to whom
                                    you authorise us to receive information held by us and/or
                                </li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>Other persons or organisations,
                                    including government agencies, regulatory bodies and law enforcement agencies, as
                                    required, authorised or permitted by law
                                </li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>Anyone to whom part or all of
                                    our businesses or assets are transferred
                                </li>
                            </ul>
                        </div>

                        <div class="mt-5">
                            <h2 class="primary-font yhyl-text text-left">What if any information may be sent outside
                                Australia
                            </h2>
                            <p>
                                In some instances we may disclose some of your personal information to partners,
                                suppliers or professional advisers located outside Australia, including the UK or United
                                States of America. Typically the transfer of information outside of Australia will be in
                                order for the company to supply essential services, including protection of personal
                                data.
                            </p>
                        </div>

                        <div class="mt-5">
                            <h2 class="primary-font yhyl-text text-left">Your use of our portal, website and business,
                                and cookies
                            </h2>

                            <ul>
                                <li class="bold-font"><i class="bx bx-check-double"></i>We may collect some personal
                                    information about you when you access or use our portal,
                                    websites or business.</li>
                                <li class="bold-font"><i class="bx bx-check-double"></i>We do not record or store
                                    information that can be used to identify you personally,
                                    although we may anonymously record which pages you visit, the time and date of your
                                    visit
                                    and the internet protocol address assigned to your computer.
                                </li>
                                <li ><i class="bx bx-check-double"></i>
                                    <p ><span class="bold-font">We may also use ‘cookies’ or
                                            other tracking technologies to help us track your usage of
                                            our portal, websites and business, and to remember your preferences. </span>
                                   ‘Cookies’ are small
                                    files that store information on your computer, TV, mobile phone or other device.
                                    They enable
                                    the entity that placed the cookie on your device to recognise you on different
                                    devices,
                                    websites, services, and/or browsing sessions. When a cookie is linked to your
                                    account it is
                                    considered personal information and is protected under the Privacy Act. We handle
                                    any
                                    information collected by cookies in the same way that we handle all other personal
                                    information described in this Privacy Policy. You can disable cookies through your
                                    internet
                                    browser, but if you choose to do so our portal and websites may not work as
                                    intended.</p>
                                </li>
                            </ul>
                        </div>

                        <div class="mt-5">
                            <h2 class="primary-font yhyl-text text-left">Links you may access from our websites
                            </h2>
                            <p>
                                One of the services we provide to you is the supply of carefully filtered websites that
                                contain really useful information to help you care for your loved ones and save time,
                                effort and stress in managing your intergenerational life. These websites are operated
                                by third parties, including government departments, agencies, charities and private
                                companies.<br /><br />
                                Those links may not always be maintained or current. Although we endeavour to curate the
                                information available through these third parties, we are not responsible for the
                                privacy practices, website content, products or services of linked websites. Their
                                privacy policies and practices may differ significantly from ours. We encourage you
                                always to read a website’s privacy policies before using them and agreeing to their
                                Terms of Use.
                            </p>
                        </div>

                        <div class="mt-5">
                            <h2 class="primary-font yhyl-text text-left">
                                When you want to access or correct your personal information
                            </h2>
                            <p>
                                We retain minimum personal information about our customers. You can access the personal
                                information we hold about you by contacting us. Sometimes we may be unable to provide
                                you with access to all of your personal information, and if this is the case we will
                                explain why. Consistent with best practice, and to avoid unauthorised release of
                                personal information, we will need to verify your identity for the purpose of releasing
                                your personal information. If you are concerned that any personal information we hold
                                about you is inaccurate, please contact us and we will take reasonable steps to ensure
                                that it is corrected.<br /><br />
                                For further information about our Privacy Policy or practices, or to access or correct
                                your personal information, or to make a complaint, please contact us at:
                                <span class="yhyl-text"><a
                                        href="wisdom@your100yearlife.com">wisdom@your100yearlife.com</a></span>
                                Or contact us at:<br />
                                <span class="yhyl-text">
                                    Your 100 Year Life<br />
                                    Att: Corporate Secretary<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PO Box 496<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Spit Junction NSW 2088<br />
                                </span>
                            </p>
                        </div>

                        <div class="mt-5">
                            
                            <!--
                            <h2 class="primary-font yhyl-text text-left">
                                More about our personal data security process, and our partner, digi.me/IDExchange
                            </h2>
                            <p class="font-italic">
                                Insert approved information about IDExchange/Australian Data Exchange/digi.me here
                            </p>

                            -->
                            <h2 class="primary-font yhyl-text text-left mt-5">
                                Complaints
                            </h2>

                            <p>
                                Should you have a complaint about the way you believe your personal information has been
                                or is being handled by Your 100 Year Life, or if you think we may have breached the
                                Australian Privacy Act 1988 Cth, please put your complaint in writing and send it to us
                                promptly. Please be sure to include your name, email address, and details of your
                                complaint. We will acknowledge your complain on receipt and respond within a reasonable
                                period of time.<br /><br />
                                For further information about our Privacy Policy or practices, or to access or correct
                                your personal information, or to make a complaint, please contact us at:<br />
                                If you believe we have not resolved your complaint, please contact the Office of the
                                Australian Information Commissioner:
                                <span class="yhyl-text"><a
                                        href="www.oaic.gov.au/privacy">&nbsp;&nbsp;&nbsp;&nbsp;www.oaic.gov.au/privacy</a></span>
                                <br />
                                <span class="yhyl-text">
                                        &nbsp;&nbsp;&nbsp;&nbsp;Telephone: 1300 363 992<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;Email: <span class="yhyl-text"><a
                                            href="www.oaic.gov.au/privacy">enquiries@oaic.gov.au</a></span>

                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;Post: GPO Box 5218 Sydney NSW 2001 or GPO Box 2999 Canberra ACT 2601<br />
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section><!-- End about Section -->

<app-landing-footer></app-landing-footer>