import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, ObservedValueOf } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  requestOptions: any;
  @Output() toggle = new EventEmitter<string>();
  @Output() data = new EventEmitter<string>();
  @Output() change = new EventEmitter<string>();
  flag: boolean;
  userId: string;
  userData: any;
  resetEmail: any;
  jwtResponse: any;
  decodedData: any;
  admin_id: any;
  tempDecodedData: any;
  constructor(private http: HttpClient, private router: Router) { }

  setToken() {
    if (JSON.parse(localStorage.getItem('currentUser'))) {
      var headerDict = {
        Authorization: 'JWT ' + JSON.parse(localStorage.getItem('currentUser'))
      };
    } else if (JSON.parse(localStorage.getItem('bearer'))) {
      var headerDict = {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('bearer'))
      };
    }

    this.requestOptions = {
      headers: new HttpHeaders(headerDict)
    };
  }

  checkToken() {
    //check if token expired
    var decodedData = jwt_decode(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    if (decodedData['exp'] < Date.now() / 1000) {
      console.log('expire');
      localStorage.clear();
      this.router.navigate(['/home']);
    } else {
      console.log('available');
    }

    console.log('checktoken', decodedData['exp']);
  }

  setTempToken() {
    if (JSON.parse(localStorage.getItem('temp'))) {
      var headerDict = {
        Authorization: 'JWT ' + JSON.parse(localStorage.getItem('temp'))
      };
    } else if (JSON.parse(localStorage.getItem('bearer'))) {
      var headerDict = {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('bearer'))
      };
    }

    this.requestOptions = {
      headers: new HttpHeaders(headerDict)
    };
  }
  //toggle mobile view
  mobileMenuToggle(msg: string) {
    this.toggle.emit(msg);
  }

  sidetoggle(flag: boolean) {
    //this.change.emit(flag);
    this.flag = flag;
  }

  getToggleFlag() {
    return this.flag;
  }
  getUserId() {
    return this.userData;
  }
  /* my-services */
  getServiceProviderData() {
    this.setToken();
    var url = 'https://api.my100yearlife.com/my-service-providers/';
    return this.http.get(url, this.requestOptions);
  }

  getCustomServices() {
    this.setToken();
    var url = 'https://api.my100yearlife.com/custom-services/';
    return this.http.get(url, this.requestOptions);
  }

  addCustomService(data): Observable<any> {
    this.setToken();
    var url = 'https://api.my100yearlife.com/custom-services/';
    return this.http.post(url, data, this.requestOptions);
  }

  editService(data, id): Observable<any> {
    this.setToken();
    var url = 'https://api.my100yearlife.com/custom-services/' + id + '/';
    return this.http.put(url, data, this.requestOptions);
  }
  deleteService(id): Observable<any> {
    this.setToken();
    var url = 'https://api.my100yearlife.com/custom-services/' + id + '/';
    return this.http.delete(url, this.requestOptions);
  }
  /* my-offers */
  getOffersData() {
    this.setToken();
    var url = 'https://api.my100yearlife.com/my-offers/';
    return this.http.get(url, this.requestOptions);
  }
  /* user data */
  getLoginData(data): Observable<any> {
    var url = 'https://api.my100yearlife.com/rest-auth/login/';
    return this.http.post(url, data);
  }

  getCopilotToken(userid): Observable<any> {
    this.setTempToken();

    var url = 'https://api.my100yearlife.com/api/obtain-copilotjwt/';
    this.http
      .post<any>(url, userid, this.requestOptions)
      .subscribe(response => {
        this.jwtResponse = response;
        localStorage.removeItem('currentUser');
        localStorage.setItem(
          'currentUser',
          JSON.stringify(this.jwtResponse.token)
        );
        this.decodedData = jwt_decode(this.jwtResponse.token);
        this.router.navigate(['/page']);
      });
    return this.decodedData;
  }

  switchAccount(): Observable<any> {
    this.setTempToken();
    var url = 'https://api.my100yearlife.com/api/switch-account/';
    return this.http.get(url, this.requestOptions);
  }

  getCopilotPermissions() {
    this.tempDecodedData = jwt_decode(localStorage.getItem('currentUser'));
    return this.tempDecodedData;
  }
  convertToken(data): Observable<any> {
    var url = 'https://api.my100yearlife.com/auth/convert-token';
    return this.http.post(url, data);
  }
  getUserData(): Observable<any> {
    this.setToken();
    var url = 'https://api.my100yearlife.com/rest-auth/user/';
    /* this.http.get(url,this.requestOptions).subscribe((data) => {
      this.userData = data;
      this.userId = this.userData.pk;
    }); */
    return this.http.get(url, this.requestOptions);
  }

  deactivateAccount(): Observable<any> {
    var url = 'https://api.my100yearlife.com/rest-auth/user/deactivate/';
    return this.http.post(url, this.requestOptions);
  }

  deleteAccount(): Observable<any> {
    var url = 'https://api.my100yearlife.com/rest-auth/user/delete/';
    return this.http.post(url, '', this.requestOptions);
  }

  disableAccount(): Observable<any> {
    var url = 'https://api.my100yearlife.com/rest-auth/user/deactivate/';
    return this.http.post(url, '', this.requestOptions);
  }

  updateUserData(data): Observable<any> {
    this.setToken();
    var url = 'https://api.my100yearlife.com/rest-auth/user/';
    /* this.http.get(url,this.requestOptions).subscribe((data) => {
      this.userData = data;
      this.userId = this.userData.pk;
    }); */
    return this.http.patch(url, data, this.requestOptions);
  }
  registerData(data): Observable<any> {
    // this.setToken();
    var url = 'https://api.my100yearlife.com/rest-auth/registration/';
    return this.http.post(url, data, this.requestOptions);
  }
  logout(): Observable<any> {
    // this.setToken();
    var url = 'https://api.my100yearlife.com/rest-auth/logout/';
    return this.http.post(url, '', this.requestOptions);
  }
  sendPasswordLink(data): Observable<any> {
    this.setToken();
    this.resetEmail = data.email;
    var url = 'https://api.my100yearlife.com/api/password_reset/';
    return this.http.post(url, data, this.requestOptions);
  }

  resetPassword(email, token, password): Observable<any> {
    const data = {
      email: email,
      password: password,
      token: token
    };
    this.setToken();
    var url =
      'https://api.my100yearlife.com/api/password_reset/confirm/?token=' + token;
    return this.http.post(url, data, this.requestOptions);
  }

  updatePassword(data): Observable<any> {
    var url = 'https://api.my100yearlife.com/rest-auth/password/change/';
    return this.http.post(url, data, this.requestOptions);
  }
  /*my-documents */
  getDocumentData(): Observable<any> {
    this.setToken();
    this.checkToken();
    var url = 'https://api.my100yearlife.com/my-documents/';
    return this.http.get(url, this.requestOptions);
  }

  addNewDocument(data): Observable<any> {
    if (JSON.parse(localStorage.getItem('currentUser'))) {
      var headers = {
        Authorization: 'JWT ' + JSON.parse(localStorage.getItem('currentUser'))
      };
    } else if (JSON.parse(localStorage.getItem('bearer'))) {
      var headers = {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('bearer'))
      };
    }
    var url = 'https://api.my100yearlife.com/my-documents/';
    return this.http.post(url, data, {
      headers,
      reportProgress: true,
      responseType: 'json'
    });
  }

  updateDocument(data, fileId): Observable<any> {
    this.setToken();
    var url = 'https://api.my100yearlife.com/my-documents/' + fileId + '/';
    return this.http.patch(url, data, this.requestOptions);
  }

  deleteDocument(fileId): Observable<any> {
    this.setToken();
    var url = 'https://api.my100yearlife.com/my-documents/' + fileId + '/';
    return this.http.delete(url, this.requestOptions);
  }

  downloadFile(id, filename): Observable<any> {
    if (JSON.parse(localStorage.getItem('currentUser'))) {
      var header = {
        Authorization: 'JWT ' + JSON.parse(localStorage.getItem('currentUser'))
      };
    } else if (JSON.parse(localStorage.getItem('bearer'))) {
      var header = {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('bearer'))
      };
    }
    this.requestOptions = {
      headers: new HttpHeaders(header),
      observe: 'response', // to display the full response
      responseType: 'blob'
    };

    var url =
      'https://api.my100yearlife.com/my-documents/' +
      id +
      '/?downloadablefilename=' +
      filename;
    // window.location.href = url;
    return this.http.get(url, this.requestOptions);
  }
  /* my-events */
  setEventData(data): Observable<any> {
    this.setToken();
    var url = 'https://api.my100yearlife.com/events/';
    return this.http.post(url, data, this.requestOptions);
  }
  getEventData(): Observable<any> {
    this.setToken();
    var url = 'https://api.my100yearlife.com/events/';
    return this.http.get(url, this.requestOptions);
  }
  editEventData(data, id): Observable<any> {
    this.setToken();
    var url = 'https://api.my100yearlife.com/events/' + id + '/';
    return this.http.put(url, data, this.requestOptions);
  }
  deleteEventData(id): Observable<any> {
    this.setToken();
    var url = 'https://api.my100yearlife.com/events/' + id + '/';
    return this.http.delete(url, this.requestOptions);
  }
  /* my-colpiots */
  getCopilots(): Observable<any> {
    this.setToken();
    var url = 'https://api.my100yearlife.com/addcopilot/';
    return this.http.get(url, this.requestOptions);
  }

  addCopilots(data): Observable<any> {
    this.setToken();
    var url = 'https://api.my100yearlife.com/addcopilot/';
    return this.http.post(url, data, this.requestOptions);
  }

  deleteCopilot(id): Observable<any> {
    this.setToken();
    var url = 'https://api.my100yearlife.com/addcopilot/' + id + '/';
    return this.http.delete(url, this.requestOptions);
  }

  editCopilot(data, id): Observable<any> {
    this.setToken();
    var url = 'https://api.my100yearlife.com/addcopilot/' + id + '/';
    return this.http.put(url, data, this.requestOptions);
  }
  /* mailjet services */
  addSubscriptionList(data): Observable<any> {
    // this.setToken();
    var url =
      'https://api.my100yearlife.com/api/mailjet/add_contact_to_subList/';
    // 'https://ec2-13-54-173-27.ap-southeast-2.compute.amazonaws.com/api/mailjet/add_contact_to_subList/';
    // 'http://localhost:8000/api/mailjet/add_contact_to_subList/';

    return this.http.post(url, data, this.requestOptions);
  }
  checkSubscribed(data): Observable<any> {
    var url = 'https://api.my100yearlife.com/api/mailjet/check_subscribed/';
    // var url = 'http://localhost:8000/api/mailjet/check_subscribed/';
    return this.http.post(url, data, this.requestOptions);
  }
  sendSubSuccessEmail(data): Observable<any> {
    var url = 'https://api.my100yearlife.com/api/mailjet/send_email/';

    // 'http://ec2-13-54-173-27.ap-southeast-2.compute.amazonaws.com/api/mailjet/send_email/';

    return this.http.post(url, data, this.requestOptions);
  }

  getAccessMessage() {
    return 'You are not allowed to perform this action !';
  }
}
