import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  auth2: any;
  @ViewChild('loginRef', { static: true }) loginElement: ElementRef;
  model: any = {};
  faFacebook = faFacebook;
  faGoogle = faGoogle;
  faUser = faUser;
  faLock = faLock;
  faEye = faEye;
  username: string;
  password: string;
  name: string = '';
  show: boolean;
  error: string;
  isError: boolean = false;
  isLoading_login: boolean = false;
  isSuccessful_login: boolean = false;

  constructor(
    private router: Router,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private authenticationService: AuthService
  ) {
    this.show = false;
  }

  ngOnInit(): void {
    this.googleSDK();
    this.fbLibrary();
  }
  googleSDK() {
    window['googleSDKLoaded'] = () => {
      window['gapi'].load('auth2', () => {
        this.auth2 = window['gapi'].auth2.init({
          client_id:
            '702872903694-en06kjgl99nqgj19ibib6ueifstg2adu.apps.googleusercontent.com',
          cookiepolicy: 'single_host_origin',
          scope: 'profile email'
        });
        this.prepareLoginButton();
      });
    };

    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://apis.google.com/js/platform.js?onload=googleSDKLoaded';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'google-jssdk');
  }

  prepareLoginButton() {
    this.auth2.attachClickHandler(
      this.loginElement.nativeElement,
      {},
      googleUser => {
        let profile = googleUser.getBasicProfile();
        /* console.log('Token || ' + googleUser.getAuthResponse().id_token);
        console.log('ID: ' + profile.getId());
        console.log('Name: ' + profile.getName());
        console.log('Image URL: ' + profile.getImageUrl());
        console.log('Email: ' + profile.getEmail()); */
        const query = {
          grant_type: 'convert_token',
          token: googleUser.getAuthResponse().access_token,
          backend: 'google-oauth2',
          client_id: 'B9Uj26ybroZLZBupYbQ0RFJrUrMKDsZw4enewDsN',
          client_secret:
            'oIVvWZ5INoLjLz2x80zQywVpVrMbp8kuk6GecWLpiBxURdkf60R3UVASmFgz6ReZOwP3Fr7zPNdW4kf4fAoNhxXjJAXmtXRWVjSxuLg8NZpn0gt3NA0kFIG6Ii9WRUKd'
        };
        this.commonService.convertToken(query).subscribe(
          (response: any) => {
            if (response) {
              localStorage.setItem(
                'bearer',
                JSON.stringify(response.access_token)
              );
              this.router.navigate(['/page']);
            }
          },
          (errorResponse: any) => {}
        );
      },
      error => {
        alert(JSON.stringify(error, undefined, 2));
      }
    );
  }
  fbLibrary() {
    /* '882454908980988 , 238187747756835' */

    (window as any).fbAsyncInit = function() {
      window['FB'].init({
        appId: '250504063238383',
        cookie: true,
        xfbml: true,
        version: 'v3.1'
      });
      window['FB'].AppEvents.logPageView();
    };

    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  fbLogin() {
    window['FB'].login(
      response => {
        console.log('login response', response.authResponse);
        if (response.authResponse) {
          // window['FB'].api('/me', {
          //   fields: 'last_name, first_name, email'
          // }, (userInfo) => {

          //   /* console.log("user information");
          //   console.log(userInfo); */
          // });
          this.prepareFacebookLoingBtn(response.authResponse.accessToken);
        } else {
          console.log('User login failed');
        }
      },
      { scope: 'email' }
    );
  }
  prepareFacebookLoingBtn(accessToken) {
    const query = {
      grant_type: 'convert_token',
      token: accessToken,
      backend: 'facebook',
      client_id: 'bm9kkwghdL8EBpmYOF0mhBipJ43s2bFFOITckxCt',
      client_secret:
        'zqoh3quYS0vby49uiYNTOlri1oTiyvS5mKY672Bbm0nVE96ruPWRCadBKWz2CDegFG4xMbiVC8SGo2fQboJGddvXmQHrkOggB3e5ts9Fjog7D2HtF6dMRZ3SwndbLDfU'
    };
    this.commonService.convertToken(query).subscribe(
      (response: any) => {
        if (response) {
          localStorage.setItem('bearer', JSON.stringify(response.access_token));
          this.router.navigate(['/page']);
        }
      },
      (errorResponse: any) => {}
    );
  }
  showPassword() {
    this.show = !this.show;
  }

  onLogin() {
    this.isError = false;
    this.isLoading_login = true;
    /* const data = {
      'username' : this.model.userName,
      'password' : this.model.password
    } */

    this.authenticationService
      .login(this.model.userName, this.model.password)
      .subscribe(
        res => {
          this.isSuccessful_login = true;
          if (res.user.length === 0) {
            this.router.navigate(['/page']);
          } else {
            this.router.navigate(['/selectProfile']);
          }
          //this.commonService.getLoginData(data);
        },
        error => {
          this.isLoading_login = false;

          if (error.error.password || error.error.non_field_errors) {
            this.isError = true;
            if (error.error.password) {
              this.error = error.error.password[0];
            } else if ((this.error = error.error.non_field_errors)) {
              this.error = error.error.non_field_errors[0];
            }
          }
        }
      );
  }

  register() {
    this.router.navigate(['/register']);
  }
}
