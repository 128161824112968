import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap'; 
import { main } from '@popperjs/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  mainText : string;
  secondaryText : string;

  constructor(config: NgbCarouselConfig) {  
    config.interval = 10000;  
    config.wrap = true;  
    config.keyboard = false;  
    config.pauseOnHover = true;  
  }

  ngOnInit(): void {
    var maintext = ["mother", "father", "sister", "yourself"];
    var secondarytext = ["health", "insurance", "banking","it all"];
    /* $(function () {
        var i = 0;
        $("#dvImage").css("background-image", "url(images/" + images[i] + ")");
        setInterval(function () {
            i++;
            if (i == images.length) {
                i = 0;
            }
            $("#dvImage").fadeOut("slow", function () {
                $(this).css("background-image", "url(images/" + images[i] + ")");
                $(this).fadeIn("slow");
            });
        }, 5000);
    }); */
    var i = 0;
    setInterval(() => {
      if(i === maintext.length){
        i = 0;
      }
      this.mainText = maintext[i];
      this.secondaryText = secondarytext[i];
      i++;
    }, 5000);
  }
  onScrollToTop(){
    window.scrollTo(0,0);
  }

}
