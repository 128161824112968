import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
@Injectable()
export class AuthService {
  errorData = {};
  serverUrl = 'https://api.my100yearlife.com/rest-auth/login/';
  copilotData: any;
  constructor(private http: HttpClient) { }

  login(username: string, password: string) {
    //username textbox could be username or email
    var emailReg = /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/;
    var isEmail = emailReg.test(username);
    console.log('isEmail', isEmail);

    return this.http
      .post<any>(
        `${this.serverUrl}`,
        isEmail === true
          ? {
            email: username,
            password: password
          }
          : { username: username, password: password }
      )
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            this.copilotData = user.user;
            if (this.copilotData) {
              localStorage.setItem('temp', JSON.stringify(user.token));
              /* 
                    for(let i=1;i<user.user.length;i++){

                    } */
            }
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user.token));
          }
          return user;
        })
      );
  }
  getCopilotForLogin() {
    return this.copilotData;
  }
  logout() {
    //if social login

    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('bearer');
    localStorage.removeItem('userId');
    localStorage.removeItem('temp');
    return true;
  }

  isLoggedIn() {
    if (localStorage.getItem('currentUser')) {
      return true;
    }
    return false;
  }

  getAuthorizationToken() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser.token;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }

    // return an observable with a user-facing error message
    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }
}
