<div class="content-wrapper pt-7 mob-pt">
  <div class="content-body">
    <div class="col-sm-9 col-md-7 col-lg-4 mx-auto">
      <div class="card card-signin my-5 shadow">
        <div class="card-body">
          <h4 class="card-title text-center yhyl-text bold-font mb-2">Login</h4>

          <form
            class="form-signin"
            name="form"
            (ngSubmit)="f.form.valid && onLogin()"
            #f="ngForm"
            novalidate
          >
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fa fa-user yhyl-text"></i
                ></span>
              </div>
              <input
                type="text"
                placeholder="Email or Username"
                name="userName"
                class="form-control"
                [(ngModel)]="model.userName"
                #userName="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && userName.invalid }"
                required
              />
              <div
                *ngIf="f.submitted && userName.invalid"
                class="invalid-feedback"
              >
                <div *ngIf="userName.errors.required">
                  Username or Email is required
                </div>
              </div>
            </div>

            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fa fa-lock yhyl-text"></i
                ></span>
              </div>
              <input
                id="password-field"
                [type]="show ? 'text' : 'password'"
                placeholder="Password"
                name="password"
                class="form-control"
                [(ngModel)]="model.password"
                #password="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && password.invalid }"
                required
                minlength="6"
              />
              <span class="password-eye" (click)="showPassword()">
                <i
                  class="fa fa-eye yhyl-text"
                  title="Show password"
                  data-toggle="tooltip"
                  data-placement="right"
                ></i>
              </span>
              <div
                *ngIf="f.submitted && password.invalid"
                class="invalid-feedback"
              >
                <div *ngIf="password.errors.required">Password is required</div>
              </div>
            </div>
            <div class="error-msg" *ngIf="isError">
              <p>{{ error }}</p>
            </div>
            <div class="form-group ">
              <button
                class="btn btn-primary btn-block primary-yhyl-bg font-20 font-sm"
              >
                <div
                  class="spinner-border"
                  role="status"
                  *ngIf="isLoading_login"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <div *ngIf="!isLoading_login && !isSuccessful_login">Login</div>
                <div *ngIf="isSuccessful_login">
                  <i class="fas fa-check"></i>
                </div>
              </button>
            </div>

            <p
              class="divider-text mb-2"
              style="vertical-align: middle; text-align: center"
            >
              <span
                >Forgot password?
                <a routerLink="/passwordReset" class="yhyl-text">Reset</a></span
              >
            </p>
            <p
              class="divider-text mb-2"
              style="vertical-align: middle; text-align: center"
            >
              <span class="bg-light">OR</span>
            </p>
            <p>
              <button
                type="button"
                #loginRef
                class="btn btn-primary btn-block btn-social btn-google font-20 font-sm"
              >
                <fa-icon [icon]="faGoogle"></fa-icon>
                <span>Login with Google</span>
              </button>
              <button
                type="button"
                (click)="fbLogin()"
                class="btn btn-primary btn-block btn-social btn-facebook font-20 font-sm"
              >
                <fa-icon [icon]="faFacebook"></fa-icon>
                <span>Login with Facebook</span>
              </button>
            </p>
            <p
              class="divider-text mb-2"
              style="vertical-align: middle; text-align: center"
            >
              <span
                >Don't have an account?
                <button
                  class="btn btn-primary btn-block primary-yhyl-bg font-20 font-sm"
                  (click)="register()"
                  type="submit"
                >
                  Click here to Register!
                </button>
              </span>
            </p>
            <p style="font-size:12px;text-align: center;padding: top 1rem;">
              Your data is secured with military-grade encryption
              <br />
              powered by
              <span
                ><img
                  src="../../assets/New Branding Assets/Shell Logos/digi me.png"
                  class="img-fluid"
                  style="max-width: 17%;"
                />
              </span>
              and
              <span
                ><img
                  src="../../assets/New Branding Assets/Shell Logos/ID exchange.png"
                  class="img-fluid"
                  style="max-width: 8%;"
              /></span>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
