import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { Page2Component } from './dashboard/menu/page2/page2.component';
import { DocumentsComponent } from './dashboard/menu/documents/documents.component';
import { InformationComponent } from './dashboard/menu/information/information.component';
import { Information2Component } from './dashboard/menu/information2/information2.component';
import { HealthComponent } from './dashboard/menu/information2/health/health.component';
import { OffersComponent } from './dashboard/menu/offers/offers.component';
import { DiaryComponent } from './dashboard/menu/diary/diary.component';
import { ServicesComponent } from './dashboard/menu/services/services.component';
import { AddFavouritesComponent } from './dashboard/menu/add-favourites/add-favourites.component';
import { CoPilotsComponent } from './dashboard/menu/co-pilots/co-pilots.component';
import { RegisterComponent } from './register/register.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { LandingComponent } from './landing/landing.component';
import { AboutComponent } from './about/about.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { FaqsComponent } from './faqs/faqs.component';
import { ContactComponent } from './contact/contact.component';
import { BusinessesComponent } from './businesses/businesses.component';
import { AboutPartnersComponent } from './about/about-partners/about-partners.component';
import { AboutStoryComponent } from './about/about-story/about-story.component';
import { AboutPeopleComponent } from './about/about-people/about-people.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { ConfirmPasswordComponent } from './confirm-password/confirm-password.component';
import { IntermediateComponent } from './intermediate/intermediate.component';
import { HomePageComponent } from './home-page/home-page.component';
import { HomeInfoComponent } from './dashboard/menu/information2/home-info/home-info.component';
import { WorkComponent } from './dashboard/menu/information2/work/work.component';
import { TechnologyComponent } from './dashboard/menu/information2/technology/technology.component';
import { SecurityComponent } from './dashboard/menu/information2/security/security.component';
import { CareComponent } from './dashboard/menu/information2/care/care.component';
import { FriendsComponent } from './dashboard/menu/information2/friends/friends.component';
import { LegacyComponent } from './dashboard/menu/information2/legacy/legacy.component';
import { FinanceComponent } from './dashboard/menu/information2/finance/finance.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  /*  { path: 'main', component: MainPageComponent,data:{sidebar:false,navbar:false} }, */
  {
    path: 'login',
    component: LoginComponent,
    data: { sidebar: false, navbar: false }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: { sidebar: false, navbar: false }
  },
  {
    path: 'passwordReset',
    component: PasswordResetComponent,
    data: { sidebar: false, navbar: false }
  },
  {
    path: 'selectProfile',
    component: IntermediateComponent,
    data: { sidebar: false, navbar: false }
  },
  {
    path: 'api/password_reset/confirm',
    component: ConfirmPasswordComponent,
    data: { sidebar: false, navbar: false }
  },
  {
    path: 'home',
    component: HomePageComponent,
    data: { sidebar: false, navbar: false, header: false }
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { sidebar: true, navbar: true }
  },
  {
    path: 'page',
    component: Page2Component,
    data: { sidebar: true, navbar: true }
  },
  /* ,canActivate:[AuthGuard] */
  {
    path: 'documents',
    component: DocumentsComponent,
    data: { sidebar: true, navbar: true }
  },
  {
    path: 'information',
    component: Information2Component,
    data: { sidebar: true, navbar: true }
  },
  {
    path: 'information/myhealth',
    component: HealthComponent,
    data: { sidebar: true, navbar: true }
  },
  {
    path: 'information/myhome',
    component: HomeInfoComponent,
    data: { sidebar: true, navbar: true }
  },
  {
    path: 'information/mycare',
    component: CareComponent,
    data: { sidebar: true, navbar: true }
  },
  {
    path: 'information/mysecurity',
    component: SecurityComponent,
    data: { sidebar: true, navbar: true }
  },
  {
    path: 'information/myfriends',
    component: FriendsComponent,
    data: { sidebar: true, navbar: true }
  },
  {
    path: 'information/mylegacy',
    component: LegacyComponent,
    data: { sidebar: true, navbar: true }
  },
  {
    path: 'information/myfinance',
    component: FinanceComponent,
    data: { sidebar: true, navbar: true }
  },
  {
    path: 'information/mytechnology',
    component: TechnologyComponent,
    data: { sidebar: true, navbar: true }
  },
  {
    path: 'information/mywork',
    component: WorkComponent,
    data: { sidebar: true, navbar: true }
  },
  {
    path: 'diary',
    component: DiaryComponent,
    data: { sidebar: true, navbar: true }
  },
  {
    path: 'offers',
    component: OffersComponent,
    data: { sidebar: true, navbar: true }
  },
  {
    path: 'services',
    component: ServicesComponent,
    data: { sidebar: true, navbar: true }
  },
  {
    path: 'copilots',
    component: CoPilotsComponent,
    data: { sidebar: true, navbar: true }
  },
  {
    path: 'favourites',
    component: AddFavouritesComponent,
    data: { sidebar: true, navbar: true }
  },
  {
    path: 'logout',
    component: LandingComponent,
    data: { sidebar: false, navbar: false }
  },
  {
    path: 'myprofile',
    component: UserProfileComponent,
    data: { sidebar: true, navbar: true }
  },
  {
    path: 'about',
    component: AboutComponent,
    data: { sidebar: false, navbar: false }
  },
  {
    path: 'about/our-story',
    component: AboutStoryComponent,
    data: { sidebar: false, navbar: false }
  },
  {
    path: 'about/our-people',
    component: AboutPeopleComponent,
    data: { sidebar: false, navbar: false }
  },
  {
    path: 'about/our-partners',
    component: AboutPartnersComponent,
    data: { sidebar: false, navbar: false }
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: { sidebar: false, navbar: false }
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent,
    data: { sidebar: false, navbar: false }
  },
  {
    path: 'faqs',
    component: FaqsComponent,
    data: { sidebar: false, navbar: false }
  },
  {
    path: 'contact',
    component: ContactComponent,
    data: { sidebar: false, navbar: false }
  },
  {
    path: 'businesses',
    component: BusinessesComponent,
    data: { sidebar: false, navbar: false }
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' }),
    DashboardModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
