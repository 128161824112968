<div class="content-wrapper pd-10">
  <div class="content-header row border-bottom">
    <div class="content-header-left col-md-12 col-12 mb-2">
      <h3 class="content-header-title font-22 bold-font font-22 bold-font">
        <i class="icon-width fas fa-desktop text-center mr-3"></i>My Welcome
        Page
      </h3>
      <!-- <p>My Page is where you and your trusted loved ones keep all of the essential information, contacts,
                documents, photos, videos that help your life run smoothly.
                Like What? Stuff that is scattered all over, hard to find when you need it, and you want or need to
                share – or not – lives here.
                My Important Things is the one-stop place that you can securely store really important stuff so that you
                save time, stress and money.
            </p> -->
    </div>
  </div>
  <div class="content-body">
    <div class="card mt-3">
      <div class="card-content">
        <div class="card-body" *ngIf="userData">
          <div>
            <h4 class="border-bottom pb-3 content-header-title font-22 bold-font">
              Welcome <span class="yhyl-text">{{ userData.first_name }}</span>,
            </h4>
          </div>

          <div class="row">
            <div class="col-md-12 col-sm-12 px-0">
              <p class="bold-font">
                You've arrived!
                <br />
                <br />
                You've taken the first step toward de-stressing and improving
                your ageing marathon.
                <br />
                <br />
                ALL THE INFORMATION AND GUIDANCE YOU NEED TO MANAGE THE MARATHON
                of AGEING
                <br />

                <!-- <i class="icon-width fas fa-info text-center mr-3"></i>

                IT'S ALL FREE! -->
              </p>
              <button routerLink="/information" class="col-sm-6 btn btn-lg primary-yhyl-bg font-20"
                style=" margin-bottom: 15px;">
                <i class="icon-width fas fa-info text-center mr-3"></i>My
                Information Hub - IT'S ALL FREE
              </button>
              <div class="col-sm-6 btn btn-lg primary-yhyl-bg font-20" style="opacity: 0;"></div>
              <div>
                <p class="bold-font">
                  ALL THE INFORMATION AND GUIDANCE YOU NEED TO MANAGE THE
                  MARATHON of AGEING
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </p>
              </div>

              <button class="col-sm-6 btn btn-lg primary-yhyl-bg font-20">
                Your Personalised Toolkit and Control Centre
              </button>
            </div>
            <div class="col-md-6 col-sm-12 px-0">
              <!-- <div id="divWeatherMain" class="float-right">
                <div *ngIf="WeatherData.isDay" class="weatherWidgetRow">
                  <i class="fas fa-3x fa-sun sun"></i>
                </div>
                <div *ngIf="!WeatherData.isDay" class="weatherWidgetRow">
                  <i class="fas fa-3x fa-moon moon"></i>
                </div>
                <div class="weatherWidgetRow cloudDiv">
                  <i class="fas fa-cloud fa-3x cloud"></i>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div
                      class="weatherWidgetRow bold-font"
                      style="font-size: 32px;margin-top: 5px;"
                    >
                      {{ WeatherData.temp_celcius }}°
                    </div>
                    <div class="weatherWidgetRow" style="font-size: 12px;">
                      Min: {{ WeatherData.temp_min }}°
                    </div>
                    <div class="weatherWidgetRow" style="font-size: 12px;">
                      Max: {{ WeatherData.temp_max }}°
                    </div>
                  </div>
                  <div class="col-5">
                    <div
                      class="weatherWidgetRow bold-font"
                      style="font-size: 25px;margin-top: 10px;"
                    >
                      {{ WeatherData.name }}
                    </div>
                    <span> </span>
                    <div class="weatherWidgetRow" style="font-size: 12px;">
                      Pressure: {{ WeatherData.main.pressure }}hPa
                    </div>
                    <div class="weatherWidgetRow" style="font-size: 12px;">
                      Humidity: {{ WeatherData.main.humidity }}%
                    </div>
                  </div>
                  <div class="col-2">
                    <i
                      style="font-size:20px;cursor:pointer;margin-top:20px;"
                      class="fas fa-fw fa-pencil-alt"
                      (click)="showDropdown()"
                    ></i>
                  </div>
                </div>

                <div class="weatherWidgetRow" style="font-size: 12px;">Feels Like:
                                    {{WeatherData.temp_feels_like}}°</div>
              </div> -->
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-lg-12 col-md-12 col-sm-12 px-0">
              <div class="font-18">
                You can do more with <b>Premium</b>: PERSONALISE YOUR AGEING
                JOURNEY with YOUR 100 YEAR LIFE
                <br />
              </div>
              <ul>
                <li class="col-lg-6 bold-font">
                  Cut the chaos! Manage in real time
                </li>
                <li class="col-lg-6 bold-font">
                  Add a co-pilot to help you manage
                </li>
                <li class="col-lg-6 bold-font">
                  Find, save and manage support and care services
                </li>
                <li class="col-lg-6 bold-font">Share those tough decisions</li>
                <li class="col-lg-6 bold-font">
                  Save and share memories, legacy, and more
                </li>
              </ul>
            </div>
            <!-- <div class="col-lg-6 col-md-12 col-sm-12 px-0">
              <h3 style="opacity:0;">More Actions</h3>
              <ul>
                <li class="bold-font">Update your profile</li>
                <li class="bold-font">Read our Privacy Policy</li>
                <li class="bold-font">Shop for Smart Life Products</li>
                <li class="bold-font">
                  Click here to know how to use the platform
                </li>
              </ul>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="card mt-3 pull-up">
            <div class="card-content">
                <div class="card-body">
                    <h4 class="border-bottom pb-3 content-header-title font-22 bold-font">We got 3 new offers for you !
                    </h4>
                    <p class="text-animation">Please visit "My Offers" page to find out more...</p>
                </div>
            </div>
        </div> -->
  </div>
</div>
<div class="text-center" *ngIf="showLoader">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div class="overlay" style="z-index: 3;" *ngIf="isShowDropdown">
  <div class="popup modify-popup col-lg-5 col-md-6 col-sm-6" style="width : 80%">
    <h3>
      <a class="close" (click)="isShowDropdown = false">&times;</a>
      <div class="clearfix"></div>
    </h3>
    <br />
    <div class="content">
      <div class="row text-center mb-4">
        <div class="col-md-12">
          <h4 class="yhyl-text">Choose your Country</h4>
        </div>
        <select (change)="selectCity($event)" class="border" style="margin:auto;width:80%;" searchable="Search here..">
          <option value="" disabled selected>Select country</option>
          <option value="{{ city.name }}" *ngFor="let city of cityData">
            {{ city.name }}</option>
        </select>
      </div>
    </div>
  </div>
</div>
<!-- BEGIN: Customizer-->
<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-xl-block"
  [ngClass]="showUpdates ? 'open' : ''">
  <a class="customizer-close" href="#"><i class="ft-x font-medium-3"></i></a>
  <a class="customizer-toggle primary-yhyl-bg box-shadow-3 btn-hover" (click)="showUpdateToggle()">
    <i class="fa fa-bell h4" theme="light" tooltip="Click to see Updates" placement="left"></i><span
      class="indicator"></span>
  </a>
  <div class="customizer-content p-2">
    <h4 class="border-bottom pb-3 content-header-title font-22 bold-font">
      Hey Test..
    </h4>
    <p class="text-animation">There are 5 new updates for you!</p>
    <p>Last 3 days to renew your service for Travel</p>
    <p>2 new offers on Health Service</p>
  </div>
</div>

<div class="sidenav-overlay"></div>
<div class="drag-target"></div>