<!-- ======= about-story Section ======= -->
<section id="about" class="about">
  <div class="container" data-aos="fade-up">
    <div class="row content">
      <div
        data-aos="zoom-in"
        data-aos-delay="200"
        class="col-12 d-flex p-0 aos-init aos-animate"
      >
        <div class="card p-0" style="border-radius: 15px ;">
          <h3
            class="primary-font primary-yhyl-bg p-5 text-center"
            style="border-radius: 15px 15px 0 0;"
          >
            Our Partners
          </h3>
          <div class="p-5">
            <p>
              We have a growing number of partners who understand and want to
              help us make ageing and caring easier for all. <br />
              <br />
              Enabling Partners share our vision and are integral to our
              operations. We have an impressive and growing list of partners.

              <br />
              <br />
              Each enhances your experience at Your 100 Year Life. Affinity
              Partners share our vision and operate in complementary fields, and
              we are pleased to cross-promote for the benefit of seniors and
              their families. In addition, we are fortunate to have a Brains
              Trust of highly skilled, experienced and wise supporters who
              contribute commercial, technical and other advice.
              <br />
              <br />
              <!-- Our world leading data security partner 'Digi.me'.

              <br />

              Digi.me is the global leader in protection of personal data and
              consent. “Digi.me Private Sharing ™ safely empowers you to secure
              and control your personal data.
            </p>

            <p>
              The Australian partner of digi.me is Australian Data Exchange
              empowering consumers to securely protect, exchange, and legal
              consent for their personal data.

              <br /><br /> -->
              Our Enabling Partners are organisations who make it possible for
              Your 100 Year Life to scale, grow, and achieve our objectives.
              They include: AWS, UTS Startups and Digi.me.
              <!--  and
              GoodThings Foundation. -->
              <br /><br />
              Our Supporting Partners are organisations who we work with
              actively in a collaborative relationship. It includes: Be
              Connected.
              <br /><br />
              Our Affinity Partners share our vision and purpose. They include:
              Shaping Connections (Monash University), Full Time Lives and The
              Waverton Hub.
              <br /><br />
              We are keen to collaborate and promote aligned organisations as
              Partners, Supporters, or Affinity Partners. If you are, or know
              of, an organisation that shares our purpose and values, please
              contact us at: wisdom@your100yearlife.com
            </p>
            <div class="row">
              <div
                class="col-lg-4 col-md-6 d-flex align-items-stretch half-top"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div class="icon-box shadow">
                  <div class="client-logo">
                    <img
                      src="../../assets/New Branding Assets/yhyl-front-page-v15/yhyl-front-page-v15 Folder/Links/powered by digi.me logo with white text landscape-border.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <h4>Digi.me</h4>
                  <p>
                    Digi.me is the global leader in protection of personal data
                    and consent. “Digi.me Private Sharing ™ safely empowers you
                    to secure and control your personal data.
                  </p>
                </div>
              </div>

              <div
                class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0 half-top"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <div class="icon-box shadow">
                  <div class="client-logo">
                    <img
                      src="../../assets/New Branding Assets/WithoutWords/ID.png"
                      class="img-fluid"
                      alt=""
                      style="padding:15px;"
                    />
                  </div>
                  <h4>ID Exchange</h4>
                  <p>
                    The Australian partner of digi.me is Australian Data
                    Exchange empowering consumers to securely protect, exchange,
                    and legal consent for their personal data.
                  </p>
                </div>
              </div>

              <div
                class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0 half-top"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div class="icon-box shadow">
                  <div class="client-logo">
                    <img
                      src="../../assets/New Branding Assets/yhyl-front-page-v15/yhyl-front-page-v15 Folder/Links/amazon-logo.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <h4>AWS</h4>
                  <p>
                    Our Enabling Partners are organisations who make it possible
                    for Your 100 Year Life to scale, grow, and achieve our
                    objectives.
                  </p>
                </div>
              </div>
              <div
                class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0 half-top"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div class="icon-box shadow">
                  <div class="client-logo">
                    <img
                      src="../../assets/New Branding Assets/WithoutWords/UTSstartups.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <h4>UTS Startups</h4>
                  <p>
                    UTS Startups supports scalable startups like Your 100-Year
                    Life by offering access to a range of resources, industry
                    connections, mentors and other benefits.
                  </p>
                </div>
              </div>
              <div
                class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0 half-top"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div class="icon-box shadow">
                  <div class="client-logo">
                    <img
                      src="../../assets/New Branding Assets/yhyl-front-page-v15/yhyl-front-page-v15 Folder/Links/shaping.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <h4>Shaping Connections</h4>
                  <p>
                    Shaping Connections is a research program that seeks to
                    understand better how technology use supports seniors
                    connectedness and enhances social inclusion and
                    participation.
                  </p>
                </div>
              </div>
              <div
                class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0 half-top"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div class="icon-box shadow">
                  <div class="client-logo">
                    <img
                      src="../../assets/New Branding Assets/yhyl-front-page-v15/yhyl-front-page-v15 Folder/Links/Full Time Lives.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <h4>Full Time Lives</h4>
                  <p>
                    Full Time Lives helps people build meaningful, active, and
                    happy lives after full time work.
                  </p>
                </div>
              </div>
              <div
                class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0 half-top"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div class="icon-box shadow">
                  <div class="client-logo">
                    <img
                      src="../../assets/New Branding Assets/yhyl-front-page-v15/yhyl-front-page-v15 Folder/Links/Waverton Hub.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <h4>The Waverton Hub</h4>
                  <p>
                    The Waverton Hub exists to support its members in
                    maintaining a happy, healthy and long life in their own
                    home.
                  </p>
                </div>
              </div>
              <div
                class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0 half-top"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div class="icon-box shadow">
                  <div class="client-logo">
                    <img
                      src="../../assets/New Branding Assets/yhyl-front-page-v15/yhyl-front-page-v15 Folder/Links/be_connected_logo.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <h4>Be Connected</h4>
                  <p>
                    Be Connected is an Australia-wide initiative empowering all
                    Australians to thrive in a digital world.
                  </p>
                </div>
              </div>

              <div
                class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0 half-top"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div class="icon-box shadow">
                  <div class="client-logo">
                    <img
                      src="../../assets/New Branding Assets/yhyl-front-page-v15/yhyl-front-page-v15 Folder/Links/movers-logo-hor-blue-450x300.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <h4>MOVERS</h4>
                  <p>
                    Movers Against Domestic Violence is a registered charity dedicated to providing a
                     safe and sustainable escape for victims of domestic violence. www.movers.org.au
                  </p>
                </div>
              </div>
              <!-- <div
                class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0 half-top"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div class="icon-box shadow">
                  <div class="client-logo">
                    <img
                      src="../../assets/New Branding Assets/yhyl-front-page-v15/yhyl-front-page-v15 Folder/Links/Good_Things_Foundation_Australia_Logo.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <h4>Good Things Foundation</h4>
                  <p>
                    Good Things Foundation Australia is a social change charity,
                    helping people to improve their lives through digital.
                  </p>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End about Section -->

<app-landing-footer></app-landing-footer>
