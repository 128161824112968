<div style="height:100%;" *ngIf="visibility">
  <app-header *ngIf="visibility"></app-header>
  <app-sidenav *ngIf="visibility">
    <router-outlet></router-outlet>
  </app-sidenav>
  <app-footer></app-footer>
</div>
<app-landing-header *ngIf="islogin && isHeader"></app-landing-header>
<div class="app-content content" *ngIf="islogin">
  <router-outlet></router-outlet>
</div>

<!-- <app-footer></app-footer> -->