import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-story',
  templateUrl: './about-story.component.html',
  styleUrls: ['./about-story.component.css','./../../shared/templates.css']
})
export class AboutStoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
