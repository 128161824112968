<!-- BEGIN: Header-->
<nav
  class="header-navbar main-header navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top"
>
  <div class="navbar-wrapper row">
    <div class="navbar-header col-md-2">
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item mobile-menu mr-auto ">
          <a class="pt-0"
            ><img class="logo-height" src="./assets/static/img/main-logo.png"
          /></a>
        </li>
        <li class="nav-item d-md-none" style="line-height: 3.8;">
          <a
            class="nav-link open-navbar-container collapsed"
            data-toggle="collapse"
            data-target="#navbar-mobile"
            aria-expanded="false"
          >
            <i class="fa fa-ellipsis-v"></i>
          </a>
        </li>
      </ul>
    </div>
    <div
      class="navbar-container col-md-10 content pr-0"
      style="background:transparent"
    >
      <div class="collapse navbar-collapse" id="navbar-mobile">
        <ul class="nav navbar-nav mr-auto float-left">
          <li class="nav-item d-none d-md-block">
            <!-- <a class="nav-link nav-menu-main menu-toggle hidden-xs"
                          href="#"><i class="ft-menu"></i></a> -->
          </li>
        </ul>
        <ul class="nav navbar-nav float-right mt-5">
          <li class="nav-item">
            <a class="header-btn btn top-menu" routerLink="/home">Home</a>
          </li>
          <li class="drop-down nav-item">
            <a
              class="header-btn btn top-menu"
              routerLink="/about"
              routerLinkActive="nav-active"
              >About Us</a
            >
            <ul>
              <li>
                <a class="nav-dropdown" routerLink="/about/our-story"
                  >Our Story</a
                >
              </li>
              <li>
                <a class="nav-dropdown" routerLink="/about/our-people"
                  >Our People</a
                >
              </li>
              <li>
                <a class="nav-dropdown" routerLink="/about/our-partners"
                  >Our Partners</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="header-btn btn top-menu" routerLink="/businesses"
              >Businesses</a
            >
          </li>
          <li class="nav-item">
            <a class="header-btn btn top-menu" routerLink="/contact"
              >Contact Us</a
            >
          </li>
          <!-- <li class="nav-item">
            <a class="header-btn btn top-menu">Explore</a>
          </li> -->
          <li class="dropdown nav-item">
            <a class="btn top-menu" routerLink="/login"
              ><button type="button" class="btn btn-primary-yhyl login-btn">
                Login
              </button></a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<!-- END: Header-->
