<header id="header" class="fixed-top flexible-top">
  <div class="container d-flex align-items-center justify-content-between">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-2">
        <li class="nav-item mobile-menu mr-auto " style="display: block;">
          <a class="pt-0" routerLink="/"><img class="logo-height" src="./assets/static/img/main-logo.png" /></a>
        </li>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-10 float-right">
        <nav id="yhyl-nav" class="nav-menu">
          <ul>
            <li>
              <a class="header-btn btn top-menu" routerLink="/home" routerLinkActive="nav-active">Home</a>
            </li>
            <li class="drop-down">
              <a class="header-btn btn top-menu" routerLink="/about" routerLinkActive="nav-active">About Us</a>
              <ul>
                <li>
                  <a class="nav-dropdown" routerLink="/about/our-story">Our Story</a>
                </li>
                <li>
                  <a class="nav-dropdown" routerLink="/about/our-people">Our People</a>
                </li>
                <li>
                  <a class="nav-dropdown" routerLink="/about/our-partners">Our Partners</a>
                </li>
              </ul>
            </li>
            <li>
              <a class="header-btn btn top-menu" routerLink="/businesses" routerLinkActive="nav-active">Businesses</a>
            </li>
            <li>
              <a class="header-btn btn top-menu" routerLink="/contact" routerLinkActive="nav-active">Contact</a>
            </li>
            <!-- <li><a class="header-btn btn top-menu">Explore</a></li> -->
            <li>
              <a class="btn top-menu" href="https://app.my100yearlife.com/login" routerLinkActive="nav-active"><button
                  type="button" class="btn login-btn">
                  Login
                </button></a>
            </li>
            <!-- <li>
              <a class="d-lg-none d-md-block">box</a>
            </li> -->
          </ul>
        </nav>
      </div>
    </div>
  </div>
</header>
<!-- ======= Landing Section ======= -->
<section id="landing" style="padding-top:16rem !important;" class="align-items-center pt-0">
  <div class="landing-container container position-relative" data-aos="fade-up">
    <div class="row">
      <div class="landing-content col-xl-12 col-lg-12">
        <h1 class="landing-header">
          Ageing is a <span class="text-white">Marathon,</span><br />
          not a Sprint!
        </h1>
        <h2 class="pt-5 pb-3">
          World’s first Dynamic Life Journey Manager for Ageing!
        </h2>
        <div class="row">
          <div class="col-lg-3 col-sm-4 pl-0 pb-3">
            <input class="form-control input-lg btn-radius" style="font-size: 1.5rem;padding-left:1.75rem;" name="name"
              [(ngModel)]="name" placeholder="Your name" type="text" (focus)="enableSignmeup()" />
          </div>
          <div class="col-lg-3 col-sm-4 pl-0 pb-3">
            <input class="form-control input-lg btn-radius" style="font-size: 1.5rem;padding-left:1.75rem;" name="email"
              [(ngModel)]="email" placeholder="Your email" type="email" (focus)="enableSignmeup()" />
          </div>
          <div class="col-lg-3 col-sm-4 pl-0 pb-3">
            <button type="button" (click)="subscribe()" class="btn btn-lg btn-radius font-20 col-sm-12"
              [disabled]="isSuccessful_signmeup">
              <div class="spinner-border" role="status" *ngIf="isLoading_signmeup">
                <span class="sr-only">Loading...</span>
              </div>
              <div *ngIf="!isLoading_signmeup && !isSuccessful_signmeup">
                Sign Me Up!
              </div>
              <div *ngIf="isSuccessful_signmeup">
                <i class="fas fa-check"></i>
              </div>
            </button>
          </div>
        </div>
        <p class="text-white">
          The first 250 subscribers get 3 months free Premium access
        </p>
      </div>
    </div>
  </div>
</section>

<!-- End landing -->
<main id="main">
  <section id="services" class="services">
    <div class="container" data-aos="fade-up">
      <div class="row">
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch icon-box-top" data-aos="zoom-in" data-aos-delay="100">
          <div class="icon-box shadow">
            <div class="row">
              <div class="col-12 premium-icon pr-0" class="display:hidden"></div>
            </div>
            <div class="icon" style="top:15px">
              <img src="./assets/New Branding Assets/yhyl-gfx-items-part1/yhyl-gfx-items/icon-find-simplify.png" />
            </div>
            <h4 style="margin-top:15px">Find and Simplify</h4>
            <p>
              All the information you need in one place to manage the life of
              your loved ones. Uniquely designed to save you time and eliminate
              stress.
            </p>
            <div class="row">
              <div class="col-12 premium-icon pr-0" style="bottom:-15px">
                <img src="./assets/New Branding Assets/yhyl-gfx-items-part1/yhyl-gfx-items/icon-yhyl-gray-small.png" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0 icon-box-top" data-aos="zoom-in"
          data-aos-delay="200">
          <div class="icon-box shadow">
            <div class="row">
              <div class="col-12 premium-icon pr-0">
                <img src="./assets/New Branding Assets/yhyl-gfx-items-part1/yhyl-gfx-items/icon-premium-small.png" />
              </div>
            </div>
            <div class="row" style="justify-content: center;">
              <div class="col-12 icon" style="top:-35px">
                <img src="./assets/New Branding Assets/yhyl-gfx-items-part1/yhyl-gfx-items/icon-store-share.png" />
              </div>
              <h4 style="margin-top:-35px">&nbsp;Store and Share</h4>
              <p>
                Securely store, share and access everything you need here -
                vital documents, videos, guidance - to simplify your caring
                life.
              </p>
            </div>
            <div class="row">
              <div class="col-12 premium-icon pr-0" style="bottom:-20px">
                <img src="./assets/New Branding Assets/yhyl-gfx-items-part1/yhyl-gfx-items/icon-yhyl-gray-small.png" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0 icon-box-top" data-aos="zoom-in"
          data-aos-delay="300">
          <div class="icon-box shadow">
            <div class="row">
              <div class="col-12 premium-icon pr-0">
                <img src="./assets/New Branding Assets/yhyl-gfx-items-part1/yhyl-gfx-items/icon-premium-small.png" />
              </div>
            </div>
            <div class="icon" style="top:-35px">
              <img src="./assets/New Branding Assets/yhyl-gfx-items-part1/yhyl-gfx-items/icon-manage-care.png" />
            </div>
            <h4 style="margin-top:-35px">Manage and Care</h4>
            <p>
              Caring for a loved one means a lot to think about! We’ll make sure
              you know what to do next without overwhelming you. One day at a
              time.
            </p>

            <div class="row">
              <div class="col-12 premium-icon pr-0">
                <img src="./assets/New Branding Assets/yhyl-gfx-items-part1/yhyl-gfx-items/icon-yhyl-gray-small.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="questionnaire">
    <div class="container">
      <h1 class="pri-header font-45-title" style="padding-top:30px !important">
        You know what the problem is :
      </h1>
      <h2 class="font-italic sec-header">
        It's complicated!
      </h2>
      <div class="problem-orbit-desktop">
        <img src="./assets/New Branding Assets/yhyl-gfx-items-part1/yhyl-gfx-items/problem-orbit-complete.jpg" />
      </div>
      <div class="premium-take-control problem-orbit-mobiles p-0" data-aos="fade-up">
        <img class="orbit-img"
          src="../../assets/New Branding Assets/yhyl-gfx-items-part1/yhyl-gfx-items/problem-orbit.jpg" />
        <div class="problem-orbit">
          <ul class="grey-color poppins-med-font bold-font font-20" style="list-style: none;">
            <li>
              <i class="fas fa-circle"></i>
              Do I need more than a Power of Attorney?
            </li>
            <li>
              <i class="fas fa-circle"></i>
              Will my money last if I live to 100?
            </li>
            <li>
              <i class="fas fa-circle"></i>
              Downsize? Rightsize? Relocate?
            </li>
            <li>
              <i class="fas fa-circle"></i>
              Reliable advice on aged care?
            </li>
            <li>
              <i class="fas fa-circle"></i>
              Get medication reminders?
            </li>
            <li>
              <i class="fas fa-circle"></i>
              Support mum better?
            </li>
            <li>
              <i class="fas fa-circle"></i>
              What if I fall?
            </li>
            <li>
              <i class="fas fa-circle"></i>
              AgeWise Travel?
            </li>
            <li>
              <i class="fas fa-circle"></i>
              Better value insurance?
            </li>
            <li>
              <i class="fas fa-circle"></i>
              Get husband to doctor?
            </li>
            <li>
              <i class="fas fa-circle"></i>
              Find all senior discounts?
            </li>
            <li>
              <i class="fas fa-circle"></i>
              Protect my details online?
            </li>
            <li>
              <i class="fas fa-circle"></i>
              Persuade Dad to stop driving?
            </li>
            <li>
              <i class="fas fa-circle"></i>
              Help maintaining my garden?
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section id="simplesteps" class="simplesteps">
    <div class="iconschacosstress">
      <div class="container aos-init">
        <div class="row">
          <div class="col-lg-4 icon  " data-aos="zoom-in" data-aos-delay="100">
            <div class="imagesteps">
              <img src="./assets/New Branding Assets/yhyl-gfx-items-part1/yhyl-gfx-items/icon-chaos.png" />
            </div>
          </div>
          <div class="col-lg-4 icon  " data-aos="zoom-in" data-aos-delay="100">
            <div class="imagesteps">
              <img src="./assets/New Branding Assets/yhyl-gfx-items-part1/yhyl-gfx-items/icon-stress.png" />
            </div>
          </div>
          <div class="col-lg-4 icon " data-aos="zoom-in" data-aos-delay="100">
            <div class="imagesteps">
              <img src="./assets/New Branding Assets/yhyl-gfx-items-part1/yhyl-gfx-items/icon-worry.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="divsimplesteps">
      <h1 class="poppins-bold-font yhyl-text font-45-title pt-0">
        Just 3 Simple Steps...
      </h1>
      <h2 class="h2fingertips">
        To a World of Knowledge,Tools and Guidance at your fingertips
      </h2>
    </div>
    <section id="simpleSteps" class="simpleSteps">
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="col-lg-4 col-md-4 d-flex align-items-stretch card bg-transparent simplesteps-pd"
            data-aos="zoom-in" data-aos-delay="100">
            <div class="row">
              <div class="col-12">
                <h4 class="circle text-center">1</h4>
              </div>

              <div class="col-12 icon-box shadow">
                <div class="icon" style="margin-top:3rem">
                  <img src="./assets/New Branding Assets/yhyl-gfx-items-part1/yhyl-gfx-items/icon-signup.png" />
                </div>
                <h4 class="font-40-title">SIGN UP</h4>
                <!-- <h1 class="font-45-title yhyl-text">Sign Up</h1> -->
                <h2 class="yhyl-text sec-header">
                  Your YHYL Profile
                </h2>
                <p style="padding-bottom:4rem">
                  <!-- Use your email, Facebook or Google account -->
                  Use your email
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 d-flex align-items-stretch card bg-transparent simplesteps-pd"
            data-aos="zoom-in" data-aos-delay="100">
            <div class="row">
              <div class="col-12">
                <h4 class="circle text-center">2</h4>
              </div>

              <div class="col-12 icon-box shadow">
                <div class="icon" style="margin-top:3rem">
                  <img src="./assets/New Branding Assets/yhyl-gfx-items-part1/yhyl-gfx-items/login-secure-icon.png" />
                </div>
                <h4 class="font-40-title">LOGIN</h4>
                <!-- <h1 class="font-45-title yhyl-text">Sign Up</h1> -->
                <h2 class="yhyl-text sec-header">
                  Military Grade Security
                </h2>
                <p style="padding-bottom:4rem">
                  Anytime, anywhere on any device
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 d-flex align-items-stretch card bg-transparent simplesteps-pd"
            data-aos="zoom-in" data-aos-delay="100">
            <div class="row">
              <div class="col-12">
                <h4 class="circle text-center">3</h4>
              </div>

              <div class="col-12 icon-box shadow">
                <div class="icon" style="margin-top:3rem">
                  <img src="./assets/New Branding Assets/yhyl-gfx-items-part1/yhyl-gfx-items/simplify-icon.png" />
                </div>
                <h4 class="font-40-title">SIMPLIFY</h4>
                <!-- <h1 class="font-45-title yhyl-text">Sign Up</h1> -->
                <h2 class="yhyl-text sec-header">
                  Cut the chaos
                </h2>
                <p style="padding-bottom:4rem">
                  All the information, guidance and tools you need
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="startsteps">
      <!-- <img src="./assets/New Branding Assets/yhyl-gfx-items-part1/yhyl-gfx-items/3-steps.jpg" /> -->
      <div class="card">
        <div class="row">
          <!-- <div class="card shadow col-lg-4" style="background:aliceblue;padding:20px;">
            <div class="row">
              <div class="icon">
                <img src="./assets/New Branding Assets/yhyl-gfx-items-part1/yhyl-gfx-items/icon-manage-care.png" />
              </div>
            </div>
            <h1 class="font-45-title yhyl-text">Sign Up</h1>
            <h2 class="yhyl-text sec-header">
              Your YHYL Profile
            </h2>
            <p>
              Use your email,Facebook or Google account
            </p>
          </div> -->
        </div>
      </div>
      <h3 class="pri-steps-content">
        WE ONLY ASK FOR YOUR NAME AND EMAIL
      </h3>

      <a class="robot-med-font starthere" href="/register">
        <!-- <div class="spinner-border" role="status" *ngIf="isLoading_starthere">
          <span class="sr-only">Loading...</span>
        </div> -->
        <div>START HERE</div>
      </a>
    </div>

    <!-- <div class="divgetfree row">
      <h4 class="poppins-bold-font yhyl-text font-45-title pt-5 pb-3">
        What you always get for free!
      </h4>

      <div class="row pt-5 pb-5">
        <div class="col-lg-1 col-sm-12 "></div>
        <div class="col-lg-10 col-sm-12 getfreecontainer pb-2 pt-2">
          <div class="row  pb-5">
            <div class="hinformation left col-xs-8 col-md-6">
              <h1 class=" pt-5 pb-3">
                Your Information Centre
              </h1>
              <p>
                Everything you need to know, plan and do is here at your
                fingertips. So you can care better for your loved ones - simply
                and effectively.
              </p>
              <p>
                We are the authoritative ‘one stop-shop’ for all the practical
                information and guidance you may need to lead your best life,
                whatever your stage.
              </p>
              <div class="col-lg-6 col-sm-8 pl-0 pb-3">
                <button
                  class="btn btn-lg-m btn-radius font-20 col-sm-12 poppins-med-font"
                >
                  ALWAYS FREE
                </button>
              </div>
            </div>
            <div class="col-xs-6 col-md-2"></div>
            <div class="col-xs-8 col-md-4 imageinfocontent">
              <img
                src="./assets/New Branding Assets/yhyl-gfx-items-part2/info-centre.png"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="divgetfree row">
      <h4 class="pri-steps-content-left font-45-title pt-5 pb-3">
        What you always get for free!
      </h4>

      <div style="padding-bottom:50px;padding-top: 60px;">
        <div class="getfreecontainer container pb-2 pt-2">
          <div class="row pb-5">
            <div class="hinformation col-xs-8 col-md-6">
              <h1 class="robot-med-font pt-5 pb-3">
                Your Information Centre
              </h1>
              <p>
                Everything you need to know, plan and do is here at your
                fingertips. So you can care better for your loved ones - simply
                and effectively.
              </p>
              <p>
                We are the authoritative ‘one stop-shop’ for all the practical
                information and guidance you may need.
              </p>
              <div class="col-lg-6 col-sm-8 pl-0 pb-3">
                <button class="btn btn-lg-m btn-radius font-20 col-sm-12">
                  ALWAYS FREE
                </button>
              </div>
            </div>
            <div class="col-xs-6 col-md-2"></div>
            <div class="col-xs-8 col-md-4 imageinfocontent">
              <img src="./assets/New Branding Assets/yhyl-gfx-items-part2/info-centre.png"
                class="img-fluid imageinfo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="premium" class="premium">
    <div class="premium-background">
      <div class="premium-features container" data-aos="fade-up">
        <img class="lady-got-it" src="../../assets/New Branding Assets/yhyl-gfx-items-part2/premium-lady-got-it.png" />
        <div class="premium-features-des">
          <img src="../../assets/New Branding Assets/yhyl-gfx-items-part2/premium-features.png" />
          <h1 class="deep-blue poppins-bold-font">Start managing!</h1>
          <p class="deep-blue poppins-med-font bold-font">
            Take control! <br />
            Now you can manage the complexities of supporting ageing loved ones
            using our Premium functions.
            <br />
            Track, store, solve, monitor, coordinate, delegate, share, schedule,
            remind. Your personalised control center.
          </p>
        </div>
      </div>
      <div class="premium-take-control container" data-aos="fade-up">
        <img src="../../assets/New Branding Assets/yhyl-gfx-items-part2/premium-cat-orbit.png" />
        <div>
          <h1 class="deep-blue poppins-bold-font">
            Take Control - Manage and Care
          </h1>
          <ul class="deep-blue poppins-med-font bold-font font-20">
            <li>
              <i class="fas fa-circle"></i>An easier way to manage your caring
              life
            </li>
            <li>
              <i class="fas fa-circle"></i>Your personalised control centre
            </li>
            <li>
              <i class="fas fa-circle"></i>Everything in one place – at your
              fingertips
            </li>
            <li>
              <i class="fas fa-circle"></i>All your information, contacts,
              schedules, expenses and memories
            </li>
            <li>
              <i class="fas fa-circle"></i>Monitor, track, store, solve,
              delegate, coordinate, schedule, remind
            </li>
            <li>
              <i class="fas fa-circle"></i>Share only what you choose to share –
              with whom you choose
            </li>
            <li>
              <i class="fas fa-circle"></i>Your privacy protected and secure
            </li>
          </ul>
          <p class="deep-blue  bold-font font-20">
            <i>All you need to conquer the chaos - all in one place!</i>
          </p>
        </div>
      </div>
    </div>
    <div class="premium-signup container col-12" data-aos="fade-up">
      <div class="row">
        <h1 class="robot-med-font">
          Free access to PREMIUM for 3 months for first 250 subscribers
        </h1>
        <div class="transparent-background robot-med-font">
          <a class="robot-med-font" href="/register">Sign me up!</a>
        </div>
      </div>
    </div>
    <div class="people-say">
      <!-- ======= Testimonials Section ======= -->
      <section id="testimonials" class="testimonials pb-0">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h1 class="poppins-bold-font yhyl-text font-45-title">
              What other people are saying about YHYL
            </h1>
            <p></p>
          </div>
          <div class="col-12">
            <ngb-carousel [interval]="'10000'">
              <ng-template ngbSlide>
                <div class="row testimonials-carousel">
                  <div data-aos="flip-down" data-aos-delay="300"
                    class="col-xl-12 p-0 col-lg-12 col-sm-12 testimonial-item">
                    <p>
                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                      I’m an ageing expert and if I can’t figure out my options,
                      what hope do others have? I wish I had this when I was
                      looking after my mother.
                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <img src="../../assets/New Branding Assets/Avatars/set-social-people-media-profile-member/boy_1.jpg"
                      class="testimonial-img" alt="" />
                    <h3>Ageing Expert, Caring for father</h3>
                    <h4>64yrs old</h4>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbSlide>
                <div class="row testimonials-carousel">
                  <div data-aos="flip-down" data-aos-delay="300"
                    class="col-xl-12 p-0 col-lg-12 col-sm-12 testimonial-item">
                    <p>
                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                      This is really useful for millennials like me
                      co-ordinating childcare, work and interstate travel
                      especially with COVID chaos.
                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <img
                      src="../../assets/New Branding Assets/Avatars/set-social-people-media-profile-member/girl_1.jpg"
                      class="testimonial-img" alt="" />
                    <h3>Mother and Carer</h3>
                    <h4>35yrs old</h4>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbSlide>
                <div class="row testimonials-carousel">
                  <div data-aos="flip-down" data-aos-delay="300"
                    class="col-xl-12 p-0 col-lg-12 col-sm-12 testimonial-item">
                    <p>
                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                      This is exactly what I need to care for my parents
                      especially when my sister cannot return home. I want to
                      use this now.
                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <img
                      src="../../assets/New Branding Assets/Avatars/set-social-people-media-profile-member/girl_2.jpg"
                      class="testimonial-img" alt="" />
                    <h3>Caring for mother and father</h3>
                    <h4>47yrs old</h4>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbSlide>
                <div class="row testimonials-carousel">
                  <div data-aos="flip-down" data-aos-delay="300"
                    class="col-xl-12 p-0 col-lg-12 col-sm-12 testimonial-item">
                    <p>
                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                      Really useful for me and my clients: we don’t have to
                      spend a lot of time searching all over the internet for
                      important information. It will provide a starting point
                      for my clients and their families to discuss things they
                      need to agree on. And clients can make sure they have a
                      complete inventory of all assets – including things like
                      prepaid funerals and small share holdings
                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <img
                      src="../../assets/New Branding Assets/Avatars/set-social-people-media-profile-member/mature_man.png"
                      class="testimonial-img" alt="" />
                    <h3>Experienced Financial Adviser</h3>
                    <h4>54 yrs old</h4>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbSlide>
                <div class="row testimonials-carousel">
                  <div data-aos="flip-down" data-aos-delay="300"
                    class="col-xl-12 p-0 col-lg-12 col-sm-12 testimonial-item">
                    <p>
                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                      How come I didn’t know about that. It’s really good. How
                      come no one has known about the free health tools you make
                      people aware of?

                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <img src="../../assets/New Branding Assets/Avatars/Barrister wearing a wig.jpeg"
                      class="testimonial-img" alt="" />
                    <h3>Female Legal Professional</h3>
                  </div>
                </div>
              </ng-template>
            </ngb-carousel>
          </div>
        </div>
      </section>
      <!-- End Testimonials Section -->
    </div>
    <div class="partners">
      <!-- ======= Clients Section ======= -->
      <section id="clients" class="clients">
        <div class="container aos-init aos-animate" data-aos="fade-up">
          <h1 class="poppins-bold-font yhyl-text font-45-title">Partners</h1>

          <div class="row clearfix aos-init aos-animate" data-aos="fade-up">
            <!-- <div class="col-lg-12 col-md-12 col-12">
                                      <div class="client-logo">
                                          <img src="../../assets/New Branding Assets/With Words/With Words/Brand_Logos.png"
                                              class="img-fluid" alt="">
                                      </div>
                                  </div> -->
            <div class="col-lg-1 col-xl-1"></div>

            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4">
              <div class="client-logo">
                <img
                  src="../../assets/New Branding Assets/yhyl-front-page-v15/yhyl-front-page-v15 Folder/Links/powered by digi.me logo with white text landscape-border.png"
                  class="img-fluid" alt="" />
              </div>
            </div>

            <div class="col-xl-2 col-lg-2 col-md-4  col-sm-4">
              <div class="client-logo">
                <img src="../../assets/New Branding Assets/WithoutWords/ID.png" class="img-fluid" alt=""
                  style="padding:15px;" />
              </div>
            </div>

            <div class="col-xl-2 col-lg-2 col-md-4  col-sm-4">
              <div class="client-logo">
                <img
                  src="../../assets/New Branding Assets/yhyl-front-page-v15/yhyl-front-page-v15 Folder/Links/amazon-logo.png"
                  class="img-fluid" alt="" />
              </div>
            </div>
            <div class="col-sm-2 d-lg-none col-md-2"></div>
            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4">
              <div class="client-logo">
                <img src="../../assets/New Branding Assets/WithoutWords/UTSstartups.png" class="img-fluid" alt=""
                  style="padding:5px;" />
              </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4">
              <div class="client-logo">
                <img
                  src="../../assets/New Branding Assets/yhyl-front-page-v15/yhyl-front-page-v15 Folder/Links/shaping.png"
                  class="img-fluid" alt="" />
              </div>
            </div>
            <div class="col-lg-1 col-xl-1"></div>
            <div class="col-lg-1 col-xl-1"></div>

            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4">
              <div class="client-logo">
                <img
                  src="../../assets/New Branding Assets/yhyl-front-page-v15/yhyl-front-page-v15 Folder/Links/Full Time Lives.png"
                  class="img-fluid" alt="" />
              </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4">
              <div class="client-logo">
                <img
                  src="../../assets/New Branding Assets/yhyl-front-page-v15/yhyl-front-page-v15 Folder/Links/Waverton Hub.png"
                  class="img-fluid" alt="" />
              </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-8 col-sm-8">
              <div class="client-logo">
                <img
                  src="../../assets/New Branding Assets/yhyl-front-page-v15/yhyl-front-page-v15 Folder/Links/be_connected_logo.png"
                  class="img-fluid" alt="" />
              </div>
            </div>


            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4">
              <div class="client-logo">
                <img
                  src="../../assets/New Branding Assets/yhyl-front-page-v15/yhyl-front-page-v15 Folder/Links/movers-logo-hor-blue-450x300.png"
                  class="img-fluid" alt="" />
              </div>
            </div>
            <!-- <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4">
              <div class="client-logo">
                <img
                  src="../../assets/New Branding Assets/yhyl-front-page-v15/yhyl-front-page-v15 Folder/Links/Good_Things_Foundation_Australia_Logo.png"
                  class="img-fluid" alt="" />
              </div>
            </div> -->

            <div class="col-sm-2 d-lg-none col-md-2"></div>
            <div class="col-xl-2 col-lg-1"></div>
          </div>
        </div>
      </section>
    </div>
    <div class="business">
      <!-- ======= Team Section ======= -->
      <section id="team" class="team">
        <div class="container">
          <div class="section-title pb-0">
            <h1 class="poppins-med-fontz yhyl-text">
              Are you a business?
            </h1>
            <button class="btn btn-lg shadow deep-blue" routerLink="/businesses">
              <div>
                Click here to know more
              </div>
            </button>
          </div>
        </div>
      </section>
      <!-- End Team Section -->
    </div>
  </section>
  <div class="overlay" style="z-index: 1000;" *ngIf="isSubscribed || isError">
    <div class="popup col-lg-6 col-md-6 col-sm-6">
      <h3>
        <!-- <a class="close" (click)="isRegistered = false; isError = false" -->
        <a class="close" (click)="isSubscribed = false; isError = false">&times;</a>

        <div class="clearfix"></div>
      </h3>
      <br />
      <div class="content pb-3">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12" *ngIf="isSubscribed || isError">
                <p class="mt-4 yhyl-text">{{ message }}</p>
                <!-- <p class="my-2">
                                  Please check your email and click on the link to go to the dashboard
                              </p> -->
              </div>
              <!-- <div class="col-md-12" *ngIf="isRegistered">
                              <a class="btn mt-3" routerLink="/login"><button type="button"
                                      class="btn btn-primary-yhyl font-20">Click here to Login</button></a>
                          </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
<!-- End #main -->
<app-landing-footer></app-landing-footer>

<!-- <a class="back-to-top" (click)="onScrollToTop()"><i class="ri-arrow-up-line"></i></a> -->
<div id="preloader"></div>