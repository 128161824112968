import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-page2',
  templateUrl: './page2.component.html',
  styleUrls: ['./page2.component.css']
})
export class Page2Component implements OnInit {
  userData: any;
  showLoader = true;

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.commonService.getUserData().subscribe(
      response => {
        this.showLoader = false;
        this.userData = response;
        localStorage.setItem('userId', this.userData.pk);
      },
      err => console.error('Observer got an error: ' + err),
      () => console.log('user api request is complete')
    );
  }
}
