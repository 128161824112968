import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-favourites',
  templateUrl: './add-favourites.component.html',
  styleUrls: ['./add-favourites.component.css']
})
export class AddFavouritesComponent implements OnInit {
  isShowing = true;
  showUpdates = false;
  showUpdateToggle(){
    this.showUpdates = !this.showUpdates;
  }
  constructor() { }

  ngOnInit(): void {
  }
}
