<!-- ======= business Section ======= -->
<section id="business" class="section-title pb-5 business">
  <div class="container" data-aos="fade-up">
    <div class="section-title py-5">
      <h2 class="primary-font" style="line-height: 1.5;">
        If you’re a business, we can increase your profitability, loyalty and
        future readiness. <br />
      </h2>

      <h3 class="bold-font pt-3" style="line-height: 1.5;">
        <span class="yhyl-text">Why? Because our customers are your customers: 
          Boomers and their GenX children - very major and growing markets.</span>
      </h3>


      <h3 class="bold-font pt-3" style="line-height: 1.5;">
        <span class="yhyl-text">How?</span> Ask for a demonstration by
        contacting us at
        <span class="yhyl-text">wisdom@your100yearlife.com</span>
      </h3>
      <h3 class="pt-3" style="line-height: 1.5;">
        If you’re a business you know that you need to do more with to reach these valuable customers.
         Businesses that can increase
        their value proposition using Your 100 Year Life include: financial
        advisers, accountants, solicitors, retirement specialists,
        superannuation funds, investment advisers, trustees, suppliers to ageing
        Australians, suppliers to Baby Boomers, and more.
      </h3>
    </div>

    <div class="bg row content p-0 card">
      <div class="col-lg-12 p-0">
        <h3 class="primary-font primary-yhyl-bg p-5 text-center">
          How we can help you? In 2 ways
        </h3>
        <ul class="p-5">
          <li class="pl-0">
            <i class="bx bx-chevron-right"></i>
            <h3>Enhancing the value proposition you offer your customers</h3>
            We can help your business position itself better to capture the
            needs and wants of Australia’s wealthiest demographic. Associating
            with Your 100 Year Life, whether as a channel partner or supporter,
            expands your reach and capacity to understand and market to this
            important demographic. We are continuously on the lookout for really
            clever offerings for our customers and portal visitors.
            <br /><br />
            Many industries are built on protecting the wellbeing, financial
            health, security, and quality of life of their customers. While
            insurance and banking immediately come to mind, businesses in many
            other sectors are keen to attract and retain customers who are
            preoccupied with managing the impact of ageing – on themselves, their
            parents, and other loved ones. Our toolkit offers a unique
            opportunity to help them reduce stress and manage the complex
            ecosystem involved in ageing at home. Helping customers manage the
            complexities of ageing may also save time and costs for your business
            in its interactions with customers. If your services or products are
            aimed at the very large demographic aged 48-85+, contact us to
            discuss what we offer specifically for this very large customer
            segment.
          </li>
          <li class="pl-0">
            <i class="bx bx-chevron-right"></i>
            <h3>
              Helping you expand your customer base by tapping into Australia’s
              wealthiest demographic
            </h3>
            Did you know that experts estimate that $3.5 trillion is being
            passed from Baby Boomers to younger generations between now and
            2040? By now everyone knows that Baby Boomers and their parents are
            infinitely better off than up and coming generations. Do you have
            reputable products and services that can aid the well-being, safety
            and security, and independence of seniors? We are seeking partners
            for our wide range of services as we build our customer base as
            Australia's one shop stop to simplify and take the worry out of the
            ageing marathon.
          </li>
        </ul>
      </div>
    </div>

    <div class="bg row content p-0 card">
      <div class="col-lg-12 p-0">
        <h3 class="primary-font primary-yhyl-bg p-5 text-center">
          Here's how Your 100 Year Life can fit into your business
        </h3>
        <ul class="p-5">
          <li class="pl-0">
            <i class="bx bx-chevron-right"></i>
            <h3>
              Increasing customers, sales and loyalty by enhancing the value
              proposition you offer your customers
            </h3>
            Smart companies are always on the lookout for strategies that
            enhance their value proposition and retain and add customers in a
            competitive market. Offering our Personalised Toolkit – customised for
            your customers’ needs – is a low-cost way of demonstrating your
            commitment to the quality of life of key customer segments.
            Especially our target demographic, Gen X (45-55), seeing their
            parents age and looking for practical ways care for them while
            working and raising their own children); Baby Boomers (aged 56-75)
            worried about their 75+ (Silent Generation) parents, and at the same
            time worried about their own wellbeing and ability to finance
            retirement – not to mention helping out their millennial and Gen Z
            children. Our Premium Toolkit turns this chaos into order –
            increasing customers, sales and loyalty by making a meaningful
            difference in the quality of life of your customers.
            <br /><br />
            The respected economists at McKinsey predict that an unplanned for
            result of women’s longevity is that by 2030 much of the Boomer
            generation’s unprecedented wealth will be managed by women.
            Financial institutions and advisers are only beginning to adjust,
            and Your 100 Year Life’s toolkit is uniquely designed to assist in
            this purpose.
          </li>
          <li class="pl-0">
            <i class="bx bx-chevron-right"></i>
            <h3>Increasing employee satisfaction retention and productivity</h3>
            Your 100 Year Life uniquely helps organisations address the growing
            reality that most employees today have one or more ‘caring’ roles in
            addition to work. Most businesses are already addressing the
            individual wellbeing of employees, and parental leave and childcare.
            But increasing longevity is also putting more and more stress on
            employees who gradually assume into a caring role for ageing parents.
            <br /><br />
            You may not realise it, but a significant proportion of employees
            are worrying about and preoccupied by their parents’ slow but
            continuous decline in self-sufficiency. Using personal and sick
            leave for parental emergencies and day-to-day care isn’t a solution
            – especially when that leave has to cover children’s sickness and
            emergencies and employees’ own sick days, as well ageing parents’
            needs. Worse, these multiple ‘care’ roles often affect employees in
            middle management, whose productivity, management and leadership is
            critical – older millennials and Gen X, aged 35 through 48 and
            older. Innovative companies have identified the need to address
            parental responsibilities as a work-life balance issue. It is
            closely linked to how companies manage to attract and retain talent,
            and manage an ageing and age-diverse workforce.
            <br /><br />
            What kind of businesses fit the profile for benefitting from Your
            100 Year Life? For example : Businesses that depend heavily on
            consistent productivity from employees, especially women, aged 35-65
            and older, concerned about ‘presenteeism’ because productivity may
            be affected by the emotional and other demands of caring. We can
            also advise you on managing an ageing workforce, and an age-diverse
            workforce, making your company ready for a longterm demographic
            shift in Australia’s workforce.
          </li>
        </ul>
      </div>
    </div>
    <h2
      class="primary-font text-center yhyl-sec-color bold-font mt-5"
      style="line-height: 1.5;"
    >
      Contact us for a demonstration:
      <br />
      <span class="yhyl-text"> wisdom@your100yearlife.com</span>
    </h2>
  </div>
</section>
<!-- End business Section -->

<app-landing-footer></app-landing-footer>
