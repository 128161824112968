import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';

@Component({
  selector: 'app-information2',
  templateUrl: './information2.component.html',
  styleUrls: ['./information2.component.css']
})
export class Information2Component implements OnInit {
  isShowing = true;
  showUpdates = false;
  activeTab ='';
  activeHomeSubTab = '';
  activeHealthSubTab = '';
  showContent = true;
  showUpdateToggle() {
    this.showUpdates = !this.showUpdates;
  }
  constructor(private router: Router) {}

  ngOnInit(): void {}
  infoTab(tab, subTab) {
    this.activeTab = tab;
    this.activeHomeSubTab = subTab;
    this.activeHealthSubTab = subTab;
    this.showContent = false;
    window.scrollTo(0, 0);
  }
  infoHomeSubTab(subTab) {
    this.activeHomeSubTab = subTab;
    this.onScrollToTop();
  }
  infoHealthSubTab(subTab) {
    this.activeHealthSubTab = subTab;
    this.onScrollToTop();
  }
  navigateMenu(menu) {
    if(menu === 'health')
    this.router.navigate(['/information/myhealth']);
    else if(menu === 'care')
    this.router.navigate(['/information/mycare']);
    else if(menu === 'work')
    this.router.navigate(['/information/mywork']);
    else if(menu === 'legacy')
    this.router.navigate(['/information/mylegacy']);
    else if(menu === 'finance')
    this.router.navigate(['/information/myfinance']);
    else if(menu === 'technology')
    this.router.navigate(['/information/mytechnology']);
    else if(menu === 'security')
    this.router.navigate(['/information/mysecurity']);
    else if(menu === 'friends')
    this.router.navigate(['/information/myfriends']);
    else if(menu === 'home')
    this.router.navigate(['/information/myhome']);
  }
  onScrollToTop() {
    var subpageReading = document.querySelector<HTMLElement>('#subpageReading');
    let bridge = subpageReading;
    let body = document.body;
    let height = 0;
    do {
      height += bridge.offsetTop;
      bridge = <HTMLElement>bridge.offsetParent;
    } while (bridge !== body);
    window.scrollTo({
      top: height - 160,
      behavior: 'smooth'
    });
  }
}