<div class="content-wrapper pd-10">
  <div class="content-header row border-bottom">
    <div class="content-header-left col-md-12 col-12 mb-2 pl-0 pr-0">
      <h3 class="content-header-title font-22 bold-font">
        <i class="icon-width fas fa-users text-center mr-3"></i>My Co-pilots
      </h3>
      <div class="temp" [class.show]="showContent">
        <br />
        Authorise full or partial sharing with the people you trust to help and
        support you.
      </div>
      <ng-container *ngIf="showContent">
        Here is where you consent to give access to some or all of your
        information to others you trust. We call them your Co-Pilots. You can
        use the table below to add one or more Co-pilots, and specify precisely
        what they can access. Please give careful thought to who and what, and
        remember you can change or eliminate Co-Pilots and Access at any time.
      </ng-container>
      <a class="yhyl-text" (click)="showContent = !showContent">{{
        showContent ? "Show less" : "Show More"
        }}</a>
    </div>
  </div>
  <div class="content-body">
    <div class="row mt-3 mb-5">
      <div class="col-md-12 pl-0">
        <div class="btn btn-primary-yhyl font-20 shadow text-md-center" (click)="showAddPopup()">
          + Add a new Co-pilot
        </div>
      </div>
    </div>
    <div class="card" *ngIf="isNewCopilot">
      <div class="card-content collapse show">
        <div class="card-body">
          <div class="card-text">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4 col-sm-12">
                    <label class="yhyl-text">Enter Email Id</label>
                    <input type="text" class="form-control" placeholder="Username" required=""
                      data-validation-required-message="This username field is required" [(ngModel)]="username" />
                  </div>
                  <div class="col-md-3 col-sm-12 yhyl-text form-check-inline mr-0">
                    <input style="margin-right: 5px;" type="radio" name="access" (click)="setFullAccess()" />Full Access
                  </div>
                  <div class="col-md-3 col-sm-12 yhyl-text form-check-inline mr-0">
                    <input style="margin-right: 5px;" type="radio" name="access"
                      (click)="setRestrictedAccess()" />Restricted Access
                  </div>
                  <div class="col-md-2 col-sm-12 yhyl-text form-check-inline mr-0">
                    <button type="button" class="btn btn-primary-yhyl" (click)="saveCopilot()" data-dismiss="modal"
                      [disabled]="!username">
                      Add</button><!--  && (isFullAccess || isRestricted) -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-content collapse show">
        <div class="card-body">
          <div class="card-text">
            <table class="table table-striped table-bordered table-sm row-border hover dataTable no-footer" datatable
              [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                <tr>
                  <th class="user shadow-light">Co-pilot</th>
                  <th class="role shadow-light">Role</th>
                  <th class="rights shadow-light">Update Rights</th>
                  <th class="rights shadow-light">Delete Rights</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let copilot of copilotList">
                  <td class="user shadow-light">{{ copilot.Email }}</td>
                  <td class="role shadow-light">
                    {{ copilot.Permission_choices }}
                  </td>
                  <td class="rights shadow-light" (click)="showEditPopup(copilot.id)">
                    <i class="fas fa-edit yhyl-color"></i>
                  </td>
                  <td class="rights shadow-light" (click)="showDeletePopup(copilot.id)">
                    <i class="fa fa-trash yhyl-color"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- popup for add -->
    <div class="overlay" style="z-index: 2;" *ngIf="!isEdit && isSelectPermission">
      <div class="popup col-lg-5 col-md-6 col-sm-9">
        <h3>
          <a class="close" (click)="isSelectPermission = false">&times;</a>

          <div class="clearfix"></div>
        </h3>
        <br />
        <div class="content pb-3">
          <div *ngIf="!isSuccess && !isError">
            <div class="row">
              <div class="col-md-12">
                <div class="modal-body">
                  <h4 class="yhyl-text mb-3">
                    Provide permission to copilot to access your information.
                  </h4>
                  <!-- <div *ngFor="let permission of permissionArray">
                    <label>{{permission.label}}</label>
                    <input type="checkbox" (change)="onChange(permission.id, $event.target.checked)" class="float-right"
                      data-toggle="toggle" data-size="sm">
                    <br />
                  </div> -->
                  <table class="table table-striped table-bordered table-sm row-border hover font-sm-table">
                    <thead>
                      <tr>
                        <th class="shadow-light"></th>
                        <th class="shadow-light">View</th>
                        <th class="shadow-light">Edit</th>
                        <th class="shadow-light">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="shadow-light">Documents</td>
                        <td class="shadow-light" *ngFor="let item of docCheckList">
                          <input type="checkbox" [(ngModel)]="item.isChecked" (change)="changeSelection('documents')"
                            data-toggle="toggle" />
                        </td>
                      </tr>
                      <tr>
                        <td class="shadow-light">Diary</td>
                        <td class="shadow-light" *ngFor="let item of diaryCheckList">
                          <input type="checkbox" [(ngModel)]="item.isChecked" (change)="changeSelection('diary')"
                            data-toggle="toggle" />
                        </td>
                      </tr>
                      <!-- <tr>
                        <td class="shadow-light">Access Offers</td>
                        <td class="shadow-light" *ngFor="let item of offerCheckList">
                          <input type="checkbox" [(ngModel)]="item.isChecked" (change)="changeSelection('offers')"
                            data-toggle="toggle">
                        </td>
                      </tr> -->
                      <tr>
                        <td class="shadow-light">Emergency Services</td>
                        <td class="shadow-light" *ngFor="let item of serviceCheckList">
                          <input type="checkbox" [(ngModel)]="item.isChecked" (change)="changeSelection('services')"
                            data-toggle="toggle" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 d-flex flex-column justify-content-end"
                style="padding-right: 30px;padding-left: 30px;">
                <button type="button" class="btn btn-primary-yhyl" (click)="savePermissions()" data-dismiss="modal">
                  Save Permissions
                </button>
              </div>
            </div>
          </div>
          <div class="mb-5 mt-3 text-center alert alert-success" *ngIf="isSuccess">
            {{ message }}
          </div>
          <div class="mb-5 mt-3 text-center alert alert-danger" *ngIf="isError">
            {{ message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- popup for edit copilot-->
<div class="overlay" style="z-index: 2;" *ngIf="isEdit">
  <div class="popup modify-popup col-lg-5 col-md-6 col-sm-9">
    <h3>
      <a class="close" (click)="isEdit = false">&times;</a>

      <div class="clearfix"></div>
    </h3>
    <br />
    <div class="content pb-3">
      <div *ngIf="!isSuccess && !isError">
        <div class="row">
          <div class="col-md-6 col-sm-12 yhyl-text form-check-inline mr-0">
            <input style="margin-right: 5px;" [checked]="isFullAccess" type="radio" name="access"
              (click)="setFullAccess()" />Full Access
          </div>
          <div class="col-md-6 col-sm-12 yhyl-text form-check-inline mr-0">
            <input style="margin-right: 5px;" [checked]="isRestricted" type="radio" name="access"
              (click)="setRestrictedAccess()" />Restricted Access
          </div>
        </div>
        <div class="row" *ngIf="isEditPermission">
          <div class="col-md-12">
            <div class="modal-body" style="padding: 0rem;">
              <h4 class="yhyl-text mb-3 mt-2">
                Provide permission to copilot to access your information.
              </h4>
              <!-- <div *ngFor="let permission of permissionArray">
                <label>{{permission.label}}</label>
                <input type="checkbox" [checked]="permission.checked"
                  (change)="onChange(permission.id, $event.target.checked)" class="float-right" data-toggle="toggle"
                  data-size="sm">
                <br />
              </div> -->
              <table class="table table-striped table-bordered table-sm row-border hover font-sm-table">
                <thead>
                  <tr>
                    <th class="shadow-light"></th>
                    <th class="shadow-light">View</th>
                    <th class="shadow-light">Edit</th>
                    <th class="shadow-light">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="shadow-light">Documents</td>
                    <td class="shadow-light" *ngFor="let item of docCheckList">
                      <input type="checkbox" [checked]="item.checked" [(ngModel)]="item.isChecked"
                        (change)="changeSelection('documents')" data-toggle="toggle" />
                    </td>
                  </tr>
                  <tr>
                    <td class="shadow-light">Diary</td>
                    <td class="shadow-light" *ngFor="let item of diaryCheckList">
                      <input type="checkbox" [checked]="item.checked" [(ngModel)]="item.isChecked"
                        (change)="changeSelection('diary')" data-toggle="toggle" />
                    </td>
                  </tr>
                  <!-- <tr>
                    <td class="shadow-light">Offers</td>
                    <td class="shadow-light" *ngFor="let item of offerCheckList">
                      <input type="checkbox" [checked]="item.checked" [(ngModel)]="item.isChecked"
                        (change)="changeSelection('offers')" data-toggle="toggle">
                    </td>
                  </tr> -->
                  <tr>
                    <td class="shadow-light">Emergency Services</td>
                    <td class="shadow-light" *ngFor="let item of serviceCheckList">
                      <input type="checkbox" [(ngModel)]="item.isChecked" (change)="changeSelection('services')"
                        data-toggle="toggle" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 d-flex flex-column justify-content-end mt-3">
            <button type="button" class="btn btn-primary-yhyl float-right" (click)="editCopilot()" data-dismiss="modal"
              [disabled]="!(isFullAccess || isRestricted)">
              Edit Permission
            </button>
          </div>
        </div>
      </div>
      <div class="mb-5 mt-3 text-center alert alert-success" *ngIf="isSuccess">
        {{ message }}
      </div>
      <div class="mb-5 mt-3 text-center alert alert-danger" *ngIf="isError">
        {{ message }}
      </div>
    </div>
  </div>
</div>
<!-- popup for delete -->
<div class="overlay" style="z-index: 3;" *ngIf="isDelete">
  <div class="popup modify-popup col-lg-5 col-md-6 col-sm-6" style="width : 30%">
    <h3>
      <a class="close" (click)="isDelete = false">&times;</a>
      <div class="clearfix"></div>
    </h3>
    <br />
    <div class="content">
      <div *ngIf="!isSuccess && !isError">
        <div class="row text-center m-4 border-bottom">
          <div class="col-md-12">
            <h4 class="yhyl-text">Do you want to delete this co-pilot?</h4>
          </div>
        </div>
        <div class="row mb-5 text-center">
          <div class="col-6">
            <button style="width:100%" class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 active"
              (click)="deleteCopilot()">
              Yes
            </button>
          </div>
          <div class="col-6">
            <button style="width:100%" class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 active"
              (click)="isDelete = false">
              No
            </button>
          </div>
        </div>
      </div>
      <div class="mb-5 mt-3 text-center alert alert-success" *ngIf="isSuccess">
        {{ message }}
      </div>
      <div class="mb-5 mt-3 text-center alert alert-danger" *ngIf="isError">
        {{ message }}
      </div>
    </div>
  </div>
</div>
<!-- BEGIN: Customizer-->
<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-xl-block"
  [ngClass]="showUpdates ? 'open' : ''">
  <a class="customizer-close" href="#"><i class="ft-x font-medium-3"></i></a>
  <a class="customizer-toggle primary-yhyl-bg box-shadow-3 btn-hover" (click)="showUpdateToggle()">
    <i class="fa fa-bell h4" theme="light" tooltip="Click to see Updates" placement="left"></i><span
      class="indicator"></span>
  </a>
  <div class="customizer-content p-2">
    <h4 class="border-bottom pb-3 content-header-title font-22 bold-font">
      Hey Test..
    </h4>
    <p class="text-animation">There are 5 new updates for you!</p>
    <p>Last 3 days to renew your service for Travel</p>
    <p>2 new offers on Health Service</p>
  </div>
</div>

<div class="text-center" *ngIf="showLoader">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div class="sidenav-overlay"></div>
<div class="drag-target"></div>