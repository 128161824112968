import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import 'boxicons';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule ,HttpClientXsrfModule} from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FooterComponent } from './shared/footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule,NoopAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardModule } from './dashboard/dashboard.module';
import { MainPageComponent } from './main-page/main-page.component';
import { RegisterComponent } from './register/register.component';
import { MustMatchDirective } from './shared/must-match.directive';
import { PageComponent } from './dashboard/menu/page/page.component';
import { DocumentsComponent } from './dashboard/menu/documents/documents.component';
import { OffersComponent } from './dashboard/menu/offers/offers.component';
import { DiaryComponent } from './dashboard/menu/diary/diary.component';
import { ServicesComponent } from './dashboard/menu/services/services.component';
import { AddFavouritesComponent } from './dashboard/menu/add-favourites/add-favourites.component';
import { CoPilotsComponent } from './dashboard/menu/co-pilots/co-pilots.component';
import { HeaderComponent } from './shared/header/header.component';
import { SidenavComponent } from './shared/sidenav/sidenav.component'
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { LogoutComponent } from './logout/logout.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { DataTablesModule } from 'angular-datatables';
import { InformationComponent } from './dashboard/menu/information/information.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { LandingComponent } from './landing/landing.component';
import { AboutComponent } from './about/about.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { ContactComponent } from './contact/contact.component';
import { BusinessesComponent } from './businesses/businesses.component';
import { AboutStoryComponent } from './about/about-story/about-story.component';
import { AboutPeopleComponent } from './about/about-people/about-people.component';
import { AboutPartnersComponent } from './about/about-partners/about-partners.component';
import { AngularWeatherWidgetModule } from 'angular2-weather-widget';
import { AuthGuard } from './auth/auth.guard';
import { CommonService } from './services/common.service';
import { AuthService } from './services/auth.service';
import { LandingFooterComponent } from './shared/landing-footer/landing-footer.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { ConfirmPasswordComponent } from './confirm-password/confirm-password.component';
import { IntermediateComponent } from './intermediate/intermediate.component';
import { HomePageComponent } from './home-page/home-page.component';
import { FaqsComponent } from './faqs/faqs.component';
import { LandingHeaderComponent } from './shared/landing-header/landing-header.component';
import { Information2Component } from './dashboard/menu/information2/information2.component';
import { HealthComponent } from './dashboard/menu/information2/health/health.component';
import { HomeInfoComponent } from './dashboard/menu/information2/home-info/home-info.component';
import { WorkComponent } from './dashboard/menu/information2/work/work.component';
import { TechnologyComponent } from './dashboard/menu/information2/technology/technology.component';
import { SecurityComponent } from './dashboard/menu/information2/security/security.component';
import { CareComponent } from './dashboard/menu/information2/care/care.component';
import { FriendsComponent } from './dashboard/menu/information2/friends/friends.component';
import { LegacyComponent } from './dashboard/menu/information2/legacy/legacy.component';
import { FinanceComponent } from './dashboard/menu/information2/finance/finance.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    DashboardComponent,
    LoginComponent,
    MainPageComponent,
    RegisterComponent,
    MustMatchDirective,
    PageComponent,
    DocumentsComponent,
    InformationComponent,
    Information2Component,
    HealthComponent,
    HomeInfoComponent,
    WorkComponent,
    LegacyComponent,
    TechnologyComponent,
    FinanceComponent,
    FriendsComponent,
    SecurityComponent,
    CareComponent,
    OffersComponent,
    DiaryComponent,
    ServicesComponent,
    AddFavouritesComponent,
    CoPilotsComponent,
    HeaderComponent,
    SidenavComponent,
    LogoutComponent,
    UserProfileComponent,
    LandingComponent,
    AboutComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    ContactComponent,
    BusinessesComponent,
    AboutStoryComponent,
    AboutPeopleComponent,
    AboutPartnersComponent,
    LandingFooterComponent,
    PasswordResetComponent,
    ConfirmPasswordComponent,
    IntermediateComponent,
    HomePageComponent,
    FaqsComponent,
    LandingHeaderComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NoopAnimationsModule,
    AppRoutingModule,
    DashboardModule,
    FontAwesomeModule,
    NgbModule,
    FormsModule,
    RouterModule,
    AngularWeatherWidgetModule,
    FlexLayoutModule,
    DataTablesModule,
    TooltipModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    })
  /* HttpClientXsrfModule.withOptions({
    cookieName: 'XSRF-TOKEN',
    headerName: 'X-XSRF-TOKEN',
  }), */
  ],
  providers: [AuthGuard,
    CommonService,
    AuthService
   /*  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, */
],
  bootstrap: [AppComponent],
})
export class AppModule {}
