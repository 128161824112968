import {RouterModule, Routes} from '@angular/router';
import { NgModule } from '@angular/core';
/* const routes: Routes = [
    {
        path: 'page',
        component: PageComponent,
    }
]; */
@NgModule({
    
    exports: [RouterModule]
  })
  export class DashboardRoutingModule { }
