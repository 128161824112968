<header id="header" class="fixed-top">
  <div class="container d-flex align-items-center justify-content-between">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-2">
        <li class="nav-item mobile-menu mr-auto " style="display: block;">
          <a class="pt-0" routerLink="/"><img class="logo-height" src="./assets/static/img/main-logo.png" /></a>
        </li>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-10 float-right">
        <nav id="yhyl-nav" class="nav-menu">
          <ul>
            <li>
              <a class="header-btn btn top-menu" routerLink="/home" routerLinkActive="nav-active">Home</a>
            </li>
            <li class="drop-down">
              <a class="header-btn btn top-menu" routerLink="/about" routerLinkActive="nav-active">About Us</a>
              <ul>
                <li>
                  <a class="nav-dropdown" routerLink="/about/our-story">Our Story</a>
                </li>
                <li>
                  <a class="nav-dropdown" routerLink="/about/our-people">Our People</a>
                </li>
                <li>
                  <a class="nav-dropdown" routerLink="/about/our-partners">Our Partners</a>
                </li>
              </ul>
            </li>
            <li>
              <a class="header-btn btn top-menu" routerLink="/businesses" routerLinkActive="nav-active">Businesses</a>
            </li>
            <li>
              <a class="header-btn btn top-menu" routerLink="/contact" routerLinkActive="nav-active">Contact</a>
            </li>
            <!-- <li><a class="header-btn btn top-menu">Explore</a></li> -->
            <li>
              <a class="btn top-menu" href="https://app.my100yearlife.com/login" routerLinkActive="nav-active"><button
                  type="button" class="btn login-btn">
                  Login
                </button></a>
            </li>
            <!-- <li>
                    <a class="d-lg-none d-md-block">box</a>
                </li> -->
          </ul>
        </nav>
      </div>
    </div>
  </div>
</header>