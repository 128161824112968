<!-- <section id="contact" class="contact">
    <div class="container" data-aos="fade-up">

        <div id="bg" class="row content">
            <div class="col-lg-12 mt-5 mt-lg-0">
                <p>To reset your password, enter your email here.</p>
                <form action="forms/contact.php" method="post" role="form" class="php-email-form">
                    <div class="form-row">
                        <div class="col-md-6 form-group">
                            <input type="text" name="name" [(ngModel)]="emailId" class="form-control" id="name"
                                placeholder="Your Email" data-rule="minlen:4"
                                data-msg="Please enter at least 4 chars" />
                            <div class="validate"></div>
                        </div>
                    </div>

                    <div><button type="submit" (click)="resetPassword()">Send Reset Link</button></div>
                </form>

            </div>

        </div>

    </div>
</section> -->

<div class="content-wrapper pt-7 mob-pt">
  <div class="content-body" *ngIf="!isSuccess">
    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <div class="card card-signin my-5 shadow">
        <div class="card-body">
          <h4 class="card-title text-center yhyl-text bold-font my-4">
            Password Reset
          </h4>
          <p>To reset your password, enter your email here.</p>
          <div class="form-group input-group mb-4">
            <input
              type="text"
              name="name"
              [(ngModel)]="emailId"
              class="form-control"
              id="name"
              placeholder="Your Email"
              data-rule="minlen:4"
              data-msg="Please enter at least 4 chars"
            />
          </div>
          <div class="error-msg form-group" *ngIf="isError">
            <p>{{ error }}</p>
          </div>
          <div class="form-group ">
            <button
              class="btn btn-primary btn-block primary-yhyl-bg font-20"
              (click)="resetPassword()"
            >
              <div
                class="spinner-border"
                role="status"
                *ngIf="isLoading_sendLink"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <div *ngIf="!isLoading_sendLink && !isSuccessful_sendLink">
                Send Reset Link
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content-body" *ngIf="isSuccess">
    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <div class="card card-signin my-5 shadow">
        <div class="card-body">
          <p class="mt-4 yhyl-text">
            A reset link has been sent to your inbox.
          </p>
          <p class="my-2">
            If you did not receive any link, please click here
          </p>
          <div class="form-group mt-3">
            <button
              class="btn btn-primary btn-block primary-yhyl-bg font-20"
              (click)="reResetPassword()"
            >
              <div>
                Resend Reset Link
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
