<!-- BEGIN: Header-->
<nav class="header-navbar main-header navbar-expand-md navbar fixed-top bg-info bg-white navbar-shadow desk-header">
  <div class="navbar-wrapper">
    <div class="navbar-header">
      <ul class="nav navbar-nav flex-row">
        <!-- <li class="nav-item mobile-menu mr-auto ">
                    <a class=" pt-0"><img src="./assets/static/img/main-logo.png" style="height:60px" /></a>
                </li> -->
        <li class="nav-item mobile-menu d-md-none mr-auto">
          <a class="nav-link nav-menu-main menu-toggle hidden-xs yhyl-text" style="margin-top: 7px;"
            (click)="mobileToggle()">
            <i class="fas fa-bars fa-2x"></i>
          </a>
        </li>
        <li class="nav-item mob-display">
          <a class="navbar-brand pt-0 pb-0" href="#">
            <img class="logo-height" src="./assets/static/img/main-logo.png" />
          </a>
        </li>
        <!-- <li class="nav-item d-sm-none ">
                    <a class="navbar-brand pt-0"><img src="./assets/static/img/main-logo.png" style="height:60px" /></a>
                </li> -->
        <li class="nav-item d-md-none">
          <a class="nav-link open-navbar-container collapsed yhyl-text" data-toggle="collapse"
            data-target="#navbar-mobile" aria-expanded="false">
            <i class="fa fa-ellipsis-v"></i>
          </a>
        </li>
      </ul>
    </div>
    <div class="navbar-container content pr-0">
      <div class="collapse navbar-collapse" id="navbar-mobile">
        <ul class="nav navbar-nav mr-auto float-left">
          <li class="nav-item d-none d-md-block"></li>
        </ul>

        <ul class="nav navbar-nav float-right mt-4">
          <li class="nav-item">
            <a class="btn top-menu">
              <input class="form-control font-20 font-sm" type="text" placeholder="Search.." />
            </a>
          </li>
          <!-- <li class="nav-item">
            <a class="btn top-menu font-20 font-sm" href="#" aria-haspopup="true" aria-expanded="false">
              <i class="fa fa-question-circle font-20 spinner" theme="light" tooltip="Help" placement="bottom"></i>
            </a>
          </li> -->
          <li class="dropdown nav-item">
            <a class="btn top-menu font-20 font-sm nav-user-img" href="#" id="navbarDropdownMenuLink2"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <!-- <i class="fa fa-user font-20" theme="light" tooltip="Settings" placement="bottom"></i> -->
              <img tooltip="Settings" placement="bottom" class="profile-img"
                src="./../../../assets/New Branding Assets/yhyl-for-web-images/profile-icon.png" />
            </a>
            <div class="dropdown-menu dropdown-menu-right shadow-light" aria-labelledby="navbarDropdownMenuLink2">
              <a class="dropdown-item" *ngIf="!showSwitchAcc || !showSwitchOption" routerLink="/myprofile"
                routerLinkActive="menu-active square btn-hover"><i class="fas fa-user mr-2"></i>My Profile</a>
              <a class="dropdown-item" *ngIf="showSwitchAcc || showSwitchOption" routerLink="/selectProfile"
                routerLinkActive="menu-active square btn-hover"><i class="fas fa-exchange-alt mr-2"></i>Switch
                Accounts</a>
              <!-- <a class="dropdown-item" href="#"><i class="fas fa-cog mr-2"></i>Setting</a> -->
              <a class="dropdown-item" (click)="logout()" routerLinkActive="menu-active square btn-hover"><i
                  class="fas fa-power-off mr-2"></i>Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<!-- END: Header-->