<div class="content-wrapper pd-10 bg-white">
  <div class="content-header row border-bottom">
    <div class="content-header-left col-md-12 col-12 mb-2 pl-0 pr-0 pr-0">
      <h3 class="content-header-title font-22 bold-font">
        <i class="yhyl-text icon-width fas fa-info text-center mr-3"></i>
        <span class="yhyl-text pointer" (click)="navigateMenu('main-info')">MY INFORMATION&nbsp;&nbsp;</span>
        <i class="fa fa-angle-right grey-color" aria-hidden="true"></i>
        <i class="fas fa-1x title-icon-style fa-house-user yhyl-text"></i>
        <span class="yhyl-text pointer font-16" (click)="navigateMenu('main-care')">MY CARE&nbsp;&nbsp;</span>
      </h3>
      <div class="temp" *ngIf="isMainPage">
        <!--Find accurate, reliable and practical information on the "need to know" issues in longevity and ageing. You told us what you need: we sourced it and put it all at your fingertips!-->
        <br />
        <div class="sub-content">
          <p class="yhyl-text font-20 bold-font">Coming Soon !</p>
        </div>
      </div>
      <div class="row mt-5" *ngIf="isMainPage">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-health-resources.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">CARE AT HOME</p>
                    <p>
                      Explore and qualify for a wide range of care and other
                      support options at home.
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-mental-health.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">
                      COORDINATING CARE
                    </p>
                    <p>
                      Coordinate, keep track of and communicate with the many
                      people and services that keep your ageing loved one at home
                      - safe, well and loved.
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-medication.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">
                      FUNDING CARE AT HOME
                    </p>
                    <p>
                      Make sure you are receiving all the benefits and financial
                      help you qualify for
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card collapse show">
            <div class="card-body">
              <div class="card-text text-center">
                <div class="row">
                  <div class="col-lg-2 col-m-12 col-sm-12">
                    <img class="card-img-top img-fluid info-img"
                      src="../../../../assets/New Branding Assets/yhyl-for-web-images/card-icon-checklists.png"
                      alt="can't load" />
                  </div>
                  <div class="col-lg-10 col-m-12 col-sm-12" style="text-align:justify">
                    <p class="grey-color bold-font font-20">
                      OTHER CARE RESOURCES
                    </p>
                    <p>
                      Lots of other clever, affordable ways to support your
                      ageing loved one at home
                    </p>
                    <button type="submit"
                      class="btn btn-primary-yhyl glow mb-1 mb-sm-0 mr-0 mr-sm-1 float-right info-btn">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- BEGIN: Customizer-->
<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-xl-block"
  [ngClass]="showUpdates ? 'open' : ''">
  <a class="customizer-close" href="#"><i class="ft-x font-medium-3"></i></a>
  <a class="customizer-toggle primary-yhyl-bg box-shadow-3 btn-hover" (click)="showUpdateToggle()">
    <i class="fa fa-bell h4" theme="light" tooltip="Click to see Updates" placement="left"></i><span
      class="indicator"></span>
  </a>
  <div class="customizer-content p-2">
    <h4 class="border-bottom pb-3 content-header-title font-22 bold-font">
      Hey Test..
    </h4>
    <p class="text-animation">There are 5 new updates for you!</p>
    <p>Last 3 days to renew your service for Travel</p>
    <p>2 new offers on Health Service</p>
  </div>
</div>

<div class="sidenav-overlay"></div>
<div class="drag-target"></div>