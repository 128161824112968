import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { AfterViewInit } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isShowing = true;
  showUpdates = false;
  fileToUpload: any;
  isUploaded = false;
  isNewUpload = false;
  isEdit = false;
  /* isEditSuccess = false;
  isEditError = false; */
  noFileError = false;
  noTitleError = false;
  isSuccess = false;
  isError = false;
  isDelete = false;
  fileTitle: string;
  showLoader = true;
  fileComment: string;
  fileList: any;
  fileToEdit: any;
  file: any;
  idToEdit: any;
  progress = 0;
  message = '';
  filename: any;
  isFileEdited = false;
  upload = false;
  copilotData: any;
  isCopilot = false;
  viewDocs = false;
  editDocs = false;
  deleteDocs = false;
  isAction = false;
  actionMessage: string;

  constructor(private commonService: CommonService) {
    this.fileList = [];
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  title = 'angulardatatables';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };
    this.getTableData();
    this.copilotData = this.commonService.getCopilotPermissions();
    if (this.copilotData.permission) {
      this.isCopilot = true;
      let permission = this.copilotData.permission;

      for (let i = 0; i < permission.length; i++) {
        /* permissions */
        if (permission[i].includes('documents')) {
          if (permission[i].includes('delete')) {
            this.deleteDocs = true;
            this.editDocs = true;
            this.viewDocs = true;
          } else if (
            permission[i].includes('add') ||
            permission[i].includes('change')
          ) {
            this.editDocs = true;
            this.viewDocs = true;
            this.deleteDocs = false;
          } else if (permission[i].includes('view')) {
            this.viewDocs = true;
            this.editDocs = false;
            this.deleteDocs = false;
          }
        }
      }
    }
  }

  getTableData() {
    this.commonService.getDocumentData().subscribe(data => {
      this.showLoader = false;
      this.fileList = data;
      this.dtTrigger.next();
      // Calling the DT trigger to manually render the table
    });
  }
  showUpdateToggle() {
    this.showUpdates = !this.showUpdates;
  }
  showAddPopup() {
    this.isNewUpload = true;
    this.isUploaded = false;
    this.fileTitle = '';
    this.fileComment = '';
    this.noFileError = false;
    this.noTitleError = false;
    this.isSuccess = false;
    this.isError = false;
  }

  handleFileInput(event) {
    this.upload = true;
    if (this.isEdit) {
      this.isFileEdited = true;
      this.fileToEdit = event.target.files[0];
      this.filename = this.fileToEdit.name;
      this.isUploaded = true;
    } else {
      this.fileToUpload = event.target.files[0];
      this.isUploaded = true;
    }
    this.noFileError = false;
    setTimeout(() => {
      this.upload = false;
    }, 2000);
  }

  downloadFile(id, filename) {
    var lastIndex = filename.lastIndexOf('/');
    var name = filename.substr(lastIndex + 1, filename.length - 1);
    this.commonService.downloadFile(id, name).subscribe(
      (response: any) => {
        //On success response
        const url = URL.createObjectURL(response.body);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
      (errorResponse: any) => {
        //On unsuccessful response
      }
    );
  }

  showEditPopup(id) {
    this.idToEdit = id;
    this.fileToEdit = this.fileList.find(obj => {
      return obj.file_id == id;
    });
    this.fileTitle = this.fileToEdit.document_name;
    this.fileComment = this.fileToEdit.comments;
    var lastIndex = this.fileToEdit.document.lastIndexOf('/');
    this.filename = this.fileToEdit.document.substr(
      lastIndex + 1,
      this.fileToEdit.document.length - 1
    );
    this.isEdit = true;
    this.isError = false;
    this.isSuccess = false;
    this.noTitleError = false;
  }

  showDeletePopup(id) {
    this.idToEdit = id;
    this.isDelete = true;
    this.isSuccess = false;
    this.isError = false;
  }

  editRecord() {
    /* var lastIndex = filename.lastIndexOf('/');
    var name = filename.substr(lastIndex+1,filename.length-1);
    {{fileToEdit.document}} */

    let formData = new FormData();
    formData.append('userid', localStorage.getItem('userId'));
    if (this.isFileEdited) {
      formData.append('document', this.fileToEdit);
    }
    if (this.fileToEdit.document_name != this.fileTitle) {
      formData.append('document_name', this.fileTitle);
    } else {
      formData.append('document_name', this.fileToEdit.document_name);
    }
    if (this.fileToEdit.comments != this.fileComment) {
      formData.append('comments', this.fileComment);
    } else {
      formData.append('comments', this.fileToEdit.comments);
    }
    this.commonService.updateDocument(formData, this.idToEdit).subscribe(
      (response: any) => {
        if (response) {
          this.isFileEdited = false;
        } //On success response
        this.isSuccess = true;
        this.noTitleError = false;
        this.message = 'Your Document has been modified successfully!';
      },
      (errorResponse: any) => {
        if (errorResponse.error.document) {
          this.noFileError = true;
        }
        if (errorResponse.error.document_name) {
          this.noTitleError = true;
        }
        if (errorResponse.status === 500) {
          this.message = 'Some error occured.Please try again.';
          this.isSuccess = false;
          this.isError = true;
        }
        /* this.isError= true;
        this.message = "Some error occured.Please try again."; */
        //On unsuccessful response
      }
    );
  }

  deleteRecord() {
    this.commonService.deleteDocument(this.idToEdit).subscribe(
      (response: any) => {
        if (response) {
          this.isSuccess = true;
          this.message = 'Your document has been deleted successfully!'; //On success response
          //this.isDelete = false;
        }
      },
      (errorResponse: any) => {
        this.isError = true;
        this.isSuccess = false;
        this.message = 'Some error occured.Please try again.';
        //On unsuccessful response
      }
    );
  }

  addNewDoc() {
    this.isFileEdited = false;
    let formData = new FormData();
    formData.append('userid', localStorage.getItem('userId'));
    formData.append('document', this.fileToUpload);
    formData.append('document_name', this.fileTitle);
    formData.append('comments', this.fileComment);

    this.commonService.addNewDocument(formData).subscribe(
      (response: any) => {
        if (response) {
          this.isSuccess = true;
          this.noFileError = false;
          this.noTitleError = false;
          this.message = 'Your Document has been added successfully!';
        }
      },
      (errorResponse: any) => {
        if (errorResponse.error.document) {
          this.noFileError = true;
        }
        if (errorResponse.error.document_name) {
          this.noTitleError = true;
        }
        if (errorResponse.status === 500) {
          this.message = 'Some error occured.Please try again.';
          this.isError = true;
          this.isSuccess = false;
        }
      }
    );
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.getTableData();
    });
  }

  showAccessMessage() {
    this.isAction = true;
    this.actionMessage = this.commonService.getAccessMessage();
  }

  close() {
    this.isNewUpload = false;
    this.isEdit = false;
    this.isDelete = false;
    this.rerender();
  }
}
