<div class="content-wrapper pt-7 mob-pt">
  <div class="content-body">
    <!-- Description -->
    <div class="col-sm-9 col-md-7 col-lg-4 mx-auto">
      <div class="card card-signin my-5 shadow">
        <div class="card-body">
          <h4 class="card-title text-center yhyl-text bold-font mb-2">
            Register
          </h4>
          <form
            class="form-signin"
            name="form"
            (ngSubmit)="f.form.valid && onRegister()"
            #f="ngForm"
            [mustMatch]="['password', 'confirmPassword']"
            novalidate
          >
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fa fa-user yhyl-text"></i
                ></span>
              </div>
              <input
                type="text"
                placeholder="First Name"
                name="firstName"
                class="form-control"
                [(ngModel)]="model.firstName"
                #firstName="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && firstName.invalid }"
                required
              />
              <div
                *ngIf="f.submitted && firstName.invalid"
                class="invalid-feedback"
              >
                <div *ngIf="firstName.errors.required">
                  First Name is required
                </div>
              </div>
            </div>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fa fa-user yhyl-text"></i
                ></span>
              </div>
              <input
                type="text"
                placeholder="Lastname"
                name="lastName"
                class="form-control"
                [(ngModel)]="model.lastName"
                #lastName="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && lastName.invalid }"
                required
              />
              <div
                *ngIf="f.submitted && lastName.invalid"
                class="invalid-feedback"
              >
                <div *ngIf="lastName.errors.required">
                  Last Name is required
                </div>
              </div>
            </div>

            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fa fa-envelope yhyl-text"></i
                ></span>
              </div>
              <input
                type="text"
                placeholder="Email Address"
                name="email"
                class="form-control"
                [(ngModel)]="model.email"
                #email="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && email.invalid }"
                required
                email
              />
              <div
                *ngIf="f.submitted && email.invalid"
                class="invalid-feedback"
              >
                <div *ngIf="email.errors.required">Email is required</div>
                <div *ngIf="email.errors.email">
                  Email must be a valid email address
                </div>
              </div>
            </div>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fa fa-lock yhyl-text"></i
                ></span>
              </div>
              <input
                id="password-field"
                [type]="show ? 'text' : 'password'"
                placeholder="Password"
                name="password"
                class="form-control"
                [(ngModel)]="model.password"
                #password="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && password.invalid }"
                required
                minlength="6"
              />
              <span class="password-eye" (click)="showPassword()">
                <i
                  class="fa fa-eye yhyl-text"
                  title="Show password"
                  data-toggle="tooltip"
                  data-placement="right"
                ></i>
              </span>
              <!-- <span
                  toggle="#password-field"
                  class="fa fa-fw fa-eye field-icon toggle-password"  (click)="showPassword()"
                ></span> -->
              <div
                *ngIf="f.submitted && password.invalid"
                class="invalid-feedback"
              >
                <div *ngIf="password.errors.required">Password is required</div>
                <div *ngIf="password.errors.minlength">
                  Password must be at least 6 characters
                </div>
              </div>
            </div>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fa fa-lock yhyl-text"></i
                ></span>
              </div>
              <input
                type="password"
                placeholder="Confirm Password"
                name="confirmPassword"
                class="form-control"
                [(ngModel)]="model.confirmPassword"
                #confirmPassword="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && confirmPassword.invalid
                }"
                required
              />
              <div
                *ngIf="f.submitted && confirmPassword.invalid"
                class="invalid-feedback"
              >
                <div *ngIf="confirmPassword.errors.required">
                  Confirm Password is required
                </div>
                <div *ngIf="confirmPassword.errors.mustMatch">
                  Passwords must match
                </div>
              </div>
            </div>
            <!-- <div class="form-group input-group">
                                <div class="input-group-prepend">

                                    <span class="input-group-text"><i class="fas fa-birthday-cake yhyl-text"></i>
                                    </span>
                                </div>
                                <input name="date" class="form-control" placeholder="" type="date" required="true"
                                    id="date">
                            </div>
                            <div class="row">
                                <form>
                                    <label class="radio-inline mr-4">
                                        <input type="radio" name="gender"> Male
                                    </label>
                                    <label class="radio-inline mr-4">
                                        <input type="radio" name="gender"> Female
                                    </label>
                                    <label class="radio-inline mr-4">
                                        <input type="radio" name="gender"> Other
                                    </label>
                                </form>
                            </div> -->

            <div class="form-group ">
              <button
                class="btn btn-primary btn-block primary-yhyl-bg font-20 font-sm"
              >
                <div
                  class="spinner-border"
                  role="status"
                  *ngIf="isLoading_register"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <div *ngIf="!isLoading_register && !isSuccessful_register">
                  Register
                </div>
                <div *ngIf="isSuccessful_register">
                  <i class="fas fa-check"></i>
                </div>
              </button>
            </div>
            <p style="font-size:12px;text-align: center;">
              Your data is secured with military-grade encryption
              <br />
              powered by
              <span
                ><img
                  src="../../assets/New Branding Assets/Shell Logos/digi me.png"
                  class="img-fluid"
                  style="max-width: 17%;"
                />
              </span>
              and
              <span
                ><img
                  src="../../assets/New Branding Assets/Shell Logos/ID exchange.png"
                  class="img-fluid"
                  style="max-width: 8%;"
              /></span>
            </p>
          </form>
        </div>
      </div>
    </div>
    <div class="overlay" style="z-index: 2;" *ngIf="isRegistered || isError">
      <div class="popup col-lg-6 col-md-6 col-sm-6">
        <h3>
          <a class="close" (click)="isRegistered = false; isError = false"
            >&times;</a
          >

          <div class="clearfix"></div>
        </h3>
        <br />
        <div class="content pb-3">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12" *ngIf="isError">
                  {{ message }}
                </div>
                <div class="col-md-12" *ngIf="isRegistered">
                  <p class="mt-4 yhyl-text">
                    A verification link has been sent to your inbox.
                  </p>
                  <p class="my-2">
                    Please check your email and click on the link to go to the
                    dashboard
                  </p>
                </div>
                <!-- <div class="col-md-12" *ngIf="isRegistered">
                                    <a class="btn mt-3" routerLink="/login"><button type="button"
                                            class="btn btn-primary-yhyl font-20">Click here to Login</button></a>
                                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
