import { Component, OnInit } from '@angular/core';
import { ɵELEMENT_PROBE_PROVIDERS__POST_R3__ } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  picturePopUp = false;
  isUploaded = false;
  fileToUpload: File = null;
  userData: any;
  firstname: any;
  lastname: any;
  preferredName: any;
  email: any;
  dob: any;
  oldPwd: any = '';
  newPwd: any = '';
  confirmPwd: any = '';
  membership: any = '';
  subscription: any;
  postcode: any;
  isDelete = false;
  isDisable = false;
  profilePic: any;
  isPwdChange = false;
  url: any;
  msg = '';
  isPwdError = false;
  passwordError: any;
  isGenSuccess = false;
  isGenError = false;
  isAccntSuccess = false;
  isAccntError = false;
  showLoader = true;
  upload = false;
  message: string;
  constructor(private commonService: CommonService, private router: Router) {}
  ngOnInit(): void {
    /* this.subscription = [
			{
				key:'64d3bf62914f40dca9a99aaaf811b26d',
				value:'Free'
			},
			{
				key:'b585c81413014fceb9cd6899b0dc9187',
				value:'Premium'
			}
		]; */
    this.getUserData();
  }

  getUserData() {
    this.commonService.getUserData().subscribe(
      response => {
        this.userData = response;
        this.showLoader = false;
      },
      err => console.error('Observer got an error: ' + err),
      () => {
        this.firstname = this.userData.first_name;
        this.lastname = this.userData.last_name;
        this.preferredName = this.userData.preferred_name;
        this.email = this.userData.email;
        this.postcode = this.userData.postcode;
        this.dob = this.userData.dob;
        this.profilePic = this.userData.profilepic;
        if (
          this.userData.subscriptions[0] ===
          'b585c814-1301-4fce-b9cd-6899b0dc9187'
        ) {
          this.subscription = 'Premium';
        } else {
          this.subscription = 'Free';
        }
      }
    );
  }
  changeSubscription() {
    let formData = new FormData();
    formData.append('pk', localStorage.getItem('userId'));
    if (this.subscription === 'Free') {
      formData.append('subscriptions', 'premium');
    } else if (this.subscription === 'Premium') {
      formData.append('subscriptions', 'free');
    }
    const data = {
      pk: localStorage.getItem('userId'),
      subscriptions: 'free'
    };
    this.commonService.updateUserData(data).subscribe(
      (response: any) => {
        if (response) {
          this.getUserData();
        }
      },
      (errorResponse: any) => {}
    );
  }
  navigate() {
    this.isGenSuccess = false;
    this.isGenError = false;
    this.isAccntError = false;
    this.isAccntSuccess = false;
    this.isPwdError = false;
  }
  showDisablePopup() {
    this.isDisable = true;
    this.isDelete = false;
  }

  showDeletePopup() {
    this.isDisable = false;
    this.isDelete = true;
  }
  deleteProfile() {
    this.commonService.deleteAccount().subscribe(
      response => {
        this.isDelete = false;
        localStorage.removeItem('currentUser');
        localStorage.removeItem('bearer');
        localStorage.removeItem('userId');
        localStorage.removeItem('temp');
      },
      err => console.error('Observer got an error: ' + err),
      () => {
        this.router.navigate(['/home']);
      }
    );
  }
  showPwdTemplate() {
    this.isPwdError = false;
    this.isPwdChange = !this.isPwdChange;
    this.isAccntSuccess = false;
    this.isAccntError = false;
  }
  disableProfile() {
    this.commonService.disableAccount().subscribe(
      response => {
        this.isDelete = false;
        localStorage.removeItem('currentUser');
        localStorage.removeItem('bearer');
        localStorage.removeItem('userId');
        localStorage.removeItem('temp');
      },
      err => console.error('Observer got an error: ' + err),
      () => {
        this.router.navigate(['/home']);
      }
    );
  }
  cancelChanges() {
    this.profilePic = this.userData.profilepic;
  }
  updateAccountDetails() {
    let formData = new FormData();
    formData.append('pk', localStorage.getItem('userId'));

    formData.append('dob', this.dob);
    formData.append('email', this.email);
    formData.append('postcode', this.postcode);
    this.commonService.updateUserData(formData).subscribe(
      (response: any) => {
        if (response) {
          if (this.isPwdChange) {
            if (this.isPwdError) {
              this.isAccntSuccess = false;
              this.isAccntError = true;
              this.message = 'Something went wrong.';
            } else {
              this.isAccntSuccess = true;
              this.isAccntError = false;
              this.message = 'Your changes have been updated Successfully!';
            }
          } else {
            this.isAccntSuccess = true;
            this.isAccntError = false;
            this.message = 'Your changes have been updated Successfully!';
          }
          this.getUserData();
        }
      },
      (errorResponse: any) => {
        this.isAccntSuccess = false;
        this.isAccntError = true;

        this.message = 'Some error occured.Please try again.';
      }
    );

    if (this.isPwdChange) {
      const data = {
        old_password: this.oldPwd,
        new_password1: this.newPwd,
        new_password2: this.confirmPwd
      };
      this.commonService.updatePassword(data).subscribe(
        (response: any) => {
          if (response) {
            this.isPwdError = false;
          }
        },
        (errorResponse: any) => {
          this.isPwdError = true;
          this.passwordError = errorResponse.error;
          if (errorResponse.error.old_password) {
          }
          if (errorResponse.error.new_password2) {
          }
        }
      );
    }
  }

  updateGeneralDetails() {
    let formData = new FormData();
    formData.append('pk', localStorage.getItem('userId'));
    if (this.isUploaded) {
      formData.append('profilepic', this.fileToUpload);
    }
    formData.append('first_name', this.firstname);
    formData.append('last_name', this.lastname);
    formData.append('preferred_name', this.preferredName);
    this.commonService.updateUserData(formData).subscribe(
      (response: any) => {
        if (response) {
          this.isGenSuccess = true;
          this.isGenError = false;
          this.message = 'Your changes have been updated Successfully!';
          this.getUserData();
        }
      },
      (errorResponse: any) => {
        this.isGenSuccess = false;
        this.isGenError = true;
        this.message = 'Some error occured.Please try again.';
      }
    );
  }
  changepicture() {
    this.picturePopUp = true;
  }
  selectFile(event) {
    this.upload = true;
    this.fileToUpload = event.target.files[0];
    this.profilePic = this.fileToUpload;
    this.isUploaded = true;
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      this.msg = 'You must select an image';
      return;
    }

    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      this.msg = 'Only images are supported';
      return;
    }

    setTimeout(() => {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      this.upload = false;
      this.picturePopUp = false;
      reader.onload = _event => {
        this.profilePic = reader.result;
      };
      //this.profilePic = this.fileToUpload.name;
    }, 2000);
  }
}
