<!-- BEGIN:sidenav-->

<div class="main-menu menu-fixed menu-light menu-collapsible menu-shadow desk-top" data-scroll-to-active="true"
  [ngClass]="isShowing ? 'sidebar-open' : 'sidebar-close'">
  <div class="main-menu-content">
    <ul>
      <li class="desk-display" *ngIf='isShowing'>
        <a class="navbar-brand desk-padding pt-0 pb-0" href="#">
          <img class="logo-height" src="./assets/static/img/main-logo.png" />
        </a>
      </li>
      <li class="desk-display" *ngIf='!isShowing'>
        <a class="navbar-brand icon-img pb-0" href="#">
          <img class="icon-logo-height" src="../../../assets/New Branding Assets/Hi Res Logo/Icon Only/06.png" />
        </a>
      </li>
      <li>
        <a id="sidebarCollapse" class="nav-link menu-class pl-4 pb-3 yhyl-text"
          style="height: 25px !important;padding-bottom:0.9em !important;" (click)="toggle()">
          <i style="padding-left:7px" class="fas fa-bars"></i>
        </a>
      </li>
      <li>
        <a class="nav-link menu-class" (click)="activeMenu('page')" routerLink="/page"
          routerLinkActive="menu-active btn-hover">
          <span [ngClass]="isPageActive ? 'highlight' : ''">
            <img class="menu-icon-width" *ngIf="!isPageActive"
              src="../../../../assets/New Branding Assets/yhyl-for-web-images/menu-icon-my-page.png">
            <img class="menu-icon-width" *ngIf="isPageActive"
              src="../../../../assets/New Branding Assets/yhyl-for-web-images/menu-icon-my-page-active.png">
            <span class="pl-3" tooltip="My Page" placement="right" theme="light" *ngIf="isShowing">
              My Page</span>
          </span>
          <!-- <i class="icon-width fas fa-desktop text-center" tooltip="My Page" placement="right" theme="light"></i> -->

        </a>
      </li>
      <li>
        <a class="nav-link menu-class" (click)="activeMenu('information')" routerLink="/information"
          routerLinkActive="menu-active btn-hover">
          <span [ngClass]="isInfoActive ? 'highlight' : ''">
            <img class="menu-icon-width" *ngIf="!isInfoActive"
              src="../../../../assets/New Branding Assets/yhyl-for-web-images/menu-icon-my-info.png">
            <img class="menu-icon-width" *ngIf="isInfoActive"
              src="../../../../assets/New Branding Assets/yhyl-for-web-images/menu-icon-my-info-active.png">
            <span class="pl-3" tooltip="My Information" placement="right" theme="light" *ngIf="isShowing">
              My Information Hub</span></span>
        </a>
      </li>
      <hr>
      <li *ngIf="!disableDocs">
        <a class="nav-link menu-class" (click)="activeMenu('document')" routerLink="/documents"
          routerLinkActive="menu-active btn-hover">
          <span [ngClass]="isDocActive ? 'highlight pt-0' : ''">
            <img class="menu-icon-width" *ngIf="!isDocActive"
              src="../../../../assets/New Branding Assets/yhyl-for-web-images/menu-icon-important.png">
            <img class="menu-icon-width" *ngIf="isDocActive"
              src="../../../../assets/New Branding Assets/yhyl-for-web-images/menu-icon-important-active.png">
            <span class="pl-3" tooltip="My Important Things" placement="right" theme="light" *ngIf="isShowing">
              My Important Things</span>
          </span>
        </a>
      </li>
      <li *ngIf="disableDocs">
        <a class="nav-link menu-class disable" (click)="showAccessMessage()">
          <!--  [disabled]='disableDocs' -->
          <!-- <i class="icon-width fas fa-file text-center" tooltip="My Important Things" placement="right"
            theme="light"></i> -->
          <img class="menu-icon-width"
            src="../../../../assets/New Branding Assets/yhyl-for-web-images/menu-icon-important-inactive.png">
          <span class="pl-3" tooltip="My Important Things" placement="right" theme="light" *ngIf="isShowing">
            My Important Things</span>
        </a>
      </li>
      <li *ngIf="!disableDiary">
        <a class="nav-link menu-class" routerLink="/diary" (click)="activeMenu('diary')"
          routerLinkActive="menu-active btn-hover">
          <span [ngClass]="isDiaryActive ? 'highlight pt-1' : ''">
            <img class="menu-icon-width" *ngIf="!isDiaryActive"
              src="../../../../assets/New Branding Assets/yhyl-for-web-images/menu-icon-diary.png">
            <img class="menu-icon-width" *ngIf="isDiaryActive"
              src="../../../../assets/New Branding Assets/yhyl-for-web-images/menu-icon-diary-active.png">
            <span class="pl-3" tooltip="My Diary" placement="right" theme="light" *ngIf="isShowing">
              My Diary</span>
          </span>
        </a>
      </li>
      <li *ngIf="disableDiary">
        <a class="nav-link menu-class disable" (click)="showAccessMessage()">
          <!-- <i class="icon-width fas fa-book text-center" tooltip="My Diary" placement="right" theme="light"></i> -->
          <img class="menu-icon-width"
            src="../../../../assets/New Branding Assets/yhyl-for-web-images/menu-icon-diary-inactive.png">
          <span class="pl-3" tooltip="My Diary" placement="right" theme="light" *ngIf="isShowing">
            My Diary</span>
        </a>
      </li>
      <li *ngIf="!disableServices">
        <a class="nav-link menu-class" (click)="activeMenu('service')" routerLink="/services"
          routerLinkActive="menu-active btn-hover">
          <span [ngClass]="isServiceActive ? 'highlight pt-1' : ''">
            <img class="menu-icon-width" *ngIf="!isServiceActive"
              src="../../../../assets/New Branding Assets/yhyl-for-web-images/menu-icon-services.png">
            <img class="menu-icon-width" *ngIf="isServiceActive"
              src="../../../../assets/New Branding Assets/yhyl-for-web-images/menu-icon-services-active.png">
            <span class="pl-3" tooltip="My Services" placement="right" theme="light" *ngIf="isShowing">
              My Services</span>
          </span>
        </a>
      </li>
      <li *ngIf="disableServices">
        <a class="nav-link menu-class disable" (click)="showAccessMessage()">
          <!--  <i class="icon-width fas fa-wrench text-center" tooltip="My Services" placement="right"
            theme="light"></i> -->
          <img class="menu-icon-width"
            src="../../../../assets/New Branding Assets/yhyl-for-web-images/menu-icon-services-inactive.png">
          <span class="pl-3" tooltip="My Services" placement="right" theme="light" *ngIf="isShowing">
            My Services</span>
        </a>
      </li>
      <li *ngIf="!disableCopilots">
        <a class="nav-link menu-class" (click)="activeMenu('copilot')" routerLink="/copilots"
          routerLinkActive="menu-active btn-hover">
          <span [ngClass]="isCopilotActive ? 'highlight' : ''">
            <img class="menu-icon-width" *ngIf="!isCopilotActive"
              src="../../../../assets/New Branding Assets/yhyl-for-web-images/menu-icon-co-pilots.png">
            <img class="menu-icon-width" *ngIf="isCopilotActive"
              src="../../../../assets/New Branding Assets/yhyl-for-web-images/menu-icon-co-pilots-active.png">
            <span class="pl-3" tooltip="My Co-pilots" placement="right" theme="light" *ngIf="isShowing">
              My Co-pilots</span>
          </span>
        </a>
      </li>
      <li *ngIf="disableCopilots">
        <a class="nav-link menu-class disable" (click)="showAccessMessage()">
          <!-- <i class="icon-width fas fa-users text-center" tooltip="My Co-pilots" placement="right"
            theme="light"></i> -->
          <img class="menu-icon-width"
            src="../../../../assets/New Branding Assets/yhyl-for-web-images/menu-icon-co-pilots-inactive.png">
          <span class="pl-3" tooltip="My Co-pilots" placement="right" theme="light" *ngIf="isShowing">
            My Co-pilots</span>
        </a>
      </li>
      <!-- <li>
        <a class="nav-link menu-class" routerLink="/offers" routerLinkActive="menu-active btn-hover"
          (click)="routeToMenu('/offers')">
          <i class="icon-width fas fa-tags text-center" tooltip="Coming soon" placement="right" theme="light"
            disabled="disabled"></i><span class="pl-3" tooltip="Coming soon" placement="right" theme="light"
            *ngIf="isShowing">
            Coming soon</span></a>
      </li> -->
      <li *ngIf="!showSwitchAcc">
        <a class="nav-link menu-class float-right setting-radius" routerLink="/myprofile"
          routerLinkActive="menu-active btn-hover" (click)="routeToMenu('/myprofile')">
          <i class="icon-width fas fa-cog spinner" theme="light" tooltip="Settings" placement="right"></i><span
            class="pl-3" *ngIf="isShowing"></span></a>
      </li>
      <li *ngIf="showSwitchAcc">
        <a class="nav-link menu-class" routerLink="/selectProfile" routerLinkActive="menu-active btn-hover"
          (click)="routeToMenu('/selectProfile')">
          <i class="icon-width fas fa-exchange-alt text-center" tooltip="Switch User Account" placement="right"
            theme="light" disabled="disabled"></i><span class="pl-3" tooltip="Switch User Account" placement="right"
            theme="light" *ngIf="isShowing">
            Switch Account</span></a>
      </li>
    </ul>
  </div>
</div>
<!-- popup for access message -->
<div class="overlay" style="z-index: 2;" *ngIf="isAction">
  <div class="popup col-lg-5 col-md-6 col-sm-6">
    <h3>
      <a class="close" (click)="isAction = false">&times;</a>
      <div class="clearfix"></div>
    </h3>
    <br />
    <div class="content">
      <div class="mb-5 mt-3 text-center alert alert-danger">
        {{ actionMessage }}
      </div>
    </div>
  </div>
</div>
<!-- END:sidenav-->
<!-- BEGIN: Content-->
<div class="app-content content" [ngClass]="isShowing ? 'app-expanded' : 'app-collapsed'">
  <router-outlet></router-outlet>
</div>
<!-- END: Content-->