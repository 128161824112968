import { Component, OnInit } from '@angular/core';
import { ViewChild, TemplateRef } from '@angular/core';
import { startOfDay, endOfDay, isSameDay, isSameMonth } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
  CalendarEventTitleFormatter
} from 'angular-calendar';
import { CommonService } from 'src/app/services/common.service';
import { CustomEventTitleFormatter } from '../../../shared/custom-event-title-formatter.provider';

const colors: any = {
  red: {
    primary: '#EF717A',
    secondary: '#EF717A'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};

@Component({
  selector: 'app-diary',
  templateUrl: './diary.component.html',
  styleUrls: ['./diary.component.css'],
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter
    }
  ]
})
export class DiaryComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  daySelected = false;
  popup = false;
  isShowing = true;
  isDelete = false;
  isEdit = false;
  showUpdates = false;
  eventData: any;
  idToEdit: string | number;
  temparr: any[];
  /* for adding an event */
  eventTitle: string;
  eventDescription: string;
  eventStartDate: Date;
  eventEndDate: Date;
  events: CalendarEvent[];
  /* flags for today,previous,next */
  isPrevious = false;
  isToday = true;
  isNext = false;
  viewSelected: String;
  eventEmail: any;
  eventAction: string;
  isSuccess = false;
  isError = false;
  mailError = false;
  titleError = false;
  descError = false;
  message: any;
  showLoader = true;
  copilotData: any;
  isCopilot = false;
  viewEvents = false;
  editEvents = false;
  deleteEvents = false;
  isAction = false;
  actionMessage: string;

  showUpdateToggle() {
    this.showUpdates = !this.showUpdates;
  }

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  ngOnInit(): void {
    this.commonService.getEventData().subscribe(data => {
      this.showLoader = false;
      this.eventData = data;
      this.setEvents(this.eventData);
    });
    this.copilotData = this.commonService.getCopilotPermissions();
    console.log(this.copilotData);
    if (this.copilotData.permission) {
      this.isCopilot = true;
      let permission = this.copilotData.permission;

      for (let i = 0; i < permission.length; i++) {
        /* permissions */
        if (permission[i].includes('cal')) {
          if (permission[i].includes('delete')) {
            this.deleteEvents = true;
            this.viewEvents = true;
            this.editEvents = true;
          } else if (
            permission[i].includes('add') ||
            permission[i].includes('change')
          ) {
            this.deleteEvents = false;
            this.viewEvents = true;
            this.editEvents = true;
          } else if (permission[i].includes('view')) {
            this.deleteEvents = false;
            this.viewEvents = true;
            this.editEvents = false;
          }
        }
      }
    }
    this.setActions();
  }

  setEvents(eventData) {
    this.events = [];
    for (var i = 0; i < eventData.length; i++) {
      let obj = {
        id: eventData[i].id,
        start: new Date(eventData[i].starttime),
        end: new Date(eventData[i].endtime),
        title: eventData[i].title,
        color: colors.red,
        //cssClass: 'my-custom-class',
        actions:
          /* {
					label: `${this.eventData.sta}`,
					
					onClick: ({
						event
					}: {
						event: CalendarEvent
					}): void => {
						this.handleEvent('Edited', event);
					},
				}, */
          this.actions,
        allDay: true,
        description: this.eventData[i].description,
        email: this.eventData[i].email,
        resizable: {
          beforeStart: true,
          afterEnd: true
        },
        draggable: true
      };
      this.events = [...this.events, obj];
    }
  }

  viewSelectedfn(e) {
    if (e.toElement.innerHTML === ' Previous ') {
      this.isPrevious = true;
      this.isToday = false;
      this.isNext = false;
    } else if (e.toElement.innerHTML === ' Today ') {
      this.isPrevious = false;
      this.isToday = true;
      this.isNext = false;
    } else if (e.toElement.innerHTML === ' Next ') {
      this.isPrevious = false;
      this.isToday = false;
      this.isNext = true;
    }
  }
  actions: CalendarEventAction[];
  setActions() {
    if (!this.isCopilot || this.deleteEvents) {
      this.actions = [
        {
          label: `<i class="fas fa-fw fa-pencil-alt text-white mr-3"></i>`,
          a11yLabel:
            'Edit' /* menu1 ? 'class1' : '',  menu2 ? 'class1' : '' ,'disabled': (editEvents === false && deleteEvents === false)*/,
          onClick: ({ event }: { event: CalendarEvent }): void => {
            this.handleEvent('Edited', event);
          }
        },
        {
          label: `<i class="fas fa-fw fa-trash-alt text-white"></i>`,
          a11yLabel: 'Delete',
          onClick: ({ event }: { event: CalendarEvent }): void => {
            //	this.events = this.events.filter((iEvent) => iEvent !== event);
            this.handleEvent('Deleted', event);
          }
        }
      ];
    } else if (this.isCopilot) {
      if (this.editEvents && this.viewEvents) {
        this.actions = [
          {
            label: `<i class="fas fa-fw fa-pencil-alt text-white mr-3"></i>`,
            a11yLabel: 'Edit',
            onClick: ({ event }: { event: CalendarEvent }): void => {
              this.handleEvent('Edited', event);
            }
          },
          {
            label: `<i class="fas fa-fw fa-trash-alt text-white disabled"></i>`,
            a11yLabel: 'Delete',
            onClick: ({ event }: { event: CalendarEvent }): void => {
              //	this.events = this.events.filter((iEvent) => iEvent !== event);
              this.showAccessMessage();
            }
          }
        ];
      } else if (this.viewEvents && !this.editEvents) {
        this.actions = [
          {
            label: `<i class="fas fa-fw fa-pencil-alt text-white mr-3 disabled"></i>`,
            a11yLabel: 'Edit',
            onClick: ({ event }: { event: CalendarEvent }): void => {
              this.showAccessMessage();
            }
          },
          {
            label: `<i class="fas fa-fw fa-trash-alt text-white disabled"></i>`,
            a11yLabel: 'Delete',
            onClick: ({ event }: { event: CalendarEvent }): void => {
              //	this.events = this.events.filter((iEvent) => iEvent !== event);
              this.showAccessMessage();
            }
          }
        ];
      }
    }
  }

  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = true;

  constructor(private modal: NgbModal, private commonService: CommonService) {}

  showAccessMessage() {
    this.isAction = true;
    this.actionMessage = this.commonService.getAccessMessage();
  }
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }
  showAddPopup(): void {
    this.isSuccess = false;
    this.isError = false;
    this.eventStartDate = startOfDay(new Date());
    this.eventEndDate = endOfDay(new Date());
    this.eventAction = 'Add';
    this.eventTitle = null;
    this.eventDescription = null;
    this.eventEmail = null;
    this.daySelected = true;
    this.mailError = false;
    this.titleError = false;
    this.descError = false;
  }
  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    /* if(action != "Deleted"){
      this.daySelected = true;
    } */
    if (action === 'Deleted') {
      this.isDelete = true;
      this.isSuccess = false;
      this.isError = false;
      this.idToEdit = event.id;
    }
    this.eventAction = action;

    if (action === 'Edited') {
      this.isEdit = true;
      this.isSuccess = false;
      this.isError = false;

      this.eventTitle = event.title;
      //   this.eventDescription = event.description;
      this.eventStartDate = event.start;
      this.eventEndDate = event.end;
      this.titleError = false;
      this.descError = false;
      //   this.eventEmail = event.email;
      this.idToEdit = event.id;
    }
    /* this.modalData = {event,action};
		this.modal.open(this.modalContent, {size: 'lg'}); */
  }

  addEvent() {
    if (this.eventTitle === '' || this.eventTitle === null) {
      this.titleError = true;
    } else {
      this.titleError = false;
    }
    if (this.eventDescription === '' || this.eventDescription === null) {
      this.descError = true;
    } else {
      this.descError = false;
    }
    // if(this.eventEmail === '' || this.eventEmail === null) {
    // 	this.mailError = true;
    // } else {
    // 	this.mailError = false;
    // }
    if (!this.titleError && !this.descError) {
      const data = {
        title: this.eventTitle,
        description: this.eventDescription,
        starttime: this.eventStartDate.toISOString(),
        endtime: this.eventEndDate.toISOString(),
        email: this.eventEmail,
        userid: localStorage.getItem('userId')
      };
      this.commonService.setEventData(data).subscribe(
        (response: any) => {
          this.commonService.getEventData().subscribe(data => {
            this.eventData = data;
            this.setEvents(this.eventData);
          });
          this.isSuccess = true;
          this.isError = false;
          this.message = 'Event has been added successfully!';
          //this.daySelected = false;
        },
        (errorResponse: any) => {
          if (errorResponse.status === 500) {
            this.message = 'Some error occured.Please try again.';
            this.isSuccess = false;
            this.isError = true;
          }
        }
      );
    }
  }

  editEvent() {
    if (
      this.eventTitle === '' ||
      this.eventTitle === null ||
      this.eventTitle === undefined
    ) {
      this.titleError = true;
    } else {
      this.titleError = false;
    }
    if (
      this.eventDescription === '' ||
      this.eventDescription === null ||
      this.eventDescription === undefined
    ) {
      this.descError = true;
    } else {
      this.descError = false;
    }
    // if (
    //   this.eventEmail === '' ||
    //   this.eventEmail === null ||
    //   this.eventEmail === undefined
    // ) {
    //   this.mailError = true;
    // } else {
    //   this.mailError = false;
    // }
    if (!this.titleError && !this.descError) {
      const data = {
        title: this.eventTitle,
        description: this.eventDescription,
        starttime: this.eventStartDate.toISOString(),
        endtime: this.eventEndDate.toISOString(),
        email: this.eventEmail,
        userid: localStorage.getItem('userId')
      };
      this.commonService.editEventData(data, this.idToEdit).subscribe(
        (response: any) => {
          this.commonService.getEventData().subscribe(data => {
            this.eventData = data;
            this.setEvents(this.eventData);
            this.refresh.next();
          });
          this.isSuccess = true;
          this.isError = false;
          this.message = 'Event has been modified successfully';
          this.daySelected = false;
        },
        (errorResponse: any) => {
          if (errorResponse.status === 500) {
            this.message = 'Some error occured.Please try again.';
            this.isSuccess = false;
            this.isError = true;
          }
        }
      );
    }
  }
  /* deleteEvent(eventToDelete: CalendarEvent) {
		this.events = this.events.filter((event) => event !== eventToDelete);
	} */
  deleteEvent() {
    this.commonService.deleteEventData(this.idToEdit).subscribe(
      (response: any) => {
        this.commonService.getEventData().subscribe(data => {
          this.eventData = data;
          this.setEvents(this.eventData);
          this.refresh.next();
        });
        this.isSuccess = true;
        this.isError = false;
        this.message = 'Event has been deleted successfully';
        this.daySelected = false;
      },
      (errorResponse: any) => {
        this.isSuccess = true;
        this.isError = true;
        this.message = 'Some error occured.Please try again.';
      }
    );
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
}
