import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit {
  isShowing = true;
  showUpdates = false;
  offerList :any;
  filterList :any;
  filterData : string;
  showContent = false;
  isNull = false;
  showLoader = true;
  copilotData: any;
  isCopilot = false;
  viewOffers = false;
  editOffers = false;
  constructor(private commonService: CommonService) {
    this.filterList = [];
    this.offerList = [];
   }

  ngOnInit(): void {
    this.commonService.getOffersData()
      .subscribe((data) =>  {
        this.showLoader = false;
        this.offerList = data;
        this.filterList = this.offerList;
        if(this.filterList.length === 0){
          this.isNull = true;
        } else {
          this.isNull = false;
        }
      });

     /*  this.copilotData = this.commonService.getCopilotPermissions();
    if(this.copilotData) {
      this.isCopilot = true;
    }
    let permission = this.copilotData.permission;
    
    if(this.copilotData) {
      for(let i=0;i<permission.length;i++){
       
        if(permission[i].includes('add') || permission[i].includes('change') || permission[i].includes('delete')){
          this.editOffers = true;
        } else if(permission[i].includes('view')){
          this.viewOffers = true;
        }
      }
    } */
  }

  showUpdateToggle(){
    this.showUpdates = !this.showUpdates;
  }
  
  filterOffers(value){
    this.filterList = [];
    for(let i=0; i < this.offerList.length ;i++){
      let zipcode = this.offerList[i].Zipcode;
      if(zipcode.toString().includes(value)){
      
        this.filterList.push(this.offerList[i]);
      }
    }
  }
  
}
