<div class="content-wrapper pd-10 bg-white">
    <div class="content-header row">
        <div class="content-header-left col-md-12 col-12 mb-2 pl-0 pr-0 pr-0">
            <h3 class="content-header-title font-22 bold-font">
                <i class="yhyl-text icon-width fas fa-info text-center mr-3"></i><span class="yhyl-text">MY INFORMATION
                </span>
            </h3>
            <div class="temp" [class.show]="showContent">
                <!--Find accurate, reliable and practical information on the "need to know" issues in longevity and ageing. You told us what you need: we sourced it and put it all at your fingertips!-->
                <br />
                <div class="sub-content">
                    <b class="font-20">All you need to know, plan and do is here at your fingertips. So
                        you can care better for your loved ones - simply and effectively.</b>
                    <!--Everything you need to know, plan and do to care better for your ageing loved ones is right here! Accurate, reliable information and guidance at your fingertips.-->
                    <br />
                    <br />
                    <p class="grey-color">
                        We help you manage your complex and shifting responsibilities by
                        finding, checking and supplying the information, services and
                        technology that enable you to stay on top of all it. No need to
                        scour the web and jump between apps. We make sure you have 'Uncommon
                        Knowledge'.It's simple: Just click on the box that you want to know
                        more about. For example, click on My Health and you'll see a few of
                        the places we've identified for 100% trustworthy advice and help.
                    </p>
                    <b class="font-18 yhyl-text">It’s all here for you in one place. </b>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="card collapse show">
                        <div class="card-body">
                            <div class="card-text text-center pointer" (click)="navigateMenu('care')">
                                <img class="card-img-top img-fluid" style="width:50%"
                                    src="../../../../assets/New Branding Assets/yhyl-for-web-images/lead-icon-my-care.png"
                                    alt="Card image cap" />
                                <p class="yhyl-text mb-1 mt-2 bold-font">MY CARE</p>
                                <span class="grey-color" style="font-size:12px">Care and support options</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="card collapse show">
                        <div class="card-body">
                            <div class="card-text text-center pointer" (click)="navigateMenu('health')">
                                <img class="card-img-top img-fluid" style="width:50%"
                                    src="../../../../assets/New Branding Assets/yhyl-for-web-images/lead-icon-my-health.png"
                                    alt="Card image cap" />
                                <p class="pink-color mb-1 mt-2 bold-font">MY HEALTH</p>
                                <span class="grey-color" style="font-size:12px">All information regarding your
                                    health</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="card collapse show">
                        <div class="card-body">
                            <div class="card-text text-center pointer" (click)="navigateMenu('home')">
                                <img class="card-img-top img-fluid" style="width:50%"
                                    src="../../../../assets/New Branding Assets/yhyl-for-web-images/lead-icon-my-home.png"
                                    alt="Card image cap" />
                                <p class="green-color mb-1 mt-2 bold-font">MY HOME</p>
                                <span class="grey-color" style="font-size:12px">Practical Information for your
                                    home</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="card collapse show">
                        <div class="card-body">
                            <div class="card-text text-center pointer" (click)="navigateMenu('friends')">
                                <img class="card-img-top img-fluid" style="width:50%"
                                    src="../../../../assets/New Branding Assets/yhyl-for-web-images/lead-icon-my-friends-family.png"
                                    alt="Card image cap" />
                                <p class="l-blue-color mb-1 mt-2 bold-font">
                                    MY FRIENDS AND FAMILY
                                </p>
                                <span class="grey-color" style="font-size:12px">Stay better connected</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="card collapse show">
                        <div class="card-body">
                            <div class="card-text text-center pointer" (click)="navigateMenu('security')">
                                <img class="card-img-top img-fluid" style="width:50%"
                                    src="../../../../assets/New Branding Assets/yhyl-for-web-images/lead-icon-my-security.png"
                                    alt="Card image cap" />
                                <p class="d-grey-color mb-1 mt-2 bold-font">MY SECURITY</p>
                                <span class="grey-color" style="font-size:12px">Be safe and secure</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="card collapse show">
                        <div class="card-body">
                            <div class="card-text text-center pointer" (click)="navigateMenu('technology')">
                                <img class="card-img-top img-fluid" style="width:50%"
                                    src="../../../../assets/New Branding Assets/yhyl-for-web-images/lead-icon-my-technology.png"
                                    alt="Card image cap" />
                                <p class="d-blue-color mb-1 mt-2 bold-font">MY TECHNOLOGY</p>
                                <span class="grey-color" style="font-size:12px">Keep up with the digital world</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="card collapse show">
                        <div class="card-body">
                            <div class="card-text text-center pointer" (click)="navigateMenu('finance')">
                                <img class="card-img-top img-fluid" style="width:50%"
                                    src="../../../../assets/New Branding Assets/yhyl-for-web-images/lead-icon-my-finance.png"
                                    alt="Card image cap" />
                                <p class="purple-color mb-1 mt-2 bold-font">MY FINANCE</p>
                                <span class="grey-color" style="font-size:12px">Your money management</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="card collapse show">
                        <div class="card-body">
                            <div class="card-text text-center pointer" (click)="navigateMenu('work')">
                                <img class="card-img-top img-fluid" style="width:50%"
                                    src="../../../../assets/New Branding Assets/yhyl-for-web-images/lead-icon-my-work.png"
                                    alt="Card image cap" />
                                <p class="yellow-color mb-1 mt-2 bold-font">MY WORK</p>
                                <span class="grey-color" style="font-size:12px">Productive lives management</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="card collapse show">
                        <div class="card-body">
                            <div class="card-text text-center pointer" (click)="navigateMenu('legacy')">
                                <img class="card-img-top img-fluid" style="width:50%"
                                    src="../../../../assets/New Branding Assets/yhyl-for-web-images/lead-icon-my-legacy.png"
                                    alt="Card image cap" />
                                <p class="purple-color mb-1 mt-2 bold-font ">MY LEGACY</p>
                                <span class="grey-color" style="font-size:12px"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- BEGIN: Customizer-->
<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-xl-block"
    [ngClass]="showUpdates ? 'open' : ''">
    <a class="customizer-close" href="#"><i class="ft-x font-medium-3"></i></a>
    <a class="customizer-toggle primary-yhyl-bg box-shadow-3 btn-hover" (click)="showUpdateToggle()">
        <i class="fa fa-bell h4" theme="light" tooltip="Click to see Updates" placement="left"></i><span
            class="indicator"></span>
    </a>
    <div class="customizer-content p-2">
        <h4 class="border-bottom pb-3 content-header-title font-22 bold-font">
            Hey Test..
        </h4>
        <p class="text-animation">There are 5 new updates for you!</p>
        <p>Last 3 days to renew your service for Travel</p>
        <p>2 new offers on Health Service</p>
    </div>
</div>

<div class="sidenav-overlay"></div>
<div class="drag-target"></div>