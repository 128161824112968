import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  emailId: string;
  error: string;
  isSuccess = false;
  isError: boolean = false;
  isLoading_sendLink: boolean = false;
  isSuccessful_sendLink: boolean = false;

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {}

  resetPassword() {
    this.isLoading_sendLink = true;
    this.isSuccess = false;
    const data = {
      email: this.emailId
    };
    this.commonService.sendPasswordLink(data).subscribe(
      response => {
        this.isLoading_sendLink = false;
        this.isSuccess = true;
      },
      (errorResponse: any) => {
        this.isLoading_sendLink = false;

        this.isSuccess = false;
        this.isError = true;

        if (errorResponse.error.email) {
          this.error = errorResponse.error.email[0];
        }
        //On unsuccessful response
      }
    );
  }
  reResetPassword() {
    this.isSuccess = false;
    const data = {
      email: this.emailId
    };
    this.commonService.sendPasswordLink(data).subscribe(
      response => {
        this.isSuccess = true;
      },
      (errorResponse: any) => {
        this.isSuccess = false;
        this.isError = true;

        if (errorResponse.error.email) {
          this.error = errorResponse.error.email[0];
        }
        //On unsuccessful response
      }
    );
  }
}
