<!-- ======= Footer ======= -->
<footer id="footer">
  <div class="footer-top">
    <div class="container">
      <div class="col-12">
        <div class="row">
          <div
            class="col-lg-4 pr-0 col-md-6 col-sm-12 footer-logo"
            style="margin:auto"
          >
            <img
              class="logo-gif"
              src="../../assets/New Branding Assets/Logo Animations/11.gif"
            />
            <img
              class="logo-text"
              src="../../assets/New Branding Assets/Hi Res Logo/Complete/11.png"
            />
          </div>
          <!-- <div class="col-lg-3 pl-0 col-md-6 footer-logi">
                        <a class="pt-0">
                            <img style="margin-top: 30px;
                            width: 55%;
                            height: 75%;" src="../../assets/New Branding Assets/Hi Res Logo/Complete/11.png" />
                        </a>
                    </div> -->
          <div class="col-lg-4 col-md-6 col-sm-12 footer-links mt-sm-5">
            <h4 class="links text-white font-28" style="text-align:left">
              Useful Links
            </h4>
            <ul>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a routerLink="/about">About Us</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a routerLink="/terms-conditions">Terms of service</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a routerLink="/privacy-policy">Privacy policy</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a routerLink="/faqs">FAQs</a>
              </li>
            </ul>
          </div>
          <div
            class="col-lg-4 col-md-12 col-sm-12 footer-newsletter social-links mt-0 mt-sm-5"
          >
            <h4 class="font-28">Subscribe for Free</h4>
            <div class="sub-text-field">

              <input
                type="text"
                
                style="margin-bottom:1.75rem;max-width:230px;border-radius: 
                5px;border:0;padding:4px 10px; outline:none;border-color:white"

                name="name"
                placeholder="Your Name Here"
               
              />
              <input
                type="email"
                [(ngModel)]="email"
                name="email"
                placeholder="Your Email Here"
                (focus)="enableSubscribe()"
              />

              

              <button type="button" (click)="subscribe()">
                <div
                  class="spinner-border"
                  role="status"
                  *ngIf="isLoading_subscribe"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <div *ngIf="!isLoading_subscribe && !isSuccessful_subscribe">
                  Subscribe
                </div>
                <div *ngIf="isSuccessful_subscribe">
                  <i class="fas fa-check"></i>
                </div>
              </button>
            </div>

            <a
              class="twitter shadow"
              href="https://www.linkedin.com/company/your100yearlife/?originalSubdomain=au"
              ><i class="bx bxl-linkedin"></i
            ></a>
            <a
              class="facebook shadow"
              href="https://www.facebook.com/your100yearlife/"
              ><i class="bx bxl-facebook"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container py-2">
    <div class="mr-md-auto text-center">
      <div class="copyright yhyl-text">
        &copy; Copyright <strong><span>Your 100 Year Life</span></strong
        >. All Rights Reserved
      </div>
    </div>
  </div>
  <div class="overlay" style="z-index: 1000;" *ngIf="isSubscribed || isError">
    <div class="popup col-lg-6 col-md-6 col-sm-6">
      <h3>
        <!-- <a class="close" (click)="isRegistered = false; isError = false" -->
        <a class="close" (click)="isSubscribed = false; isError = false"
          >&times;</a
        >

        <div class="clearfix"></div>
      </h3>
      <br />
      <div class="content pb-3">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12" *ngIf="isSubscribed || isError">
                <p class="mt-4 yhyl-text">{{ message }}</p>
                <!-- <p class="my-2">
                                    Please check your email and click on the link to go to the dashboard
                                </p> -->
              </div>
              <!-- <div class="col-md-12" *ngIf="isRegistered">
                                <a class="btn mt-3" routerLink="/login"><button type="button"
                                        class="btn btn-primary-yhyl font-20">Click here to Login</button></a>
                            </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- End Footer -->
