import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import {CommonService} from '../../services/common.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {

  isShowing= false;
  copilotData: any;
  isCopilot= false;
  disableDocs = false;
  disableDiary = false;
  disableCopilots = false;
  disableServices = false;
  copilotLogin: any;
  showSwitchAcc = false;
  isAction = false;
  actionMessage : string;
  accessData : any;
  isInfoActive = false;
  isPageActive = false;
  isDocActive = false;
  isDiaryActive = false;
  isServiceActive = false;
  isCopilotActive = false;
  href= '';
  toggle() {
     this.isShowing = !this.isShowing;
  }
  
  /* callMethods() {
      this.toggleSidenav();
  } */
  constructor(private router: Router,private commonService: CommonService,private authenticationService: AuthService) { }

  ngOnInit(): void {
    this.href = this.router.url;
    if(this.href === '/information') {
      this.isInfoActive = !this.isInfoActive;
      this.isPageActive = false;
      this.isDocActive = false;
      this.isDiaryActive = false;
      this.isServiceActive = false;
      this.isCopilotActive = false;
    } else if(this.href === '/page') {
      this.isPageActive = !this.isPageActive;
      this.isInfoActive = false;
      this.isDocActive = false;
      this.isDiaryActive = false;
      this.isServiceActive = false;
      this.isCopilotActive = false;
    } else if(this.href === '/diary') {
      this.isDiaryActive = !this.isDiaryActive;
      this.isInfoActive = false;
      this.isPageActive = false;
      this.isDocActive = false;
      this.isServiceActive = false;
      this.isCopilotActive = false;
    } else if(this.href === '/copilots') {
      this.isCopilotActive = !this.isCopilotActive;
      this.isInfoActive = false;
      this.isPageActive = false;
      this.isDocActive = false;
      this.isDiaryActive = false;
      this.isServiceActive = false;
    } else if(this.href === '/services') {
      this.isServiceActive = !this.isServiceActive;
      this.isInfoActive = false;
      this.isPageActive = false;
      this.isDocActive = false;
      this.isDiaryActive = false;
      this.isCopilotActive = false;
    } else if(this.href === '/documents') {
      this.isDocActive = !this.isDocActive;
      this.isInfoActive = false;
      this.isPageActive = false;
      this.isDiaryActive = false;
      this.isServiceActive = false;
      this.isCopilotActive = false;
    }
    this.commonService.toggle
    .subscribe((data:string) => {
      if(data === "true"){
        this.isShowing = !this.isShowing;
      }
    });
    /* this.copilotLogin = this.commonService.getCopilotPermissions(); */
    //this.copilotData = this.authenticationService.getCopilotForLogin();
    this.commonService.switchAccount().subscribe((response) =>  {
      this.copilotData=response;
      this.copilotData = response.find(obj => {
        return obj.userid == localStorage.getItem('userId');
      });
      this.accessData = this.commonService.getCopilotPermissions();
    if(this.accessData) {
      if(this.accessData.permission) {
        this.disableCopilots = true;
        this.showSwitchAcc = true;
      }
    }
    
    if(this.copilotData != undefined && (this.accessData.permission != undefined)) {
      this.isCopilot = true;
      if(this.copilotData.Documents === ""){
        this.disableDocs = true;
      }
      if(this.copilotData.Diary === ""){
        this.disableDiary = true;
      }
      if(this.copilotData.Services === ""){
        this.disableServices = true;
      }
    } 
    });
        
  }

  activeMenu(menu) {
    if(menu === 'information') {
      this.isInfoActive = !this.isInfoActive;
      this.isPageActive = false;
      this.isDocActive = false;
      this.isDiaryActive = false;
      this.isServiceActive = false;
      this.isCopilotActive = false;
    } else if(menu === 'page') {
      this.isPageActive = !this.isPageActive;
      this.isInfoActive = false;
      this.isDocActive = false;
      this.isDiaryActive = false;
      this.isServiceActive = false;
      this.isCopilotActive = false;
    } else if(menu === 'diary') {
      this.isDiaryActive = !this.isDiaryActive;
      this.isInfoActive = false;
      this.isPageActive = false;
      this.isDocActive = false;
      this.isServiceActive = false;
      this.isCopilotActive = false;
    } else if(menu === 'copilot') {
      this.isCopilotActive = !this.isCopilotActive;
      this.isInfoActive = false;
      this.isPageActive = false;
      this.isDocActive = false;
      this.isDiaryActive = false;
      this.isServiceActive = false;
    } else if(menu === 'service') {
      this.isServiceActive = !this.isServiceActive;
      this.isInfoActive = false;
      this.isPageActive = false;
      this.isDocActive = false;
      this.isDiaryActive = false;
      this.isCopilotActive = false;
    } else if(menu === 'document') {
      this.isDocActive = !this.isDocActive;
      this.isInfoActive = false;
      this.isPageActive = false;
      this.isDiaryActive = false;
      this.isServiceActive = false;
      this.isCopilotActive = false;
    }
    
  }

  showAccessMessage() {
    this.isAction = true;
    this.actionMessage = this.commonService.getAccessMessage();
}
  routeToMenu(e){
    this.router.navigate([e]);
  }

}
